import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Layout, Table, Typography } from 'antd'

import AdminOperations from './AdminOperations'
import CreateAdminModal from './CreateAdminModal'
import CreateAdminSuccessModal from './CreateAdminSuccessModal'
import DeleteAdminModal from './DeleteAdminModal'
import DeleteAdminSuccessModal from './DeleteAdminSuccessModal'
import EditAdminConfirmModal from './EditAdminConfirmModal'
import EditAdminModal from './EditAdminModal'
import EditAdminSuccessModal from './EditAdminSuccessModal'
import { ReactComponent as CrownIcon } from 'assets/svgs/crown.svg'
import useAdmins from 'hooks/useAdmins'
import { Admin } from 'models/admin'

const { Content } = Layout

export default function AdministratorList() {
  const { t } = useTranslation()
  const { loading, error, admins } = useAdmins()
  const [editAdmin, setEditAdmin] = useState<Admin | null>(null)
  const [deleteAdmin, setDeleteAdmin] = useState<Admin | null>(null)
  const [createAdmin, setCreateAdmin] = useState<boolean>(false)
  const [createAdminSuccess, setCreateAdminSuccess] = useState<boolean>(false)
  const [editAdminSuccess, setEditAdminSuccess] = useState<boolean>(false)
  const [confirmEditAdmin, setConfirmEditAdmin] = useState<boolean>(false)
  const [deleteAdminSuccess, setDeleteAdminSuccess] = useState<boolean>(false)

  const columns = [
    {
      title: t('lable.customerName'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: '18%'
    },
    {
      title: t('lable.gender'),
      dataIndex: 'sex',
      key: 'sex',
      width: '10%',
      render: (sex: string | undefined) => (
        <Typography>{sex ? t(sex) : ''}</Typography>
      )
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      width: '25%',
      render: (role: string, record: Admin) => {
        if (record?.currentOwner) {
          return (
            <Flex className="items-center gap-[5px]">
              <CrownIcon className="inline-block w-[17px]" /> {role}
            </Flex>
          )
        }

        return role
      }
    },
    {
      title: t('affiliation'),
      dataIndex: 'affiliation',
      key: 'affiliation',
      width: '35%',
      render: (text: string) => (
        <Content className="custom-scrollbar">{text}</Content>
      )
    }
    // {
    //   title: '操作',
    //   dataIndex: 'operation',
    //   key: 'operation',
    //   width: '12%',
    //   render: (_: void, record: Admin) => (
    //     <AdminOperations
    //       admin={record}
    //       setEditAdmin={setEditAdmin}
    //       setDeleteAdmin={setDeleteAdmin}
    //     />
    //   )
    // }
  ]

  if (error) {
    //Todo handle show error
  }

  const renderAdminList = () => {
    return (
      <Table
        pagination={false}
        dataSource={admins}
        columns={columns}
        className="mt-4 table-admin pb-10"
        rowKey="key"
        loading={loading}
      />
    )
  }

  return (
    <>
      <Flex className="justify-end">
        <Button
          type="primary"
          className="mt-4 text-white bg-primary py-2 px-8 leading-none invisible"
          onClick={() => setCreateAdmin(true)}
        >
          {t('newAddition')}
        </Button>
      </Flex>
      {renderAdminList()}
      <CreateAdminModal
        isModalOpen={createAdmin}
        onCancel={() => setCreateAdmin(false)}
        onSuccess={() => setCreateAdminSuccess(true)}
      />
      <CreateAdminSuccessModal
        isModalOpen={createAdminSuccess}
        onCancel={() => setCreateAdminSuccess(false)}
      />
      <EditAdminModal
        admin={editAdmin}
        onCancel={() => setEditAdmin(null)}
        onSuccess={() => setEditAdminSuccess(true)}
        onConfirm={() => setConfirmEditAdmin(true)}
      />
      <EditAdminConfirmModal
        isModalOpen={confirmEditAdmin}
        onCancel={() => setConfirmEditAdmin(false)}
        onSuccess={() => setEditAdminSuccess(true)}
      />
      <EditAdminSuccessModal
        isModalOpen={editAdminSuccess}
        onCancel={() => setEditAdminSuccess(false)}
      />
      <DeleteAdminModal
        admin={deleteAdmin}
        onCancel={() => setDeleteAdmin(null)}
        onSuccess={() => setDeleteAdminSuccess(true)}
      />
      <DeleteAdminSuccessModal
        isModalOpen={deleteAdminSuccess}
        onCancel={() => setDeleteAdminSuccess(false)}
      />
    </>
  )
}
