import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery } from '@apollo/client'
import { FILTER_ITEM_CATEGORY } from 'graphql/ItemCategory/filterItemCategory'

const useTestItemCategory = () => {
  const [queryTestItemCategory, { loading, error, data }] =
    useLazyQuery(FILTER_ITEM_CATEGORY)

  const getListItemCategory = async (variables: any) => {
    try {
      const result = await queryTestItemCategory({
        variables: {
          filter: '',
          page: 0,
          size: -1,
          sortBy: '',
          ...variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getListItemCategory,
    data,
    loading,
    error
  }
}

export default useTestItemCategory
