import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import CustomModal from 'components/modals'
import { Admin } from 'models/admin'

const { Paragraph } = Typography

type Props = {
  admin: Admin | null
  onCancel: () => void
  onSuccess: () => void
}

const DeleteAdminModal = ({ admin = null, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //todo
    onSuccess()
    onCancel()
  }

  return (
    <CustomModal
      open={admin ? true : false}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Flex vertical className="text-center pt-4 px-11 pb-5">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('deleteAdmin')}
        </Typography>
        <ExclamationCircleFilled className="mt-[43px] m-auto w-[40px] text-error" />
        <Paragraph className="mt-[23px] mb-0">
          {t('content.deleteAdminConfirm')}
        </Paragraph>
        <Paragraph className="mb-0">{t('content.deleteAdmin')}</Paragraph>
        <Row className="mt-8 gap-3 items-center">
          <Col flex="148px">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
              {t('lable.customerName')}
            </Paragraph>
          </Col>
          <Col>
            <Paragraph className="mb-0">{admin?.fullName}</Paragraph>
          </Col>
        </Row>
        <Row className="mt-1.5 gap-3 items-center">
          <Col flex="148px">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
              {t('lable.mailAddress')} <br />
              <Typography className="text-xs p-0">{t('lable.id')}</Typography>
            </Paragraph>
          </Col>
          <Col>
            <Paragraph className="mb-0">{admin?.email}</Paragraph>
          </Col>
        </Row>
        <Row className="mt-1.5 gap-3 items-center">
          <Col flex="148px">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
              {t('role')}
            </Paragraph>
          </Col>
          <Col>
            <Paragraph className="mb-0 text-wrap text-left">
              {admin?.role}
            </Paragraph>
          </Col>
        </Row>
        <Row className="mt-1.5 gap-3 items-center">
          <Col flex="148px">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
              {t('affiliation')}
            </Paragraph>
          </Col>
          <Col>
            <Paragraph className="mb-0 whitespace-pre-wrap">
              {admin?.affiliation}
            </Paragraph>
          </Col>
        </Row>
        <Flex className="justify-center gap-[12px] mt-[39px]">
          <Button
            type="primary"
            className="w-[180px] font-bold"
            htmlType="submit"
            onClick={handleOk}
          >
            {t('button.delete')}
          </Button>
          <Button
            className="w-[180px] border-[#137695] text-[#137695] font-bold"
            onClick={onCancel}
          >
            {t('button.cancel')}
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  )
}

export default DeleteAdminModal
