import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Input } from 'antd'
import { Col, List, Modal, Row, Typography } from 'antd'

import editIcon from '../../../assets/svgs/EditOutlined.svg'
import saveIcon from '../../../assets/svgs/save.svg'
import SpinLoading from '../../../components/screens/Spin/SpinLoading'
import styles from './JudgmentSettings.module.scss'
import { ReactComponent as FemaleIcon } from 'assets/svgs/female.svg'
import { ReactComponent as MaleIcon } from 'assets/svgs/male.svg'
import { MEDICAL_CHECKUP_MASTER_EXAMPLE } from 'configs/api'
import { GENDER, VALUE_WITH_NO_DATA } from 'configs/constant'
import { useItemCategoryList } from 'hooks/useFilterItem'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import useTestItemMaster from 'hooks/useTestItemMaster'
import { FilterITemCategory } from 'models/filterItemCategory'
import { FilterItemMaster } from 'models/filterItemMaster'
import { getLanguage, handleReferenceValue } from 'utilities/helpers'

const { Paragraph } = Typography

const JudgmentSettings = () => {
  const { t } = useTranslation()
  let language = getLanguage()
  const [idSelected, setIdSelected] = useState<number | null>(null)
  const [idInput, setIdInput] = useState<string | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [loading, setLoading] = useState<any>(false)
  const [filterItemMasterList, setFilterItemMasterList] = useState<
    FilterItemMaster[]
  >([])

  const handleOk = () => {
    setIsEdited(false)
    setIsModalVisible(false)
  }

  const methods = useForm()

  const scrollToElement = (id: any) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const handleSelected = (id: any, className: any) => {
    setIdSelected(id)
    scrollToElement(className)
  }

  const onMouseOut = (id: any) => {
    setIdInput(id)
  }

  const onMouseMove = (id: any) => {
    setIdInput(id)
  }

  const suffix = (
    <img
      src={editIcon}
      className="w-[15] h-[15] cursor-pointer"
      style={{ fontSize: 12 }}
    />
  )

  const suffixAfter = (
    <img
      src={saveIcon}
      className="w-[15] h-[15] cursor-pointer"
      style={{ fontSize: 12 }}
    />
  )

  const judgementItemList = [
    {
      title: '判定区分',
      evaluation: 'A',
      description: t('judgementSetting.noAbnormality')
    },
    {
      title: '',
      evaluation: 'B',
      description: t('judgementSetting.mildAbnormality')
    },
    {
      title: '',
      evaluation: 'C',
      description: t('judgementSetting.examination')
    }
  ]

  const [filterItemCategoryList, setFilterItemCategoryList] = useState<
    FilterITemCategory[]
  >([])

  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { loadItemCategoryList } = useItemCategoryList()

  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()

  useEffect(() => {
    setIdSelected(0)
    const fetchMedicalCheckupMasterById = async () => {
      setLoading(true)
      const dataMedicalCheckupMaster = await getMedicalCheckupMastersById(
        MEDICAL_CHECKUP_MASTER_EXAMPLE
      )

      let categoryOrder =
        dataMedicalCheckupMaster?.medicalCheckupMaster?.additionalInfo
          ?.categoryOrder ?? {}

      const arrItemMasterRefId =
        dataMedicalCheckupMaster?.medicalCheckupMaster
          ?.associatedTestItemMasters ?? []

      // Hard code category to reduce draft data
      const paramsItemCategory: any = {
        // filter: `(in,STRING,associatedTestItemMasterRefIds,${arrItemMasterRefId});(eq,STRING,status,PUBLISHED)`,
        filter: `(in,STRING,refId,13215064603757287001726460393637,88665023344512015941726460435377,83216780091146409801726460484181,66863821368653047771726460525201,45797661172382888381726460564253,50303274715688017811726460592767,35872960772656501091726460641343,6157615577841498081726460663928,75393933929457064601726460690362,15761740032421589841726460728026,88765061765373168031726460770519,24127498591334101701726460803388,50069000893238963771726460828814,48850182716056353011726460846236);(eq,STRING,status,PUBLISHED)`, //hard code
        page: 0,
        size: -1,
        sortBy: '(desc,refId)'
      }
      const itemCategory = await loadItemCategoryList(paramsItemCategory)

      let listRefId: string[] = []
      let filterCategories: FilterITemCategory[] = []

      if (itemCategory) {
        let tempItemCategory = itemCategory.reduce((acc: any, obj: any) => {
          if (!acc[obj.refId] || acc[obj.refId].createdDate < obj.createdDate) {
            acc[obj.refId] = obj
          }
          return acc
        }, {})

        filterCategories = Object.values(tempItemCategory)
        for (let i = 0; i < filterCategories.length; i++) {
          listRefId.push(...filterCategories[i].associatedTestItemMasterRefIds)
        }
      }

      let itemMasterCategory = await getListItemMasterByMultipleLanguages([
        {
          type: 'MATCH',
          criteria: {
            and: [
              {
                field: 'refId',
                operator: 'in',
                value: listRefId
              },
              {
                field: 'additionalInfo.key1',
                operator: 'eq',
                value: 'カテゴリマスタ'
              }
            ]
          }
        }
      ])

      let itemMasterData = await getListItemMasterByMultipleLanguages([
        {
          type: 'MATCH',
          criteria: {
            and: [
              {
                field: 'refId',
                operator: 'in',
                value: arrItemMasterRefId
              },
              {
                field: 'additionalInfo.key1',
                operator: 'eq',
                value: '検査項目マスター'
              }
            ]
          }
        }
      ])

      const filterDisplayData = (dataCategory: any, dataItemMaster: any) => {
        dataCategory = dataCategory.map(function (category: any) {
          let dataCommentCategory = dataItemMaster.filter(function (item: any) {
            return (
              category.associatedTestItemMasterRefIds.includes(item.refId) &&
              item?.additionalInfo?.key1 === 'カテゴリマスタ' &&
              item?.additionalInfo?.key3 !== '所見コメント'
            )
          })

          return {
            ...category,
            lang: dataCommentCategory?.[0]?.lang
          }
        })

        return dataCategory
      }

      const dataItemCategory: FilterITemCategory[] = filterDisplayData(
        filterCategories,
        itemMasterCategory
      )
      if (dataItemCategory) {
        let dataFilterItemCategoryList = []

        for (let category of dataItemCategory) {
          let checkItemMasterCategory: any = getItemMasterCategory(
            category?.associatedTestItemMasterRefIds,
            itemMasterData
          )

          if (checkItemMasterCategory && checkItemMasterCategory.length > 0) {
            dataFilterItemCategoryList.push(category)
          }
        }

        dataFilterItemCategoryList = dataFilterItemCategoryList
          .map((category: any) => {
            return {
              ...category,
              order: categoryOrder?.[category?.refId] ?? 9999
            }
          })
          .sort((a: any, b: any) => a.order - b.order)

        setFilterItemCategoryList(dataFilterItemCategoryList)
      }

      if (itemMasterData) {
        setFilterItemMasterList(itemMasterData)
      }
      setLoading(false)
    }
    fetchMedicalCheckupMasterById()
  }, [])
  const getItemMasterCategory = (
    ItemMasterRefIds: any,
    listItemMaster: any
  ) => {
    const data = []

    for (let itemMaster of listItemMaster) {
      if (
        ItemMasterRefIds.includes(itemMaster?.refId) &&
        itemMaster?.additionalInfo?.key1 === '検査項目マスター'
      ) {
        data.push(itemMaster)
      }
    }
    return data
  }

  const showData = (
    itemMaster: FilterItemMaster,
    judgementItem: any,
    gender: any = null
  ) => {
    let data = itemMaster.referenceValues.filter(function (item: any) {
      if (gender)
        return (
          item.evaluation === judgementItem.evaluation &&
          item?.constraints?.gender.includes(gender)
        )

      return item.evaluation === judgementItem.evaluation
    })

    let readingType = itemMaster.readingType

    if (!data || data.length < 1) {
      return null
    }
    if (readingType == 'STRING')
      return (
        <div className="flex flex-col gap-1">
          {data.map((item: any) => (
            <Paragraph className="font-normal mb-0" key={item.refId}>
              {item?.textValue ?? ''}
            </Paragraph>
          ))}
        </div>
      )

    return (
      <div className="flex flex-col gap-1">
        {data.map((item: any) => (
          <Paragraph className="font-normal mb-0" key={item.refId}>
            {handleReferenceValue(item, t)}
          </Paragraph>
        ))}
      </div>
    )
  }

  const showReferenceValues = (item: any) => {
    let dataConstraints = item.referenceValues.filter((itemValue: any) => {
      return (
        itemValue?.constraints &&
        (itemValue?.constraints?.gender.includes(GENDER.MALE) ||
          itemValue?.constraints?.gender.includes(GENDER.FEMALE))
      )
    })

    return (
      <Flex className="pt-4 pb-2.5 add-border">
        <div className="w-1/5">
          <p>{item.lang?.[language] ?? VALUE_WITH_NO_DATA}</p>
          <p>{item.lang?.en ?? VALUE_WITH_NO_DATA}</p>
        </div>
        <div className="flex-1">
          {dataConstraints.length > 0 ? (
            <>
              <div className="w-full flex">
                <div className="w-1/4 flex">
                  <MaleIcon className="w-[22px] mr-6" />
                  {item.unitOfMeasure !== 'uom' ? item.unitOfMeasure : ''}
                </div>
                {judgementItemList.map((data, index) => (
                  <div className="w-1/4" key={index}>
                    {showData(item, data, GENDER.MALE)}
                  </div>
                ))}
              </div>
              <div className="w-full flex mt-6">
                <div className="w-1/4 flex">
                  <FemaleIcon className="w-[22px] mr-6" />
                  {item.unitOfMeasure !== 'uom' ? item.unitOfMeasure : ''}
                </div>
                {judgementItemList.map((data, index) => (
                  <div className="w-1/4" key={index}>
                    {showData(item, data, GENDER.FEMALE)}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="w-full flex ">
                <div className="w-1/4 pl-[46px]">
                  {item.unitOfMeasure !== 'uom' ? item.unitOfMeasure : ''}
                </div>
                {judgementItemList.map((data, index) => (
                  <div className="w-1/4" key={index}>
                    {showData(item, data)}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Flex>
    )
  }
  return (
    <div className="overflow-x-scroll">
      <FormProvider {...methods}>
        <form>
          <Flex vertical className="pl-6 py-2 pt-4">
            <Flex vertical className="h-[103px]">
              <Row>
                <Col span={3} className="content-between">
                  <Flex vertical className="justify-between h-[103px]">
                    <Typography className="text-[14px] font-bold items-center text-[#137695] tracking-[1.4px]">
                      {t('judgementSetting.judgmentSetting')}
                    </Typography>
                    <div className="cursor-not-allowed w-max invisible">
                      <Button
                        type="primary"
                        className="text-white bg-primary w-[120px] h-[30px] leading-none mb-2 pointer-events-none"
                        autoInsertSpace={false}
                      >
                        {t('button.edit')}
                      </Button>
                    </div>
                  </Flex>
                </Col>
                <Col span={21} className="px-2.5">
                  <Flex className="justify-end px-2 gap-3">
                    {judgementItemList.map((item: any, index) => (
                      <Col className="w-[21.7%]" key={item.refId ?? index}>
                        <Flex
                          vertical
                          className="h-[103px] rounded-lg px-2 justify-center w-full"
                          style={{
                            backgroundColor:
                              item.evaluation === 'A'
                                ? '#5CAF53'
                                : item.evaluation === 'B'
                                  ? '#F5B125'
                                  : '#F5006B'
                          }}
                        >
                          {item.title !== '' ? (
                            <Flex className="h-[30px] items-center">
                              <Typography className="text-white text-sm text-[14px] items-center">
                                {t('judgementSetting.judgmentCategory')}
                              </Typography>
                            </Flex>
                          ) : (
                            <div className="h-[30px]"></div>
                          )}
                          <Flex className="mb-[5px]">
                            <Input
                              className="h-[30px] pl-[25px]"
                              // suffix={suffixAfter} //hidden icon
                              value={item.evaluation}
                            />
                          </Flex>
                          <Flex
                            className="mb-[5px]"
                            onMouseMove={() => onMouseMove(item.evaluation)}
                            onMouseOut={() => onMouseOut('')}
                          >
                            <Input
                              className="h-[30px] pl-[25px]"
                              // suffix={
                              //   idInput === item.evaluation //hidden icon
                              //     ? suffixAfter
                              //     : suffix
                              // }
                              value={item.description}
                            />
                          </Flex>
                        </Flex>
                      </Col>
                    ))}
                  </Flex>
                </Col>
              </Row>
            </Flex>
          </Flex>

          {loading && (
            <div className="flex justify-center items-center">
              <SpinLoading loading={loading} />
            </div>
          )}
          {filterItemMasterList && filterItemCategoryList && (
            <Row>
              <Col span={3}>
                <Flex vertical className="mr-[2px] pl-[10px] h-full">
                  <div className="rounded-custom-tl-br shadow-md bg-[#DCE3EC] flex-1 pt-[6px] pl-[6px]">
                    {filterItemCategoryList.map((item: any, index) => (
                      <List.Item
                        key={item.refId}
                        style={{
                          padding: '8px 16px',
                          backgroundColor:
                            idSelected === index ? '#137695' : '#DCE3EC',
                          color: idSelected === index ? '#FFFFFF' : '#137695',
                          borderRadius:
                            idSelected === index ? '6px 0px 0px 6px' : '',
                          cursor: 'pointer'
                        }}
                        onClick={(e: any) => handleSelected(index, item.refId)}
                      >
                        <Typography
                          className="font-bold text-[14px]"
                          style={{
                            color: idSelected === index ? '#FFFFFF' : '#137695'
                          }}
                        >
                          {item.lang?.[language] ?? VALUE_WITH_NO_DATA}
                        </Typography>
                      </List.Item>
                    ))}
                  </div>
                </Flex>
              </Col>
              <Col span={21}>
                <Flex vertical className="px-2.5 w-full">
                  {filterItemCategoryList.map((data: any, indexCategory) => (
                    <Flex
                      vertical
                      className="bg-[#EFF3F6] mb-[10px]"
                      key={data.refId}
                    >
                      <Col className="px-2">
                        <Flex
                          vertical
                          className={`pt-[18px] pb-[14px]`}
                          id={data.refId}
                        >
                          <Typography className="leading-none min-h-[14px]">
                            {data.lang?.[language] ?? VALUE_WITH_NO_DATA}
                          </Typography>
                          <Typography className="leading-none min-h-[14px] mt-[5px]">
                            {data.lang?.en ?? VALUE_WITH_NO_DATA}
                          </Typography>
                        </Flex>
                      </Col>

                      <Flex vertical className="bg-[#FFFFFF] px-2">
                        {filterItemMasterList.map(
                          (itemMaster, indexItem) =>
                            data.associatedTestItemMasterRefIds.includes(
                              itemMaster.refId
                            ) && showReferenceValues(itemMaster)
                        )}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Col>
            </Row>
          )}

          <Flex>
            <Modal
              title={null}
              open={isModalVisible}
              onOk={handleOk}
              width={600}
              centered
              footer={[
                <div key="footer" className={styles.btn_wrapper}>
                  <Button
                    key="close"
                    onClick={handleOk}
                    style={{
                      width: 180,
                      height: 30,
                      borderColor: '#137695',
                      fontWeight: 'bold',
                      color: '#137695'
                    }}
                  >
                    {t('button.close')}
                  </Button>
                </div>
              ]}
              closable={false}
              className={styles.modal_wrapper}
              bodyStyle={{ textAlign: 'center' }}
            >
              <div className={styles.modal_header}></div>
              <div className={styles.modal_title}>{t('saveComplete')}</div>
              <p className={styles.modal_content}>
                {t('content.settingsSaved')}
              </p>
            </Modal>
          </Flex>
        </form>
      </FormProvider>
    </div>
  )
}

export default JudgmentSettings
