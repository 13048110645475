import { DocumentNode, gql } from '@apollo/client'

export const params = {
  surveyFeedbackRefIds: ['31601660450127697221699264657721']
}

export const DELETE_SURVEY_FEEDBACK: DocumentNode = gql`
  mutation deleteSurveyFeedback($surveyFeedbackRefIds: [String]!) {
    deleteSurveyFeedback(surveyFeedbackRefIds: $surveyFeedbackRefIds) {
      statusCode
      message
      timestamp
      payload {
        isDeleted
        refId
        # reason
      }
    }
  }
`
