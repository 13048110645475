import { API_BACKEND_URL } from 'configs/api'
import networkAdapter from 'utilities/networkAdapter'

const useAutoDiagnostics = () => {
  const handleAutomaticDiagnostic = async (data: any) => {
    try {
      return await networkAdapter
        .postBackend(
          `${API_BACKEND_URL}/judgment-comment`,
          JSON.stringify(data)
        )
        .then((response: any) => {
          return response
        })
    } catch (error) {
      console.log((error as Error).message)
    }
  }

  return {
    handleAutomaticDiagnostic
  }
}

export default useAutoDiagnostics
