import React, { useState } from 'react'

import { STATUS_CODE_SUCCESS } from '../../configs/constant'
import {
  FILTER_SUB_TENANT,
  FILTER_TENANT,
  FIND_BY_CODE
} from '../../graphql/tenant/filter'
import { useLocalStorage } from '../index'
import { useQuery } from '@apollo/client'

export const useSubTenantList = () => {
  const [subTenantCodes, setSubTenantCodes] = useState<string[]>([])
  const [currentTenant] = useLocalStorage('@tenant', {})

  const { loading, data: tenantList } = useQuery(FILTER_SUB_TENANT, {
    variables: {
      ...FIND_BY_CODE,
      filter: `(eq,STRING,code,${currentTenant?.tenant})`
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (!loading && tenantList) {
      const {
        filterTenant: { status, payload }
      } = tenantList

      if (
        status === STATUS_CODE_SUCCESS &&
        payload.length === FIND_BY_CODE.size
      ) {
        const { subTenantCodes } = payload[0]
        const subTenantCode = (subTenantCodes ?? []).map((sub: any) => sub.name)
        setSubTenantCodes(subTenantCode)
      }
    }
  }, [loading, tenantList])

  return {
    subTenantCodes
  }
}

export const useTenantList = () => {
  const [tenant, setTenant] = useState<any>(null)
  const [currentTenant] = useLocalStorage('@tenant', {})

  let tempVariablesFilter = {
    ...FIND_BY_CODE,
    filter: `(eq,STRING,code,${currentTenant?.tenant})`
  }

  const { loading, data } = useQuery(FILTER_TENANT, {
    variables: tempVariablesFilter,
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (!loading && data) {
      const {
        filterTenant: { status, payload }
      } = data

      if (
        status === STATUS_CODE_SUCCESS &&
        payload.length === FIND_BY_CODE.size
      ) {
        setTenant(payload[0])
      }
    }
  }, [loading, data])

  return {
    tenant
  }
}
