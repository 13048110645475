import { DocumentNode, gql } from '@apollo/client'

export const params = {
}

export const UPDATE_COMMENT: DocumentNode = gql`
  mutation updateItemMaster($request:[UpdateItemMaster]){
    updateItemMaster(request:$request){
      status,
      message,
      timestamp
      payload {
        refId
        shortName
        displayName
        code {
          coding {
            system
            code
            display
          }
        }
        unitOfMeasure
        readingType
        referenceValues
        additionalInfo
        status
        createdBy
        createdDate
      }
    }
  }
`
