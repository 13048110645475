// define api endpoint here
import { getEnvVar, loadTenantList } from '../utilities/helpers'

export const API_BASE_URL = getEnvVar('REACT_APP_API_BASE_URL')
export const API_BACKEND_URL = getEnvVar('REACT_APP_API_BACKEND')
export const API_SURVEY_URL = getEnvVar('REACT_APP_API_SURVEY_CORE_URL')
export const API_RESERVATION_URL = getEnvVar('REACT_APP_API_RESERVATION_URL')
export const API_RULE_URL = getEnvVar('REACT_APP_API_RULE_URL')
export const TOKEN_PROVIDER = 'API'
export const KEYCLOAK_URL = getEnvVar('REACT_APP_API_KEYCLOAK_URL')
export const KEYCLOAK_REALM = getEnvVar('REACT_APP_API_KEYCLOAK_REALM')
export const KEYCLOAK_CLIENT_ID: string =
  getEnvVar('REACT_APP_API_KEYCLOAK_CLIENT_ID') ?? ''
export const KEYCLOAK_CLIENT_SECRET: string = ''
export const KEYCLOAK_CLIENT_UUID = getEnvVar(
  'REACT_APP_API_KEYCLOAK_CLIENT_UUID'
)
export const MEDICAL_CHECKUP_MASTER_EXAMPLE: string = getEnvVar(
  'REACT_APP_MEDICAL_CHECKUP_MASTER'
)

export const OVERALL_VERDICT_CATEGORY_EXAMPLE: string = getEnvVar(
  'REACT_APP_OVERALL_VERDICT_CATEGORY'
)
export const API_TENANT_LIST: any[] = loadTenantList()
export const API_CLIENT_CODE = getEnvVar('REACT_APP_API_KEYCLOAK_REALM')

// Sentry
export const SENTRY_DSN = getEnvVar('REACT_APP_SENTRY_DSN')

export const API_LOGIN: string = '/api/user/login'
export const API_LOGIN_CLIENT_TOKEN = '/api/keycloak/client/token'

// Keycloak API
export const REALM_API_TOKEN = `realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`
export const API_USER = `admin/realms/${KEYCLOAK_REALM}/users`
export const ROLE_LIST = `admin/realms/${KEYCLOAK_REALM}/clients/${KEYCLOAK_CLIENT_UUID}/roles`
export const ROLE_MAPPING = `admin/realms/${KEYCLOAK_REALM}/users/USER_ID/role-mappings`
export const ROLE_DETAIL = `admin/realms/${KEYCLOAK_REALM}/roles-by-id`
export const ROLE_UPDATE = `admin/realms/${KEYCLOAK_REALM}/clients/${KEYCLOAK_CLIENT_UUID}/roles`
export const ASSIGNED_GROUP_LIST = `admin/realms/${KEYCLOAK_REALM}/users/USER_ID/groups`
export const GROUP_DETAIL = `admin/realms/${KEYCLOAK_REALM}/groups`
