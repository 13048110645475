import { DocumentNode, gql } from '@apollo/client'

export const params = {
}

export const UPDATE_COMMENT_CATEGORY: DocumentNode = gql`
  mutation updateTestItemCategory($request: [UpdateTestItemCategory]!) {
    updateTestItemCategory(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        shortName
        associatedTestItemMasterRefIds
        createdDate
        createdBy
      }
    }
  }
`