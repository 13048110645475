import { useEffect, useState } from 'react'

import { useLocalStorage } from './index'
import { useLazyQuery } from '@apollo/client'
import { API_USER, KEYCLOAK_CLIENT_ID } from 'configs/api'
import { FILTER_TENANT } from 'graphql/tenant/filter'
import networkAdapter from 'utilities/networkAdapter'

const useAdmins = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [admins, setAdmins] = useState<any>([])
  const [loadTenantList] = useLazyQuery(FILTER_TENANT)
  const [currentTenant] = useLocalStorage('@tenant', {})

  useEffect(() => {
    const handleGetAdmins = async () => {
      try {
        onClearError()
        setLoading(true)
        // TODO call api
        const response = await networkAdapter
          .getClient(API_USER)
          .then((response: any) => {
            return response
          })

        const dataAdmin = await handleDataAdmin(response)
        setAdmins(dataAdmin)
      } catch (error) {
        setError((error as Error).message)
      }

      setLoading(false)
    }

    handleGetAdmins()
  }, [])

  const handleDataAdmin = async (response: any) => {
    const data = []
    const tenant: any = await getTenant({
      filter: `(eq,STRING,code,${currentTenant?.tenant})`
    })

    const tenantName = tenant?.additionalInfo?.name

    for (let i = 0; i < response.length; i++) {
      data.push({
        key: response?.[i]?.id,
        fullName: (
          response?.[i]?.firstName +
          ' ' +
          response?.[i]?.lastName
        ).trim(),
        sex: getDataSex(response?.[i]),
        role: await getRoleByUserId(response?.[i]?.id),
        affiliation: tenantName ?? ''
      })
    }

    return data
  }

  const getDataSex = (data: any) => {
    const sex = data?.attributes?.sex?.[0]
    let result = ''
    if (sex === 'MALE') result = 'lable.male'
    else if (sex === 'FEMALE') result = 'lable.female'

    return result
  }

  const getRoleByUserId = async (id: any) => {
    try {
      const response = await networkAdapter
        .getClient(`${API_USER}/${id}/role-mappings`)
        .then((response: any) => {
          return response
        })

      const mappingRole =
        response?.clientMappings?.[KEYCLOAK_CLIENT_ID ?? '']?.mappings
      if (!mappingRole || !Array.isArray(mappingRole)) return ''

      let data = []
      for (let i = 0; i < mappingRole.length; i++) {
        data.push(mappingRole?.[i]?.description)
      }

      return data.join(',')
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const getAffiliationByUserId = async (id: any) => {
    try {
      const response = await networkAdapter
        .getClient(`${API_USER}/${id}/groups`)
        .then((response: any) => {
          return response
        })

      let data = []
      for (let i = 0; i < response.length; i++) {
        data.push(response?.[i]?.name)
      }

      return data.join(',')
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const getTenant = async (variables: any) => {
    try {
      const response = await loadTenantList({
        variables: {
          filter: '',
          sortBy: '(desc,_id)',
          page: 0,
          size: 1,
          ...variables
        },
        fetchPolicy: 'network-only'
      })
      return response?.data?.filterTenant?.payload?.[0]
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const onClearError = () => {
    setError('')
  }

  return {
    loading,
    error,
    admins,
    onClearError
  }
}

export default useAdmins
