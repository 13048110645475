import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Flags from 'react-world-flags'

import { Flex, Select, Typography } from 'antd'

import { Country, countries } from '../country'
import { CaretDownOutlined } from '@ant-design/icons'

type Props = {
  name: string
  placeholder?: string
  className?: string
}

const SelectCountry: FC<Props> = ({ name, placeholder, className }) => {
  const { control } = useFormContext()

  const options = countries.map((country: Country) => ({
    label: (
      <Flex align="center">
        <Flags
          code={country.iso2}
          style={{
            marginRight: '11px',
            height: '17px',
            width: '26px',
            border: '0.1px solid #E9E9E9'
          }}
        />
        <Typography className="text-sm text-[#545454]">
          {country.name}
        </Typography>
      </Flex>
    ),
    value: country.value,
    key: country.iso2
  }))

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          options={options}
          suffixIcon={
            <CaretDownOutlined
              style={{ color: '#137695', pointerEvents: 'none' }}
            />
          }
          {...field}
          placeholder={placeholder}
          defaultValue={''}
          className={className}
        />
      )}
    />
  )
}

export default SelectCountry
