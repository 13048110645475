import { useState } from 'react'

import {
  DEFAULT_SPECIFIED_DAY_TIMES,
  WORK_SCHEDULE_TYPE
} from '../models/setting'
import {
  groupDelete,
  groupDetail,
  groupUpdate,
  groupsList,
  subgroupCreate
} from '../services/KeycloakService'
import {
  getAttr,
  getAttributes,
  isArrayEmpty,
  json2str,
  str2json
} from '../utilities/helpers'
import { useRolePermissions } from './role-permission/usePermissions'
import { useBetween } from 'use-between'

const prepareSpecifiedDayTimes = (days: any) => {
  if (days === '') {
    return DEFAULT_SPECIFIED_DAY_TIMES
  }
  // FIXME: handle other values

  return days.sort((a: any, b: any) => a.order - b.order)
}

const useSharedGroupModal = () => {
  const [isModeEdit, setIsModeEdit] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  )

  return {
    isModeEdit,
    setIsModeEdit,
    showDeleteModal,
    setShowDeleteModal,
    showEditModal,
    setShowEditModal,
    selectedIndex,
    setSelectedIndex
  }
}

export const useGroupModal = () => useBetween(useSharedGroupModal)

const useUserGroup = () => {
  const { currentUserId } = useRolePermissions()
  const [groupId, setGroupId] = useState<string>('')
  const [group, setGroup] = useState<any>()

  const getGroup = async () => {
    // use user id to call api get groups
    const groups = await groupsList(currentUserId)
    if (isArrayEmpty(groups)) {
      return null
    }

    setGroupId(groups[0].id)

    // use groupId to get group detail
    const group = await groupDetail(groups[0].id)
    setGroup(group)

    const { subGroups, attributes } = group
    const workScheduleType = getAttributes(attributes, 'workScheduleType')
    let fixedDayTimes: any = []
    if (workScheduleType === WORK_SCHEDULE_TYPE.fixedDay) {
      fixedDayTimes = str2json(getAttributes(attributes, 'fixedDayTimes'))
    }

    let _specifiedDayTimes: any = DEFAULT_SPECIFIED_DAY_TIMES
    if (workScheduleType === WORK_SCHEDULE_TYPE.specifiedDays) {
      _specifiedDayTimes = prepareSpecifiedDayTimes(
        getAttr(attributes, 'specifiedDayTimes').map((day: any) =>
          str2json(day)
        )
      )

      const specifiedBiweekly: any = {}
      getAttr(attributes, 'specifiedBiweekly')
        .map((d: any) => str2json(d))
        .map((day: any) => {
          const wd = !isArrayEmpty(Object.keys(day)) ? Object.keys(day)[0] : ''
          specifiedBiweekly[wd] = day[wd].map((time: any) => {
            const { t, a, w, s, e } = time
            return { typeDay: t, active: Boolean(a), week: w, start: s, end: e }
          })
        })

      _specifiedDayTimes.map((day: any) => {
        const { workday } = day
        if (specifiedBiweekly[workday]) {
          day.biweekly = specifiedBiweekly[workday]
        }

        return day
      })
    }

    subGroups.map((sub: any) => {
      const { attributes } = sub
      sub.phoneNumber = getAttributes(attributes, 'phoneNumber') ?? ''
      sub.note = getAttributes(attributes, 'note') ?? ''
      sub.key = sub.id
      return sub
    })

    const groupFormData: any = {
      subGroups: subGroups,
      name: getAttributes(attributes, 'name'),
      kanaName: getAttributes(attributes, 'kanaName'),
      clinicName: getAttributes(attributes, 'clinicName'),
      clinicKanaName: getAttributes(attributes, 'clinicKanaName'),
      zipCodePart1: getAttributes(attributes, 'zipCode').substring(0, 3),
      zipCodePart2: getAttributes(attributes, 'zipCode').substring(3),
      province: getAttributes(attributes, 'province'),
      district: getAttributes(attributes, 'district'),
      address: getAttributes(attributes, 'address'),
      workScheduleType: workScheduleType,
      workdays: getAttributes(attributes, 'workdays').split(','),
      fixedDayStartTime: fixedDayTimes?.start ?? '',
      fixedDayEndTime: fixedDayTimes?.end ?? '',
      specifiedDayTimes: _specifiedDayTimes
    }

    return groupFormData
  }

  const updateGroup = async (groupId: string, data: any) => {
    const { attributes, subGroups } = data

    const { specifiedDayTimes: _specifiedDayTimes } = attributes

    let fixedDayTimes: any = []
    if (attributes.workScheduleType === WORK_SCHEDULE_TYPE.fixedDay) {
      fixedDayTimes = [
        json2str({
          start: attributes.fixedDayStartTime,
          end: attributes.fixedDayEndTime
        })
      ]
    }

    let specifiedBiweekly: any = []
    let specifiedDayTimes: any = []
    if (attributes.workScheduleType === WORK_SCHEDULE_TYPE.specifiedDays) {
      specifiedBiweekly = [..._specifiedDayTimes].map((day: any) => {
        const { workday, biweekly } = day
        if (biweekly && !isArrayEmpty(biweekly)) {
          const bw: any = {}
          bw[workday] = biweekly.map((wk: any) => {
            const { typeDay: t, active: a, week: w, start: s, end: e } = wk
            return { t, a: Number(Boolean(a)), w, s, e }
          })
          return json2str(bw)
        }
        return null
      })

      specifiedDayTimes = _specifiedDayTimes.map((day: any) => {
        delete day.id
        delete day.biweekly
        return json2str(day)
      })
    }

    const attributesUpdate: any = {
      name: [attributes.name],
      kanaName: [attributes.kanaName],
      clinicName: [attributes.clinicName],
      clinicKanaName: [attributes.clinicKanaName],
      zipCode: [attributes.zipCodePart1 + attributes.zipCodePart2],
      province: [attributes.province],
      district: [attributes.district],
      address: [attributes.address],
      workScheduleType: [attributes.workScheduleType],
      workdays: [attributes.workdays.join(',')],
      fixedDayTimes: fixedDayTimes,
      specifiedBiweekly: specifiedBiweekly.filter((d: any) => d),
      specifiedDayTimes: specifiedDayTimes
    }

    if (subGroups)
      subGroups.map((s: any) => {
        s.attributes.phoneNumber = [s.phoneNumber]
        s.attributes.note = [s.note]
        delete s.phoneNumber
        delete s.note
        delete s.key
        return s
      })

    const groupData = { ...data, attributes: attributesUpdate, subGroups }
    // console.log({ groupData })

    return await groupUpdate(groupId, groupData)
  }

  const deleteGroup = async (groupId: string) => {
    await groupDelete(groupId)
  }

  const createSubgroup = async (groupId: string, data: any) => {
    console.log('create children groupId', groupId)
    return await subgroupCreate(groupId, data)
  }

  const updateSubGroup = async (groupId: string, group: any) => {
    const { phoneNumber, note } = group
    delete group.phoneNumber
    delete group.note
    delete group.key

    return await groupUpdate(groupId, {
      ...group,
      attributes: { phoneNumber: [phoneNumber], note: [note] }
    })
  }

  return {
    groupId,
    group,
    getGroup,
    createSubgroup,
    updateGroup,
    deleteGroup,
    updateSubGroup
  }
}

export default useUserGroup
