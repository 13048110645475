import { Outlet } from 'react-router-dom'

import styles from './PublicLayout.module.scss'

interface Props {
  children?: React.ReactNode
}

export default function PublicLayout({ children }: Props) {
  return (
    <div className={styles.container}>
      {children}
      <Outlet />
    </div>
  )
}
