import { DocumentNode, gql } from '@apollo/client'

export const POST_RULE_LOGICAL: DocumentNode = gql`
  mutation PostRuleLogicMutation($sourceCode: String = "") {
    postRuleLogic(params: { sourceCode: $sourceCode }) {
      message
      status
      timestamp
      payload {
        refId
        sourceCode
      }
    }
  }
`

export const EVALUATE_MUTATION: DocumentNode = gql`
  mutation EvaluateLogicMutation($frg: Float!, $HbA1c: Float!) {
    evaluateLogic(params: { frg: $frg, HbA1c: $HbA1c }) {
      message
      status
      timestamp
      payload {
        evaluate
      }
    }
  }
`
