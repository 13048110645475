import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { TimePickerForm } from '../../../elements/TimePickerForm'
import SpecificDays from './SpecificDays'
import { WORK_SCHEDULE_TYPE } from 'models/setting'

const TimeSetting = () => {
  const { control, watch } = useFormContext()
  const { t } = useTranslation()

  const { fields: specifiedDayTimes, update: specifiedDayUpdate } =
    useFieldArray({
      control,
      name: `specifiedDayTimes`
    })

  const workScheduleType = watch('workScheduleType')
  const workdays = watch('workdays')

  React.useEffect(() => {
    if (workScheduleType === WORK_SCHEDULE_TYPE.specifiedDays) {
      const removedDays: any[] = specifiedDayTimes
        .filter((d: any) => d.active)
        .filter((x: any) => !workdays.includes(x.workday))

      for (const workday of removedDays) {
        const { order: index } = workday
        specifiedDayUpdate(index, { ...workday, active: false })
      }

      for (const workday of workdays) {
        const index = specifiedDayTimes.findIndex(
          (x: any) => workday === x.workday
        )
        const prevWorkday: any = specifiedDayTimes[index]
        if (prevWorkday && !prevWorkday.active) {
          specifiedDayUpdate(index, { ...prevWorkday, active: true })
        }
      }
    }
  }, [workScheduleType, workdays])

  if (workScheduleType === WORK_SCHEDULE_TYPE.specifiedDays) {
    return (
      <Flex vertical gap={8}>
        {specifiedDayTimes
          .filter((d: any) => d.active)
          .map((workday: any) => {
            const fieldName: string = `specifiedDayTimes.${workday.order}`
            return (
              <SpecificDays
                key={workday.order}
                fieldName={fieldName}
                workday={workday}
              />
            )
          })}
      </Flex>
    )
  }

  return (
    <Flex>
      <TimePickerForm
        name="fixedDayStartTime"
        className="w-[10rem]"
        format="HH:mm"
        showSecond={false}
        minuteStep={30}
        showNow={false}
        needConfirm={false}
        placeholder={t('placeholder.selectTime')}
      />

      <Typography className="self-center mx-[10px]">～</Typography>

      <TimePickerForm
        name="fixedDayEndTime"
        className="w-[10rem] mr-[10px]"
        format="HH:mm"
        showSecond={false}
        minuteStep={30}
        showNow={false}
        needConfirm={false}
        placeholder={t('placeholder.selectTime')}
      />
    </Flex>
  )
}

export default TimeSetting
