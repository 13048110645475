import React from 'react'
import { CodeBlock } from 'react-code-blocks'

import { Flex, Typography } from 'antd'

import { useRuleFunc } from '../../hooks/rule-logical/useRuleLogical'

export const FunctionReader = () => {
  const { ruleFunc } = useRuleFunc()

  return (
    <Flex
      vertical
      className="size-full bg-white rounded-[5px] shadow p-[10px] gap-[8px] mb-5"
      style={{
        boxShadow: '0px 3px 5px #00000029'
      }}
    >
      <Typography className="font-bold text-[16px]">Function Viewer</Typography>

      <Flex vertical gap={16} className="justify-center items-center">
        <div className="border size-full text-[18px]">
          <CodeBlock
            text={ruleFunc.sourceCode}
            language="python"
            showLineNumbers
          />
        </div>
      </Flex>
    </Flex>
  )
}
