import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery } from '@apollo/client'
import { FILTER_MEDICAL_CHECKUP_MASTER } from 'graphql/MedicalCheckupMaster/filterMedicalCheckupMaster'

const useGetMedicalCheckupMasterDetails = () => {
  const [loadMedicalCheckupMasterList, { loading, data, error }] = useLazyQuery(
    FILTER_MEDICAL_CHECKUP_MASTER
  )

  const getMedicalCheckupMastersById = async (id: string) => {
    try {
      const result = await loadMedicalCheckupMasterList({
        variables: {
          filter: `(eq,STRING,refId,${id})`,
          page: 0,
          size: 1
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result?.data?.filterMedicalCheckupMasterDetails?.payload?.[0]
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getMedicalCheckupMastersById,
    data,
    loading,
    error
  }
}

const useFirstMedicalCheckupMaster = () => {
  const [loadMedicalCheckupMasterList, { loading, data, error }] = useLazyQuery(
    FILTER_MEDICAL_CHECKUP_MASTER
  )

  const getFirstMedicalCheckupMaster = async () => {
    try {
      const result = await loadMedicalCheckupMasterList({
        variables: {
          filter: '',
          page: 0,
          size: 1
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result?.data?.filterMedicalCheckupMasterDetails?.payload?.[0]
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getFirstMedicalCheckupMaster,
    data,
    loading,
    error
  }
}

const useMedicalCheckupMaster = () => {
  const [loadMedicalCheckupMasterList, { loading, data, error }] = useLazyQuery(
    FILTER_MEDICAL_CHECKUP_MASTER
  )

  const getMedicalCheckupMaster = async (variables: any) => {
    try {
      const result = await loadMedicalCheckupMasterList({
        variables: {
          filter: '',
          page: 0,
          size: -1,
          ...variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result?.data?.filterMedicalCheckupMasterDetails?.payload
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getMedicalCheckupMaster,
    data,
    loading,
    error
  }
}

export { useGetMedicalCheckupMasterDetails, useFirstMedicalCheckupMaster,useMedicalCheckupMaster }
