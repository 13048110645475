import { DocumentNode, gql } from '@apollo/client'

export interface VariableProps {
  filter: string
  sortBy?: string
  size?: number
  page?: number
}

export const FIND_BY_CODE: VariableProps = {
  filter: '(eq,STRING,code,)',
  sortBy: '(desc,_id)',
  page: 0,
  size: 1
}

export const FILTER_SUB_TENANT: DocumentNode = gql`
  query filterTenant($filter: String!, $page: Int!, $size: Int!) {
    filterTenant(page: $page, size: $size, filter: $filter) {
      status
      message
      timestamp
      totalPages
      currentPageNumber
      totalElements
      payload {
        subTenantCodes
      }
    }
  }
`

export const FILTER_TENANT: DocumentNode = gql`
  query filterTenant(
    $filter: String!
    $sortBy: String
    $page: Int!
    $size: Int!
  ) {
    filterTenant(page: $page, size: $size, filter: $filter, sortBy: $sortBy) {
      status
      message
      timestamp
      totalPages
      currentPageNumber
      totalElements
      payload {
        refId
        code
        name
        notes
        phoneNumbers
        additionalInfo
        subTenantCodes
      }
    }
  }
`
