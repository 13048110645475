import { DocumentNode, gql } from '@apollo/client'

export interface VariableProps {
  filter: string
  sortBy?: string
  paginationInput?: paginationInput
}

export interface paginationInput {
  page: number
  size: number
}

export interface PaginationInput {
  page: number
  size: number
}

export const FILTER_ALL: VariableProps = {
  filter: ''
}

export const FILTER_SURVEY_FEEDBACK: DocumentNode = gql`
  query filterSurveyFeedback(
    $filter: String!
    $sortBy: String
    $paginationInput: PaginationInput
  ) {
    filterSurveyFeedback(
      filter: $filter
      sortBy: $sortBy
      paginationInput: $paginationInput
    ) {
      statusCode
      message
      timestamp
      totalElements
      payload {
        refId
        userRefId
        startedDateTime
        endedDateTime
        feedbackStatus
        surveyRefId
        selectedAnswerList {
          refId
          displayNameMap {
            en
            ja
          }
          options {
            refId
            displayNameMap {
              en
              ja
            }
          }
          question {
            refId
            order
            subsectionType
            status
            questionType
            displayNameMap {
              en
              ja
            }
            isRequired
            options {
              refId
              order
              status
              no
              displayNameMap {
                en
                ja
              }
            }
          }
          selectedDate
          selectedTime
          value
        }
      }
    }
  }
`

export const FILTER_SURVEY_FEEDBACK_ALL: DocumentNode = gql`
  query filterSurveyFeedback($filter: String!, $sortBy: String) {
    filterSurveyFeedback(
      filter: $filter
      sortBy: $sortBy
      paginationInput: { page: 0, size: 100 }
    ) {
      statusCode
      message
      timestamp
      totalElements
      payload {
        refId
        userRefId
        startedDateTime
        endedDateTime
        feedbackStatus
        surveyRefId
        selectedAnswerList {
          refId
          displayNameMap {
            en
            ja
          }
          options {
            refId
            displayNameMap {
              en
              ja
            }
          }
          question {
            refId
            order
            subsectionType
            status
            questionType
            displayNameMap {
              en
              ja
            }
            isRequired
            setting {
              refId
              status
              no
              displayNameMap {
                en
                ja
              }
              minLength
              maxLength
              fromDate
              toDate
              fromTime
              toTime
              maxSizeInKB
              noOfFiles
              startPoint
              endPoint
              slice
              scaleType
              isMultiple
              choiceType
              startLabel
              endLabel
              format
            }
            options {
              refId
              order
              status
              no
              displayNameMap {
                en
                ja
              }
            }
            statements {
              refId
              order
              status
              no
              displayNameMap {
                en
                ja
              }
            }
          }
          selectedDate
          selectedTime
          mediaFileList {
            refId
            name
            url
            mediaType
            status
            file
          }
          value
          statementResult {
            refId
            statementRefId
            optionRefId
          }
        }
      }
    }
  }
`

export const INTERVIEW_FILTER_SURVEY_FEEDBACK: DocumentNode = gql`
  query filterSurveyFeedback(
    $filter: String!
    $sortBy: String
    $paginationInput: PaginationInput
  ) {
    filterSurveyFeedback(
      filter: $filter
      sortBy: $sortBy
      paginationInput: $paginationInput
    ) {
      statusCode
      message
      timestamp
      totalElements
      payload {
        refId
        userRefId
        # endedDateTime
        feedbackStatus
        surveyRefId
        createdDateTime
      }
    }
  }
`

export const CONSULTANT_MANAGEMENT_FILTER_SURVEY_FEEDBACK: DocumentNode = gql`
  query filterSurveyFeedback(
    $filter: String!
    $sortBy: String
    $paginationInput: PaginationInput
  ) {
    filterSurveyFeedback(
      filter: $filter
      sortBy: $sortBy
      paginationInput: $paginationInput
    ) {
      statusCode
      message
      timestamp
      totalElements
      payload {
        createdDateTime
        refId
        userRefId
        startedDateTime
        endedDateTime
        feedbackStatus
        surveyRefId
        selectedAnswerList {
          refId
          displayNameMap {
            en
            ja
          }
          # options
          question {
            refId
            order
            subsectionType
            status
            questionType
            displayNameMap {
              en
              ja
            }
            isRequired
            setting {
              refId
              status
              no
              displayNameMap {
                en
                ja
              }
              minLength
              maxLength
              fromDate
              toDate
              fromTime
              toTime
              maxSizeInKB
              noOfFiles
              startPoint
              endPoint
              slice
              scaleType
              isMultiple
              choiceType
              startLabel
              endLabel
              format
            }
            options {
              refId
              order
              status
              no
              displayNameMap {
                en
                ja
              }
            }
            statements {
              refId
              order
              status
              no
              displayNameMap {
                en
                ja
              }
            }
          }
          selectedDate
          selectedTime
          mediaFileList {
            refId
            name
            url
            mediaType
            status
            file
          }
          value
          statementResult {
            refId
            statementRefId
            optionRefId
          }
        }
      }
    }
  }
`

export const CONSULTANT_MANAGEMENT_SURVEY_FEEDBACK: DocumentNode = gql`
  query filterSurveyFeedback(
    $filter: String!
    $sortBy: String
    $paginationInput: PaginationInput
  ) {
    filterSurveyFeedback(
      filter: $filter
      sortBy: $sortBy
      paginationInput: $paginationInput
    ) {
      statusCode
      message
      timestamp
      totalElements
      payload {
        createdDateTime
        refId
        userRefId
        startedDateTime
        endedDateTime
        feedbackStatus
        surveyRefId
      }
    }
  }
`
