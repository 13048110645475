import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Alert, Button, Col, Flex, Row, Typography } from 'antd'

import CreateSucceededModal from '../../components/modals/CreateSucceededModal'
import {
  FreeQuestionItemConfirmCard,
  QuestionItemConfirmCard
} from '../../components/screens/Questionnaires'
import UpdateConfirmModal from '../../components/screens/SurveyCreateUpdate/UpdateConfirmModal'
import { StatusEnums, SubsectionTypeEnums } from '../../models/survey'
import './SurveyCreateConfirmation.css'
import { ExclamationCircleFilled } from '@ant-design/icons'

type Props = { formData: any; goBack: any; onSubmit: any; isUpdate?: boolean }

export default function SurveyCreateConfirmation({
  formData,
  goBack,
  isUpdate = false,
  onSubmit
}: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const {
    departments,
    title,
    displayCategories,
    fixedSection,
    displayEnglish,
    sectionList
  } = formData

  const handleSubmit = async () => {
    const response = await onSubmit()
    if (response) {
      setIsModalVisible(true)
      setConfirmDialogVisible(false)
    }
  }

  const handleConfirm = async () => {
    setConfirmDialogVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  return (
    <Flex vertical gap="middle" className="questionnaire-creation-confirm">
      <CreateSucceededModal
        title={t('questionnaire.label.createSucceededTitle')}
        body={t('questionnaire.label.createSucceededMessage')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          navigate('/survey-list')
        }}
        button={t('button.close')}
      />
      <UpdateConfirmModal
        open={confirmDialogVisible}
        onCancel={() => setConfirmDialogVisible(false)}
        onConfirm={() => handleSubmit()}
      />
      <Flex gap="middle" className="items-center">
        <Typography.Text
          className="mr-12 text-[16px]"
          strong
          style={{ color: '#545454' }}
        >
          {t('questionnaire.create')}
        </Typography.Text>
        <Typography.Text>
          {t('questionnaire.helperText.createConfirm')}
        </Typography.Text>
      </Flex>

      <Flex vertical gap="middle" className="ml-3">
        <Flex gap="middle">
          <Alert
            message={t('questionnaire.helperText.message')}
            type="error"
            showIcon
            className="font-bold"
            style={{
              color: '#DF2475',
              backgroundColor: '#FFF2F7',
              borderColor: '#DF2475',
              minWidth: '35%'
            }}
            icon={
              <ExclamationCircleFilled
                style={{ fontSize: '14px', color: '#DF2475' }}
              />
            }
          />
        </Flex>
        <Flex vertical gap="small">
          <Row>
            <Col span={12}>
              <Flex gap="middle" justify="flex-start" align="center">
                <Col span={5} className="justify-end flex">
                  <Typography.Text className="text-default">
                    {t('questionnaire.departmentSelect')}
                  </Typography.Text>
                </Col>
                <Col span={19}>
                  <Typography.Text className="text-default">
                    {departments.filter((d: any) => d !== '').join('、')}
                  </Typography.Text>
                </Col>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Flex gap="middle" justify="flex-start" align="center">
                <Col span={5} className="justify-end flex">
                  <Typography.Text className="text-default">
                    {t('questionnaire.title')}
                  </Typography.Text>
                </Col>
                <Col span={19}>
                  <Typography.Text className="text-default">
                    {title}
                  </Typography.Text>
                </Col>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Flex gap="middle" justify="flex-start" align="center">
                <Col span={5} className="justify-end flex">
                  <Typography.Text className="text-default">
                    {t('questionnaire.label.questionCategories')}
                  </Typography.Text>
                </Col>
                <Col span={19}>
                  <Typography.Text className="text-default">
                    {Boolean(displayCategories) ? 'ON' : 'OFF'}
                  </Typography.Text>
                </Col>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Flex gap="middle" justify="flex-start" align="center">
                <Col span={5} className="justify-end flex">
                  <Typography.Text className="text-default">
                    {t('questionnaire.label.englishDisplay')}
                  </Typography.Text>
                </Col>
                <Col span={19}>
                  <Typography.Text className="text-default">
                    {Boolean(displayEnglish) ? t('display') : t('hidden')}
                  </Typography.Text>
                </Col>
              </Flex>
            </Col>
          </Row>
        </Flex>
      </Flex>

      <Flex vertical className="bg-white p-[12px] rounded-r-lg shadow">
        <Flex gap="small" vertical className="bg-[#EFF3F6] p-[8px] gap-[0px]">
          <Typography.Text strong className="text-primary">
            {t('questionnaire.label.fixedList2')}
          </Typography.Text>
        </Flex>

        <Flex vertical className="mt-3 p-3" gap={8}>
          {fixedSection &&
            fixedSection.subsectionList &&
            fixedSection.subsectionList
              .filter((s: any) => s.status === StatusEnums.active)
              .map((subsection: any, index: number) => (
                <QuestionItemConfirmCard
                  question={subsection}
                  index={index}
                  key={index}
                  displayEnglish={displayEnglish}
                />
              ))}
        </Flex>

        <Flex
          gap="small"
          vertical
          className="bg-[#EFF3F6] p-2 my-2.5 gap-[0px]"
        >
          <Typography.Text strong className="text-primary">
            {t('questionnaire.label.freeQuestion')}
          </Typography.Text>
        </Flex>

        {sectionList.map((section: any, index: number) => (
          <Flex key={index} vertical gap="small" className="section-item my-3">
            {displayCategories ? (
              <Flex
                gap="small"
                vertical
                className="bg-[#137695] gap-[0px] section-title p-3"
              >
                <Typography.Text strong className="text-[#fff]">
                  {Boolean(displayEnglish) ? section.title.en : ''}
                </Typography.Text>
                <Typography.Text strong className="text-[#fff]">
                  {section.title.ja}
                </Typography.Text>
              </Flex>
            ) : null}

            <Flex vertical className="p-3 pt-0">
              {section.subsectionList.map((question: any, idx: number) => {
                const { subsectionType } = question

                if (subsectionType === SubsectionTypeEnums.question) {
                  return (
                    <FreeQuestionItemConfirmCard
                      question={question}
                      index={idx}
                      key={idx}
                      displayEnglish={Boolean(displayEnglish)}
                    />
                  )
                } else {
                  // TODO render other type
                  return null
                }
              })}
            </Flex>
          </Flex>
        ))}

        <Flex gap="middle" className="my-3.5" justify="center" align="center">
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            onClick={() => (isUpdate ? handleConfirm() : handleSubmit())}
            className="shadow-none font-bold w-[180px]"
            autoInsertSpace={false}
          >
            {t('keepButton')}
          </Button>
          <Button
            type="primary"
            ghost
            size="middle"
            className="shadow-none font-bold w-[180px]"
            autoInsertSpace={false}
            onClick={() => goBack()}
          >
            {t('button.fixes')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
