import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Select, Typography } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'

type Props = {
  options: any[]
  fieldName: string
  required: boolean
  question?: any
}

export const DropdownChoiceForm = ({ required, fieldName, options }: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: required,
        validate: (value: string[], _: any) => {
          return required ? value.filter((v) => v !== '').length > 0 : true
        }
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...restField } = field
        return (
          <Select
            allowClear
            className="w-[15em] flex-wrap gap-2.5 flex"
            onChange={(value: string) => onChange([value])}
            value={value && value.length > 0 ? value[0] : ''}
            size="middle"
            status={error ? 'error' : ''}
            suffixIcon={
              <CaretDownOutlined
                color="#137695"
                className="text-[#137695] text-[14px]"
                style={{ pointerEvents: 'none' }}
              />
            }
            {...restField}
          >
            {options.map((option: any, idx: number) => (
              <Select.Option key={idx} value={option.refId} className="mr-0">
                <Typography className="font-bold tracking-[0.7px]">
                  {option?.displayNameMap?.ja}
                </Typography>
              </Select.Option>
            ))}
          </Select>
        )
      }}
    />
  )
}
