import { DocumentNode, gql } from '@apollo/client'

export const UPDATE_TEST_RESULT: DocumentNode = gql`
  mutation updateTestResult($request: [UpdateTestResult!]!) {
    updateTestResult(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        medicalCheckupRefId
        medicalCheckupUserRefId
        itemMasterRefId
        value
        valueBy
        deviceRefId
        readingTakenOn
        additionalInfo
        evaluation
        createdBy
        createdDate
      }
    }
  }
`
