import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Typography } from 'antd'
import i18n from 'i18next'
import * as yup from 'yup'

import { useRoles } from '../../../hooks/useRoles'
import SelectCountry from './SelectCountry'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm } from 'components/elements/InputForm'
import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onSuccess: () => void
}

export type InputFormType = {
  name: string
  country: string
}

export const schema = yup
  .object()
  .shape({
    name: yup.string().min(1).max(30).required(),
    country: yup.string().default('')
  })
  .required()

const AddRoleModel = ({ isModalOpen = false, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation('')
  const { onAddRole } = useRoles()
  const currentLocale = i18n.language

  const methods = useForm<InputFormType>({
    defaultValues: {
      name: '',
      country: currentLocale
    },
    resolver: yupResolver(schema)
  })

  const { handleSubmit, reset } = methods

  const onSubmit = (data: InputFormType) => {
    onAddRole({ ...data }).then()

    reset({}, { keepDefaultValues: true })

    onSuccess()
    onCancel()
  }

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel}>
      <Flex
        vertical
        className="px-11 pt-4 pb-5 text-center text-wrap add-role-modal"
      >
        <Typography className="text-xl font-bold">{t('addNewRole')}</Typography>
        <Paragraph className="mt-6  mb-0">
          <Trans i18nKey="content.addNewRole" />
        </Paragraph>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex vertical className="mt-14" gap="middle">
              <Row gutter={12}>
                <Col flex="100px" className="p-0 h-8 leading-none">
                  <InputLabel label={t('language')} />
                </Col>
                <Col flex="auto">
                  <SelectCountry name="country" className="h-[30px] w-full" />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col flex="100px" className="p-0 h-8 leading-none">
                  <InputLabel label={t('lable.roleName')} />
                </Col>
                <Col flex="auto">
                  <InputForm
                    name="name"
                    className="w-full"
                    placeholder={t('placeholder.enterRoleName')}
                  />
                </Col>
              </Row>
            </Flex>
            <Flex className="justify-center mt-[52px]" gap={12}>
              <Button
                className="w-[180px] text-white bg-primary py-2 px-8 leading-none font-bold "
                type="primary"
                autoInsertSpace={false}
                htmlType="submit"
              >
                {t('button.registration')}
              </Button>
              <Button
                className="w-[180px] text-primary bg-white py-2 px-12 leading-none border-[#137695] border-solid border-1 font-bold"
                onClick={onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </CustomModal>
  )
}

export default AddRoleModel
