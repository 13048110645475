import { DocumentNode, gql } from '@apollo/client'

interface VariableProps {
  page: number
  size: number
  filter: string
}

export const FILTER_ALL: VariableProps = {
  page: 0,
  size: -1,
  filter: ''
}

export const FILTER_TEST_RESULT: DocumentNode = gql`
  query filterTestResult(
    $page: Int
    $size: Int
    $filter: String
    $sortBy: String
  ) {
    filterTestResult(
      page: $page
      size: $size
      filter: $filter
      sortBy: $sortBy
    ) {
      status
      message
      timestamp
      totalPages
      currentPageNumber
      totalElements
      payload {
        refId
        medicalCheckupRefId
        medicalCheckupUserRefId
        itemMasterRefId
        value
        valueBy
        deviceRefId
        readingTakenOn
        readingLocation
        additionalInfo
        evaluation
      }
    }
  }
`

export const FILTER_TEST_RESULT_CONSULTANT_MANAGEMENT: DocumentNode = gql`
  query filterTestResult(
    $page: Int
    $size: Int
    $filter: String
    $sortBy: String
  ) {
    filterTestResult(
      page: $page
      size: $size
      filter: $filter
      sortBy: $sortBy
    ) {
      status
      message
      timestamp
      payload {
        refId
        medicalCheckupRefId
        medicalCheckupUserRefId
        readingTakenOn
        createdDate
      }
    }
  }
`
