import { DocumentNode, gql } from '@apollo/client'

export interface variableProps {
  filter: string
  paginationInput?: paginationInput
}

export interface paginationInput {
  page: number
  size: number
}

export const FILTER_ALL: variableProps = {
  filter: ''
}

export const FILTER_TEST_ITEM_MASTER: DocumentNode = gql`
  query filterTestItemMaster(
    $filter: String!
    $page: Int!
    $size: Int!
    ){
    filterTestItemMaster(page: $page, size: $size, filter: $filter) {
      status
      message
      timestamp
      totalElements
      payload {
        refId
        shortName
        displayName
        unitOfMeasure
        readingType
        referenceValues
        additionalInfo
        createdDate
        createdBy
      }
    }
  }
`
