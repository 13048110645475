import { useEffect, useState } from 'react'
import {
  FILTER_TEST_ITEM_CATEGORY,
  variableProps
} from '../graphql/CommentSetting/filter'
import { commentSettingList } from '../models/commentSetting'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Endpoint } from 'utilities/apolloClient'
import { UPDATE_COMMENT } from 'graphql/TestItemMaster/updateComment'
import { CREATE_COMMENT } from 'graphql/TestItemMaster/createComment'
import { UPDATE_COMMENT_CATEGORY } from 'graphql/TestItemMaster/updateCommentCategory'

const variablesInitFilter: variableProps = {
  filter: '',
  paginationInput: { page: 0, size: -1 }
}

const useCommentSetting = () => {
  const [commentList, setCommentSettingList] = useState<
    commentSettingList[]
  >([])

  const [variablesFilter, setVariablesFilter] = useState<variableProps>(variablesInitFilter)

  const [loadCommentSettingList, { called, loading, data }] = useLazyQuery(
    FILTER_TEST_ITEM_CATEGORY,
    {
      variables: variablesFilter,
      context: { version: Endpoint.CHECKUP_CORE },
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first' // Used for subsequent executions
    }
  )

  useEffect(() => {
    if (!loading && data && data.hasOwnProperty('filterCommentSetting')) {
      const {
        filterCommentSetting: { payload }
      } = data

      const commentSettingData: commentSettingList[] = []
      for (let comment of payload) {
        commentSettingData.push({
          data: comment
        })
      }

      setCommentSettingList(commentSettingData)
    }
  }, [loading])

  const [updateCommentSettingMutation] = useMutation(UPDATE_COMMENT, {
    context: { version: Endpoint.CHECKUP_CORE }
  })

  const updateCommentSetting = async (data: any) => {
    const variables = {
      request: data
    }

    try {
      const response = await updateCommentSettingMutation({
        variables: variables
      })

      const { data: { updateItemMaster: { status, payload } } } = response
      if (status === 'S0000') {
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  const [createCommentSettingMutation] = useMutation(CREATE_COMMENT, {
    context: { version: Endpoint.CHECKUP_CORE }
  })

  const createCommentSetting = async (data: any) => {
    const variables = {
      request: data
    }

    try {
      const response = await createCommentSettingMutation({
        variables: variables
      })

      const { data: { addItemMaster: { status, payload } } } = response
      if (status === 'S0000') {
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  const [updateCommentCategoryMutation] = useMutation(UPDATE_COMMENT_CATEGORY, {
    context: { version: Endpoint.CHECKUP_CORE }
  })

  const updateTestItemCategory = async (data: any) => {
    const variables = {
      request: data
    }

    try {
      const response = await updateCommentCategoryMutation({
        variables: variables
      })

      const { data: { updateTestItemCategory: { status, payload } } } = response
      if (status === 'S0000') {
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    commentList,
    variablesFilter,
    setVariablesFilter,
    setCommentSettingList,
    loadCommentSettingList,
    updateCommentSetting,
    createCommentSetting,
    updateTestItemCategory,
    loading
  }
}

export default useCommentSetting
