import React, { FC } from 'react'

import { Flex, Typography } from 'antd'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

import { COUNTRY } from '../../../../models/setting'
import { ReactComponent as ArrowBottom } from 'assets/svgs/arrow-bottom.svg'
import { ReactComponent as Reload } from 'assets/svgs/reload.svg'
import { InputLabel } from 'components/elements/InputLabel'
import { CustomerDetail } from 'hooks/useCustomer'
import {
  createProvinceList,
  findProvinceLabel,
  formatInternationalPhone
} from 'utilities/helpers'

type Props = {
  t: TFunction<'translation', undefined>
  data: CustomerDetail | null
}

const CustomerDetailInformationForm: FC<Props> = ({ t, data }) => {
  const provinceList: { value: string; label: string }[] = createProvinceList(t)
  const province = findProvinceLabel(data?.province, provinceList)

  return (
    <div>
      {/*widget*/}
      <Flex className="mb-[34px] mt-2 w-full">
        <Typography className="mr-[50px] text-[16px] font-bold tracking-[1.6px] text-[#137695]">
          {t('customerDetail.basicInformation')}
        </Typography>
        <ArrowBottom
          width="16px"
          height="16px"
          className="mr-[15px] mt-[6px] cursor-pointer"
        />
        <Reload
          width="16px"
          height="16px"
          className="mr-[15px] mt-[4px] cursor-pointer"
        />
        <Typography className="font-medium tracking-[1.4px] underline">
          {dayjs().format(t('formatDate'))}
        </Typography>
      </Flex>

      {/*form*/}
      <div>
        {/*1 + 2*/}
        <div className="mb-3 grid grid-cols-4">
          <Flex className="items-center">
            <InputLabel
              label={t('customerDetail.form.label.firstName')}
              className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
            />
            <Typography className="ml-3 flex h-[30px] w-[200px] items-center tracking-[1.4px]">
              {data?.firstName}
            </Typography>
          </Flex>

          <Flex className="items-center">
            <InputLabel
              label={t('customerDetail.form.label.lastName')}
              className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
            />
            <Typography className="ml-3 flex h-[30px] w-[200px] items-center tracking-[1.4px]">
              {data?.lastName}
            </Typography>
          </Flex>
        </div>

        {/*3 + 4*/}
        <div className="mb-3 grid grid-cols-4">
          <Flex className="items-center">
            <InputLabel
              label={t('customerDetail.form.label.firstNameKana')}
              className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
            />
            <Typography className="ml-3 flex h-[30px] w-[200px] items-center tracking-[1.4px]">
              {data?.firstNameKana}
            </Typography>
          </Flex>

          <Flex className="items-center">
            <InputLabel
              label={t('customerDetail.form.label.lastNameKana')}
              className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
            />
            <Typography className="ml-3 flex h-[30px] w-[200px] items-center tracking-[1.4px]">
              {data?.lastNameKana}
            </Typography>
          </Flex>
        </div>

        {/*5*/}
        <Flex className="mb-3 items-center">
          <InputLabel
            label={t('customerDetail.form.label.dateOfBirth')}
            className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
          />
          <Typography className="ml-3 flex h-[30px] w-[400px] items-center tracking-[1.4px]">
            {`${dayjs(data?.birthday, 'YYYY-MM-DD').format('YYYY/MM/DD')}`}
          </Typography>
        </Flex>

        {/*6*/}
        <Flex className="mb-3 items-center">
          <Flex
            justify="space-between"
            align="center"
            className="h-auto w-[168px] font-bold bg-[#F0F3F7] px-[13px] self-stretch"
          >
            <Typography className="text-[14px] tracking-[1.4px] text-[#545454]">
              {t('customerRegister.label.address')}
            </Typography>
          </Flex>

          <div className="ml-3">
            {data?.country === COUNTRY.JAPAN && (
              <div className="mb-[7px]">
                <Typography>
                  {t(`basicSetting.country.${data?.country}`)}
                </Typography>

                <Typography className="mt-[7x]">{data.zipCode}</Typography>
              </div>
            )}

            <Typography>{province}</Typography>

            <Typography className="mt-[7px]">{data?.district}</Typography>

            <Typography className="mt-[7px]">{data?.address}</Typography>

            <Typography className="mt-[7px]">{data?.apartment}</Typography>

            {data?.country === COUNTRY.VIETNAM && (
              <div className="mt-[7px]">
                <Typography>{data?.stateName}</Typography>

                <Typography className="mt-[7px]">{data?.zipCode}</Typography>
              </div>
            )}
          </div>
        </Flex>

        {/*7*/}
        <Flex className="mb-3 items-center">
          <InputLabel
            label={t('customerDetail.form.label.phone')}
            className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
          />
          <Typography className="ml-3 flex h-[30px] w-[400px] items-center tracking-[1.4px]">
            {formatInternationalPhone(data?.dialCodePhone, data?.phone)}
          </Typography>
        </Flex>

        {/*8*/}
        <Flex className="mb-3 items-center">
          <InputLabel
            label={t('customerDetail.form.label.emergencyContact')}
            className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
          />
          <Typography className="ml-3 flex h-[30px] w-[400px] items-center tracking-[1.4px]">
            {formatInternationalPhone(
              data?.dialCodeEmergencyContact,
              data?.emergencyContact
            )}
          </Typography>
        </Flex>

        {/*9*/}
        <Flex className="mb-3 items-center">
          <InputLabel
            label={t('customerDetail.form.label.email')}
            className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
          />
          <Typography className="ml-3 flex h-[30px] w-[400px] items-center tracking-[1.4px]">
            {data?.email}
          </Typography>
        </Flex>

        {/*10*/}
        <Flex className="mb-3 items-center">
          <InputLabel
            label={t('customerDetail.form.label.typeOfInsurance')}
            className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
          />
          <Typography className="ml-3 flex h-[30px] w-[400px] items-center tracking-[1.4px]">
            {data?.typeOfInsurance}
          </Typography>
        </Flex>

        {/*11 + 12*/}
        <div className="mb-3 grid grid-cols-3">
          <Flex className="items-center">
            <InputLabel
              label={t('customerDetail.form.label.numberOfInsurance')}
              className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
            />
            <Typography className="ml-3 flex h-[30px] w-[200px] items-center tracking-[1.4px]">
              {data?.numberOfInsurance}
            </Typography>
          </Flex>

          <Flex className="items-center">
            <InputLabel
              label={t('customerDetail.form.label.symbolOrNumber')}
              className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
            />
            <Typography className="ml-3 flex h-[30px] w-[200px] items-center tracking-[1.4px]">
              {data?.insuranceSymbol && data?.insuranceNumber
                ? `${data?.insuranceSymbol} / ${data?.insuranceNumber}`
                : data?.insuranceSymbol
                  ? `${data?.insuranceSymbol}`
                  : data?.insuranceNumber
                    ? `${data?.insuranceNumber}`
                    : ''}
            </Typography>
          </Flex>
        </div>

        {/*13 + 14*/}
        <div className="mb-3 grid grid-cols-3">
          <Flex className="items-center">
            <InputLabel
              label={t('customerDetail.form.label.startDate')}
              className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
            />
            <Typography className="ml-3 flex h-[30px] w-[200px] items-center tracking-[1.4px]">
              {data?.insuranceStartDate}
            </Typography>
          </Flex>

          <Flex className="items-center">
            <InputLabel
              label={t('customerDetail.form.label.endDate')}
              className="flex h-[36px] w-[160px] items-center px-[7px] font-bold tracking-[1.4px]"
            />
            <Typography className="ml-3 flex h-[30px] w-[200px] items-center tracking-[1.4px]">
              {data?.insuranceEndDate}
            </Typography>
          </Flex>
        </div>

        {/*15*/}
        <Flex className="items-center">
          <InputLabel
            label={t('customerDetail.form.label.applicationStartDate')}
            className="flex h-auto w-[160px] items-center px-[7px] font-bold tracking-[1.4px] self-stretch"
          />
          <Typography className="ml-3 flex h-[30px] w-[400px] items-center tracking-[1.4px]">
            {data?.applicationStartDate}
          </Typography>
        </Flex>
      </div>
    </div>
  )
}

export default CustomerDetailInformationForm
