import {
  atom,
  selector,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil'

import { KEYCLOAK_CLIENT_ID } from '../../configs/api'
import { roleDetail, roleMapping } from '../../services/KeycloakService'
import { isLoggedIn, sleep } from '../../utilities/helpers'

const localStorageEffect =
  (key: string) =>
  // @ts-ignore
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

const currentUserIdState = atom({
  key: 'CurrentUserId',
  default: '',
  effects: [localStorageEffect('current_user_id')]
})

const fetchAssignedRolePermissionEffect = async (userId: string) => {
  if (!userId) return []
  if (!isLoggedIn()) return []

  await sleep(500)
  const response = await roleMapping(userId)
  const { clientMappings } = response
  if (!clientMappings) return []

  const { mappings } = clientMappings[KEYCLOAK_CLIENT_ID]

  let _permissionKeys: any[] = []
  for (const role of mappings) {
    const res = await roleDetail(role.id)
    const {
      attributes: { permissions }
    } = res
    const _permissions = permissions ?? []
    _permissionKeys = [..._permissionKeys, ..._permissions]
  }

  return _permissionKeys
}

const permissionListQuery = selector({
  key: 'PermissionList',
  get: async ({ get }) => {
    return await fetchAssignedRolePermissionEffect(get(currentUserIdState))
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent'
  }
})

export const useRolePermissions = () => {
  const resetUserId = useResetRecoilState(currentUserIdState)
  const setUserIdState = useSetRecoilState(currentUserIdState)
  const permissionList = useRecoilValue(permissionListQuery)
  const currentUserId = useRecoilValue(currentUserIdState)
  // console.log({ permissionList })

  const hasPermissions = (permission: any): boolean => {
    if (!permission || permission === '') return true
    return permissionList.includes(permission)
  }

  return {
    currentUserId,
    permissionList,
    hasPermissions,
    setUserIdState,
    resetUserId
  }
}
