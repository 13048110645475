import { DocumentNode, gql } from '@apollo/client'

export const params = {
  request: [
    {
      medicalCheckupRefId: '9f6daf3e-d191-47d8-98c6-adc1e6a6274d',
      medicalCheckupUserRefId: '9f6daf3e-d191-47d8-98c6-adc1e6a628gf',
      itemMasterRefId: 'STEP_CO6628f73aca434a7f4ae9ce05UNT',
      value: 75,
      valueBy: 'Hospital X',
      deviceRefId: '9f6daf3e-d191-47d8-98c6-adc1e6a6274d',
      readingTakenOn: '2023-10-21',
      readingLocation: 'Knight-Industries',
      additionalInfo: {
        key1: {
          Object1: 'Object1'
        },
        key2: {
          Object2: 'Object2'
        }
      },
      evaluation: 'HIGH',
      visibility: {
        clientCode: 'YAKULT',
        tenantCodes: ['admin'],
        subTenantCodes: ['XX', 'YY']
      }
    },
    {
      medicalCheckupRefId: '5f6daf3e-d191-47d8-98c6-adc1e6a6274d',
      medicalCheckupUserRefId: '9f6daf3e-d191-47d8-98c6-adc1e6a628gf',
      itemMasterRefId: 'STEP_CO6628f73aca434a7f4ae9ce05UNT',
      value: 100,
      valueBy: 'Huracio Pagani',
      deviceRefId: '9f6daf3e-d191-47d8-98c6-adc1e6a6274d',
      readingTakenOn: '2023-10-21',
      readingLocation: 'SPA',
      additionalInfo: {
        key1: {
          Object1: 'Object1'
        },
        key2: {
          Object2: 'Object2'
        }
      },
      evaluation: 'HIGH'
    }
  ]
}

export const CREATE_TEST_RESULT: DocumentNode = gql`
  mutation addTestResult($request: [CreateTestResult!]!) {
    addTestResult(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        medicalCheckupRefId
        medicalCheckupUserRefId
        itemMasterRefId
        value
        valueBy
        deviceRefId
        readingTakenOn
        additionalInfo
        evaluation
      }
    }
  }
`
