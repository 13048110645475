import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, Flex } from 'antd'

import { SizeType } from 'antd/es/config-provider/SizeContext'

type Props = {
  className?: string
  defaultValue?: string
  name: string
  options: {
    label: string
    value: number | string
  }[]
  size?: SizeType
}

export const CheckboxGroupForm = ({
  className = '',
  name,
  options = []
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Checkbox.Group className={className} {...field}>
          <Flex gap={20}>
            {options.map((option: any, index: number) => (
              <Checkbox key={index} value={option.value}>
                {t(option.label)}
              </Checkbox>
            ))}
          </Flex>
        </Checkbox.Group>
      )}
    />
  )
}
