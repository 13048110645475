import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'

import {
  Button,
  Flex,
  Select,
  Table,
  TableColumnsType,
  Tag,
  Tooltip,
  Typography
} from 'antd'

import { ConsultantResult } from '../../../models/consultantResult'
import './CustomTable.css'
import { CaretDownOutlined } from '@ant-design/icons'
import { ReactComponent as EditOutlined } from 'assets/svgs/EditOutlined.svg'
import { ReactComponent as ArrowTopBottom } from 'assets/svgs/arrow-top-bottom.svg'
import { PaginationCustom } from 'components/elements/PaginationCustom/PaginationCustom'
import {
  CONSULTANT_SORT_TYPE,
  FEEDBACK_LIST_TOTAL,
  FEEDBACK_STATUS,
  VALUE_WITH_NO_DATA
} from 'configs/constant'
import useSurveyFeedback, {
  useCreateSurveyFeedback
} from 'hooks/useSurveyFeedback'
import useSurveyForFeedback from 'hooks/useSurveyForFeedback'
import { TAB } from 'models/customer'
import { getListIDCustomer } from 'utilities/common'

const optionsPerPage = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
  { value: 500, label: '500' }
]

export const ConsultantResultList = ({
  data,
  setShowHealthCheck,
  setSurveyData,
  onChangePerPage,
  onChangePage,
  onGoToLastPage,
  onGoToFirstPage,
  totalElement,
  pageSize,
  currentPage,
  loading,
  toggleSortWithUser,
  changeStatusSurveyFeedBack,
  totalByType,
  status
}: {
  data: ConsultantResult[]
  setShowHealthCheck: Function
  onChangePerPage: Function
  onChangePage: Function
  onGoToLastPage: Function
  onGoToFirstPage: Function
  setSurveyData: Function
  totalElement: number
  pageSize: number
  currentPage: number
  loading: boolean
  toggleSortWithUser: Function
  changeStatusSurveyFeedBack: Function
  totalByType: any
  status: any
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { control } = useFormContext()

  const [surveyFeedback, setSurveyFeedback] = useState<any>(null)
  const [survey, setSurvey] = useState<any>(null)
  const [patientId, setPatientId] = useState<any>(null)
  const [patientName, setPatientName] = useState<any>(null)

  const { variablesFilter: filterSurvey, loadSurveyList } =
    useSurveyForFeedback()
  const { variablesFilter: filterSurveyFeedback, loadSurveyFeedbackList } =
    useSurveyFeedback()

  const { parseFormFeedbackSurvey } = useCreateSurveyFeedback()

  useEffect(() => {
    if (survey && surveyFeedback) {
      const dataShow = parseFormFeedbackSurvey(survey, surveyFeedback, true)
      dataShow.patientId = patientId
      dataShow.patientName = patientName
      setSurveyData(dataShow)
    }
  }, [survey, surveyFeedback, patientId, patientName])

  const onClickShowResult = async (
    feedBackId: string,
    patientId: string,
    patientName: string
  ) => {
    setShowHealthCheck(true)

    const variablesFilterSurveyFeedback = {
      ...filterSurveyFeedback,
      filter: `(eq,STRING,refId,${feedBackId})`,
      paginationInput: { page: 0, size: 1 }
    }
    const feedbacks = await loadSurveyFeedbackList({
      variables: variablesFilterSurveyFeedback
    })
    const feedback = feedbacks.data.filterSurveyFeedback.payload[0]
    setSurveyFeedback(feedback)

    const variablesFilterSurvey = {
      ...filterSurvey,
      filter: `(eq,STRING,refId,${feedback.surveyRefId})`,
      paginationInput: { page: 0, size: 1 }
    }
    const listSurvey = await loadSurveyList({
      variables: variablesFilterSurvey
    })
    const survey = listSurvey.data.filterSurvey.payload[0]
    setSurvey(survey)

    setPatientId(patientId)
    const indexOfOpenParen = patientName.indexOf('(')
    setPatientName(
      indexOfOpenParen !== -1
        ? patientName.substring(0, indexOfOpenParen).trim()
        : patientName
    )
  }

  const onClickShowCustomerDetail = (dataItem: ConsultantResult) => {
    const idIndexUser = data.findIndex((element) => element === dataItem)
    if (idIndexUser !== -1) {
      navigate(
        `/customer-detail?id=${dataItem.id}&currentTab=${TAB.INTERVIEW_LIST}`,
        {
          state: {
            customer: { data },
            openListResultTab: TAB.INTERVIEW_LIST,
            location: { ids: getListIDCustomer(data), index: idIndexUser }
          }
        }
      )
    }
  }

  const onClickEdit = (data: ConsultantResult) => {
    navigate({
      pathname: '/interview-result-create',
      search: createSearchParams({
        t: '2',
        userRefId: data.id,
        surveyFeedbackRefId: data.surveyFeedbackRefId
      }).toString()
    })
  }

  const InterviewResultColumns: TableColumnsType<ConsultantResult> = [
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">ID</span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.USER_ID)
            }}
          />
        </Flex>
      ),
      dataIndex: 'id',
      width: '24%',
      render: (text) => (
        <Typography className="h-[22px] whitespace-nowrap pl-3">
          {text}
        </Typography>
      )
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">
            {t('lable.customerName')} {t('lable.Kana')}
          </span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.USER_NAME)
            }}
          />
        </Flex>
      ),
      dataIndex: 'name',
      width: '19%',
      render: (_, record) => (
        <div className="px-3 w-full text-ellipsis whitespace-nowrap overflow-hidden ">
          <Typography.Text
            onClick={() => onClickShowCustomerDetail(record)}
            strong
            underline
            className="text-primary cursor-pointer"
          >
            {record.name}
          </Typography.Text>
        </div>
      )
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.dateOfBirth')}</span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.USER_DATE_OF_BIRTH)
            }}
          />
        </Flex>
      ),
      ellipsis: true,
      width: '7%',
      align: 'center',
      dataIndex: 'dateOfBirth'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.age')}</span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.USER_AGE)
            }}
          />
        </Flex>
      ),
      dataIndex: 'age',
      align: 'center',
      width: '6%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.gender')}</span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.USER_GENDER)
            }}
          />
        </Flex>
      ),
      dataIndex: 'gender',
      align: 'center',
      width: '6%',
      render: (gender) => t(gender)
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.consultantTitle')}</span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_TITLE)
            }}
          />
        </Flex>
      ),
      dataIndex: 'title',
      align: 'center',
      width: '14%',
      render: (_, record) => (
        <Typography
          className={`${record.title ? 'cursor-pointer' : ''} text-primary font-bold underline`}
          onClick={() => {
            if (record.title)
              onClickShowResult(
                record.surveyFeedbackRefId,
                record.id,
                record.fullName
              )
          }}
        >
          {record.title}
        </Typography>
      )
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.examDate')}</span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_EXAM_DATE)
            }}
          />
        </Flex>
      ),
      dataIndex: 'examDate',
      align: 'center',
      width: '7%'
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.status')}</span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_STATUS)
            }}
          />
        </Flex>
      ),
      dataIndex: 'status',
      align: 'center',
      width: '7%',
      render: (_, record) => {
        let colorStatus = '#BDCBD5'
        if (record.statusEnum === FEEDBACK_STATUS.ASSIGNED)
          colorStatus = '#137695'
        if (record.statusEnum === FEEDBACK_STATUS.COMPLETED)
          colorStatus = '#545454'

        return (
          <Tag
            color={colorStatus}
            className="rounded-2xl text-center h-[26px] min-w-[103px] flex items-center justify-center mx-auto"
          >
            {t(record.status)}
          </Tag>
        )
      }
    },
    {
      title: (
        <Flex className="justify-center items-center">
          <span className="font-medium mr-4">{t('lable.createdAt')}</span>
          <ArrowTopBottom
            className="cursor-pointer"
            onClick={() => {
              toggleSortWithUser(CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_CREATE_AT)
            }}
          />
        </Flex>
      ),
      width: '7%',
      align: 'center',
      dataIndex: 'createdAt'
    },
    // {
    //   title: (
    //     <Flex className="justify-center items-center">
    //       <span className="font-medium mr-4">{t('lable.modifiedAt')}</span>
    //       <ArrowTopBottom className="cursor-pointer" onClick={() => {}} />
    //     </Flex>
    //   ),
    //   dataIndex: 'modifiedAt',
    //   width: '9%',
    //   align: 'center'
    //   // sorter: (a, b) => (a.modifiedAt).localeCompare(b.modifiedAt)
    // },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: '3%',
      render: (_, record) => (
        <Tooltip title={t('lable.edit')}>
          <EditOutlined
            onClick={() => onClickEdit(record)}
            className="m-auto cursor-pointer w-[22px] h-[22px]"
          />
        </Tooltip>
      )
    }
  ]

  return (
    <div>
      <Flex
        vertical
        className="bg-white rounded-[5px] shadow p-[10px] pt-1 gap-1.5 mb-5"
        style={{
          boxShadow: '0px 3px 5px #00000029'
        }}
      >
        <Flex justify="space-between">
          <Flex align="center" gap={10}>
            <Button
              type="text"
              onClick={() => {
                changeStatusSurveyFeedBack('')
              }}
            >
              <Typography.Text
                strong={!status ? true : false}
                underline
                style={{
                  color: !status ? '#137695' : '#545454'
                }}
              >
                {t('lable.showAll')}(
                {totalByType?.[FEEDBACK_LIST_TOTAL.ALL] ?? 0})
              </Typography.Text>
            </Button>

            <Button
              type="text"
              onClick={() => {
                changeStatusSurveyFeedBack(VALUE_WITH_NO_DATA)
              }}
            >
              <Typography.Text
                underline
                strong={status === VALUE_WITH_NO_DATA ? true : false}
                style={{
                  color: status === VALUE_WITH_NO_DATA ? '#137695' : '#545454'
                }}
              >
                {t('lable.unanswered')}(
                {totalByType?.[FEEDBACK_LIST_TOTAL.UNANSWERED] ?? 0})
              </Typography.Text>
            </Button>

            <Button
              type="text"
              onClick={() => {
                changeStatusSurveyFeedBack(FEEDBACK_STATUS.ASSIGNED)
              }}
            >
              <Typography.Text
                underline
                strong={status === FEEDBACK_STATUS.ASSIGNED ? true : false}
                style={{
                  color:
                    status === FEEDBACK_STATUS.ASSIGNED ? '#137695' : '#545454'
                }}
              >
                {t('lable.waitingAnswer')}(
                {totalByType?.[FEEDBACK_LIST_TOTAL.ASSIGNED] ?? 0})
              </Typography.Text>
            </Button>
            <Button
              type="text"
              onClick={() => {
                changeStatusSurveyFeedBack(FEEDBACK_STATUS.COMPLETED)
              }}
            >
              <Typography.Text
                underline
                strong={status === FEEDBACK_STATUS.COMPLETED ? true : false}
                style={{
                  color:
                    status === FEEDBACK_STATUS.COMPLETED ? '#137695' : '#545454'
                }}
              >
                {t('lable.answered')}(
                {totalByType?.[FEEDBACK_LIST_TOTAL.COMPLETED] ?? 0})
              </Typography.Text>
            </Button>
          </Flex>
          <Flex justify="flex-end" align="center">
            <Typography.Text className="float-right mr-2 text-[12px]">
              {t('lable.displayedResultNumber')}
            </Typography.Text>

            <Controller
              name="perPage"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  status={error ? 'error' : ''}
                  suffixIcon={
                    <CaretDownOutlined
                      color="#137695"
                      className="text-[#137695] text-[14px]"
                      style={{ pointerEvents: 'none' }}
                    />
                  }
                  {...field}
                  onChange={(value) => {
                    field.onChange(value)
                    onChangePerPage()
                  }}
                >
                  {optionsPerPage.map((option) => (
                    <Select.Option value={option.value} key={option.value}>
                      <Typography className="mr-1">
                        {option.label + t('lable.ken')}
                      </Typography>
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Flex>
        </Flex>
        <Flex vertical gap={16}>
          <Table
            size="small"
            columns={InterviewResultColumns}
            dataSource={data}
            showSorterTooltip={{ target: 'sorter-icon' }}
            pagination={false}
            className="consultant-management-table"
            scroll={{
              y: 'calc(100vh - 115px)'
            }}
            loading={loading}
          />
        </Flex>
      </Flex>
      {!loading ? (
        <PaginationCustom
          onChangePage={onChangePage}
          currentPage={currentPage}
          total={totalElement}
          onGoToFirstPage={onGoToFirstPage}
          onGoToLastPage={onGoToLastPage}
          pageSize={pageSize}
        />
      ) : (
        <div></div>
      )}
    </div>
  )
}
