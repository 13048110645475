import { useTranslation } from 'react-i18next'

import { Checkbox, Col, Flex, Modal, Row, Typography } from 'antd'

import {
  Document,
  Font,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { StatusEnums } from 'models/survey'

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  surveyFeedbackList?: any
}

// Create styles PDF
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20,
    fontFamily: ['YuGothicUI', 'Roboto']
  },
  headerTitle: {
    color: '#707070',
    paddingLeft: 10,
    fontSize: 12,
    fontWeight: 'bold'
  },
  header: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: '#93D1CA',
    color: '#FFFFFF',
    padding: 10,
    fontSize: 12,
    marginTop: 8,
    fontWeight: 'bold'
  },
  content: {
    paddingTop: 20
  },
  contentSurvey: {
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 10
  },
  categoryTitle: {
    backgroundColor: '#007096',
    color: '#FFFFFF',
    marginBottom: 10,
    paddingLeft: 10,
    paddingBottom: 5,
    paddingTop: 5,
    fontWeight: 'bold',
    flexDirection: 'column'
  },
  subsection: {
    marginBottom: 10
  },
  question: {
    color: '#545454',
    backgroundColor: '#F0F3F7',
    padding: 4,
    paddingLeft: 10,
    flexDirection: 'column'
  },
  questionJa: {
    fontWeight: 'bold'
  },
  questionEn: {
    paddingTop: 4
  },
  answer: {
    color: '#545454',
    paddingLeft: 10,
    paddingTop: 16
  }
})

// Create ui PDF
const PDFDocument = ({ surveyFeedbackList }: any) => (
  <Document>
    <Page size="A3" style={styles.page}>
      <View style={styles.headerTitle}>
        <Text>
          {surveyFeedbackList.patientId} / {surveyFeedbackList.patientName}
        </Text>
      </View>
      <View style={styles.header}>
        <Text>{surveyFeedbackList.displayNameMap?.ja}</Text>
      </View>
      <View style={styles.content}>
        {surveyFeedbackList?.sectionList
          ?.filter((s: any) => s.status === StatusEnums.active)
          .map((item: any) => {
            let checkTitle =
              surveyFeedbackList?.additionalInfo?.displayCategories ===
                'true' ||
              surveyFeedbackList?.additionalInfo?.displayCategories === '1'
            return (
              <View
                key={item.refId}
                style={
                  checkTitle
                    ? { marginBottom: 30, ...styles.contentSurvey }
                    : styles.contentSurvey
                }
              >
                {checkTitle && (
                  <View style={styles.categoryTitle}>
                    <Text>{item.title.ja ? item.title.ja : ''}</Text>
                    <Text>
                      {(surveyFeedbackList?.additionalInfo?.displayEnglish ===
                        'true' ||
                        surveyFeedbackList?.additionalInfo?.displayEnglish ===
                          '1') &&
                      item.title.ja
                        ? item.title.en
                        : ''}
                    </Text>
                  </View>
                )}

                {item.subsectionList
                  .filter((s: any) => s.status === StatusEnums.active)
                  .map((itemSubsection: any) => {
                    if (
                      itemSubsection.subsectionType === 'QUESTION' &&
                      itemSubsection.questionType === 'CHOICE' &&
                      itemSubsection.setting.isMultiple === false
                    ) {
                      return (
                        <View
                          key={itemSubsection.refId}
                          style={styles.subsection}
                        >
                          <View style={styles.question}>
                            <Text style={styles.questionJa}>
                              {itemSubsection.displayNameMap.ja}
                            </Text>
                            {(surveyFeedbackList?.additionalInfo
                              ?.displayEnglish === 'true' ||
                              surveyFeedbackList?.additionalInfo
                                ?.displayEnglish === '1') && (
                              <Text style={styles.questionEn}>
                                {itemSubsection.displayNameMap.en}
                              </Text>
                            )}
                          </View>

                          <View style={styles.answer}>
                            <Text style={{ minHeight: 10 }}>
                              {itemSubsection?.selectedAnswerList?.options[0]
                                ?.displayNameMap.ja || ''}
                            </Text>
                          </View>
                        </View>
                      )
                    }

                    if (
                      itemSubsection.subsectionType === 'QUESTION' &&
                      itemSubsection.questionType === 'CHOICE' &&
                      itemSubsection.setting.isMultiple
                    ) {
                      return (
                        <View
                          key={itemSubsection.refId}
                          style={styles.subsection}
                        >
                          <View style={styles.question}>
                            <Text style={styles.questionJa}>
                              {itemSubsection.displayNameMap.ja}
                            </Text>
                            {(surveyFeedbackList?.additionalInfo
                              ?.displayEnglish === 'true' ||
                              surveyFeedbackList?.additionalInfo
                                ?.displayEnglish === '1') && (
                              <Text style={styles.questionEn}>
                                {itemSubsection.displayNameMap.en}
                              </Text>
                            )}
                          </View>

                          <View style={styles.answer}>
                            {itemSubsection?.selectedAnswerList?.options.map(
                              (optionItem: any) => {
                                return (
                                  <Text
                                    key={optionItem?.refId}
                                    style={{ minHeight: 10 }}
                                  >
                                    {optionItem?.displayNameMap?.ja || ''}
                                  </Text>
                                )
                              }
                            )}
                          </View>
                        </View>
                      )
                    }

                    if (itemSubsection.questionType === 'TEXT') {
                      return (
                        <View
                          key={itemSubsection?.refId}
                          style={styles.subsection}
                        >
                          <View style={styles.question}>
                            <Text style={styles.questionJa}>
                              {itemSubsection.displayNameMap.ja}
                            </Text>
                            {(surveyFeedbackList?.additionalInfo
                              ?.displayEnglish === 'true' ||
                              surveyFeedbackList?.additionalInfo
                                ?.displayEnglish === '1') && (
                              <Text style={styles.questionEn}>
                                {itemSubsection.displayNameMap.en}
                              </Text>
                            )}
                          </View>

                          <View style={styles.answer}>
                            <Text style={{ minHeight: 10 }}>
                              {
                                itemSubsection.selectedAnswerList.displayNameMap
                                  .ja
                              }
                            </Text>
                          </View>
                        </View>
                      )
                    }

                    if (itemSubsection.questionType === 'DATE') {
                      return (
                        <View
                          key={itemSubsection?.refId}
                          style={styles.subsection}
                        >
                          <View style={styles.question}>
                            <Text style={styles.questionJa}>
                              {itemSubsection.displayNameMap.ja}
                            </Text>
                            {(surveyFeedbackList?.additionalInfo
                              ?.displayEnglish === 'true' ||
                              surveyFeedbackList?.additionalInfo
                                ?.displayEnglish === '1') && (
                              <Text style={styles.questionEn}>
                                {itemSubsection.displayNameMap.en}
                              </Text>
                            )}
                          </View>

                          <View style={styles.answer}>
                            <Text style={{ minHeight: 10 }}>
                              {itemSubsection.selectedAnswerList.selectedDate}
                            </Text>
                          </View>
                        </View>
                      )
                    }

                    if (itemSubsection.questionType === 'TIME') {
                      return (
                        <View
                          key={itemSubsection.refId}
                          style={styles.subsection}
                        >
                          <View style={styles.question}>
                            <Text style={styles.questionJa}>
                              {itemSubsection.displayNameMap.ja}
                            </Text>
                            {(surveyFeedbackList?.additionalInfo
                              ?.displayEnglish === 'true' ||
                              surveyFeedbackList?.additionalInfo
                                ?.displayEnglish === '1') && (
                              <Text style={styles.questionEn}>
                                {itemSubsection.displayNameMap.en}
                              </Text>
                            )}
                          </View>

                          <View style={styles.answer}>
                            <Text style={{ minHeight: 10 }}>
                              {itemSubsection.selectedAnswerList.selectedTime}
                            </Text>
                          </View>
                        </View>
                      )
                    }
                  })}
              </View>
            )
          })}
      </View>
    </Page>
  </Document>
)

const HealthCheckModal = ({
  isModalOpen = false,
  onCancel,
  surveyFeedbackList
}: Props) => {
  const { t } = useTranslation('')

  const createNamePdfDownload = () => {
    const currentDate = new Date()
    const currentTimeString = currentDate.toLocaleString().replace(/\s/g, '')
    let fileNamePDF = surveyFeedbackList.patientId + `-${currentTimeString}.pdf`
    return fileNamePDF
  }

  return (
    <Modal
      footer={null}
      closeIcon={null}
      centered
      className="modal"
      maskClosable={true}
      style={{ padding: 10 }}
      open={isModalOpen}
      onCancel={onCancel}
      width={1900}
    >
      <Flex vertical className="mb-10">
        <Flex className="w-full pl-[10px] mt-[5px] pr-[10px] ">
          <Flex className="flex justify-end w-full">
            <button
              className="w-[23px] h-[21px] text-black border border-gray-600 flex items-center justify-center text-[18px] pb-[4px]"
              onClick={onCancel}
            >
              x
            </button>
          </Flex>
        </Flex>
        <Flex className="w-full pl-[10px] mt-[5px] pr-[10px] ">
          <Flex className="w-[50%]">
            <Typography className="w-full pl-[14px] font-bold text-[16px]">
              {surveyFeedbackList.patientId} / {surveyFeedbackList.patientName}
            </Typography>
          </Flex>
          <Flex className="w-[50%] justify-end">
            <PDFDownloadLink
              className="w-[12%] bg-gradient-to-r from-[#327790] to-[#97C9CC] text-[#ffffff] flex justify-center content-center"
              document={<PDFDocument surveyFeedbackList={surveyFeedbackList} />}
              fileName={createNamePdfDownload()}
            >
              <button>{t('button.export_PDF')}</button>
            </PDFDownloadLink>
            {/* <button className="w-[12%] ml-[15px] bg-gradient-to-r from-[#327790] to-[#97C9CC] text-[#ffffff]">
              {t('button.export_CSV')}
            </button> */}
          </Flex>
        </Flex>
        <Flex className="w-full h-[40px] pl-[10px] mt-[5px] pr-[10px] ">
          <Typography className="w-full text-[16px] pl-[14px] pt-[8px] font-bold text-[#ffffff] bg-gradient-to-r from-[#93D1CA] to-[#127594] rounded-tl-[5px] rounded-tr-[5px]">
            {surveyFeedbackList.displayNameMap?.ja}
          </Typography>
        </Flex>
        <div className="w-full pt-5">
          {surveyFeedbackList &&
            surveyFeedbackList.sectionList &&
            surveyFeedbackList.sectionList
              .filter((s: any) => s.status === StatusEnums.active)
              .map((item: any, index: number) => {
                let checkTitle =
                  surveyFeedbackList?.additionalInfo?.displayCategories ===
                    'true' ||
                  surveyFeedbackList?.additionalInfo?.displayCategories === '1'
                return (
                  <Flex
                    className={`${checkTitle ? 'mb-[30px]' : ''}`}
                    key={index}
                  >
                    <div className="w-full h-[auto] ml-[100px] mr-[110px]">
                      <div className="ml-[10px] w-full">
                        {checkTitle && (
                          <div className="w-full bg-[#137695] mb-[10px]">
                            <Typography className="pl-[7px] pt-[8px] text-[14px] font-bold text-[#ffffff]">
                              {item.title.ja ? item.title.ja : ''}
                            </Typography>
                            <Typography className="pl-[7px] pb-[8px] text-[14px] font-bold text-[#ffffff]">
                              {(surveyFeedbackList?.additionalInfo
                                ?.displayEnglish === 'true' ||
                                surveyFeedbackList?.additionalInfo
                                  ?.displayEnglish === '1') &&
                              item.title.en
                                ? item.title.en
                                : ''}
                            </Typography>
                          </div>
                        )}
                      </div>

                      <div className="ml-[10px] w-full">
                        {item.subsectionList
                          .filter((s: any) => s.status === StatusEnums.active)
                          .map((itemSubsection: any) => {
                            if (
                              itemSubsection.subsectionType === 'QUESTION' &&
                              itemSubsection.questionType === 'CHOICE' &&
                              itemSubsection.setting.isMultiple === false
                            ) {
                              return (
                                <div
                                  key={itemSubsection.refId}
                                  className="h-[auto] bg-[#F0F3F7] mb-5"
                                >
                                  <Flex className="bg-[#F0F3F7] gap-6 pl-[7px]">
                                    <div className="py-1">
                                      <span className="font-bold">
                                        {itemSubsection.displayNameMap.ja}
                                      </span>

                                      {(surveyFeedbackList?.additionalInfo
                                        ?.displayEnglish === 'true' ||
                                        surveyFeedbackList?.additionalInfo
                                          ?.displayEnglish === '1') && (
                                        <span className="mt-[6px]">
                                          {itemSubsection.displayNameMap.en}
                                        </span>
                                      )}
                                    </div>
                                  </Flex>
                                  <Flex className="pt-4 bg-[#ffffff] gap-6 pl-[7px]">
                                    <div className="flex items-center justify-center">
                                      <span className="font-bold">
                                        {itemSubsection?.selectedAnswerList
                                          ?.options?.[0]?.displayNameMap?.ja ||
                                          ''}
                                      </span>
                                    </div>
                                  </Flex>
                                </div>
                              )
                            }

                            if (
                              itemSubsection.subsectionType === 'QUESTION' &&
                              itemSubsection.questionType === 'CHOICE' &&
                              itemSubsection.setting.isMultiple
                            ) {
                              return (
                                <div
                                  key={itemSubsection.refId}
                                  className="h-[auto] bg-[#F0F3F7] mb-5"
                                >
                                  <Flex className="bg-[#F0F3F7] gap-6 pl-[7px]">
                                    <div className="py-1">
                                      <span className="font-bold">
                                        {itemSubsection.displayNameMap.ja}
                                      </span>
                                      {(surveyFeedbackList?.additionalInfo
                                        ?.displayEnglish === 'true' ||
                                        surveyFeedbackList?.additionalInfo
                                          ?.displayEnglish === '1') && (
                                        <span className="mt-[6px]">
                                          {itemSubsection.displayNameMap.en}
                                        </span>
                                      )}
                                    </div>
                                  </Flex>
                                  <div className="pt-4 bg-[#ffffff]">
                                    <Flex
                                      style={{ width: '100%' }}
                                      className="mt-[-40px]"
                                    >
                                      {itemSubsection.options.map(
                                        (colItems: any, index: number) => {
                                          const selectedOptions =
                                            itemSubsection.selectedAnswerList
                                              ?.options || []
                                          const isChecked =
                                            selectedOptions.some(
                                              (selectedItem: any) =>
                                                selectedItem.refId ===
                                                colItems.refId
                                            )
                                          return (
                                            <Col
                                              span={5}
                                              key={index}
                                              className="ml-10"
                                            >
                                              <Row className="question-preview mt-10">
                                                <Checkbox checked={isChecked}>
                                                  <Typography className="text-[14px] font-bold">
                                                    {colItems.displayNameMap.ja}
                                                  </Typography>
                                                </Checkbox>
                                              </Row>
                                            </Col>
                                          )
                                        }
                                      )}
                                    </Flex>
                                  </div>
                                </div>
                              )
                            }

                            if (itemSubsection.questionType === 'TEXT') {
                              return (
                                <div
                                  key={itemSubsection.refId}
                                  className="h-[auto] bg-[#F0F3F7] mb-5"
                                >
                                  <Flex className="bg-[#F0F3F7] gap-6 pl-[7px]">
                                    <div className="py-1">
                                      <span className="font-bold">
                                        {itemSubsection.displayNameMap.ja}
                                      </span>
                                      {(surveyFeedbackList?.additionalInfo
                                        ?.displayEnglish === 'true' ||
                                        surveyFeedbackList?.additionalInfo
                                          ?.displayEnglish === '1') && (
                                        <span className="mt-[6px]">
                                          {itemSubsection.displayNameMap.en}
                                        </span>
                                      )}
                                    </div>
                                  </Flex>
                                  <Flex className="pt-4 bg-[#ffffff] gap-6 pl-[7px]">
                                    <div className="flex items-center justify-center">
                                      <span className="font-bold">
                                        {
                                          itemSubsection.selectedAnswerList
                                            .displayNameMap.ja
                                        }
                                      </span>
                                    </div>
                                  </Flex>
                                </div>
                              )
                            }

                            if (itemSubsection.questionType === 'DATE') {
                              return (
                                <div
                                  key={itemSubsection.refId}
                                  className="h-[auto] bg-[#F0F3F7] mb-5"
                                >
                                  <Flex className="bg-[#F0F3F7] gap-6 pl-[7px]">
                                    <div className="py-1">
                                      <span className="font-bold">
                                        {itemSubsection.displayNameMap.ja}
                                      </span>
                                      {(surveyFeedbackList?.additionalInfo
                                        ?.displayEnglish === 'true' ||
                                        surveyFeedbackList?.additionalInfo
                                          ?.displayEnglish === '1') && (
                                        <span className="mt-[6px]">
                                          {itemSubsection.displayNameMap.en}
                                        </span>
                                      )}
                                    </div>
                                  </Flex>
                                  <Flex className="pt-4 bg-[#ffffff] gap-6 pl-[7px]">
                                    <div className="flex items-center justify-center">
                                      <span className="font-bold">
                                        {
                                          itemSubsection.selectedAnswerList
                                            .selectedDate
                                        }
                                      </span>
                                    </div>
                                  </Flex>
                                </div>
                              )
                            }

                            if (itemSubsection.questionType === 'TIME') {
                              return (
                                <div
                                  key={itemSubsection.refId}
                                  className="h-[auto] bg-[#F0F3F7] mb-5"
                                >
                                  <Flex className="bg-[#F0F3F7] gap-6 pl-[7px]">
                                    <div className="py-1">
                                      <span className="font-bold">
                                        {itemSubsection.displayNameMap.ja}
                                      </span>
                                      {(surveyFeedbackList?.additionalInfo
                                        ?.displayEnglish === 'true' ||
                                        surveyFeedbackList?.additionalInfo
                                          ?.displayEnglish === '1') && (
                                        <span className="mt-[6px]">
                                          {itemSubsection.displayNameMap.en}
                                        </span>
                                      )}
                                    </div>
                                  </Flex>
                                  <Flex className="pt-4 bg-[#ffffff] gap-6 pl-[7px]">
                                    <div className="flex items-center justify-center">
                                      <span className="font-bold">
                                        {
                                          itemSubsection.selectedAnswerList
                                            .selectedTime
                                        }
                                      </span>
                                    </div>
                                  </Flex>
                                </div>
                              )
                            }
                          })}
                      </div>
                    </div>
                  </Flex>
                )
              })}
        </div>
        {/* TODO: other type */}
        {/* <Flex className="mt-[15px]">
          <div className="w-full h-[auto] ml-[165px] mr-[65px]">
            <div className="ml-[10px] w-full">
              <div className="w-full h-[56px] bg-[#137695]">
                <Typography className="pl-[7px] pt-[8px] text-[14px] font-bold text-[#ffffff]">
                  B. MEDICAL HISTORY -TO BE COMPLETED BY THE EXAMINEE
                </Typography>
                <Typography className="pl-[7px] text-[14px] font-bold text-[#ffffff]">
                  {t('content.medicalHistory')}
                </Typography>
              </div>
            </div>
            <MedicalHistory />
          </div>
        </Flex>
        <Flex className="mt-[15px]">
          <div className="w-full h-[auto] ml-[165px] mr-[65px]">
            <div className="ml-[10px] w-full">
              <div className="w-full h-[56px] bg-[#137695]">
                <Typography className="pl-[7px] pt-[8px] text-[14px] font-bold text-[#ffffff]">
                  OBSTETRIC HISTORY -TO BE COMPLETED BY FEMALE APPLICANT
                </Typography>
                <Typography className="pl-[7px] pb-[8px] text-[14px] font-bold text-[#ffffff]">
                  {t('content.obstetricHistory')}
                </Typography>
              </div>
            </div>
            <ObstetricHistory />
          </div>
        </Flex>
        <Flex className="mt-[15px]">
          <div className="w-full h-[auto] ml-[165px] mr-[65px]">
            <div className="ml-[10px] w-full">
              <div className="w-full h-[56px] bg-[#137695]">
                <Typography className="pl-[7px] pt-[8px] text-[14px] font-bold text-[#ffffff]">
                  VACCINATION HISTORY
                </Typography>
                <Typography className="pl-[7px] pb-[8px] text-[14px] font-bold text-[#ffffff]">
                  {t('content.vaccinationHistory')}
                </Typography>
              </div>
            </div>
            <VaccinationHistory />
          </div>
        </Flex>
        <Flex className="mt-[15px]">
          <div className="w-full h-[auto] ml-[165px] mr-[65px]">
            <div className="ml-[10px] w-full">
              <div className="w-full h-[56px] bg-[#137695]">
                <Typography className="pl-[7px] pt-[8px] text-[14px] font-bold text-[#ffffff]">
                  FAMILY HISTORY -TO BE COMPLETED BY THE EXAMINEE
                </Typography>
                <Typography className="pl-[7px] text-[14px] font-bold text-[#ffffff]">
                  {t('content.familyHistory')}
                </Typography>
              </div>
            </div>
            <FamilyHistory />
          </div>
        </Flex>
        <Flex className="mt-[15px]">
          <div className="w-full h-[auto] ml-[165px] mr-[65px]">
            <div className="ml-[10px] w-full">
              <div className="w-full h-[56px] bg-[#137695]">
                <Typography className="pl-[7px] pt-[8px] text-[14px] font-bold text-[#ffffff]">
                  SYMPTOMS -TO BE COMPLETED BY THE EXAMINEE
                </Typography>
                <Typography className="pl-[7px] text-[14px] font-bold text-[#ffffff]">
                  {t('content.symptoms')}
                </Typography>
              </div>
            </div>
            <SymptomsHistory />
          </div>
        </Flex>
        <Flex className="mt-[15px]">
          <div className="w-full h-[auto] ml-[165px] mr-[65px]">
            <div className="ml-[10px] w-full">
              <div className="w-full h-[56px] bg-[#137695]">
                <Typography className="pl-[7px] pt-[8px] text-[14px] font-bold text-[#ffffff]">
                  HEALTH HISTORY -TO BE COMPLETED BY THE EXAMINEE
                </Typography>
                <Typography className="pl-[7px] text-[14px] font-bold text-[#ffffff]">
                  {t('content.healthHistory')}
                </Typography>
              </div>
            </div>
            <HealthHistory />
          </div>
        </Flex>
        <Flex className="mt-[15px]">
          <div className="w-full h-[auto] ml-[165px] mr-[65px]">
            <div className="ml-[10px] w-full">
              <div className="w-full h-[56px] bg-[#137695]">
                <Typography className="pl-[7px] pt-[17px] text-[14px] font-bold text-[#ffffff]">
                  {t('content.lifestyleReport')}
                </Typography>
              </div>
            </div>
            <LifestyleReport />
          </div>
        </Flex> */}
      </Flex>
    </Modal>
  )
}

export default HealthCheckModal
