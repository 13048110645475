import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Flex,
  Modal,
  Popover,
  Row,
  Select,
  Typography
} from 'antd'
import * as yup from 'yup'

import SpinLoading from '../../../components/screens/Spin/SpinLoading'
import styles from './CommentSettingg.module.scss'
import { CaretDownOutlined } from '@ant-design/icons'
import { ReactComponent as Trash } from 'assets/svgs/awesome-trash.svg'
import { ReactComponent as Copy } from 'assets/svgs/copy–268.svg'
import { ReactComponent as WarningIcon } from 'assets/svgs/exclamationCircleFilled.svg'
import { ReactComponent as EyeNoHidden } from 'assets/svgs/eye-blue-37.svg'
import { ReactComponent as EyeInvisible } from 'assets/svgs/eye-hidden14132.svg'
import { ReactComponent as AddItemIcon } from 'assets/svgs/plus–42.svg'
import CurrentCategoryList from 'components/categoryList/CurrentCategoryList'
import { InputForm } from 'components/elements/InputForm'
import { SelectCustom } from 'components/elements/Select'
import { TextAreaForm } from 'components/elements/TextAreaForm'
import { openNotification } from 'components/widgets/Notification'
import {
  MEDICAL_CHECKUP_MASTER_EXAMPLE,
  OVERALL_VERDICT_CATEGORY_EXAMPLE
} from 'configs/api'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import useCommentSetting from 'hooks/useCommentSetting'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import useTestItemMaster from 'hooks/useTestItemMaster'
import { getLanguage, uuid } from 'utilities/helpers'

const { Panel } = Collapse

const schema = yup.object().shape({})

type InputForm = yup.InferType<typeof schema>

const CommentSettings = ({ setLoadingScreen }: { setLoadingScreen: any }) => {
  const { t } = useTranslation()
  let language = getLanguage()
  const [loading, setLoading] = useState<any>(false)
  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()

  const handleAddJudgementItem = (catId: string, item: any, newId: string) => {
    let newItem: any
    if (item !== '') {
      newItem = JSON.parse(JSON.stringify(item))
      newItem.categoryId = catId
    } else {
      newItem = {
        additionalInfo: {
          key1: 'カテゴリマスタ',
          key3: '所見コメント',
          judgementTitle: '',
          judgementDescription: '',
          judgementComment: '',
          isHidden: 0
        },
        categoryId: catId,
        shortName: 'TG',
        displayName: 'comment',
        unitOfMeasure: '',
        readingType: 'STRING',
        referenceValues: []
      }
    }
    newItem.refId = newId

    setCommentSettingData((prevCommentSettingData: any) =>
      prevCommentSettingData.map((cat: any) =>
        cat.refId === catId
          ? {
              ...cat,
              comments: [...cat.comments, newItem]
            }
          : cat
      )
    )
  }

  const handleHiddenJudgementItem = (
    catId: string,
    index: number,
    value: number
  ) => {
    let newArray = commentSettingData.map((cat: any) => {
      if (cat.refId === catId) {
        const newComments = cat.comments.map((comment: any, idx: number) => {
          if (idx === index) {
            return {
              ...comment,
              additionalInfo: {
                ...comment.additionalInfo,
                isHidden: value
              }
            }
          }
          return comment
        })

        return {
          ...cat,
          comments: newComments
        }
      }
      return cat
    })
    setCommentSettingData(newArray)
  }

  const [isModalBulkChanges, setIsModalBulkChanges] = useState(false)
  const [modalData, setModalData] = useState<any>(null)

  const handleOk = async () => {
    setIsModalBulkChanges(false)
    setLoadingScreen(true)
    // Update live data
    commentBulkChangeData.forEach((comment: any) => {
      commentSettingData.forEach((category: any) => {
        category.comments.forEach((catComment: any) => {
          if (catComment.refId === comment.refId) {
            const updatedComment = {
              ...catComment,
              additionalInfo: { ...comment.additionalInfo }
            }

            const index = category.comments.indexOf(catComment)
            if (index !== -1) {
              category.comments[index] = updatedComment
            }
          }
        })
      })
    })

    // Remove checked
    emptyAllSubArrays(transformedCheckData)

    if (commentBulkChangeData.length !== 0) {
      const updateCommentResult = await updateCommentSetting(
        commentBulkChangeData
      )
      if (updateCommentResult && updateCommentResult.length > 0) {
        openNotification({
          type: 'success',
          title: t('judgementNotify.updateCommentSuccess'),
          message: ''
        })
      } else {
        openNotification({
          type: 'error',
          title: t('judgementNotify.updateCommentFail'),
          message: ''
        })
      }
      fetchData()
    } else {
      openNotification({
        type: 'error',
        title: t('judgementNotify.nothingToUpdate'),
        message: ''
      })
    }
    setLoadingScreen(false)
    setDropdownBatchChange(null)
  }

  const handleCancel = () => {
    setIsModalBulkChanges(false)
    setDropdownBatchChange(null)
  }

  const [commentBulkChangeData, setCommentBulkChangeData] = useState<any>(null)
  const handleBulkChangeData = (changeData: any, change: number) => {
    let commentsArray: any[] = []
    const getNewAdditionalInfo = (additionalInfo: any) => {
      switch (change) {
        case 1:
          return { ...additionalInfo, isDeleted: 1 }
        case 2:
          return { ...additionalInfo, isHidden: 0 }
        case 3:
          return { ...additionalInfo, isHidden: 1 }
        default:
          return additionalInfo
      }
    }

    changeData.forEach((item: any) => {
      if (item.comments) {
        item.comments.forEach((comment: any) => {
          const newComment = {
            ...comment,
            additionalInfo: getNewAdditionalInfo(comment.additionalInfo)
          }
          delete newComment.createdDate
          delete newComment.createdBy
          commentsArray.push(newComment)
        })
      }
    })
    setCommentBulkChangeData(commentsArray)
  }

  const handleSelectBulkChange = (value: number) => {
    const dataZ = filterCategoriesBulkChange(
      commentSettingData,
      transformedCheckData
    )
    if (dataZ.length === 0) {
      openNotification({
        type: 'error',
        title: t('judgementNotify.pleaseSelectItem'),
        message: ''
      })
    } else {
      setDropdownBatchChange(value)

      const modalDataMap: {
        [key: number]: { title: string; content: string; line2: string }
      } = {
        1: {
          title: t('bulkChange.bulkDelete'),
          content: t('bulkChange.content.bulkDeleteLine1'),
          line2: t('bulkChange.content.bulkDeleteLine2')
        },
        2: {
          title: t('bulkChange.bulkPublish'),
          content: t('bulkChange.content.bulkPublishLine1'),
          line2: ''
        },
        3: {
          title: t('bulkChange.bulkUnpublish'),
          content: t('bulkChange.content.bulkUnpublishLine1'),
          line2: ''
        }
      }

      const modalData = modalDataMap[value]
      if (modalData) {
        handleBulkChangeData(dataZ, value)
        setModalData({
          ...modalData,
          items: dataZ
        })
        setIsModalBulkChanges(true)
      }
    }
  }

  function emptyAllSubArrays(obj: { [key: string]: any[] }): void {
    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key])) {
        obj[key].length = 0
      }
    })
  }

  const {
    variablesFilter: filterCommentSetting,
    loadCommentSettingList,
    updateCommentSetting,
    createCommentSetting,
    updateTestItemCategory
  } = useCommentSetting()
  const [commentSettingData, setCommentSettingData] = useState<any>(null)

  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const [categoryMasterData, setCategoryMasterData] = useState<any>(null)
  const [dropdownBatchChange, setDropdownBatchChange] = useState<any>(null)

  const fetchData = async () => {
    setLoading(true)
    // Call filterMedicalCheckupMasterDetails
    let data = await getMedicalCheckupMastersById(
      MEDICAL_CHECKUP_MASTER_EXAMPLE
    )

    let categoryOrder =
      data?.medicalCheckupMaster?.additionalInfo?.categoryOrder ?? {}

    // Todo: hardcode category to reduce draft data
    const variablesFilterTestItemCategory = {
      ...filterCommentSetting,
      // filter: `(in,STRING,associatedTestItemMasterRefIds,${arrItemMasterRefId.join(',')});(eq,STRING,status,PUBLISHED)`,
      filter: `(in,STRING,refId,${OVERALL_VERDICT_CATEGORY_EXAMPLE},13215064603757287001726460393637,88665023344512015941726460435377,83216780091146409801726460484181,66863821368653047771726460525201,45797661172382888381726460564253,50303274715688017811726460592767,35872960772656501091726460641343,6157615577841498081726460663928,75393933929457064601726460690362,15761740032421589841726460728026,88765061765373168031726460770519,24127498591334101701726460803388,50069000893238963771726460828814,48850182716056353011726460846236);(eq,STRING,status,PUBLISHED)`, //hard code
      page: 0,
      size: -1,
      sortBy: '(desc,refId)'
    }

    let categories = await loadCommentSettingList({
      variables: variablesFilterTestItemCategory
    })

    let filterCategories
    if (categories) {
      // Remove comments same refId
      let tempCategories =
        categories?.data?.filterTestItemCategory?.payload?.reduce(
          (acc: any, obj: any) => {
            if (
              !acc[obj.refId] ||
              acc[obj.refId].createdDate < obj.createdDate
            ) {
              acc[obj.refId] = obj
            }
            return acc
          },
          {}
        )
      filterCategories = Object.values(tempCategories)

      const elementToMove = filterCategories.find(
        (item: any) => item.refId === OVERALL_VERDICT_CATEGORY_EXAMPLE
      )
      if (elementToMove) {
        filterCategories = filterCategories.filter(
          (item: any) => item.refId !== OVERALL_VERDICT_CATEGORY_EXAMPLE
        )

        filterCategories.unshift(elementToMove)
      }

      filterCategories = filterCategories
        .map((category: any) => {
          return {
            ...category,
            order: categoryOrder?.[category?.refId] ?? 9999
          }
        })
        .sort((a: any, b: any) => a.order - b.order)

      setCategoryMasterData(filterCategories)
    }

    let listData = []
    if (filterCategories) {
      for (let i = 0; i < filterCategories.length; i++) {
        const item: any = filterCategories[i]
        let idsFilter = item.associatedTestItemMasterRefIds

        if (idsFilter && idsFilter.length > 0) {
          // Call test item master

          const itemMasters: any = await getListItemMasterByMultipleLanguages([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'in',
                    value: idsFilter
                  },
                  {
                    field: 'additionalInfo.key1',
                    operator: 'eq',
                    value: 'カテゴリマスタ'
                  }
                ]
              }
            }
          ])

          let comments: any = itemMasters.filter(
            (comment: any) => comment.additionalInfo?.key3 === '所見コメント'
          )

          let itemMaster: any = itemMasters.find(
            (data: any) => data.additionalInfo?.key3 !== '所見コメント'
          )

          listData.push({
            ...item,
            comments: comments.map((comment: any) => {
              const {
                lang,
                _id,
                _class,
                status,
                visibility,
                lastModifiedDate,
                id,
                ...rest
              } = comment

              return rest
            }),
            lang: itemMaster?.lang
          })
        }
      }
    }
    setCommentSettingData(listData)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const methods = useForm<any>({
    mode: 'onChange'
  })

  const {
    handleSubmit,
    getValues,
    trigger,
    formState: { isValid }
  } = methods

  const onSubmit = (data: InputForm) => {
    // Do nothing
  }

  const filterCategoriesBulkChange = (
    targetCategory: any,
    checkedData: any
  ) => {
    return targetCategory
      .filter(
        (item: any) =>
          checkedData[item.refId] && checkedData[item.refId].length > 0
      )
      .map((item: any) => {
        const validComments = item.comments.filter((comment: any) =>
          checkedData[item.refId].includes(comment.refId)
        )
        const {
          associatedTestItemMasterRefIds,
          createdBy,
          createdDate,
          ...rest
        } = item
        return {
          ...rest,
          comments: validComments
        }
      })
  }

  const parseSubmitData = (formData: any, targetCategory: any) => {
    const commentsData = targetCategory.reduce((acc: any, item: any) => {
      return acc.concat(item.comments)
    }, [])

    const updatedCommentsData = commentsData.map((item: any) => {
      if (formData.judgementCommentList[item.refId]) {
        const judgementComment = formData.judgementCommentList[item.refId]

        const newItem = {
          ...item,
          additionalInfo: {
            ...item.additionalInfo,
            judgementTitle:
              judgementComment.judgementTitle ||
              item.additionalInfo.judgementTitle,
            judgementDescription:
              judgementComment.judgementDescription ||
              item.additionalInfo.judgementDescription,
            judgementComment:
              judgementComment.judgementComment ||
              item.additionalInfo.judgementComment
          }
        }

        const { createdBy, createdDate, ...filteredItem } = newItem
        return filteredItem
      }

      const { createdBy, createdDate, ...filteredItem } = item
      return filteredItem
    })

    const [newItems, updateItems] = updatedCommentsData.reduce(
      (acc: any, item: any) => {
        if (item.refId.startsWith('newItem_') && item.categoryId) {
          acc[0].push(item)
        } else {
          acc[1].push(item)
        }
        return acc
      },
      [[], []]
    )

    return { newItems, updateItems }
  }

  const submitCommentData = async (event: any, categoryId: string) => {
    setLoadingScreen(true)
    event.preventDefault()

    const formData = getValues()
    let resultArray = commentSettingData

    if (categoryId) {
      const targetCategory = commentSettingData.find(
        (element: any) => element.refId === categoryId
      )
      resultArray = targetCategory ? [targetCategory] : []

      if (targetCategory) {
        const triggerPromises = targetCategory.comments.map(
          async (comment: any) => {
            const nameCheck: string = `judgementCommentList.${comment.refId}`

            const res = await trigger([
              `${nameCheck}.judgementTitle`,
              `${nameCheck}.judgementDescription`,
              `${nameCheck}.judgementComment`
            ])
            return res
          }
        )

        const triggerResults = await Promise.all(triggerPromises)
        // If any of the trigger results is falsy, return early.
        if (triggerResults.some((res) => !res)) {
          return
        }
      }
    } else {
      trigger().then()
      if (!isValid) {
        setLoadingScreen(false)
        return
      }
    }

    const { newItems, updateItems } = parseSubmitData(formData, resultArray)
    // check data and call api update comment
    if (updateItems.length !== 0) {
      const updateCommentResult = await updateCommentSetting(updateItems)
      if (updateCommentResult && updateCommentResult.length > 0) {
        openNotification({
          type: 'success',
          title: t('judgementNotify.updateCommentSuccess'),
          message: ''
        })
        fetchData()
      } else {
        openNotification({
          type: 'error',
          title: t('judgementNotify.updateCommentFail'),
          message: ''
        })
      }
    }

    // check data, call api create comment and update category
    if (newItems.length !== 0) {
      const categorizedItems = newItems.reduce((acc: any, item: any) => {
        const { categoryId, refId, ...rest } = item
        if (!acc[categoryId]) {
          acc[categoryId] = []
        }
        acc[categoryId].push({ ...rest, refId: uuid(false) })
        return acc
      }, {})

      let updateCategoryData = []
      for (const categoryId in categorizedItems) {
        if (categorizedItems.hasOwnProperty(categoryId)) {
          const itemsArray = categorizedItems[categoryId]
          // call api create comment
          const createResult = await fetchCreateComments(itemsArray)
          // get refId
          const newCommentRefIds = createResult.map((item: any) => item.refId)
          // update to category
          const targetCategory = categoryMasterData.find(
            (item: any) => item.refId === categoryId
          )
          updateCategoryData.push({
            refId: categoryId,
            associatedTestItemMasterRefIds:
              targetCategory.associatedTestItemMasterRefIds.concat(
                newCommentRefIds
              )
          })
        }
      }
      // call api updateTestItemCategory
      const updateTestItemCategoryResult =
        await updateTestItemCategory(updateCategoryData)
      if (
        updateTestItemCategoryResult &&
        updateTestItemCategoryResult.length > 0
      ) {
        openNotification({
          type: 'success',
          title: t('judgementNotify.createCommentSuccess'),
          message: ''
        })
        fetchData()
      } else {
        openNotification({
          type: 'error',
          title: t('judgementNotify.createCommentFail'),
          message: ''
        })
      }
    }

    setLoadingScreen(false)
  }

  const fetchCreateComments = async (itemsArray: any) => {
    const createCommentResult = await createCommentSetting(itemsArray)
    return createCommentResult
  }

  const transformCheckArray = (data: any[]): { [key: number]: any[] } => {
    return data.reduce(
      (acc, item) => {
        acc[item.refId] = []
        return acc
      },
      {} as { [key: number]: any[] }
    )
  }

  const [transformedCheckData, setTransformedCheckData] = useState<any>(null)
  useEffect(() => {
    if (commentSettingData) {
      const fromValues = transformCheckArray(commentSettingData)
      setTransformedCheckData(fromValues)
    }
  }, [commentSettingData])

  const handleDeleteComment = (catId: string, index: number) => {
    let newArray = commentSettingData.map((cat: any) => {
      if (cat.refId === catId) {
        const newComments = cat.comments.map((comment: any, idx: number) => {
          if (idx === index) {
            return {
              ...comment,
              additionalInfo: {
                ...comment.additionalInfo,
                isDeleted: 1
              }
            }
          }
          return comment
        })

        return {
          ...cat,
          comments: newComments
        }
      }
      return cat
    })
    setCommentSettingData(newArray)
  }

  return (
    <>
      <div className="pl-2.5 pr-3 pt-[29px] pb-10">
        <Typography>{t('judgementSetting.commentSettingTitle')}</Typography>

        <Flex className="pt-5">
          <Flex className="ml-auto">
            <Typography className="text-[14px] text-[#707070] pr-2 pt-1">
              {t('judgementSetting.batchOperations')}
            </Typography>
          </Flex>

          <Flex className="w-[11.8%] text-[#707070]">
            <Select
              suffixIcon={
                <CaretDownOutlined className="text-[#137695] text-[14px]" />
              }
              className="w-full h-[30px]"
              placeholder={t('placeholder.select')}
              onChange={handleSelectBulkChange}
              value={dropdownBatchChange}
              options={[
                { label: t('bulkChange.bulkDelete'), value: 1 },
                { label: t('bulkChange.bulkPublish'), value: 2 },
                { label: t('bulkChange.bulkUnpublish'), value: 3 }
              ]}
            />
          </Flex>
        </Flex>
        <div className="flex justify-center items-center">
          <SpinLoading loading={loading} />
        </div>

        {!loading && commentSettingData && transformedCheckData && (
          <Flex className="pt-4">
            <Flex className="w-[12%] mr-[2px]">
              <CurrentCategoryList
                categoryJpValues={commentSettingData.map(
                  (item: any) => item?.lang?.[language] ?? VALUE_WITH_NO_DATA
                )}
              />
            </Flex>

            <div className="w-[88%]">
              <div className="w-full">
                {commentSettingData.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`mb-2 bg-[#EFF3F6] ${styles.inspection_content_box}`}
                  >
                    <Collapse
                      defaultActiveKey={[index]}
                      expandIconPosition="end"
                      collapsible="icon"
                    >
                      <Panel
                        className="bg-[#EFF3F6]"
                        id={`category_${index}`}
                        header={
                          <Row className="px-1 items-center">
                            <Col span={4}>
                              <Checkbox
                                className="pl-1.5"
                                checked={
                                  transformedCheckData[item.refId]?.length ===
                                    item.comments.filter(
                                      (comment: any) =>
                                        comment.additionalInfo.isDeleted !== 1
                                    ).length &&
                                  transformedCheckData[item.refId].length !== 0
                                }
                                onChange={(e) => {
                                  const clone = { ...transformedCheckData }
                                  if (!e.target.checked) {
                                    clone[item.refId] = []
                                  } else {
                                    clone[item.refId] = item.comments
                                      .filter(
                                        (comment: { additionalInfo: any }) =>
                                          comment.additionalInfo.isDeleted !== 1
                                      )
                                      .map(
                                        (comment: { refId: string }) =>
                                          comment.refId
                                      )
                                  }
                                  setTransformedCheckData(clone)
                                }}
                              ></Checkbox>

                              <span className="text-[#707070] text-[14px] font-bold pl-5">
                                {item?.lang?.[language] ?? VALUE_WITH_NO_DATA}
                              </span>
                            </Col>
                            <Col span={4}>
                              <span className="text-[#707070] text-[14px]">
                                {item?.lang?.en ?? VALUE_WITH_NO_DATA}
                              </span>
                            </Col>
                          </Row>
                        }
                        key={index}
                      >
                        <FormProvider {...methods}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col bg-[#EFF3F6]">
                              <div>
                                {item.comments.map(
                                  (judgementItem: any, index: number) => {
                                    const fieldName: string = `judgementCommentList.${judgementItem.refId}`
                                    if (
                                      judgementItem.additionalInfo.isDeleted !==
                                      1
                                    )
                                      return (
                                        <Row
                                          key={`${judgementItem.refId}`}
                                          className="bg-[#CDD6DD] m-1 py-1 items-center"
                                        >
                                          <Col span={6}>
                                            <Flex>
                                              <Checkbox
                                                name={`${fieldName}.isChecked`}
                                                className="px-1.5"
                                                value={judgementItem.refId}
                                                checked={
                                                  transformedCheckData[
                                                    item.refId
                                                  ] &&
                                                  transformedCheckData[
                                                    item.refId
                                                  ].includes(
                                                    judgementItem.refId
                                                  )
                                                }
                                                onChange={(e) => {
                                                  const clone = {
                                                    ...transformedCheckData
                                                  }
                                                  if (e.target.checked) {
                                                    clone[item.refId] = [
                                                      ...clone[item.refId],
                                                      e.target.value
                                                    ]
                                                    setTransformedCheckData(
                                                      clone
                                                    )
                                                  } else {
                                                    clone[item.refId] = clone[
                                                      item.refId
                                                    ].filter(
                                                      (key: any) =>
                                                        key !==
                                                        judgementItem.refId
                                                    )
                                                    setTransformedCheckData(
                                                      clone
                                                    )
                                                  }
                                                }}
                                              ></Checkbox>

                                              <SelectCustom
                                                name={`${fieldName}.judgementTitle`}
                                                className="w-full h-[30px] mr-2.5"
                                                placeholder={t(
                                                  'placeholder.selectJudgement'
                                                )}
                                                defaultValue={
                                                  judgementItem.additionalInfo
                                                    .judgementTitle || undefined
                                                }
                                                rules={{
                                                  required: true,
                                                  minLength: 1,
                                                  maxLength: 1
                                                }}
                                                options={[
                                                  { label: 'A', value: 'A' },
                                                  { label: 'B', value: 'B' },
                                                  { label: 'C', value: 'C' }
                                                ]}
                                              />
                                            </Flex>
                                          </Col>
                                          <Col
                                            span={5}
                                            className="content-center"
                                          >
                                            <InputForm
                                              name={`${fieldName}.judgementDescription`}
                                              className="w-full h-[30px]"
                                              placeholder={t(
                                                'placeholder.templateName'
                                              )}
                                              defaultValue={
                                                judgementItem.additionalInfo
                                                  .judgementDescription
                                              }
                                              rules={{
                                                required: false,
                                                maxLength: 100
                                              }}
                                            />
                                          </Col>
                                          <Col span={11} className="ml-2.5">
                                            <TextAreaForm
                                              autoSize={{
                                                minRows: 2.5,
                                                maxRows: 2.5
                                              }}
                                              rules={{
                                                required: true,
                                                minLength: 1,
                                                maxLength: 400
                                              }}
                                              name={`${fieldName}.judgementComment`}
                                              placeholder={t(
                                                'placeholder.yourComment'
                                              )}
                                              defaultValue={
                                                judgementItem.additionalInfo
                                                  .judgementComment
                                              }
                                            />
                                          </Col>
                                          <Col
                                            span={2}
                                            className="flex justify-center items-center space-x-2"
                                          >
                                            {judgementItem.additionalInfo
                                              .isHidden === 1 ? (
                                              <EyeInvisible
                                                className="cursor-pointer"
                                                onClick={() =>
                                                  handleHiddenJudgementItem(
                                                    item.refId,
                                                    index,
                                                    0
                                                  )
                                                }
                                              />
                                            ) : (
                                              <EyeNoHidden
                                                className="cursor-pointer"
                                                onClick={() =>
                                                  handleHiddenJudgementItem(
                                                    item.refId,
                                                    index,
                                                    1
                                                  )
                                                }
                                              />
                                            )}
                                            <Popover
                                              placement="top"
                                              content={
                                                <div className="py-1 px-2 bg-[#545454] text-white rounded">
                                                  {t('popover.copy')}
                                                </div>
                                              }
                                              overlayClassName="popover-permission"
                                            >
                                              <Copy
                                                width="22px"
                                                height="22px"
                                                className="cursor-pointer"
                                                onClick={() =>
                                                  handleAddJudgementItem(
                                                    item.refId,
                                                    judgementItem,
                                                    'newItem_' + Date.now()
                                                  )
                                                }
                                              />
                                            </Popover>
                                            <Popover
                                              placement="top"
                                              content={
                                                <div className="px-2.5 py-1.5 bg-[#545454] text-white rounded">
                                                  {t('button.delete')}
                                                </div>
                                              }
                                              overlayClassName="popover-permission"
                                            >
                                              <Trash
                                                width="16px"
                                                height="16px"
                                                className="cursor-pointer"
                                                onClick={() =>
                                                  handleDeleteComment(
                                                    item.refId,
                                                    index
                                                  )
                                                }
                                              />
                                            </Popover>
                                          </Col>
                                        </Row>
                                      )
                                  }
                                )}
                              </div>

                              <div className="mt-2.5 mx-2 mb-[11px]">
                                <div
                                  className="my-2 w-fit cursor-pointer"
                                  onClick={() =>
                                    handleAddJudgementItem(
                                      item.refId,
                                      '',
                                      'newItem_' + Date.now()
                                    )
                                  }
                                >
                                  <Flex>
                                    <AddItemIcon className="bg-[#137695] h-[20px] w-[20px] rounded-sm" />
                                    <Typography className="text-[14px] text-[#137695] ml-1">
                                      {t('newAdditions')}
                                    </Typography>
                                  </Flex>
                                </div>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  onClick={(event) =>
                                    submitCommentData(event, `${item.refId}`)
                                  }
                                  className="h-[30px] w-[90px] font-bold"
                                  autoInsertSpace={false}
                                >
                                  {t('keepButton')}
                                </Button>
                              </div>
                            </div>
                          </form>
                        </FormProvider>
                      </Panel>
                    </Collapse>
                  </div>
                ))}
              </div>

              <Button
                type="primary"
                htmlType="submit"
                onClick={(event) => submitCommentData(event, '')}
                className="h-[30px] w-[90px] font-bold mt-2 mx-2"
                autoInsertSpace={false}
              >
                {t('keepButton')}
              </Button>
            </div>
          </Flex>
        )}

        <div>
          <Modal
            title={null}
            open={isModalBulkChanges}
            onOk={handleOk}
            closable={false}
            onCancel={handleCancel}
            width={1300}
            footer={[
              <div key="footer" className={styles.btn_wrapper}>
                <Button
                  className={modalData?.line2 ? 'btn-warning-delete' : ''}
                  {...(modalData?.line2 ? {} : { type: 'primary' })}
                  onClick={handleOk}
                >
                  <Typography className="text-[14px] text-[#FFFFFF] font-bold">
                    {modalData?.line2 ? t('button.delete') : t('button.keep')}
                  </Typography>
                </Button>

                <Button
                  className={modalData?.line2 ? 'btn-warning-cancel' : ''}
                  {...(modalData?.line2 ? {} : { type: 'default' })}
                  onClick={handleCancel}
                >
                  <Typography
                    className={`text-[14px] font-bold ${modalData?.line2 ? 'text-[#DF2475]' : 'text-[#137695]'}`}
                  >
                    {t('button.cancel')}
                  </Typography>
                </Button>
              </div>
            ]}
            className={styles.modal_wrapper}
          >
            <div className={styles.modal_header}></div>
            <div className={styles.modal_title}>{modalData?.title}</div>
            <div className={styles.modal_content_bulk_change}>
              {modalData?.line2 && (
                <WarningIcon className="h-[40px] mb-5 mt-5" />
              )}
              <p>{modalData?.content}</p>
              <p className="text-[#DF2475]">{modalData?.line2}</p>
            </div>
            <div className="px-10 pt-4">
              {modalData?.items?.map((item: any, index: number) => (
                <div key={item.refId + index}>
                  <Row className="bg-[#F0F3F7] h-[40px] mt-5">
                    <Col span={4} className="flex items-center pl-5">
                      <span className="text-[#707070] text-[14px] font-bold">
                        {item.displayName}
                      </span>
                    </Col>
                    <Col span={20} className="flex items-center">
                      <span className="text-[#707070] text-[14px]">
                        {item.displaynameEn}
                      </span>
                    </Col>
                  </Row>
                  {item.comments.map((commentItem: any, idx: number) => (
                    <Row key={commentItem.refId + idx} className="min-h-10">
                      <Col span={4} className="flex items-center pl-5">
                        <span className="text-[#137695] text-[16px] font-bold">
                          {commentItem.additionalInfo.judgementTitle}
                        </span>
                      </Col>
                      <Col span={20} className="flex items-center">
                        <span className="text-[#707070] text-[14px]">
                          {commentItem.additionalInfo.judgementComment}
                        </span>
                      </Col>
                    </Row>
                  ))}
                </div>
              ))}
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default CommentSettings
