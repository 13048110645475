import { DocumentNode, gql } from '@apollo/client'

export const params = {
  'surveyFeedbackInput': {
    'refId': '12426369669846326401699267078314',
    'endedDateTime': '2023-10-19T09:19:59.54',
    'feedbackStatus': 'DRAFT',
    'selectedAnswerList': [
      {
        'questionRefId': '46669800115670684491699267008907',
        'options': [
          '37407315027975403831697688253252'
        ],
        'displayNameMap': {
          'en': 'en',
          'ja': 'ja bla bla bla '
        }
      },
      {
        'questionRefId': '46669800115670684491699267008907',
        'options': [
          '50863513887683209211698281808275'
        ]
      },
      {
        'questionRefId': '46669800115670684491699267008907',
        'options': [
          '44666464193732103631698281808285'
        ]
      },
      {
        'questionRefId': '46669800115670684491699267008907',
        'options': [
          '73821667965021920171698281808292',
          '64852541049334207201698281808294'
        ]
      },
      {
        'questionRefId': '46669800115670684491699267008907',
        'selectedDate': '2002-01-02'
      },
      {
        'questionRefId': '46669800115670684491699267008907',
        'value': '3'
      },
      {
        'questionRefId': '46669800115670684491699267008907',
        'statementResult': [
          {
            'statementRefId': '75989058933451856531698281808329',
            'optionRefId': '67848445936421640401698281808325'
          },
          {
            'statementRefId': '36583592189063799871698281808332',
            'optionRefId': '58956961810774565591698281808327'
          }
        ]
      }
    ]
  }
}

export const UPDATE_SURVEY_FEEDBACK: DocumentNode = gql`
mutation ($surveyFeedbackInput: SurveyFeedbackUpdateInput) {
  updateSurveyFeedback(surveyFeedbackInput: $surveyFeedbackInput) {
    statusCode
    message
    timestamp
    payload {
      isUpdated
      refId
      reason
      updated {
        refId
        userRefId
        startedDateTime
        endedDateTime
        feedbackStatus
        surveyRefId
        selectedAnswerList {
          refId
          displayNameMap {
            en
            ja
          }
          options {
            refId
          }
          question {
            refId
            order
            subsectionType
            status
            questionType
            displayNameMap {
              en
              ja
            }
            isRequired
          }
          selectedDate
          selectedTime
          mediaFileList {
            refId
            name
            url
            mediaType
            status
            file
          }
          # value
          statementResult {
            refId
            statementRefId
            optionRefId
          }
        }
      }
    }
  }
}
`
