import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Flex, Input, Typography } from 'antd'
import * as yup from 'yup'

import { openNotification } from '../../components/widgets/Notification'
import { useAuth } from '../../context/AuthProvider'
import { useLocalStorage } from '../../hooks'
import { findValidTenant } from '../../utilities/helpers'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReactComponent as EmailIcon } from 'assets/svgs/email.svg'
import { ReactComponent as PasswordIcon } from 'assets/svgs/password.svg'

type DataForm = {
  email: string
  password: string
}

const createSchema = (t: any) => {
  return yup
    .object()
    .shape({
      email: yup.string().required(t('errors.email')),
      password: yup.string().required(t('errors.password'))
    })
    .required()
}

const TENANT_PARAM_KEY = 'tenant'

export default function Login() {
  const { t } = useTranslation()
  const schema = createSchema(t)
  const auth = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [, setTenant] = useLocalStorage('@tenant', {})

  useEffect(() => {
    if (
      searchParams.has(TENANT_PARAM_KEY) &&
      findValidTenant(searchParams.get(TENANT_PARAM_KEY) ?? '')
    ) {
      const tenant = findValidTenant(searchParams.get(TENANT_PARAM_KEY) ?? '')
      // console.log('founded tenant', tenant)
      setTenant(tenant)
    }
  }, [searchParams, setTenant])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<DataForm>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: DataForm) => {
    const response = await auth?.loginAction(data)
    if (response && response.error && response?.code === 401) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.login')
      })
      return
    }

    if (response?.error) {
      openNotification({
        type: 'error',
        title: 'commonError',
        message: 'errors.login'
      })
      return
    }

    navigate('/')
  }

  return (
    <Flex vertical className="w-[400px] pt-[278px] text-center m-auto">
      <Typography className="tracking-[2.4px] text-primary font-bold text-2xl leading-none">
        {t('login')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex vertical className="mt-[92px]">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                status={errors.email ? 'error' : ''}
                placeholder={t('placeholder.emailAddress')}
                prefix={<EmailIcon className="w-[18px] mr-2.5" />}
                className="px-[13px] h-10"
              />
            )}
          />
          <Typography className="text-error text-xs h-3 mt-1.5 leading-none text-left">
            {errors.email ? errors.email?.message : ''}
          </Typography>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                status={errors.password ? 'error' : ''}
                placeholder={t('placeholder.password')}
                prefix={<PasswordIcon className="w-5 mr-2.5 " />}
                className="px-[13px] h-10 mt-8"
              />
            )}
          />

          <Typography className="text-error text-xs h-3 mt-1.5 leading-none text-left">
            {errors.password ? errors.password?.message : ''}
          </Typography>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="mt-8 text-sm"
          >
            {t('login')}
          </Button>
        </Flex>
      </form>
      {/* <Link to="/forgot-password">
        <Typography className="tracking-[.7px] text-primary underline font-bold leading-none mt-[23px]">
          {t('forgotPassword')}
        </Typography>
      </Link> */}
    </Flex>
  )
}
