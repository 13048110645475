import { useTranslation } from 'react-i18next'

import { Flex } from 'antd'

export default function ComprehensiveJudgment({
  listEvaluation
}: {
  listEvaluation: any
}) {
  const { t } = useTranslation()
  return (
    <div className="w-full">
      <Flex className="h-[50px] bg-[#ffffff] text-[#545454]">
        <div className="w-[25%] flex justify-between items-center">
          <div className="ml-[7px]">
            <span className="font-bold">
              {t('testResult.generalEvaluation')}
            </span>
          </div>
          <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
        </div>
        {listEvaluation &&
          listEvaluation.map((item: any, index: any) => (
            <div key={index} className="w-[25%] flex justify-between">
              <div className="w-[99%] mt-[4px] ml-[11px] mr-[7px] justify-between">
                <span className="font-bold block text-[29px]">
                  {item.evaluation}
                </span>
              </div>
              <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
            </div>
          ))}
      </Flex>
      <div className="w-full h-[1px] bg-[#BFC6CB]"></div>
    </div>
  )
}
