import { CSSProperties, useState } from 'react'

import { Flex, List } from 'antd'

import { VALUE_WITH_NO_DATA } from 'configs/constant'

export const InspectionResultCategoryList = ({
  items,
  styles,
  fixedCategory,
  language
}: {
  items: any
  styles?: CSSProperties
  fixedCategory: any
  language: any
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0)
  const category = [...items, ...fixedCategory]

  return (
    <Flex style={styles} className="h-full">
      <div className="shadow-md bg-[#DCE3EC] flex-1">
        <div className="ml-3 mt-3">
          <List itemLayout="vertical">
            {category.map((item, index) => (
              <a
                href={`#${item.refId}`}
                style={{ color: 'inherit', textDecoration: 'none' }}
                key={index}
              >
                <List.Item
                  className="font-bold"
                  style={{
                    padding: '8px 16px',
                    backgroundColor:
                      activeIndex === index ? '#137695' : '#DCE3EC',
                    color: activeIndex === index ? '#FFFFFF' : '#137695',
                    borderRadius:
                      activeIndex === index ? '6px 0px 0px 6px' : '',
                    cursor: 'pointer'
                  }}
                  onClick={() => setActiveIndex(index)}
                >
                  {item?.lang?.[language] ?? VALUE_WITH_NO_DATA}
                </List.Item>
              </a>
            ))}
          </List>
        </div>
      </div>
    </Flex>
  )
}
