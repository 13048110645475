import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Col, Flex, Input, Typography } from 'antd'
import * as yup from 'yup'

import InspectionResultByType from './InspectionResultByType'
import { InspectionResultCategoryList } from './InspectionResultCategoryList'
import InstructionsAndComments from './InstructionsAndComments'
import PatientInfoForm from './PatientInfoForm'
import SaveInspectionResultModal from './SaveInspectionResultModal'
import SaveSuccessModal from './SaveSuccessModal'
import TemporarySaveModal from './TemporarySaveModal'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReactComponent as SearchOutlinedIcon } from 'assets/svgs/SearchOutlined.svg'
import { ReactComponent as CheckedIcon } from 'assets/svgs/checked-38.svg'
import { SpinLoading } from 'components/screens/Spin'
import { openNotification } from 'components/widgets/Notification'
import {
  MEDICAL_CHECKUP_MASTER_EXAMPLE,
  OVERALL_VERDICT_CATEGORY_EXAMPLE
} from 'configs/api'
import { MEDICAL_CHECKUP_STATUS } from 'configs/constant'
import { useAuth } from 'context/AuthProvider'
import { useGetCheckupUsers, useGetUserDetails } from 'hooks/useCheckupUser'
import {
  useCreateMedicalCheckup,
  useMedicalCheckupDetails,
  useUpdateMedicalCheckup
} from 'hooks/useMedicalCheckup'
import { useFirstMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import useTestItemCategory from 'hooks/useTestItemCategory'
import useTestItemMaster from 'hooks/useTestItemMaster'
import useTestResult, {
  useCreateTestResult,
  useUpdateTestResult
} from 'hooks/useTestResult'
import {
  getCurrentDateFormatted,
  getLanguage,
  getUserByToken,
  isObjectEmpty
} from 'utilities/helpers'

type DataForm = {
  inspection_date: string
  medical_record_number: string
  date_of_birth: string
  sex: string
  full_name: string
  report_number: string
  age: number
  medical_checkup_course: string
}

export const infoFixedCategory = [
  {
    code: '03.0001',
    order: 1,
    comprehensiveJudgment: true
  },
  {
    code: '03.0152',
    order: 2
  }
  // {
  //   code: '03.0004',
  //   order: 3
  // },
  // {
  //   code: '1.00069',
  //   order: 4
  // }
  // {
  //   code: '03.0005',
  //   order: 5
  // }
]

const FULL_NAME = 'full_name'
const PATIENT_ID = 'patient_id'
const SEX = 'sex'
const DATE_OF_BIRTH = 'date_of_birth'

const basicInformation = [
  {
    code: '1.00003',
    order: 1,
    disable: false
  },
  {
    code: '1.00005',
    order: 2,
    field: FULL_NAME,
    disable: true
  },
  {
    code: '1.00004',
    order: 3,
    field: PATIENT_ID,
    disable: true
  },
  {
    code: '1.00011',
    order: 4,
    field: SEX,
    disable: true
  },
  {
    code: '1.0001',
    order: 5,
    field: DATE_OF_BIRTH,
    disable: true
  },
  {
    code: '1.00246',
    order: 6,
    disable: false
  }
]

const initialSchema = {}

const enumValueEvaluation = ['A', 'B', 'C']

export default function InspectionResultCreate() {
  const { t } = useTranslation('')
  let language = getLanguage()
  const navigate = useNavigate()
  const { getUserDetailsById } = useGetUserDetails()
  const { getCheckupUsers } = useGetCheckupUsers()
  const { getMedicalCheckupDetailsById } = useMedicalCheckupDetails()
  const { getFirstMedicalCheckupMaster } = useFirstMedicalCheckupMaster()
  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { createMedicalCheckup } = useCreateMedicalCheckup()
  const { updateMedicalCheckup } = useUpdateMedicalCheckup()
  const { getListItemCategory } = useTestItemCategory()
  const { createTestResult } = useCreateTestResult()
  const { updateTestResult } = useUpdateTestResult()
  const { getListTestResult } = useTestResult()

  const [searchParams, setSearchParams] = useSearchParams()
  const [userContext, setUserContext] = useState<any>(
    useAuth()?.user ?? getUserByToken()
  )
  const [temporarySave, setTemporarySave] = useState<boolean>(false)
  const [save, setSave] = useState<boolean>(false)
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
  const [status, setStatus] = useState<any>(null)
  const [user, setUser] = useState<any>({})
  const [medicalCheckupMaster, setMedicalCheckupMaster] = useState<any>({})
  const [medicalCheckup, setMedicalCheckup] = useState<any>({})
  const [medicalCheckupMasterInfo, setMedicalCheckupMasterInfo] = useState<any>(
    []
  )
  const [dataCategory, setDataCategory] = useState<any>([])
  const [dataTestResult, setDataTestResult] = useState<any>(null)
  const [fixedCategory, setFixedCategory] = useState<any>([])
  const [overallVerdict, setOverallVerdict] = useState<any>([])
  const [valueAssessment, setValueAssessment] = useState<any>({})
  const [changeCommentOverallVerdict, setChangeCommentOverallVerdict] =
    useState<any>(false)
  const [nameComprehensiveJudgment, setNameComprehensiveJudgment] =
    useState<any>(null)

  const [schema, setSchema] = useState<any>(initialSchema)
  const [resolverSchema, setResolverSchema] = useState<any>(
    yup.object().shape(schema)
  )
  const methods = useForm<DataForm>({
    resolver: yupResolver(resolverSchema),
    mode: 'onBlur'
  })

  useEffect(() => {
    setResolverSchema(yup.object().shape(schema))
  }, [schema])

  const {
    handleSubmit,
    setValue,
    getValues,
    trigger: triggerValidation
  } = methods

  const getDataUser = async (idUser: string) => {
    const user = await getUserDetailsById(idUser)

    if (user) {
      setUser(user)
    } else {
      navigate('/')
    }
  }

  const getFixedData = async () => {
    const arrCodes = [...infoFixedCategory, ...basicInformation]
    const arrCodesMap = arrCodes.map((item) => item.code)

    let itemMaster = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'code.coding.code',
              operator: 'in',
              value: arrCodesMap
            }
          ]
        }
      }
    ])

    let itemMasterFixedCategory = []
    let itemMasterBasicInformation = []

    for (let item of itemMaster) {
      const isCategory = infoFixedCategory.find(
        (category) => category.code === item?.code?.coding?.[0]?.code
      )

      if (isCategory) {
        if (isCategory?.comprehensiveJudgment) {
          setNameComprehensiveJudgment(item?.refId)
        }

        itemMasterFixedCategory.push({ ...item, order: isCategory.order })

        continue
      }

      const isItemMasterInfo = basicInformation.find(
        (itemMasterInfo) =>
          itemMasterInfo.code === item?.code?.coding?.[0]?.code
      )

      if (isItemMasterInfo)
        itemMasterBasicInformation.push({
          ...item,
          order: isItemMasterInfo.order,
          field: isItemMasterInfo?.field,
          disable: isItemMasterInfo.disable
        })
    }

    itemMasterFixedCategory.sort((a: any, b: any) => a.order - b.order)
    itemMasterBasicInformation.sort((a: any, b: any) => a.order - b.order)

    setFixedCategory(itemMasterFixedCategory)
    setMedicalCheckupMasterInfo(itemMasterBasicInformation)

    let validationSchema = {}
    validationSchema = itemMasterBasicInformation.reduce(
      (acc: any, field: any) => {
        let validator = yup.string()

        return { ...acc, [field.refId]: validator }
      },
      validationSchema
    )
    setSchema((prev: any) => ({
      ...prev,
      ...validationSchema
    }))
  }

  useEffect(() => {
    const idUser = searchParams.get('userRefId')
    const refId = searchParams.get('refId')

    if (idUser) {
      getDataUser(idUser)
    }

    getFixedData()

    if (refId) {
      const fetchDataMedicalCheckup = async () => {
        const data = await getMedicalCheckupDetailsById(refId)
        if (
          data &&
          data?.medicalCheckup?.additionalInfo?.healthCheckStatus !==
            MEDICAL_CHECKUP_STATUS.SUBMITTED
        ) {
          setUser(data?.checkupUser?.[0])
          setMedicalCheckup(data?.medicalCheckup)
          if (
            data?.medicalCheckupMaster &&
            data.medicalCheckupMaster.length > 0
          ) {
            setMedicalCheckupMaster(data?.medicalCheckupMaster?.[0])
            fetchFirstMedicalCheckupMaster(data?.medicalCheckupMaster?.[0])
          }
        } else {
          navigate('/customer-management')
        }
      }
      fetchDataMedicalCheckup()
    } else {
      fetchFirstMedicalCheckupMaster()
    }
  }, [])

  useEffect(() => {
    const runDiagnostics = async () => {
      try {
        if (
          !valueAssessment ||
          Object.keys(valueAssessment).length === 0 ||
          !nameComprehensiveJudgment
        )
          return

        let objectValue = { ...valueAssessment }

        for (let category of dataCategory) {
          let value =
            valueAssessment?.[category?.refId] ?? getValues(category?.refId)
          if (!value) continue

          objectValue[category?.refId] = value
        }

        if (!changeCommentOverallVerdict) setChangeCommentOverallVerdict(true)

        let arrValue = Object.values(objectValue)
        arrValue = arrValue.sort().reverse()
        for (let value of arrValue) {
          if (enumValueEvaluation.includes(value as string)) {
            setValue(nameComprehensiveJudgment, value)
            break
          }
        }
      } catch (err) {
        console.error(err)
      }
    }

    runDiagnostics()
  }, [valueAssessment])

  const fetchFirstMedicalCheckupMaster = async (
    dataMedicalCheckupMaster: any = null
  ) => {
    let data = null
    if (dataMedicalCheckupMaster && !isObjectEmpty(dataMedicalCheckupMaster)) {
      data = dataMedicalCheckupMaster
    } else {
      //  data = await getFirstMedicalCheckupMaster()
      data = await getMedicalCheckupMastersById(MEDICAL_CHECKUP_MASTER_EXAMPLE)

      data = data?.medicalCheckupMaster
    }

    let categoryOrder = data?.additionalInfo?.categoryOrder ?? {}

    setMedicalCheckupMaster(data)
    let validationSchema = {}

    const arrItemMasterRefId = data?.associatedTestItemMasters

    let listItemMaster = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'refId',
              operator: 'in',
              value: arrItemMasterRefId
            }
          ]
        }
      }
    ])

    const dataCategoryFetch = await getListItemCategory({
      // filter: `(in,STRING,associatedTestItemMasterRefIds,${arrItemMasterRefId.join(',')});(eq,STRING,status,PUBLISHED)`
      filter: `(in,STRING,refId,${OVERALL_VERDICT_CATEGORY_EXAMPLE},13215064603757287001726460393637,88665023344512015941726460435377,83216780091146409801726460484181,66863821368653047771726460525201,45797661172382888381726460564253,50303274715688017811726460592767,35872960772656501091726460641343,6157615577841498081726460663928,75393933929457064601726460690362,15761740032421589841726460728026,88765061765373168031726460770519,24127498591334101701726460803388,50069000893238963771726460828814,48850182716056353011726460846236);(eq,STRING,status,PUBLISHED)`, //hard code
      sortBy: '(desc,refId)'
    })

    let dataCategory = dataCategoryFetch?.data?.filterTestItemCategory?.payload

    dataCategory = Object.values(
      dataCategory.reduce((acc: any, obj: any) => {
        if (!acc[obj.refId] || acc[obj.refId].createdDate < obj.createdDate) {
          acc[obj.refId] = obj
        }
        return acc
      }, {})
    )

    let elementToMove = dataCategory.find(
      (item: any) => item.refId === OVERALL_VERDICT_CATEGORY_EXAMPLE
    )

    if (elementToMove) {
      const dataCommentOverallVerdict = await getCommentCategory(
        elementToMove?.associatedTestItemMasterRefIds
      )

      let commentOverallVerdict = dataCommentOverallVerdict.filter(function (
        item: any
      ) {
        return item?.additionalInfo?.key3 === '所見コメント'
      })

      commentOverallVerdict = commentOverallVerdict.filter(function (
        item: any
      ) {
        return !(
          item?.additionalInfo?.isHidden === 1 ||
          item?.additionalInfo?.isDeleted === 1
        )
      })

      setOverallVerdict(commentOverallVerdict)

      dataCategory = dataCategory.filter(
        (item: any) => item.refId !== OVERALL_VERDICT_CATEGORY_EXAMPLE
      )
    }

    let categoryFormat = []

    for (let itemCategory of dataCategory) {
      let itemMasterCategory = getItemMasterCategory(
        itemCategory?.associatedTestItemMasterRefIds,
        listItemMaster
      )

      if (itemMasterCategory && itemMasterCategory.length > 0) {
        const dataCommentCategory = await getCommentCategory(
          itemCategory?.associatedTestItemMasterRefIds
        )

        let idItemMasterCategory = dataCommentCategory.filter(function (
          item: any
        ) {
          return item?.additionalInfo?.key3 !== '所見コメント'
        })

        let comment = dataCommentCategory.filter(function (item: any) {
          return item?.additionalInfo?.key3 === '所見コメント'
        })

        comment = comment.filter(function (item: any) {
          return !(
            item?.additionalInfo?.isHidden === 1 ||
            item?.additionalInfo?.isDeleted === 1
          )
        })

        categoryFormat.push({
          refId: itemCategory?.refId,
          shortName: itemCategory?.shortName,
          associatedTestItemMasterRefIds:
            itemCategory?.associatedTestItemMasterRefIds,
          itemMaster: itemMasterCategory,
          comment: comment,
          idItemMaster: idItemMasterCategory?.[0]?.refId,
          lang: idItemMasterCategory?.[0]?.lang,
          order: categoryOrder?.[itemCategory?.refId] ?? 9999
        })
      }
    }

    validationSchema = categoryFormat.reduce((acc: any, field: any) => {
      let validator = yup.string()

      return {
        ...acc,
        [field.refId]: validator,
        [field.refId + '_comment']: validator
      }
    }, validationSchema)

    categoryFormat.sort((a: any, b: any) => a.order - b.order)
    setDataCategory(categoryFormat)
    setSchema((prev: any) => ({
      ...prev,
      ...validationSchema
    }))
  }

  const getItemMasterCategory = (
    ItemMasterRefIds: any,
    listItemMaster: any
  ) => {
    const data = []
    let validationSchema = {}
    for (let itemMaster of listItemMaster) {
      if (
        ItemMasterRefIds.includes(itemMaster?.refId) &&
        itemMaster?.additionalInfo?.key1 === '検査項目マスター'
      ) {
        let validator: any = yup.string()
        if (itemMaster?.readingType === 'NUMERICAL') {
          validator = yup
            .number()
            .nullable()
            .min(0)
            .transform((value, originalValue) => {
              return originalValue.trim() === '' ? null : value
            })
        }

        validationSchema = {
          ...validationSchema,
          [itemMaster.refId]: validator
        }
        data.push(itemMaster)
      }
    }

    setSchema((prev: any) => ({
      ...prev,
      ...validationSchema
    }))

    return data
  }

  useEffect(() => {
    if (
      medicalCheckupMasterInfo &&
      medicalCheckupMasterInfo.length > 0 &&
      user &&
      !isObjectEmpty(user) &&
      dataCategory &&
      dataCategory.length > 0
    ) {
      if (searchParams.get('refId')) {
        handleDataMedicalCheckup(searchParams.get('refId'))
      } else {
        handleUserInfo(user)
      }
    }
  }, [medicalCheckupMasterInfo, dataCategory])

  const getCommentCategory = async (ItemMasterRefIds: any) => {
    let dataItemMasterFetch = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'refId',
              operator: 'in',
              value: ItemMasterRefIds
            },
            {
              field: 'additionalInfo.key1',
              operator: 'eq',
              value: 'カテゴリマスタ'
            }
          ]
        }
      }
    ])

    return dataItemMasterFetch
  }

  const onSubmit = () => {
    if (!checkUserId()) return
    setSave(true)
  }

  const checkUserId = () => {
    if (!user?.refId) {
      openNotification({
        type: 'error',
        title: t('errors.errorOccurred'),
        message: t('errors.userIdNotFound')
      })

      return false
    }

    return true
  }

  const handleCreateMedicalCheckup = async (
    data: any,
    healthCheckStatus: any
  ) => {
    if (medicalCheckup?.refId) {
      await handleUpdateMedicalCheckup(data, healthCheckStatus)
      return
    }

    let userRefId = user?.refId

    const requestMedicalCheckup = [
      {
        medicalCheckupMasterRef: medicalCheckupMaster?.refId,
        checkupUserRef: userRefId,
        additionalInfo: {
          healthCheckStatus: healthCheckStatus
        }
      }
    ]

    const medicalCheckupCreate: any = await createMedicalCheckup(
      requestMedicalCheckup
    )

    const medicalCheckupId =
      medicalCheckupCreate?.data?.addMedicalCheckup?.payload?.[0]?.refId

    // const medicalCheckupId = '42279366232129054141719911591431'

    const dataCreateTestResultUserInfo = []
    const dataCreateTestResultCategory = []
    const dataCreateTestResultCategoryItem = []
    const dataCreateTestResultCategoryFixed = []

    for (let item of medicalCheckupMasterInfo) {
      dataCreateTestResultUserInfo.push({
        medicalCheckupRefId: medicalCheckupId,
        medicalCheckupUserRefId: user.refId,
        itemMasterRefId: item.refId,
        value: data[item.refId] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        readingLocation: '',
        additionalInfo: {
          timestamp: Date.now(),
          key1: '基本情報マスター',
          healthCheckStatus: healthCheckStatus
        },
        evaluation: ''
      })
    }

    for (let item of dataCategory) {
      dataCreateTestResultCategory.push({
        medicalCheckupRefId: medicalCheckupId,
        medicalCheckupUserRefId: user.refId,
        itemMasterRefId: item.idItemMaster,
        value: data[item.refId + '_comment'] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        readingLocation: '',
        additionalInfo: {
          timestamp: Date.now(),
          key1: 'カテゴリマスタ',
          healthCheckStatus: healthCheckStatus
        },
        evaluation: data[item.refId] ?? ''
      })

      for (let itemMaster of item.itemMaster) {
        dataCreateTestResultCategoryItem.push({
          medicalCheckupRefId: medicalCheckupId,
          medicalCheckupUserRefId: user.refId,
          itemMasterRefId: itemMaster.refId,
          value: data[itemMaster.refId] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          readingLocation: '',
          additionalInfo: {
            timestamp: Date.now(),
            key1: '検査項目マスター',
            healthCheckStatus: healthCheckStatus
          },
          evaluation: ''
        })
      }
    }

    for (let item of fixedCategory) {
      dataCreateTestResultCategoryFixed.push({
        medicalCheckupRefId: medicalCheckupId,
        medicalCheckupUserRefId: user.refId,
        itemMasterRefId: item.refId,
        value: data[item.refId] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        readingLocation: '',
        additionalInfo: {
          timestamp: Date.now(),
          healthCheckStatus: healthCheckStatus
        },
        evaluation: ''
      })
    }

    await createTestResult([
      ...dataCreateTestResultUserInfo,
      ...dataCreateTestResultCategory,
      ...dataCreateTestResultCategoryItem,
      ...dataCreateTestResultCategoryFixed
    ])
  }

  const handleUpdateMedicalCheckup = async (
    data: any,
    healthCheckStatus: any
  ) => {
    const { visibility, createdDate, ...dataMedicalCheckupUpdate } =
      medicalCheckup

    const requestMedicalCheckup = [
      {
        ...dataMedicalCheckupUpdate,
        additionalInfo: {
          healthCheckStatus: healthCheckStatus
        }
      }
    ]

    await updateMedicalCheckup(requestMedicalCheckup)

    const dataUpdateTestResultUserInfo = []
    const dataUpdateTestResultCategory = []
    const dataUpdateTestResultCategoryItem = []
    const dataUpdateTestResultCategoryFixed = []

    const dataCreateTestResult = []
    for (let item of medicalCheckupMasterInfo) {
      if (!dataTestResult[item.refId]) {
        dataCreateTestResult.push({
          medicalCheckupRefId: dataMedicalCheckupUpdate?.refId,
          medicalCheckupUserRefId: user.refId,
          itemMasterRefId: item.refId,
          value: data[item.refId] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          readingLocation: '',
          additionalInfo: {
            timestamp: Date.now(),
            key1: '基本情報マスター',
            healthCheckStatus: healthCheckStatus
          },
          evaluation: ''
        })

        continue
      }

      dataUpdateTestResultUserInfo.push({
        ...dataTestResult[item.refId],
        value: data[item.refId] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        additionalInfo: {
          timestamp: Date.now(),
          key1: '基本情報マスター'
        }
      })
    }

    for (let item of dataCategory) {
      if (!dataTestResult[item.idItemMaster]) {
        dataCreateTestResult.push({
          medicalCheckupRefId: dataMedicalCheckupUpdate?.refId,
          medicalCheckupUserRefId: user.refId,
          itemMasterRefId: item.idItemMaster,
          value: data[item.refId + '_comment'] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          readingLocation: '',
          additionalInfo: {
            timestamp: Date.now(),
            key1: 'カテゴリマスタ',
            healthCheckStatus: healthCheckStatus
          },
          evaluation: data[item.refId] ?? ''
        })
      } else {
        dataUpdateTestResultCategory.push({
          ...dataTestResult[item.idItemMaster],
          value: data[item.refId + '_comment'] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          additionalInfo: {
            timestamp: Date.now(),
            key1: 'カテゴリマスタ'
          },
          evaluation: data[item.refId] ?? ''
        })
      }

      for (let itemMaster of item.itemMaster) {
        if (!dataTestResult[itemMaster.refId]) {
          dataCreateTestResult.push({
            medicalCheckupRefId: dataMedicalCheckupUpdate?.refId,
            medicalCheckupUserRefId: user.refId,
            itemMasterRefId: itemMaster.refId,
            value: data[itemMaster.refId] ?? '',
            valueBy: userContext?.name,
            readingTakenOn: getCurrentDateFormatted(),
            readingLocation: '',
            additionalInfo: {
              timestamp: Date.now(),
              key1: '検査項目マスター',
              healthCheckStatus: healthCheckStatus
            },
            evaluation: ''
          })

          continue
        }

        dataUpdateTestResultCategoryItem.push({
          ...dataTestResult[itemMaster.refId],
          value: data[itemMaster.refId] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          additionalInfo: {
            timestamp: Date.now(),
            key1: '検査項目マスター'
          }
        })
      }
    }

    for (let item of fixedCategory) {
      if (!dataTestResult[item.refId]) {
        dataCreateTestResult.push({
          medicalCheckupRefId: dataMedicalCheckupUpdate?.refId,
          medicalCheckupUserRefId: user.refId,
          itemMasterRefId: item.refId,
          value: data[item.refId] ?? '',
          valueBy: userContext?.name,
          readingTakenOn: getCurrentDateFormatted(),
          readingLocation: '',
          additionalInfo: {
            timestamp: Date.now(),
            healthCheckStatus: healthCheckStatus
          },
          evaluation: ''
        })

        continue
      }
      dataUpdateTestResultCategoryFixed.push({
        ...dataTestResult[item.refId],
        value: data[item.refId] ?? '',
        valueBy: userContext?.name,
        readingTakenOn: getCurrentDateFormatted(),
        additionalInfo: {
          timestamp: Date.now()
        }
      })
    }

    if (dataCreateTestResult.length > 0) {
      await createTestResult([...dataCreateTestResult])
    }

    await updateTestResult([
      ...dataUpdateTestResultUserInfo,
      ...dataUpdateTestResultCategory,
      ...dataUpdateTestResultCategoryItem,
      ...dataUpdateTestResultCategoryFixed
    ])
  }

  const handleCheckUserSearch = async (e: any) => {
    if (e.target.value) {
      let dataCheckupUser = await getCheckupUsers({
        filter: `(eq,STRING,refId,${e.target.value})`,
        page: 0,
        size: 1,
        sortBy: '(desc,createdDate)'
      })

      let user = dataCheckupUser?.[0]
      if (user) {
        const newParams = new URLSearchParams(searchParams)
        newParams.set('userRefId', user.refId)
        setSearchParams(newParams)
        setUser(user)
        handleUserInfo(user)
      } else {
        openNotification({
          type: 'error',
          title: t('errors.errorOccurred'),
          message: t('errors.patientIdNotFound')
        })
      }
    }
  }

  const handleDataMedicalCheckup = async (medicalCheckupRefId: any) => {
    let dataItemTestResult = await getListTestResult({
      filter: `(eq,STRING,medicalCheckupRefId,${medicalCheckupRefId});(eq,STRING,status,PUBLISHED)`,
      sortBy: '(desc,readingTakenOn)'
    })
    let dataForm = dataItemTestResult?.data?.filterTestResult?.payload

    const data = dataForm.reduce((acc: any, obj: any) => {
      if (
        !acc[obj.itemMasterRefId] ||
        acc[obj.itemMasterRefId].readingTakenOn < obj.readingTakenOn
      ) {
        acc[obj.itemMasterRefId] = obj
      }
      return acc
    }, {})

    for (let item of medicalCheckupMasterInfo) {
      setValue(item.refId, data?.[item.refId]?.value)
    }

    if (searchParams.get('refId')) {
      setDataTestResult(data)
      setStatus(medicalCheckup?.additionalInfo?.healthCheckStatus)
      for (let item of dataCategory) {
        setValue(item.refId, data?.[item.idItemMaster]?.evaluation)
        let categoryComment: any = `${item.refId}_comment`
        setValue(categoryComment, data?.[item.idItemMaster]?.value)
        for (let itemMaster of item.itemMaster) {
          if (data?.[itemMaster.refId]?.value) {
            setValue(itemMaster.refId, data?.[itemMaster.refId]?.value)
          }
        }
      }

      for (let item of fixedCategory) {
        setValue(item.refId, data?.[item.refId]?.value)
      }
    }
  }

  const handleUserInfo = async (user: any) => {
    for (let item of medicalCheckupMasterInfo) {
      let field = item?.field

      switch (field) {
        case FULL_NAME:
          const firstName = user?.additionalInfo?.firstName || ''
          const lastName = user?.additionalInfo?.lastName || ''
          setValue(item.refId, `${firstName}${lastName}`.trim())
          break
        case PATIENT_ID:
          setValue(item.refId, user?.refId)
          break
        case SEX:
          setValue(item.refId, user?.gender)
          break
        case DATE_OF_BIRTH:
          setValue(item.refId, user?.birthday)
          break
      }
    }
  }

  const handleChangeRatingValue = (name: string, value: any) => {
    setValueAssessment((prevValues: any) => ({
      ...prevValues,
      [name]: value
    }))
  }

  if (
    searchParams.get('refId') &&
    (!dataTestResult || dataTestResult.length < 1)
  ) {
    return (
      <div className="flex justify-center items-center">
        <SpinLoading loading={true} />
      </div>
    )
  }

  return (
    <Flex vertical className="ml-[-10px] mb-87px">
      <Input
        prefix={<SearchOutlinedIcon />}
        placeholder={t('placeholder.search')}
        className="w-[23.5%] ml-2.5"
        disabled={searchParams.get('refId') ? true : false}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleCheckUserSearch(e)
          }
        }}
      />

      <Flex className="mt-4 gap-8">
        <div className="w-[11.7%]">
          <InspectionResultCategoryList
            items={dataCategory}
            fixedCategory={fixedCategory}
            language={language}
          />
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex-1" noValidate>
            <Flex>
              {/* {!temporarySave && (
                <Typography className="bg-[#f9d3e3] flex-1 flex items-center text-error leading-none mr-[14.4%]">
                  <ExclamationCircleFilled className="w-[21px] text-error ml-6 mr-3" />
                  未入力の項目が100項目あります。
                </Typography>
              )} */}
              {temporarySave && (
                <Typography className="bg-[#d0e4ea] flex-1 flex items-center text-primary font-semibold leading-none mr-[14.4%]">
                  <CheckedIcon className="rounded-full ml-6 mr-3" />
                  {t('content.temporarilySavedContent')}
                </Typography>
              )}

              <Flex className="justify-end gap-2 flex-1">
                <Button
                  type="primary"
                  className="w-[80px] font-bold"
                  htmlType="submit"
                >
                  {t('button.keep')}
                </Button>
                <Button
                  className="min-w-[80px] border-[#137695] text-[#137695] font-bold"
                  onClick={async () => {
                    if (!checkUserId()) return
                    setTemporarySave(true)
                  }}
                >
                  {t('button.saveDraft')}
                </Button>
              </Flex>
            </Flex>

            <div className="mt-5">
              <PatientInfoForm
                medicalCheckupMasterInfo={medicalCheckupMasterInfo}
                language={language}
              />
            </div>

            <Flex className="text-white bg-primary pb-3 pt-3.5 pl-1.5 pr-5 font-bold leading-none mt-[30px]">
              <Col flex="16.9%">{t('testResult.inspectionItem')}</Col>
              <Col flex="12.4%">{t('testResult.referenceValue')}</Col>
              <Col flex="auto">{t('lable.inspectionResult')}</Col>
            </Flex>
            {/* {InspectionResultExample.length > 0 &&
                InspectionResultExample.map((item) => (
                  <InspectionResultByType key={item.name} data={item} />
                ))} */}
            {dataCategory.length > 0 &&
              dataCategory.map((item: any, index: any) => (
                <InspectionResultByType
                  key={item.refId + index}
                  data={item}
                  user={user}
                  setValue={setValue}
                  triggerValidation={triggerValidation}
                  dataTestResult={dataTestResult}
                  handleChangeRatingValue={handleChangeRatingValue}
                  language={language}
                />
              ))}
            <InstructionsAndComments
              fixedCategory={fixedCategory}
              overallVerdict={overallVerdict}
              setValue={setValue}
              triggerValidation={triggerValidation}
              changeCommentOverallVerdict={changeCommentOverallVerdict}
              setChangeCommentOverallVerdict={setChangeCommentOverallVerdict}
              language={language}
            />

            {/* <Flex
                className="bg-primary gap-12 px-1.5 pt-[14px] pb-3 mt-[26px]"
                id="health_status_report"
              >
                <Typography className="font-bold text-white">
                  健康状態レポート
                </Typography>
              </Flex>
              {HealthStatusExample.length > 0 && (
                <Flex className="flex-wrap gap-[7.6%]">
                  {HealthStatusExample.map((item) => (
                    <div className="w-[34%]" key={item.name}>
                      <HealthStatusReport data={item} />
                    </div>
                  ))}
                </Flex>
              )} */}

            <Flex className="justify-end gap-2 flex-1 mt-12 mb-[131px]">
              <Button
                type="primary"
                className="w-[80px] font-bold"
                htmlType="submit"
              >
                {t('button.keep')}
              </Button>
              <Button
                className="min-w-[80px] border-[#137695] text-[#137695] font-bold"
                onClick={async () => {
                  if (!checkUserId()) return
                  setTemporarySave(true)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
              >
                {t('button.saveDraft')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Flex>

      <SaveSuccessModal
        onCancel={() => {
          setSaveSuccess(false)
          navigate('/customer-management')
        }}
        isModalOpen={saveSuccess}
      />
      <SaveInspectionResultModal
        isModalOpen={save}
        onCancel={() => {
          setSave(false)
        }}
        data={getValues()}
        status={status}
        setSaveSuccess={setSaveSuccess}
        handleCreateMedicalCheckup={handleCreateMedicalCheckup}
      />
      <TemporarySaveModal
        onCancel={() => {
          setTemporarySave(false)
        }}
        onOk={() => {
          setTemporarySave(false)
          navigate('/customer-management')
        }}
        isModalOpen={temporarySave}
        status={status}
        data={getValues()}
        handleCreateMedicalCheckup={handleCreateMedicalCheckup}
      />
    </Flex>
  )
}
