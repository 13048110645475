import { useTranslation } from 'react-i18next'
import { Button, Flex, Typography } from 'antd'
import CustomModal from 'components/modals'
import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg';

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onSuccess: () => void
}

const EditAdminConfirmModal = ({
  isModalOpen = false,
  onCancel,
  onSuccess
}: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //todo
    onSuccess()
    onCancel()
  }

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={handleOk}>
      <Flex vertical className="text-center p-4 pb-5">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('editAdmin')}
        </Typography>
        <ExclamationCircleFilled className="mt-[95px] m-auto w-[40px] text-error" />
        <Paragraph className="mt-[23px] mb-0 text-error">
          {t('content.editAdminNotify')}
        </Paragraph>
        <Flex className="bg-[#fef2f7] text-error py-3 mt-[22px] justify-center border-solid border-error border-[1px]">
          {t('content.editAdminName')}
        </Flex>
        <Paragraph className="mt-[23px] mb-0">
          {t('content.editAdminConfirm')}
        </Paragraph>

        <Flex className="justify-center gap-[12px] mt-[93px]">
          <Button
            type="primary"
            className="w-[180px] font-bold"
            htmlType="submit"
            onClick={handleOk}
          >
            {t('button.keep')}
          </Button>
          <Button
            className="w-[180px] border-[#137695] text-[#137695] font-bold"
            onClick={onCancel}
          >
            {t('button.cancel')}
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  )
}

export default EditAdminConfirmModal
