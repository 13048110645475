import axios, { HttpStatusCode } from 'axios'
import qs from 'qs'

import { openNotification } from '../components/widgets/Notification'
import { KEYCLOAK_URL } from '../configs/api'
import { getAccessTokenClient, getValidToken } from './helpers'
import * as Sentry from '@sentry/react'
import axiosRetry from 'axios-retry'

// Exponential back-off retry delay between requests
axiosRetry(axios, { retries: 2, retryDelay: axiosRetry.exponentialDelay })

export const axiosInstance: any = axios.create({
  baseURL: `${KEYCLOAK_URL}`,
  timeout: 60000
})

/**
 * get request headers
 * @returns
 */
axiosInstance.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    let token = getValidToken(getAccessTokenClient() || '')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }

    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'

    return config
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// define error handle
axiosInstance.interceptors.response.use(
  (response: any) => {
    const { data } = response
    // handle error code
    switch (data.error_code) {
      default:
        break
    }
    return response.data
  },
  async (error: any) => {
    Sentry.captureException(error)

    const { response, code } = error
    // handle error code

    if (response && response.status === HttpStatusCode.Unauthorized) {
      localStorage.clear()
      window.location.href = '/login'
    }

    switch (code) {
      case 'ERR_BAD_REQUEST':
        openNotification({
          type: 'error',
          title: 'commonError',
          message: ''
        })
        return Promise.reject(error)
      default:
        openNotification({
          type: 'error',
          title: 'commonError',
          message: ''
        })
        break
    }

    return response && response.data ? response : Promise.reject(error)
  }
)

export default {
  /**
   * post request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   * @param headers
   */
  post: (endpoint: string, data: any, headers?: any) => {
    return axiosInstance.post(endpoint, data, headers)
  },

  /**
   * put
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  put: (endpoint: string, data: any) => {
    return axiosInstance.put(endpoint, JSON.stringify(data), {})
  },

  /**
   * get request
   * @param {string} endpoint
   * @param {*} params All parameter need to pass for server
   */
  get: (endpoint: string, params: any = {}) => {
    return axiosInstance.get(endpoint, {
      params,
      paramsSerializer: {
        encode: qs.parse,
        serialize: qs.stringify
      }
    })
  },

  /**
   * get request async
   * @param {string} endpoint
   * @param {*} params All parameter need to pass for server
   */
  getAsync: async (endpoint: string, params: any = {}) => {
    const response = await axiosInstance.get(endpoint, {
      params,
      paramsSerializer: {
        encode: qs.parse,
        serialize: qs.stringify
      }
    })
    const { data, status, statusText } = response
    if (statusText && statusText !== 'ok') {
      console.warn('handle refresh token')

      return response && data ? response : Promise.reject(data)
    }

    if (status === 403) throw new Error('Forbidden')

    return response
  },

  /**
   * delete request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  delete: (endpoint: string, data?: any) => {
    return axiosInstance.delete(endpoint, {
      data
    })
  },

  /**
   * patch request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  patch: (endpoint: string, data: any) => {
    return axiosInstance.patch(endpoint, data)
  }
}
