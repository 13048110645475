import React, { useState } from 'react'
import { UseFieldArrayUpdate } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, ConfigProvider, Flex, Typography } from 'antd'

import { useGroupModal } from '../../../../hooks/useUserGroup'
import DeleteSuccessModal from '../../../../pages/BasicSetting/components/DeleteSuccessModal'
import CustomModal from 'components/modals'

type Props = {
  subTenantList: any
  isModalOpen: boolean
  onCancel: () => void
  update: UseFieldArrayUpdate<any, 'subTenantList'>
}

const DeleteModal = ({
  isModalOpen = false,
  onCancel,
  subTenantList,
  update
}: Props) => {
  const { t } = useTranslation()
  let open = isModalOpen
  const [isShowModal, setIsShowModal] = useState(false)
  const { selectedIndex } = useGroupModal()

  const [group, setGroup] = useState<any>(
    selectedIndex ? subTenantList[selectedIndex] : {}
  )

  React.useEffect(() => {
    if (selectedIndex !== undefined) {
      setGroup(subTenantList[selectedIndex])
    }

    return () => {}
  }, [selectedIndex, subTenantList])

  const handleConfirm = () => {
    if (selectedIndex !== undefined) {
      const { additionalInfo } = group
      update(selectedIndex, {
        ...group,
        additionalInfo: { ...additionalInfo, status: 'deleted' }
      })
      showSuccessModal()
    }
  }

  const showSuccessModal = () => {
    open = false
    onCancel()
    setIsShowModal(true)
  }

  return (
    <>
      <CustomModal open={open} onOk={handleConfirm} onCancel={onCancel}>
        <Flex vertical className="p-[16px] gap-[26px]">
          <Typography className="text-[20px] text-[#545454] font-bold self-center">
            {t('deleteContact')}
          </Typography>
          <Flex vertical className="gap-6 ml-[12rem]">
            <Typography className="text-[14px]">
              {t('content.deleteContact')}
            </Typography>
            <Typography className="text-[14px] text-[#DF2475] mt-[-20px]">
              {t('content.cannotRestoreContact')}
            </Typography>
          </Flex>
          <Flex vertical className="px-[60px] gap-[28px]">
            <Flex vertical className="gap-[10px]">
              <Typography className="font-bold">
                {t('basicSetting.familyName')}
              </Typography>
              <Typography>{group?.name}</Typography>
            </Flex>
            <Flex vertical className="gap-[10px]">
              <Typography className="font-bold">{t('lable.phone')}</Typography>
              <Typography>{group?.phoneNumber}</Typography>
            </Flex>
            <Flex vertical className="gap-[10px]">
              <Flex>
                <Typography className="font-bold">
                  {t('content.cautionNote')}
                </Typography>
              </Flex>
              <Typography className="break-words whitespace-pre-line">
                {group?.note}
              </Typography>
            </Flex>
          </Flex>
          <Flex className="justify-center gap-[12px] mt-[40px]">
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorPrimary: `#DF2475`,
                    colorPrimaryHover: `#DF2475`,
                    colorPrimaryActive: `#DF2475`,
                    lineWidth: 1
                  }
                }
              }}
            >
              <Button
                type="primary"
                className="w-[180px] text-white font-bold"
                onClick={handleConfirm}
                autoInsertSpace={false}
              >
                {t('button.delete')}
              </Button>
            </ConfigProvider>
            <Button
              className="w-[180px] border-[#137695] text-[#137695] font-bold"
              onClick={onCancel}
            >
              {t('button.cancel')}
            </Button>
          </Flex>
        </Flex>
      </CustomModal>
      <DeleteSuccessModal
        onCancel={() => setIsShowModal(false)}
        isModalOpen={isShowModal}
      />
    </>
  )
}

export default DeleteModal
