import { useTranslation } from 'react-i18next'
import { Button, Flex, Typography } from 'antd'
import CustomModal from 'components/modals'
import { ReactComponent as CheckedIcon } from 'assets/svgs/checkedIcon.svg'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
}

const EditAdminSuccessModal = ({ isModalOpen = false, onCancel }: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //
  }

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={handleOk}>
      <Flex vertical className="text-center pt-4 pb-[47px]">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('editAdminSuccess')}
        </Typography>
        <CheckedIcon
          className="mt-[158.5px] m-auto w-[40px] h-[40px]"
        />
        <Paragraph className="mt-[23px] mb-0">
          {t('content.editAdminSuccess')}
        </Paragraph>

        <Button
          className="w-[180px] text-primary bg-white py-2 leading-none font-bold mt-[116px] m-auto border-primary"
          onClick={onCancel}
        >
          {t('button.close')}
        </Button>
      </Flex>
    </CustomModal>
  )
}

export default EditAdminSuccessModal
