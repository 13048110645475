import React, { FC } from 'react'

import { Button, Flex, Typography } from 'antd'
import { TFunction } from 'i18next'

import CustomModal from 'components/modals/CustomModal'

type Props = {
  t: TFunction<'translation', undefined>
  isOpen: boolean
  onCancel: () => void
}

const RegisteredNotice: FC<Props> = ({ t, isOpen, onCancel }) => {
  return (
    <CustomModal
      open={isOpen}
      onOk={() => {}}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="pb-6 pt-4">
          <div>
            <Typography className="mb-[176px] flex justify-center text-[20px] font-bold tracking-[1px]">
              {t('customerRegister.modal.registeredNotice.created')}
            </Typography>
            <Typography className="flex justify-center tracking-[1px]">
              {t('customerRegister.modal.registeredNotice.successfully')}
            </Typography>
            <Flex justify="center" className="mt-[171px]">
              <Button
                type="default"
                htmlType="button"
                size="large"
                onClick={onCancel}
                className="h-[30px] w-[180px] rounded-none border-[1px] border-[#137695] bg-white p-0 text-center text-[14px] font-bold text-[#137695] shadow-none"
              >
                {t('customerRegister.modal.registeredNotice.back')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default RegisteredNotice
