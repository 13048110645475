import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Collapse, Flex, Input, Typography } from 'antd'

import styles from './InspectionItemSetting.module.scss'
import { MenuOutlined } from '@ant-design/icons'
import { ReactComponent as ArrowMenu } from 'assets/svgs/arrow-menu.svg'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import 'tailwindcss/tailwind.css'

const { Panel } = Collapse

type Props = {
  showInspectionDescription: boolean
  showUpdate: boolean
  setShowNoteHiddenSettingModal: (boolean: boolean) => void
  category: any
  language: any
}

const CollapsibleList = ({
  showInspectionDescription,
  showUpdate,
  setShowNoteHiddenSettingModal,
  category,
  language
}: Props) => {
  const { t } = useTranslation()

  const [isEdit, setIsEditValue] = useState(false)
  return (
    <div
      className={`category-with-items w-full border border-[#BDCBD5] pr-[8px] pb-[44px] bg-white rounded-r-lg ${styles.inspection_content_box} ${styles.inspection_content_header}`}
    >
      <Typography className="font-bold pl-[7px] mt-[13px] leading-none">
        {t('setInspection')}
      </Typography>

      <div className="mt-[25px] max-h-[700px] overflow-x-hidden overflow-y-scroll">
        {category &&
          category.map((item: any) => (
            <Collapse
              key={item.refId}
              defaultActiveKey={[item.refId]}
              expandIcon={({ isActive }) => (
                <ArrowMenu
                  className={`mr-3 ml-2 ${isActive ? '' : 'rotate-[-90deg]'}`}
                  onClick={
                    showUpdate && isActive
                      ? () => setShowNoteHiddenSettingModal(true)
                      : () => {}
                  }
                />
              )}
              expandIconPosition="right"
              collapsible={showUpdate ? 'icon' : undefined}
            >
              <Panel
                header={
                  <div
                    className="flex justify-between items-center"
                    id={item.refId}
                  >
                    <div
                      className={`w-[36.6%] leading-none ${showUpdate ? 'pl-[24px]' : 'pl-[27px]'}`}
                    >
                      {!showUpdate &&
                        (item.lang?.[language] ?? VALUE_WITH_NO_DATA)}
                      {showUpdate && (
                        <Input
                          defaultValue={item.lang?.[language]}
                          className="h-[22px] px-1"
                        />
                      )}
                    </div>
                    <span className="flex-1 text-xs text-[#BDCBD5] tracking-[.6px] text-center">
                      {item?.code?.coding?.[0]?.code}
                    </span>
                    <div className="w-[46.8%] text-left leading-none">
                      {!showUpdate &&
                        showInspectionDescription &&
                        (item.lang?.en ?? VALUE_WITH_NO_DATA)}
                      {showUpdate && (
                        <Input
                          defaultValue={item.lang?.en}
                          className="h-[22px] px-1"
                        />
                      )}
                    </div>
                  </div>
                }
                key={item.refId}
              >
                <div className="flex flex-col space-y-2">
                  {item.items.map((categoryItem: any) => (
                    <div
                      key={categoryItem.refId}
                      className="flex justify-between items-center border-b border-[#BDCBD5] min-h-[30px]"
                    >
                      <Flex className="flex-1 items-center">
                        <span
                          className={`w-[36.7%] py-2 leading-none ${showUpdate ? 'pl-[24px]' : 'pl-[27px]'}`}
                        >
                          {!showUpdate &&
                            (categoryItem.lang?.[language] ??
                              VALUE_WITH_NO_DATA)}
                          {showUpdate && (
                            <Input
                              defaultValue={categoryItem.lang?.[language]}
                              className="h-[22px] px-1"
                            />
                          )}
                        </span>
                        <span className="flex-1 text-xs text-[#BDCBD5] tracking-[.6px] text-center">
                          {categoryItem?.code?.coding?.[0]?.code}
                        </span>
                        <span className="w-[47%] text-left leading-none">
                          {!showUpdate &&
                            showInspectionDescription &&
                            (categoryItem.lang?.en ?? VALUE_WITH_NO_DATA)}
                          {showUpdate && (
                            <Input
                              defaultValue={categoryItem.lang?.en}
                              className="h-[22px] px-1"
                            />
                          )}
                        </span>
                      </Flex>

                      <span className={`w-[15px] mr-3 ml-2`}>
                        <MenuOutlined />
                      </span>
                    </div>
                  ))}
                </div>
              </Panel>
            </Collapse>
          ))}
      </div>
    </div>
  )
}

export default CollapsibleList
