import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CREATE_CHECKUP_USER } from 'graphql/CheckupUser/createCheckupUser'
import { FILTER_CHECKUP_USER } from 'graphql/CheckupUser/filterCheckupUser'

const useCreateCheckupUser = () => {
  const [createCheckupUserMutation, { data, loading, error }] =
    useMutation(CREATE_CHECKUP_USER)

  const createCheckupUser = async (request: any) => {
    try {
      const result = await createCheckupUserMutation({
        variables: { request: request },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    createCheckupUser,
    data,
    loading,
    error
  }
}

const useGetUserDetails = () => {
  const [loadUserList, { loading, data, error }] =
    useLazyQuery(FILTER_CHECKUP_USER)

  const getUserDetailsById = async (id: string) => {
    try {
      const result = await loadUserList({
        variables: {
          filter: `(eq,STRING,refId,${id})`,
          page: 0,
          size: 1,
          sortBy: '(desc,createdDate)'
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result?.data?.filterCheckupUser?.payload?.[0]
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getUserDetailsById,
    data,
    loading,
    error
  }
}

const useGetCheckupUsers = () => {
  const [loadUserList, { loading, data, error }] =
    useLazyQuery(FILTER_CHECKUP_USER)

  const getCheckupUsers = async (variables: any) => {
    try {
      const result = await loadUserList({
        variables: variables,
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result?.data?.filterCheckupUser?.payload
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getCheckupUsers,
    data,
    loading,
    error
  }
}

export { useCreateCheckupUser, useGetUserDetails, useGetCheckupUsers }
