import { useEffect, useState } from 'react'

import {
  FILTER_MEDICAL_CHECKUP,
  FILTER_MEDICAL_CHECKUP_DETAILS,
  VariableProps
} from '../graphql/MedicalCheckup/filterMedicalCheckup'
import { MedicalCheckup } from '../models/medicalCheckup'
import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CREATE_MEDICAL_CHECKUP } from 'graphql/MedicalCheckup/createMedicalCheckup'
import { UPDATE_MEDICAL_CHECKUP } from 'graphql/MedicalCheckup/updateMedicalCheckup'

const variablesInitFilter: VariableProps = {
  page: 0,
  size: 10,
  filter:
    '(eq,STRING,refId,),(eq,STRING,medicalCheckupMasterRef,),(eq,STRING,checkupUserRef,)'
}
const useMedicalCheckup = () => {
  const [medicalCheckup, setMedicalCheckup] = useState<MedicalCheckup[]>([])
  const [variablesFilter, setVariablesFilter] =
    useState<VariableProps>(variablesInitFilter)

  const [loadMedicalCheckup, { called, loading, data }] = useLazyQuery(
    FILTER_MEDICAL_CHECKUP,
    {
      variables: variablesFilter,
      context: { version: Endpoint.CHECKUP_CORE },
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first' // Used for subsequent executions
    }
  )

  useEffect(() => {
    !called && loadMedicalCheckup()

    if (!loading && data && data.hasOwnProperty('filterMedicalCheckup')) {
      const {
        filterMedicalCheckup: { payload }
      } = data
      // console.log({ payload })

      const medicalCheckupData: MedicalCheckup[] = []

      setMedicalCheckup(medicalCheckupData)
    }
  }, [loading])

  return {
    medicalCheckup,
    variablesFilter,
    setVariablesFilter,
    setMedicalCheckup,
    loadMedicalCheckup,
    loading
  }
}

export const useMedicalCheckupDetails = () => {
  const [loadMedicalCheckupList, { loading, data, error }] = useLazyQuery(
    FILTER_MEDICAL_CHECKUP_DETAILS
  )

  const getMedicalCheckupDetailsById = async (id: string) => {
    try {
      const result = await loadMedicalCheckupList({
        variables: {
          filter: `(eq,STRING,refId,${id});(eq,STRING,status,PUBLISHED);(ne,STRING,additionalInfo.status,DELETED)`,
          page: 0,
          size: 1,
          sortBy: '(desc,createdDate)'
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result?.data?.filterMedicalCheckupDetails?.payload?.[0]
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getMedicalCheckupDetailsById,
    data,
    loading,
    error
  }
}

export const useCreateMedicalCheckup = () => {
  const [createMedicalCheckupMutation, { data, loading, error }] = useMutation(
    CREATE_MEDICAL_CHECKUP
  )

  const createMedicalCheckup = async (request: any) => {
    try {
      const result = await createMedicalCheckupMutation({
        variables: { request: request },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    createMedicalCheckup,
    data,
    loading,
    error
  }
}

export const useUpdateMedicalCheckup = () => {
  const [updateMedicalCheckupMutation, { data, loading, error }] = useMutation(
    UPDATE_MEDICAL_CHECKUP
  )

  const updateMedicalCheckup = async (request: any) => {
    try {
      const result = await updateMedicalCheckupMutation({
        variables: { request: request },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    updateMedicalCheckup,
    data,
    loading,
    error
  }
}

export default useMedicalCheckup
