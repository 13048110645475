import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Input, List, Modal, Popover, Typography } from 'antd'

import styles from './InspectionItemSetting.module.scss'
import { MenuOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { ReactComponent as CopyIcon } from 'assets/svgs/copy–268.svg'
import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import { ReactComponent as AddItemIcon } from 'assets/svgs/plus–42.svg'
import { VALUE_WITH_NO_DATA } from 'configs/constant'

type Props = {
  isAddNewCategory: boolean
  showInspectionDescription: boolean
  showUpdate: boolean
  setIsAddNewCategory: (boolean: boolean) => void
  category: any
  isAddNewItemCategory: boolean
  setIsAddNewItemCategory: (boolean: boolean) => void
  language: any
}

const CategoryAndItemList = ({
  isAddNewCategory,
  showInspectionDescription,
  showUpdate,
  setIsAddNewCategory,
  category,
  isAddNewItemCategory,
  setIsAddNewItemCategory,
  language
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModalReset = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const { t } = useTranslation()

  return (
    <div className="rounded-lg shadow-md bg-[#fff] pr-[11px] pt-2 pl-[13px] flex-1 pb-6 category-item-list h-max">
      <Flex className="items-center pl-[9px] justify-between">
        <Flex className="gap-[3px]">
          <Typography className="font-bold">
            {t('currentHiddenTest')}
          </Typography>

          <Popover
            placement="top"
            content={
              <div className="p-2 w-[330px] bg-[#545454] text-white rounded">
                {t('popover.hideByDragAndDrop')}
              </div>
            }
            overlayClassName="popover-permission"
          >
            <QuestionCircleFilled className="text-sm text-[#B4B4B4]" />
          </Popover>
        </Flex>

        <Typography
          className="border border-[#137695] rounded-2xl text-[#137695] font-bold text-[12px] py-1.5 pl-[9px] pr-[3px] leading-none cursor-pointer"
          onClick={showModalReset}
        >
          {t('restoreDefaultSetting')}
        </Typography>
      </Flex>

      <List className="mt-[21px]">
        {category &&
          category.map((item: any) => (
            <div key={item.refId} className="mt-5">
              <List.Item className="border border-[#CDD6DD] bg-[#EFF3F6] h-[30px]">
                <Flex className="w-full items-center">
                  <Flex
                    className={`flex-1 ${showUpdate ? 'gap-1.5 pl-[9px] pr-[3px]' : ''}`}
                  >
                    {!showUpdate && (
                      <Typography className="w-1/2 pl-2">
                        {item.lang?.[language] ?? VALUE_WITH_NO_DATA}
                      </Typography>
                    )}
                    {!showUpdate && showInspectionDescription && (
                      <Typography className="w-1/2">
                        {item.lang?.en ?? VALUE_WITH_NO_DATA}
                      </Typography>
                    )}
                    {showUpdate && (
                      <Input
                        defaultValue={item.lang?.[language]}
                        className="w-1/2 h-[22px] p-1"
                      />
                    )}
                    {showUpdate && (
                      <Input
                        defaultValue={item.lang?.en}
                        className="w-1/2 h-[22px] p-1"
                        placeholder={t(
                          'questionnaire.placeholder.englishDisplay'
                        )}
                      />
                    )}
                  </Flex>

                  <Flex className="gap-[3px] items-center mr-[14px]">
                    <Popover
                      placement="top"
                      content={
                        <div className="px-1 py-[2px] text-white rounded bg-[#545454]">
                          {t('popover.copy')}
                        </div>
                      }
                      overlayClassName="popover-permission"
                    >
                      {/*<CopyIcon className="cursor-pointer" />*/}
                    </Popover>
                    <MenuOutlined />
                  </Flex>
                </Flex>
              </List.Item>
              {item.items.map((data: any) => (
                <List.Item className="border border-[#D9E0E5] bg-white h-[30px] mt-2.5">
                  <Flex className="w-full items-center">
                    <Flex
                      className={`flex-1 ${showUpdate ? 'gap-1.5 pl-[9px] pr-[3px]' : ''}`}
                    >
                      {!showUpdate && (
                        <Typography className="w-1/2 pl-2">
                          {data.lang?.[language] ?? VALUE_WITH_NO_DATA}
                        </Typography>
                      )}
                      {!showUpdate && showInspectionDescription && (
                        <Typography className="w-1/2">
                          {data.lang?.en ?? VALUE_WITH_NO_DATA}
                        </Typography>
                      )}
                      {showUpdate && (
                        <Input
                          defaultValue={data.lang?.[language]}
                          className="w-1/2 h-[22px] p-1"
                        />
                      )}
                      {showUpdate && (
                        <Input
                          defaultValue={data.lang?.en}
                          className="w-1/2 h-[22px] p-1"
                          placeholder={t(
                            'questionnaire.placeholder.englishDisplay'
                          )}
                        />
                      )}
                    </Flex>

                    <Flex className="gap-[3px] items-center mr-[14px]">
                      <Popover
                        placement="top"
                        content={
                          <div className="px-1 py-[2px] text-white rounded bg-[#545454]">
                            {t('popover.copy')}
                          </div>
                        }
                        overlayClassName="popover-permission"
                      >
                        {/*<CopyIcon className="cursor-pointer" />*/}
                      </Popover>

                      <MenuOutlined />
                    </Flex>
                  </Flex>
                </List.Item>
              ))}
            </div>
          ))}
      </List>
      <div>
        <Modal
          title={null}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={true}
          closable={false}
          width={600}
          footer={[
            <div key="footer" className={styles.btn_wrapper}>
              <Button key="close" type="primary" onClick={handleOk}>
                <Typography className="text-[14px] text-[#FFFFFF] font-bold">
                  {t('button.keep')}
                </Typography>
              </Button>

              <Button key="close" type="default" onClick={handleCancel}>
                <Typography className="text-[14px] font-bold">
                  {t('button.cancel')}
                </Typography>
              </Button>
            </div>
          ]}
          className={styles.modal_wrapper}
          style={{ textAlign: 'center' }}
        >
          <div className={styles.modal_header}></div>
          <div className={styles.modal_title}>
            {t('content.initTestItemSetting')}
          </div>
          <div className={styles.modal_content_reset}>
            <ExclamationCircleFilled
              style={{
                paddingBottom: '40px',
                width: '40px',
                display: 'inline-block'
              }}
            />
            <p>{t('content.initTestItemWarning1')}</p>
            <p>{t('content.initTestItemWarning2')}</p>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default CategoryAndItemList
