import { DocumentNode, gql } from '@apollo/client'

export const params = {
}

export const CREATE_COMMENT: DocumentNode = gql`
  mutation addItemMaster($request:[CreateItemMaster!]!){
    addItemMaster(request:$request){
      status,
      message,
      timestamp
      payload {
        refId,
        shortName,
        displayName,
        unitOfMeasure,
        readingType,
        referenceValues,
        additionalInfo
      }
    }
  }
`
