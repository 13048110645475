import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import i18n, { TFunction } from 'i18next'

import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { format } from 'date-fns'
import { getObjectByIdItemMaster, getTenantAddress } from 'utilities/helpers'

const styles = StyleSheet.create({
  container: {
    fontSize: 6,
    lineHeight: '1',
    margin: 0,
    width: 'auto',
    fontFamily: ['YuGothicUI', 'Roboto'],
    fontWeight: 'normal'
  }
})

const defaultRow = 63

type Props = {
  t: TFunction<'translation', undefined>
  i18n: any
  customer: any
}

const NameView: FC<Props> = ({ t, i18n, customer }) => {
  if (i18n.language === 'jp') {
    return (
      <View
        style={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'row',
          fontSize: 8
        }}
      >
        <View
          style={{
            fontWeight: 'bold',
            width: '19.75%'
          }}
        >
          <Text>{`${customer?.firstName}${customer?.lastName}`}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
            flex: '1',
            paddingRight: 35
          }}
        >
          {/* <Text>花子</Text> */}
          <Text>{t('pdfExport.personTitle')}</Text>
        </View>
      </View>
    )
  } else {
    return (
      <View
        style={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'row',
          fontSize: 8
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            flex: '1',
            paddingRight: 35
          }}
        >
          {/* <Text>花子</Text> */}
          <Text>{t('pdfExport.personTitle')}</Text>
        </View>
        <View
          style={{
            fontWeight: 'bold',
            width: '19.75%'
          }}
        >
          <Text>{`${customer?.firstName}${customer?.lastName}`}</Text>
        </View>
      </View>
    )
  }
}

const ResultPreviewPdf = ({
  pdfResult,
  tenant
}: {
  pdfResult: any
  tenant?: any
}) => {
  const { t } = useTranslation()
  let language = i18n.language
  let customer = pdfResult?.customer
  let lastMedical = pdfResult?.data?.[0]
  let secondMedical = pdfResult?.data?.[1]
  let firstMedical = pdfResult?.data?.[2]

  let birthday = customer?.birthday
    ? format(customer?.birthday, 'yyyy/MM/dd')
    : ''
  let tenantAddress = getTenantAddress(tenant)

  let dataLastMedical = getObjectByIdItemMaster(
    lastMedical?.category,
    customer?.gender,
    t
  )
  let dataSecondMedical = getObjectByIdItemMaster(
    secondMedical?.category,
    customer?.gender,
    t
  )
  let dataFirstMedical = getObjectByIdItemMaster(
    firstMedical?.category,
    customer?.gender,
    t
  )

  let tableFirst = []
  let tableFirstLength = 0
  let tableSecond = []
  let tableSecondLength = 0

  let category: any = Array.isArray(lastMedical?.category)
    ? lastMedical.category
    : []

  for (let itemCategory of category) {
    let copyItemCategory = JSON.parse(JSON.stringify(itemCategory))

    if (tableFirstLength < defaultRow) {
      let lengthCut = 1 + copyItemCategory?.itemMaster?.length

      if (tableFirstLength + lengthCut > defaultRow) {
        let numItemCut = defaultRow - tableFirstLength

        if (numItemCut === 1) {
          let keepItemCategory = JSON.parse(JSON.stringify(copyItemCategory))

          tableFirstLength = tableFirstLength + 1
          tableSecondLength =
            tableSecondLength + 1 + copyItemCategory?.itemMaster?.length

          keepItemCategory.itemMaster = []
          tableFirst.push(keepItemCategory)
          tableSecond.push(copyItemCategory)

          continue
        }

        let totalItemCut = numItemCut - 1

        tableFirstLength = tableFirstLength + 1 + totalItemCut
        tableSecondLength =
          tableSecondLength +
          1 +
          (copyItemCategory?.itemMaster?.length - totalItemCut)

        let itemMasterInitial = copyItemCategory.itemMaster
        //push table first
        copyItemCategory.itemMaster = itemMasterInitial.slice(0, totalItemCut)
        tableFirst.push(copyItemCategory)

        //push table second
        copyItemCategory.itemMaster = itemMasterInitial.slice(totalItemCut)
        tableFirst.push(copyItemCategory)
      } else {
        tableFirstLength =
          tableFirstLength + 1 + copyItemCategory?.itemMaster?.length
        tableFirst.push(copyItemCategory)
      }
    }
  }

  if (tableFirstLength < defaultRow) {
    for (let i = 0; i < defaultRow - tableFirstLength; i++) {
      tableFirst.push({})
    }
  }

  if (tableSecondLength < defaultRow) {
    for (let i = 0; i < defaultRow - tableSecondLength; i++) {
      tableSecond.push({})
    }
  }

  if (category.length % 3 !== 0) {
    for (let i = 0; i < category.length % 3; i++) {
      category.push({})
    }
  }

  return (
    <Document>
      <Page size="A3" orientation="landscape" style={[styles.container]} wrap>
        <View
          style={{
            width: '100%',
            paddingTop: 55,
            paddingRight: 16,
            paddingLeft: 32,
            paddingBottom: 40
          }}
        >
          <View style={{ gap: 12, display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '25.8%', height: 745 }}>
              <Text
                style={{ fontSize: 10, color: '#147695', fontWeight: 'bold' }}
              >
                {t('pdfExport.regularHealthCheckReport')}
              </Text>
              <Text style={{ marginTop: 16 }}>
                {tenant?.additionalInfo?.name ?? ''}
              </Text>
              <NameView t={t} i18n={i18n} customer={customer} />

              {/* info1 */}
              <View
                style={{
                  marginTop: 12,
                  display: 'flex',
                  flexDirection: 'row',
                  border: '1px solid #93d1ca',
                  fontWeight: 'bold'
                }}
              >
                <View style={{ width: '52.5%' }}>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        width: '37.6%',
                        backgroundColor: '#b9e1dc',
                        padding: 2
                      }}
                    >
                      <Text>{t('customerDetail.form.label.address')}</Text>
                    </View>
                    <View
                      style={{ flex: '1', padding: 2, display: 'flex', gap: 4 }}
                    >
                      <Text>{`${customer?.zipCodePart1}-${customer?.zipCodePart2}`}</Text>
                      <Text>{`${customer?.province}${customer?.district}`}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        width: '37.6%',
                        backgroundColor: '#b9e1dc',
                        padding: 2
                      }}
                    >
                      <Text>{t('customerDetail.form.label.dateOfBirth')}</Text>
                    </View>
                    <View style={{ flex: '1', padding: 2 }}>
                      <Text>{birthday}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        width: '37.6%',
                        backgroundColor: '#b9e1dc',
                        padding: 2
                      }}
                    >
                      <Text>{t('lable.age')}</Text>
                    </View>
                    <View style={{ flex: '1', padding: 2 }}>
                      <Text>{customer?.age ?? ''}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        width: '37.6%',
                        backgroundColor: '#b9e1dc',
                        padding: 2
                      }}
                    >
                      <Text>{t('pdfExport.publicHealthNurseNumber')}</Text>
                    </View>
                    <View style={{ flex: '1', padding: 2 }}>
                      <Text></Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        width: '37.6%',
                        backgroundColor: '#b9e1dc',
                        padding: 2
                      }}
                    >
                      <Text>{t('customerDetail.form.label.symbol')}</Text>
                    </View>
                    <View style={{ flex: '1', padding: 2 }}>
                      <Text>{customer?.insuranceSymbol ?? ''}</Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flex: '1',
                    paddingTop: 24
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        width: '32.6%',
                        backgroundColor: '#b9e1dc',
                        padding: 2
                      }}
                    >
                      <Text>{t('lable.gender')}</Text>
                    </View>
                    <View style={{ flex: '1', padding: 2 }}>
                      <Text>
                        {customer?.gender
                          ? customer.gender === 'MALE'
                            ? t('customerDetail.header.male')
                            : t('customerDetail.header.female')
                          : ''}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        width: '32.6%',
                        backgroundColor: '#b9e1dc',
                        padding: 2
                      }}
                    >
                      <Text>ID</Text>
                    </View>
                    <View
                      style={{
                        flex: '1',
                        padding: 2
                      }}
                    >
                      <Text>
                        {customer?.patientId
                          ? customer.patientId.split('')
                          : ''}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 'auto'
                    }}
                  >
                    <View
                      style={{
                        width: '32.6%',
                        backgroundColor: '#b9e1dc',
                        padding: 2
                      }}
                    >
                      <Text>{t('customerDetail.form.label.number')}</Text>
                    </View>
                    <View style={{ flex: '1', padding: 2 }}>
                      <Text>{customer?.insuranceNumber ?? ''}</Text>
                    </View>
                  </View>
                </View>
              </View>

              {/* info 2 */}
              <View
                style={{
                  marginTop: 12,
                  display: 'flex',
                  border: '1px solid #93d1ca',
                  fontWeight: 'bold'
                }}
              >
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      width: '19.75%',
                      backgroundColor: '#b9e1dc',
                      padding: 2,
                      borderBottom: '1px solid #93d1ca'
                    }}
                  >
                    <Text>{t('pdfExport.examinationDate')}</Text>
                  </View>
                  <View
                    style={{ flex: '1', padding: 2, display: 'flex', gap: 4 }}
                  >
                    <Text>{lastMedical?.consultationDate ?? ''}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      width: '19.75%',
                      backgroundColor: '#b9e1dc',
                      padding: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Text>{t('testResult.inspectionItem')}</Text>
                  </View>
                  <View
                    style={{ flex: '1', padding: 2, display: 'flex', gap: 4 }}
                  >
                    <View
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                      }}
                    >
                      {Array.isArray(lastMedical?.category) &&
                        lastMedical.category.map((item: any, idx: any) => {
                          return (
                            <Text
                              key={idx}
                              style={{
                                width: idx % 2 === 0 ? '41.25%' : '58.75',
                                padding: 2
                              }}
                            >
                              {item?.lang?.[language] ?? ''}
                            </Text>
                          )
                        })}
                    </View>
                  </View>
                </View>
              </View>

              {/* Overall verdict */}
              <View
                style={{
                  marginTop: 14,
                  border: '1px solid #93d1ca',
                  fontWeight: 'bold',
                  width: '68%'
                }}
              >
                <Text
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    width: '100%',
                    backgroundColor: '#137695',
                    padding: 2,
                    paddingBottom: 4
                  }}
                >
                  {t('testResult.generalEvaluation')}
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      width: '29%',
                      backgroundColor: '#b9e1dc',
                      padding: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Text>{t('pdfExport.current')}</Text>
                  </View>
                  <View
                    style={{
                      flex: '1',
                      textAlign: 'center'
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Text
                        style={{
                          flex: '1',
                          paddingTop: 2,
                          paddingBottom: 2,
                          color: '#1c8fbe',
                          border: '1px solid #93d1ca'
                        }}
                      >
                        {lastMedical?.comprehensiveJudgment ?? ''}
                      </Text>
                      <Text
                        style={{
                          width: '40.4%',
                          paddingTop: 2,
                          paddingBottom: 2,
                          border: '1px solid #93d1ca',
                          backgroundColor: '#eaeaea'
                        }}
                      >
                        {t('pdfExport.last')}
                      </Text>
                      <Text
                        style={{
                          flex: '1',
                          paddingTop: 2,
                          paddingBottom: 2,
                          color: '#1c8fbe',
                          border: '1px solid #93d1ca'
                        }}
                      >
                        {secondMedical?.comprehensiveJudgment ?? ''}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Text
                        style={{
                          flex: '1',
                          paddingTop: 2,
                          paddingBottom: 2,
                          color: '#1c8fbe',
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          width: '40.4%',
                          paddingTop: 2,
                          paddingBottom: 2,
                          border: '1px solid #93d1ca',
                          backgroundColor: '#eaeaea'
                        }}
                      >
                        {t('pdfExport.twoTimes')}
                      </Text>
                      <Text
                        style={{
                          flex: '1',
                          paddingTop: 2,
                          paddingBottom: 2,
                          color: '#1c8fbe',
                          border: '1px solid #93d1ca'
                        }}
                      >
                        {firstMedical?.comprehensiveJudgment ?? ''}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              {/* table 3 */}

              <View
                style={{
                  marginTop: 14,
                  border: '1px solid #93d1ca',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View
                  style={{
                    flex: '1',
                    borderRight: '1px solid #93d1ca'
                  }}
                >
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.metabolicSyndromeAssessment')}
                  </Text>
                  <Text
                    style={{
                      width: '100%',
                      padding: lastMedical?.metabolicSyndromeAssessment ? 2 : 5,
                      textAlign: 'center'
                    }}
                  >
                    {lastMedical?.metabolicSyndromeAssessment ?? ''}
                  </Text>
                </View>
                <View
                  style={{
                    flex: '1'
                  }}
                >
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.specificHealthGuidance')}
                  </Text>
                  <Text
                    style={{
                      width: '100%',
                      padding: 2,
                      textAlign: 'center'
                    }}
                  >
                    {lastMedical?.specificHealthGuidance ?? ''}
                  </Text>
                </View>
              </View>

              {/* table 4 */}
              <View
                style={{
                  marginTop: 14,
                  border: '1px solid #93d1ca',
                  fontWeight: 'bold'
                }}
              >
                <Text
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    width: '100%',
                    backgroundColor: '#137695',
                    padding: 2,
                    paddingBottom: 4
                  }}
                >
                  {t('testResult.categoryEvaluation')}
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                >
                  {category.map((item: any, idx: any) => {
                    return (
                      <View
                        key={idx}
                        style={{
                          width: '33.3%',
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        <Text
                          style={{
                            width: '58.6%',
                            backgroundColor: '#b9e1dc',
                            padding: 2,
                            border: '1px solid #93d1ca'
                          }}
                        >
                          {item?.lang?.[language]}
                        </Text>
                        <Text
                          style={{
                            flex: '1',
                            padding: 2,
                            textAlign: 'center',
                            color: '#1c8fbe',
                            border: '1px solid #93d1ca'
                          }}
                        >
                          {item?.evaluation}
                        </Text>
                      </View>
                    )
                  })}
                </View>
              </View>

              <View
                style={{
                  marginTop: 4,
                  display: 'flex',
                  gap: 8,
                  fontSize: 5
                }}
              >
                <Text>A…{t('pdfExport.evaluationA')}</Text>
                <Text>B…{t('pdfExport.evaluationB')}</Text>
                <Text>C…{t('pdfExport.evaluationC')}</Text>
                <Text>D…{t('pdfExport.evaluationD')}</Text>
              </View>

              <View
                style={{
                  marginTop: 16,
                  border: '1px solid #137695'
                }}
              >
                <Text
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    width: '100%',
                    backgroundColor: '#137695',
                    padding: 2,
                    paddingBottom: 4
                  }}
                >
                  {t('testResult.generalComment')}
                </Text>
                <View
                  style={{
                    minHeight: 132,
                    padding: 6
                  }}
                >
                  <Text>{lastMedical?.generalComments ?? ''}</Text>
                </View>
              </View>
            </View>

            {/* block 2 */}
            <View style={{ flex: '1', height: 745 }}>
              <View
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#137695'
                }}
              >
                <View
                  style={{
                    width: '5.714%'
                  }}
                >
                  <Text
                    style={{
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('testResult.evaluation')}
                  </Text>
                </View>
                <View
                  style={{
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <Text
                    style={{
                      width: '17.99%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('inspectionItem')}
                  </Text>
                  <Text
                    style={{
                      width: '17.99%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  ></Text>
                  <Text
                    style={{
                      width: '5.68%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('testResult.unit')}
                  </Text>
                  <Text
                    style={{
                      width: '10.227%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('testResult.referenceValue')}
                  </Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.current')}
                  </Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.last')}
                  </Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.twoTimes')}
                  </Text>
                </View>
              </View>
              {tableFirst.map((category: any, i) => (
                <View
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      width: '5.714%',
                      backgroundColor: '#b9e1dc',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #93d1ca'
                    }}
                  >
                    <Text
                      style={{
                        padding: 2
                      }}
                    >
                      {dataLastMedical?.[category?.idItemMaster]}
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: '1',
                      display: 'flex'
                    }}
                  >
                    <View
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#b9e1dc'
                      }}
                    >
                      <Text
                        style={{
                          width: '17.99%',
                          padding: category?.lang?.[language] ? 2 : 5,
                          border: '1px solid #93d1ca'
                        }}
                      >
                        {category?.lang?.[language] ?? ''}
                      </Text>
                      <Text
                        style={{
                          width: '17.99%',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      >
                        {category?.lang?.en ?? ''}
                      </Text>
                      <Text
                        style={{
                          width: '5.68%',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          width: '10.227%',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          flex: '1',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          flex: '1',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          flex: '1',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                    </View>
                    {Array.isArray(category?.itemMaster) &&
                      category.itemMaster.map((itemMaster: any, k: any) => {
                        return (
                          <View
                            key={k}
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                          >
                            <Text
                              style={{
                                width: '17.99%',
                                backgroundColor: '#e9f5f4',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {itemMaster?.lang?.[language] ?? ''}
                            </Text>
                            <Text
                              style={{
                                width: '17.99%',
                                backgroundColor: '#e9f5f4',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {itemMaster?.lang?.en ?? ''}
                            </Text>
                            <Text
                              style={{
                                width: '5.68%',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {itemMaster?.unitOfMeasure ?? ''}
                            </Text>
                            <Text
                              style={{
                                width: '10.227%',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {dataLastMedical?.[
                                itemMaster.refId + '_referenceValues'
                              ] ?? ''}
                            </Text>
                            <Text
                              style={{
                                flex: '1',
                                textAlign: 'right',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {dataLastMedical?.[itemMaster.refId] ?? ''}
                            </Text>
                            <Text
                              style={{
                                flex: '1',
                                textAlign: 'right',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {dataSecondMedical?.[itemMaster.refId] ?? ''}
                            </Text>
                            <Text
                              style={{
                                flex: '1',
                                textAlign: 'right',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {dataFirstMedical?.[itemMaster.refId] ?? ''}
                            </Text>
                          </View>
                        )
                      })}
                  </View>
                </View>
              ))}
            </View>
            {/* block 3 */}

            <View style={{ flex: '1', height: 745 }}>
              <View
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#137695'
                }}
              >
                <View
                  style={{
                    width: '5.714%'
                  }}
                >
                  <Text
                    style={{
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('testResult.evaluation')}
                  </Text>
                </View>
                <View
                  style={{
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <Text
                    style={{
                      width: '17.99%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('inspectionItem')}
                  </Text>
                  <Text
                    style={{
                      width: '17.99%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  ></Text>
                  <Text
                    style={{
                      width: '5.68%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('testResult.unit')}
                  </Text>
                  <Text
                    style={{
                      width: '10.227%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('testResult.referenceValue')}
                  </Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.current')}
                  </Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.last')}
                  </Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.twoTimes')}
                  </Text>
                </View>
              </View>
              {tableSecond.map((category: any, i) => (
                <View
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      width: '5.714%',
                      backgroundColor: '#b9e1dc',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #93d1ca'
                    }}
                  >
                    <Text
                      style={{
                        padding: 2
                      }}
                    >
                      {dataLastMedical?.[category?.idItemMaster]}
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: '1',
                      display: 'flex'
                    }}
                  >
                    <View
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#b9e1dc'
                      }}
                    >
                      <Text
                        style={{
                          width: '17.99%',
                          padding: category?.lang?.[language] ? 2 : 5,
                          border: '1px solid #93d1ca'
                        }}
                      >
                        {category?.lang?.[language] ?? ''}
                      </Text>
                      <Text
                        style={{
                          width: '17.99%',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      >
                        {category?.lang?.en ?? ''}
                      </Text>
                      <Text
                        style={{
                          width: '5.68%',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          width: '10.227%',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          flex: '1',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          flex: '1',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                      <Text
                        style={{
                          flex: '1',
                          padding: 2,
                          border: '1px solid #93d1ca'
                        }}
                      ></Text>
                    </View>
                    {Array.isArray(category?.itemMaster) &&
                      category.itemMaster.map((itemMaster: any, k: any) => {
                        return (
                          <View
                            key={k}
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                          >
                            <Text
                              style={{
                                width: '17.99%',
                                backgroundColor: '#e9f5f4',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {itemMaster?.lang?.[language] ?? ''}
                            </Text>
                            <Text
                              style={{
                                width: '17.99%',
                                backgroundColor: '#e9f5f4',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {itemMaster?.lang?.en ?? ''}
                            </Text>
                            <Text
                              style={{
                                width: '5.68%',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {itemMaster?.unitOfMeasure ?? ''}
                            </Text>
                            <Text
                              style={{
                                width: '10.227%',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {dataLastMedical?.[
                                itemMaster.refId + '_referenceValues'
                              ] ?? ''}
                            </Text>
                            <Text
                              style={{
                                flex: '1',
                                textAlign: 'right',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {dataLastMedical?.[itemMaster.refId] ?? ''}
                            </Text>
                            <Text
                              style={{
                                flex: '1',
                                textAlign: 'right',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {dataSecondMedical?.[itemMaster.refId] ?? ''}
                            </Text>
                            <Text
                              style={{
                                flex: '1',
                                textAlign: 'right',
                                padding: 2,
                                border: '1px solid #93d1ca'
                              }}
                            >
                              {dataFirstMedical?.[itemMaster.refId] ?? ''}
                            </Text>
                          </View>
                        )
                      })}
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            paddingTop: 8,
            paddingRight: 32,
            paddingLeft: 32,
            paddingBottom: 40
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 'bold'
            }}
          >
            <Text>{`${customer?.firstName}${customer?.lastName}`}</Text>
            <Text style={{ marginLeft: 10 }}>{t('pdfExport.personTitle')}</Text>
            <Text style={{ marginLeft: 68 }}>
              {t('pdfExport.examinationDate')}:
            </Text>
            <Text style={{ marginLeft: 2 }}>
              {lastMedical?.consultationDate ?? ''}
            </Text>
            <Text style={{ marginLeft: 35 }}>ID:</Text>
            <Text style={{ marginLeft: 2 }}>{customer?.patientId ?? ''}</Text>
          </View>

          <View
            style={{
              gap: 22,
              display: 'flex',
              flexDirection: 'row',
              marginTop: 6
            }}
          >
            <View style={{ width: '44.3%', height: 780 }}>
              <View
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#137695'
                }}
              >
                <Text
                  style={{
                    width: '4.748%',
                    padding: 2,
                    border: '1px solid #93d1ca',
                    paddingBottom: 4
                  }}
                >
                  {t('testResult.evaluation')}
                </Text>

                <Text
                  style={{
                    width: '15.43%',
                    padding: 2,
                    border: '1px solid #93d1ca',
                    paddingBottom: 4
                  }}
                >
                  {t('inspectionItem')}
                </Text>
                <Text
                  style={{
                    width: '15.43%',
                    padding: 2,
                    border: '1px solid #93d1ca',
                    paddingBottom: 4
                  }}
                ></Text>
                <Text
                  style={{
                    flex: '1',
                    padding: 2,
                    border: '1px solid #93d1ca',
                    paddingBottom: 4
                  }}
                >
                  {t('pdfExport.current')}
                </Text>
                <Text
                  style={{
                    flex: '1',
                    padding: 2,
                    border: '1px solid #93d1ca',
                    paddingBottom: 4
                  }}
                >
                  {t('pdfExport.last')}
                </Text>
                <Text
                  style={{
                    flex: '1',
                    padding: 2,
                    border: '1px solid #93d1ca',
                    paddingBottom: 4
                  }}
                >
                  {t('pdfExport.twoTimes')}
                </Text>
              </View>
              {Array.from({ length: 63 }).map((record: any, i) => (
                <View
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <Text
                    style={{
                      width: '4.748%',
                      padding: 5,
                      border: '1px solid #93d1ca',
                      backgroundColor: '#b9e1dc'
                    }}
                  ></Text>

                  <Text
                    style={{
                      width: '15.43%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      backgroundColor: '#e9f5f4'
                    }}
                  ></Text>
                  <Text
                    style={{
                      width: '15.43%',
                      padding: 2,
                      border: '1px solid #93d1ca',
                      backgroundColor: '#e9f5f4'
                    }}
                  ></Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca'
                    }}
                  ></Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca'
                    }}
                  ></Text>
                  <Text
                    style={{
                      flex: '1',
                      padding: 2,
                      border: '1px solid #93d1ca'
                    }}
                  ></Text>
                </View>
              ))}
            </View>
            <View style={{ flex: '1', height: 780, display: 'flex', gap: 10 }}>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                <View style={{ flex: '1', border: '1px solid #93d1ca' }}>
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.medicalHistory')}
                  </Text>
                  <View
                    style={{
                      minHeight: 40
                    }}
                  >
                    <Text></Text>
                  </View>
                </View>
                <View style={{ flex: '1', border: '1px solid #93d1ca' }}>
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.currentMedicalHistory')}
                  </Text>
                  <View
                    style={{
                      minHeight: 40
                    }}
                  >
                    <Text></Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 8
                }}
              >
                <View style={{ flex: '1', border: '1px solid #93d1ca' }}>
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.medicationInformation')}
                  </Text>
                  <View
                    style={{
                      minHeight: 32
                    }}
                  >
                    <Text></Text>
                  </View>
                </View>
                <View style={{ flex: '1', border: '1px solid #93d1ca' }}>
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('content.familyHistory')}
                  </Text>
                  <View
                    style={{
                      minHeight: 32
                    }}
                  >
                    <Text></Text>
                  </View>
                </View>
                <View style={{ flex: '1', border: '1px solid #93d1ca' }}>
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('content.vaccinationHistory')}
                  </Text>
                  <View
                    style={{
                      minHeight: 32
                    }}
                  >
                    <Text></Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 8
                }}
              >
                <View style={{ flex: '1', border: '1px solid #93d1ca' }}>
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('content.symptoms')}
                  </Text>
                  <View
                    style={{
                      minHeight: 32
                    }}
                  >
                    <Text></Text>
                  </View>
                </View>
                <View style={{ flex: '1', border: '1px solid #93d1ca' }}>
                  <Text
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      width: '100%',
                      backgroundColor: '#137695',
                      padding: 2,
                      paddingBottom: 4
                    }}
                  >
                    {t('pdfExport.objectiveSymptom')}
                  </Text>
                  <View
                    style={{
                      minHeight: 32
                    }}
                  >
                    <Text></Text>
                  </View>
                </View>
              </View>
              <View style={{ border: '1px solid #93d1ca' }}>
                <Text
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    width: '100%',
                    backgroundColor: '#137695',
                    padding: 2,
                    paddingBottom: 4
                  }}
                >
                  {t('pdfExport.healthStatusReport')}
                </Text>
                <View
                  style={{
                    minHeight: 170
                  }}
                >
                  <Text></Text>
                </View>
              </View>
              <View style={{ border: '1px solid #93d1ca' }}>
                <Text
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    width: '100%',
                    backgroundColor: '#137695',
                    padding: 2,
                    paddingBottom: 4
                  }}
                >
                  {t('content.lifestyleReport')}
                </Text>
                <View
                  style={{
                    minHeight: 170
                  }}
                >
                  <Text></Text>
                </View>
              </View>
              <View style={{ border: '1px solid #93d1ca' }}>
                <View
                  style={{
                    color: 'white',
                    width: '100%',
                    backgroundColor: '#137695',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 8,
                    fontWeight: 'bold',
                    padding: 2,
                    paddingBottom: 4
                  }}
                >
                  <Text>{t('pdfExport.healthGuidance')}</Text>
                  <Text>Health Guidance</Text>
                </View>
                <View
                  style={{
                    minHeight: 115
                  }}
                >
                  <Text></Text>
                </View>
              </View>
              <View style={{ display: 'flex', gap: 4 }}>
                <Text>{tenant?.additionalInfo?.name ?? ''}</Text>
                <Text>{tenantAddress}</Text>
                <View
                  style={{
                    minHeight: 32,
                    width: '10%',
                    borderBottom: '2px solid #d9d9d9'
                  }}
                ></View>
              </View>
              <View
                style={{
                  marginTop: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <View
                  style={{
                    width: '49.15%',
                    padding: 4,
                    borderBottom: '2px solid #d9d9d9'
                  }}
                >
                  <Text>{lastMedical?.doctorName ?? ''}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
export default ResultPreviewPdf
