import React, { useEffect, useState } from 'react'

import { List, Typography } from 'antd'

import styles from '../../pages/InspectionItemSetting/InspectionItemSetting.module.scss'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import 'tailwindcss/tailwind.css'

const CurrentCategoryList = ({
  categoryJpValues
}: {
  categoryJpValues: string[]
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  useEffect(() => {
    setActiveIndex(0)
  }, [])

  return (
    <div className="rounded-custom-tl-br shadow-md bg-[#DCE3EC] flex-1">
      <div
        className={`pl-[9px] pt-4 ${styles.inspection_current_category_list}`}
      >
        <List itemLayout="vertical">
          {categoryJpValues.map((item, index) => (
            <a
              href={`#category_${index}`}
              style={{ color: 'inherit', textDecoration: 'none' }}
              key={index}
            >
              <List.Item
                style={{
                  padding: '8px 16px',
                  backgroundColor:
                    activeIndex === index ? '#137695' : '#DCE3EC',
                  color: activeIndex === index ? '#FFFFFF' : '#137695',
                  borderRadius: activeIndex === index ? '6px 0px 0px 6px' : '',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  lineHeight: 1
                }}
                onClick={() => setActiveIndex(index)}
              >
                <Typography className="text-inherit">
                  {item ?? VALUE_WITH_NO_DATA}
                </Typography>
              </List.Item>
            </a>
          ))}
        </List>
      </div>
    </div>
  )
}

export default CurrentCategoryList
