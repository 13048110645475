export enum WORK_SCHEDULE_TYPE {
  fixedDay = '一律で固定',
  specifiedDays = '曜日ごとに指定'
}

export enum COUNTRY {
  JAPAN = 'ja',
  VIETNAM = 'vi'
}

export enum DAY_TYPE_VALUES {
  businessDay = '1',
  closedDay = '2'
}

export enum DAY_TYPE {
  businessDay = 'basicSetting.businessDay',
  closedDay = 'basicSetting.closedDay'
}

export const WORK_TYPES = [
  {
    label: `basicSetting.fixedUniformly`,
    value: WORK_SCHEDULE_TYPE.fixedDay
  },
  {
    label: `basicSetting.specifyDayOfWeek`,
    value: WORK_SCHEDULE_TYPE.specifiedDays
  }
]

export const selectTimeOptions: any[] = []
for (let i = 1; i <= 24; i++) {
  const time = `${i.toString().padStart(2, '0')}:00`
  selectTimeOptions.push({ value: time, label: time })
}

export const WeekOptions = [
  {
    value: '1',
    label: 'basicSetting.no.first'
  },
  {
    value: '2',
    label: 'basicSetting.no.second'
  },
  {
    value: '3',
    label: 'basicSetting.no.third'
  },
  {
    value: '4',
    label: 'basicSetting.no.fourth'
  },
  {
    value: '5',
    label: 'basicSetting.no.fifth'
  }
]

export const DEFAULT_SPECIFIED_DAY_TIMES = [
  { workday: 'Monday', start: '09:00', end: '20:00', active: false, order: 0 },
  { workday: 'Tuesday', start: '09:00', end: '20:00', active: false, order: 1 },
  {
    workday: 'Wednesday',
    start: '09:00',
    end: '20:00',
    active: false,
    order: 2
  },
  {
    workday: 'Thursday',
    start: '09:00',
    end: '20:00',
    active: false,
    order: 3
  },
  { workday: 'Friday', start: '09:00', end: '20:00', active: false, order: 4 },
  {
    workday: 'Saturday',
    start: '09:00',
    end: '20:00',
    active: false,
    order: 5
  },
  { workday: 'Sunday', start: '09:00', end: '20:00', active: false, order: 6 },
  { workday: 'Holiday', start: '09:00', end: '20:00', active: false, order: 7 }
]

export type InputForm = {
  name?: string
  kanaName?: string
  clinicName?: string
  clinicKanaName?: string
  zipCode?: string
  province?: string
  district?: string
  address?: string
  workScheduleType?: WORK_SCHEDULE_TYPE
  startTime?: string
  endTime?: string
  days?: string[]
}
