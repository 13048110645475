import { useTranslation } from 'react-i18next'

import { Flex } from 'antd'

import { VALUE_WITH_NO_DATA } from 'configs/constant'

export default function JudgmentCategory({
  listCategory,
  language
}: {
  listCategory: any
  language: any
}) {
  const { t } = useTranslation()
  return (
    <div className="w-full">
      <Flex className="h-full bg-[rgb(255,255,255)] text-[#545454]">
        <div className="w-[12%] pb-[1px]">
          <div className="h-full">
            <div className="ml-[7px] h-full justify-between flex items-center">
              <span className="font-bold">
                {t('testResult.categoryEvaluation')}
              </span>
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#BFC6CB]"></div>
        </div>
        <div className="w-[88%] justify-between">
          {listCategory.map((item: any, index: any) => (
            <div key={index}>
              <Flex className="">
                <div className="w-[14.3%] ml-[0.5%] justify-between flex">
                  <div>
                    <span className="font-bold block">
                      {item.categoryName?.[language] ?? VALUE_WITH_NO_DATA}
                    </span>
                    <span className="block">
                      {item.detailName?.en ?? VALUE_WITH_NO_DATA}
                    </span>
                  </div>
                  <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
                </div>
                {item.evaluationList.map((item: any, idx: any) => (
                  <div
                    className="w-[27.9%] ml-[0.5%] justify-between flex"
                    key={idx}
                  >
                    <div>
                      <span className="font-bold mt-[5px] block">
                        {item.evaluation}
                      </span>
                    </div>
                    <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
                  </div>
                ))}
              </Flex>
              <div className="w-full h-[1px] bg-[#BFC6CB]"></div>
            </div>
          ))}
        </div>
      </Flex>
    </div>
  )
}
