import { DocumentNode, gql } from '@apollo/client'

// 2.01  A basic survey update
export const params = {
  surveyInput: {
    refId: '66501309772481349101699063323961',
    code: 'survey-basic-1',
    formType: 'SURVEY',
    surveyStatus: 'DRAFT',
    validFromDate: '2023-10-01',
    validToDate: '2023-10-30',
    createdBy: '11508963493098088971697671282167',
    displayNameMap: {
      en: 'Human capital Survey blah',
      ja: '猫は犬よりも優れています'
    },
    sectionList: [
      {
        refId: '20912896695696281661698045082758',
        order: 1,
        status: 'ACTIVE',
        title: {
          en: 'Smoking one',
          ja: '猫は犬よりも優れています'
        },
        description: {
          en: 'smoking querie ones',
          ja: '猫は犬よりも優れています'
        }
      }
    ]
  }
}

export const UPDATE_SURVEY: DocumentNode = gql`
  mutation ($surveyInput: SurveyUpdateInput!) {
    updateSurvey(surveyInput: $surveyInput) {
      statusCode
      message
      timestamp
      payload {
        isUpdated
        refId
        reason
        updated {
          refId
          code
          formType
          surveyStatus
          validFromDate
          validToDate
          createdBy
          createdDate
          additionalInfo
          displayNameMap {
            en
            ja
          }
          sectionList {
            refId
            order
            status
            title {
              en
              ja
            }
            description {
              en
              ja
            }
            icon {
              refId
              url
              name
              status
            }
            subsectionList {
              refId
              order
              subsectionType
              status
              mediaType
              no
              url
              name
              file
              title {
                en
                ja
              }
              description {
                en
                ja
              }
              questionType
              displayNameMap {
                en
                ja
              }
              isRequired
              setting {
                refId
                status
                no
                displayNameMap {
                  en
                  ja
                }
                minLength
                maxLength
                fromDate
                toDate
                fromTime
                toTime
                maxSizeInKB
                noOfFiles
                startPoint
                endPoint
                slice
                scaleType
                isMultiple
                choiceType
                startLabel
                endLabel
                format
              }
              options {
                refId
                order
                status
                no
                displayNameMap {
                  en
                  ja
                }
              }
              statements {
                refId
                order
                status
                no
                displayNameMap {
                  en
                  ja
                }
              }
            }
          }
        }
      }
    }
  }
`
