import { DocumentNode, gql } from '@apollo/client'

export const FILTER_RULE_LOGICAL: DocumentNode = gql`
  query GetRuleLogicalQuery {
    getRuleLogical {
      message
      status
      payload {
        refId
        sourceCode
      }
    }
  }
`
