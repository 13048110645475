import { DocumentNode, gql } from '@apollo/client'

export type AggregationFilter = {
  collection: string
  page: number
  size: number
  request: any[]
}

export const AGGREGATION_FILTER: DocumentNode = gql`
  query commonAggregationFilter(
    $collection: String!
    $page: Int!
    $size: Int!
    $request: [AggregationFilterDto]
  ) {
    commonAggregationFilter(
      collection: $collection
      page: $page
      size: $size
      request: $request
    ) {
      timestamp
      status
      message
      totalElements
      payload
    }
  }
`
