import { Table } from 'antd'

import { PermissionTableDataSource } from 'models/admin'

type Props = {
  dataSource: PermissionTableDataSource[]
  loading: boolean
  columns: any[]
}

const PermissionTable = ({ dataSource, columns, loading }: Props) => {
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      className="table-permission mt-3 flex-1"
      rowKey="key"
      loading={loading}
      pagination={false}
    />
  )
}

export default PermissionTable
