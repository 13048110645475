import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { useRolePermissions } from '../hooks/role-permission/usePermissions'
import { openNotification } from './widgets/Notification'

interface Props {
  redirectPath?: string
  permission?: string
  children: any | null
}

export const ProtectedRoute = ({
  redirectPath = '/',
  permission,
  children
}: Props) => {
  const { t } = useTranslation()
  const { hasPermissions } = useRolePermissions()

  if (!hasPermissions(permission)) {
    // not logged in so redirect to login page with the return url
    openNotification({
      type: 'error',
      title: t('commonError'),
      message: t('errors.pageForbidden')
    })
    return <Navigate to={redirectPath} replace />
  }

  return children
}
