import React from 'react'
import {
  Controller,
  useFormContext
} from 'react-hook-form'

import { Input } from 'antd'

type Props = {
  fieldName: string,
  question: any,
  showEng?: boolean
}

export const InputTextAreaForm = ({ fieldName, question }: Props) => {
  const { isRequired, setting: { maxLength, minLength } } = question
  const { control } = useFormContext()

  const inputFieldName: string = `${fieldName}.selectedAnswerList.displayNameMap.ja`

  // const [text, setText] = React.useState<string>()

  // React.useEffect(() => {
  //   const answerList = resolvePath('selectedAnswerList.displayNameMap.ja', question)
  //   setText(answerList)
  // }, [question])

  // const onChange = (value: string) => {
  //   setText(value)
  //   setValue(inputFieldName, value, { shouldValidate: true })
  // }

  return (
    <Controller
      name={inputFieldName}
      control={control}
      rules={{
        required: isRequired,
        maxLength: maxLength,
        minLength: minLength
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...restField } = field
        return (
          <Input.TextArea
            value={value}
            status={error ? 'error' : ''}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => onChange(value)}
            rows={2}
            style={{ resize: 'none' }}
            {...restField}
          />
        )
      }}
    />
  )
}
