import { DocumentNode, gql } from '@apollo/client'

export interface VariableProps {
  filter: string
  sortBy?: string
  paginationInput: paginationInput
}

export interface paginationInput {
  page: number
  size: number
}

export const FILTER_SURVEY_LIST: DocumentNode = gql`
  query filterSurvey(
    $filter: String!
    $sortBy: String
    $paginationInput: PaginationInput
  ) {
    filterSurvey(
      filter: $filter
      sortBy: $sortBy
      paginationInput: $paginationInput
    ) {
      statusCode
      message
      timestamp
      totalElements
      payload {
        refId
        code
        # formType
        surveyStatus
        # validFromDate
        # validToDate
        createdBy
        createdDate
        displayNameMap {
          en
          ja
        }
      }
    }
  }
`

export const FILTER_SURVEY_DETAIL: DocumentNode = gql`
  query filterSurvey($filter: String!, $sortBy: String) {
    filterSurvey(
      filter: $filter
      sortBy: $sortBy
      paginationInput: { page: 0, size: 1 }
    ) {
      statusCode
      message
      timestamp
      totalElements
      payload {
        refId
        code
        formType
        surveyStatus
        validFromDate
        validToDate
        createdBy
        createdDate
        additionalInfo
        displayNameMap {
          en
          ja
        }
        sectionList {
          refId
          order
          status
          title {
            en
            ja
          }
          description {
            en
            ja
          }
          # icon {
          #  refId
          #  url
          #  name
          #  status
          # }
          subsectionList {
            refId
            order
            subsectionType
            status
            mediaType
            no
            url
            name
            file
            title {
              en
              ja
            }
            description {
              en
              ja
            }
            questionType
            displayNameMap {
              en
              ja
            }
            isRequired
            setting {
              refId
              status
              no
              displayNameMap {
                en
                ja
              }
              minLength
              maxLength
              fromDate
              toDate
              fromTime
              toTime
              maxSizeInKB
              noOfFiles
              startPoint
              endPoint
              slice
              scaleType
              isMultiple
              choiceType
              startLabel
              endLabel
              format
            }
            options {
              refId
              order
              status
              no
              displayNameMap {
                en
                ja
              }
            }
            statements {
              refId
              order
              status
              no
              displayNameMap {
                en
                ja
              }
            }
          }
        }
      }
    }
  }
`

export const FILTER_SURVEY: DocumentNode = gql`
  query filterSurvey($filter: String!, $sortBy: String) {
    filterSurvey(
      filter: $filter
      sortBy: $sortBy
      paginationInput: { page: 0, size: 20 }
    ) {
      statusCode
      message
      timestamp
      totalElements
      payload {
        refId
        additionalInfo
        code
        formType
        surveyStatus
        validFromDate
        validToDate
        createdBy
        createdDate
        additionalInfo
        displayNameMap {
          en
          ja
        }
        sectionList {
          refId
          order
          status
          title {
            en
            ja
          }
          description {
            en
            ja
          }
          # icon {
          #   refId
          #   url
          #   name
          #   status
          # }
          subsectionList {
            refId
            order
            subsectionType
            status
            # mediaType
            # no
            # url
            # name
            # file
            # title {
            #   en
            #   ja
            # }
            # description {
            #   en
            #   ja
            # }
            questionType
            displayNameMap {
              en
              ja
            }
            isRequired
            setting {
              refId
              status
              # no
              # displayNameMap {
              #   en
              #   ja
              # }
              minLength
              maxLength
              fromDate
              toDate
              fromTime
              toTime
              maxSizeInKB
              noOfFiles
              startPoint
              endPoint
              slice
              # scaleType
              isMultiple
              choiceType
              # startLabel
              # endLabel
              format
            }
            options {
              refId
              order
              status
              no
              displayNameMap {
                en
                ja
              }
            }
            # statements {
            #   refId
            #   order
            #   status
            #   no
            #   displayNameMap {
            #     en
            #     ja
            #   }
            # }
          }
        }
      }
    }
  }
`
