import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, Select, SelectProps, Space, Tooltip, Typography } from 'antd'

import styles from './DropdownList.module.scss'
import { ReactComponent as FilterOutlined } from 'assets/svgs/FilterOutlined.svg'
import { preventEnterKeyDefault } from 'utilities/helpers'

const sharedProps: any = {
  mode: 'multiple',
  style: {
    width: '100%'
  },
  options: [],
  defaultValue: [],
  menuItemSelectedIcon: null,
  maxTagCount: 'responsive'
}

interface ISelectDropdown {
  options: SelectProps['options']
  name: string
}

export const DropdownList = (prop: ISelectDropdown) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  sharedProps.options = prop.options

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%'
      }}
    >
      <Controller
        name={prop.name}
        control={control}
        render={({ field }) => (
          <Select
            {...sharedProps}
            {...field}
            // defaultValue={getValues(prop.name)}
            className={`${styles.selection} w-full max-w-[250px]`}
            popupClassName={styles.dropdownSelect}
            suffixIcon={
              <FilterOutlined className="absolute top-[-2px] right-[-4px] cursor-pointer" />
            }
            placeholder={t('questionnaire.departmentSelect')}
            onInputKeyDown={preventEnterKeyDefault}
            optionRender={(option: any) => {
              return (
                <Space>
                  <Checkbox
                    key={option.key}
                    checked={field.value.includes(option.label)}
                    onKeyDown={preventEnterKeyDefault}
                  ></Checkbox>
                  <Typography.Text className="text-[14px]">
                    {option.label}
                  </Typography.Text>
                </Space>
              )
            }}
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                overlayStyle={{
                  pointerEvents: 'none'
                }}
                title={omittedValues.map(({ label }) => label).join('、')}
              >
                <span>+ {omittedValues.length} ...</span>
              </Tooltip>
            )}
          />
        )}
      />
    </Space>
  )
}
