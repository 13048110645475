import { DocumentNode, gql } from '@apollo/client'

// 1.05 Create survey with all subsection types
export const params = {
  'surveyFeedbackInputs': [
    {
      'userRefId': 'HR_Sachithya_3',
      'surveyRefId': '12595046518851963271699267008904',
      'feedbackStatus': 'ASSIGNED'
    },
    {
      'userRefId': 'SE_Nimesha_4',
      'surveyRefId': '12595046518851963271699267008904',
      'feedbackStatus': 'ASSIGNED'
    }
  ]
}

export const CREATE_SURVEY_FEEDBACK: DocumentNode = gql`
mutation ($surveyFeedbackInputs: [SurveyFeedbackInput]!) {
  addSurveyFeedback(surveyFeedbackInputs: $surveyFeedbackInputs) {
    statusCode
    message
    timestamp
    payload {
      isCreated
      refId
      reason
    }
  }
}
`
