import { DocumentNode, gql } from '@apollo/client'

export const UPDATE_TENANT: DocumentNode = gql`
  mutation updateTenant($request: [UpdateTenant!]!) {
    updateTenant(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        code
        name
        notes
        phoneNumbers
        additionalInfo
        subTenantCodes
      }
    }
  }
`
