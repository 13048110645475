import { useTranslation } from 'react-i18next'

import { Flex } from 'antd'

import { VALUE_WITH_NO_DATA } from 'configs/constant'

export default function TestName({
  listTestName,
  language
}: {
  listTestName: any
  language: any
}) {
  const { t } = useTranslation()
  return (
    <div className="w-full">
      <Flex className="h-[auto] bg-[#ffffff] text-[#545454]">
        <div className="w-[25%] flex justify-between">
          <div className="mt-[9px] ml-[7px]">
            <span className="font-bold">{t('testResult.inspectionItem')}</span>
          </div>
          <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
        </div>
        {listTestName &&
          listTestName.map((item: any, index: any) => (
            <div key={index} className="w-[25%] flex justify-between">
              <div className="w-[99%] mt-[4px] ml-[11px] mr-[7px] justify-between">
                {/* <span className="font-bold block text-[16px]">
                  {item.title}
                </span> */}

                <Flex className="pb-[10px] flex-wrap">
                  {item.categoryName &&
                    item.categoryName.map((categoryName: any, index: any) => (
                      <div className="w-[50%] text-[14px]" key={index}>
                        {categoryName.categoryName?.[language] ??
                          VALUE_WITH_NO_DATA}
                      </div>
                    ))}
                </Flex>
              </div>
              <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
            </div>
          ))}
      </Flex>
      <div className="w-full h-[1px] bg-[#BFC6CB]"></div>
    </div>
  )
}
