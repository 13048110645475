import * as yup from 'yup'

import {
  QuestionTypeEnums,
  StatusEnums,
  SubsectionTypeEnums
} from '../../models/survey'

const displayNameMapSchema = yup.object().shape({
  en: yup.string().max(400, 'errors.surveyQuestionMax400').nullable(),
  ja: yup
    .string()
    .min(1, 'errors.surveyQuestionRequired')
    .max(200, 'errors.surveyQuestionMax200')
})

const subsectionTypeTextSchema = yup
  .object()
  .shape({
    refId: yup.string().nullable(),
    order: yup.number(),
    subsectionType: yup.string().default(() => SubsectionTypeEnums.question),
    status: yup.string().default(() => StatusEnums.active),
    questionType: yup.string().default(() => QuestionTypeEnums.text),
    isRequired: yup.boolean().default(() => false),
    no: yup.string(),
    displayNameMap: displayNameMapSchema,
    setting: yup.object()
  })
  .required()

const optionAnswerSchema = yup
  .object()
  .shape({
    order: yup.number(),
    status: yup.string().default(() => StatusEnums.active),
    no: yup.string(),
    displayNameMap: displayNameMapSchema
  })
  .required()

const subsectionTypeChoiceSchema = yup
  .object()
  .shape({
    order: yup.number(),
    subsectionType: yup.string().default(() => SubsectionTypeEnums.question),
    status: yup.string().default(() => StatusEnums.active),
    questionType: yup.string().default(() => QuestionTypeEnums.choice),
    isRequired: yup.boolean().default(() => false),
    no: yup.string(),
    displayNameMap: displayNameMapSchema,
    setting: yup.object(),
    options: yup.array().of(optionAnswerSchema).required()
  })
  .required()

const subsectionTypeDateTimeSchema = yup
  .object()
  .shape({
    order: yup.number(),
    subsectionType: yup.string().default(() => SubsectionTypeEnums.question),
    status: yup.string().default(() => StatusEnums.active),
    questionType: yup.string().default(() => QuestionTypeEnums.date),
    isRequired: yup.boolean().default(() => false),
    no: yup.string(),
    displayNameMap: displayNameMapSchema,
    setting: yup.object()
  })
  .required()

const subsectionTypeDescription = yup
  .object()
  .shape({
    order: yup.number(),
    subsectionType: yup.string(),
    status: yup.string()
  })
  .required()

const sectionSchema = yup
  .object()
  .shape({
    order: yup.number().default(() => 1),
    status: yup.string().default(() => StatusEnums.active),
    title: yup.object().shape({
      en: yup.string().max(100).nullable(),
      ja: yup.string().max(100).nullable()
    }),
    // subsectionList: yup.array().min(1)
    subsectionList: yup
      .array()
      .of(
        yup.lazy((subsection: any) => {
          if (typeof subsection === 'object') {
            const { subsectionType, questionType } = subsection
            if (subsectionType === SubsectionTypeEnums.question) {
              if (questionType === QuestionTypeEnums.text) {
                return subsectionTypeTextSchema
              }
              if (questionType === QuestionTypeEnums.choice) {
                return subsectionTypeChoiceSchema
              }
              if (
                questionType === QuestionTypeEnums.date ||
                questionType === QuestionTypeEnums.time
              ) {
                return subsectionTypeDateTimeSchema
              }
            }
            if (
              [
                SubsectionTypeEnums.description,
                SubsectionTypeEnums.media_file
              ].includes(subsectionType)
            ) {
              return subsectionTypeDescription
            }
          }
          return yup.object().nullable()
        })
      )
      .nullable()
  })
  .nullable()

export const schemas = yup
  .object()
  .shape({
    departments: yup.array().of(yup.string().nullable()).required(),
    title: yup.string().min(1).max(50).required(),
    displayCategories: yup
      .number()
      .default(() => 1)
      .nullable(),
    displayEnglish: yup
      .number()
      .default(() => 1)
      .nullable(),
    sectionList: yup.array().of(sectionSchema).nullable(),
    fixedSection: yup
      .object()
      .shape({
        subsectionList: yup.array().nullable()
      })
      .nullable()
  })
  .required()

export type TypeSchemas = yup.InferType<typeof schemas>
