import { useEffect, useState } from 'react'

import { STATUS_CODE_SUCCESS } from '../configs/constant'
import {
  FILTER_SURVEY_FEEDBACK,
  VariableProps
} from '../graphql/SurveyFeedback/filter'
import { UPDATE_SURVEY_FEEDBACK } from '../graphql/SurveyFeedback/update'
import { QuestionTypeEnums, StatusEnums } from '../models/survey'
import { surveyFeedbackList as ISurveyFeedbackList } from '../models/surveyFeedbackList'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CREATE_SURVEY_FEEDBACK } from 'graphql/SurveyFeedback/create'
import { Endpoint } from 'utilities/apolloClient'

const variablesInitFilter: VariableProps = {
  filter: '(eq,STRING,refId,)',
  sortBy: '',
  paginationInput: { page: 0, size: 10 }
}

const useSurveyFeedback = () => {
  const [surveyFeedbackList, setSurveyFeedbackList] = useState<
    ISurveyFeedbackList[]
  >([])
  const [variablesFilter, setVariablesFilter] =
    useState<VariableProps>(variablesInitFilter)

  const [loadSurveyFeedbackList, { called, loading, data }] = useLazyQuery(
    FILTER_SURVEY_FEEDBACK,
    {
      variables: variablesFilter,
      context: { version: Endpoint.SURVEY_CORE },
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first' // Used for subsequent executions
    }
  )

  useEffect(() => {
    if (!loading && data && data.hasOwnProperty('filterSurveyFeedback')) {
      const {
        filterSurveyFeedback: { payload }
      } = data

      const surveyFeedbackData: ISurveyFeedbackList[] = []
      for (let survey of payload) {
        surveyFeedbackData.push({
          data: survey.data
        })
      }

      setSurveyFeedbackList(surveyFeedbackData)
    }
  }, [loading])

  return {
    surveyFeedbackList,
    variablesFilter,
    setVariablesFilter,
    setSurveyFeedbackList,
    loadSurveyFeedbackList,
    loading
  }
}

export const useCreateSurveyFeedback = () => {
  const [createSurveyFeedbackMutation] = useMutation(CREATE_SURVEY_FEEDBACK, {
    context: { version: Endpoint.SURVEY_CORE }
  })

  const [updateSurveyFeedbackMutation] = useMutation(UPDATE_SURVEY_FEEDBACK, {
    context: { version: Endpoint.SURVEY_CORE }
  })

  const createSurveyFeedback = async (
    userRefId: string,
    surveyRefId: string,
    feedbackStatus: string = 'ASSIGNED'
  ) => {
    const variables = {
      surveyFeedbackInputs: [
        {
          userRefId: userRefId,
          surveyRefId: surveyRefId,
          feedbackStatus: feedbackStatus
        }
      ]
    }

    try {
      const response = await createSurveyFeedbackMutation({
        variables: variables,
        context: { version: Endpoint.SURVEY_CORE },
        fetchPolicy: 'network-only'
      })

      const {
        data: {
          addSurveyFeedback: { statusCode, payload }
        }
      } = response

      // console.log({ statusCode, payload })
      if (statusCode === STATUS_CODE_SUCCESS) {
        return payload[0]
      }
    } catch (err) {
      console.error(err)
    }
  }

  const updateSurveyFeedback = async (
    refId: string,
    formData: any,
    feedbackStatus: string = 'ASSIGNED'
  ) => {
    const variables = {
      surveyFeedbackInput: {
        refId: refId,
        endedDateTime: new Date().toISOString().replace(/Z$/, ''),
        feedbackStatus: feedbackStatus,
        selectedAnswerList: parseDataUpdateFeedback(formData)
      }
    }

    try {
      const response = await updateSurveyFeedbackMutation({
        variables: variables
      })

      const {
        data: {
          updateSurveyFeedback: { statusCode, payload }
        }
      } = response

      // console.log({ statusCode, payload })
      if (statusCode === STATUS_CODE_SUCCESS) {
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  const parseDataUpdateFeedback = (formData: any = {}) => {
    let answers: any[] = []
    if (formData?.sectionList) {
      for (const section of formData.sectionList) {
        for (const subsection of section.subsectionList) {
          const { refId: questionRefId, selectedAnswerList } = subsection
          if (!selectedAnswerList) continue

          const { displayNameMap, options, selectedDate, selectedTime } =
            selectedAnswerList

          if (options && options.length > 0) {
            answers.push({ questionRefId, options })
          }
          if (selectedDate) {
            answers.push({ questionRefId, selectedDate })
          }
          if (selectedTime) {
            answers.push({ questionRefId, selectedTime })
          }
          if (displayNameMap && displayNameMap.ja) {
            answers.push({
              questionRefId,
              displayNameMap: { ...displayNameMap, en: '' },
              options: []
            })
          }
        }
      }
    }
    return answers
  }

  const parseFormFeedbackSurvey = (
    survey: any = {},
    feedback: any = {},
    forReview: boolean = false
  ) => {
    let answerList: any = {}

    if (feedback && feedback.selectedAnswerList) {
      const { selectedAnswerList } = feedback
      for (const answer of selectedAnswerList) {
        const {
          refId,
          question: { refId: questionRefId },
          displayNameMap,
          options,
          selectedDate,
          selectedTime
        } = answer

        let _answer = {}
        if (displayNameMap) _answer = { refId, displayNameMap }
        if (selectedDate) _answer = { refId, selectedDate }
        if (selectedTime) _answer = { refId, selectedTime }
        if (options && options.length > 0) {
          let _options = options.map((o: any) => o.refId)
          if (forReview) {
            _options = options
          }
          _answer = { refId, options: _options }
        }

        answerList[questionRefId] = _answer
      }
    }

    if (survey?.sectionList)
      survey.sectionList
        .sort((a: any, b: any) => a.order - b.order)
        .filter((s: any) => s.status === StatusEnums.active)
        .map((section: any) => {
          section.subsectionList
            .sort((a: any, b: any) => a.order - b.order)
            .filter((s: any) => s.status === StatusEnums.active)
            .map((question: any) => {
              const { questionType, refId } = question
              let selectedAnswerList = {}
              if (questionType === QuestionTypeEnums.text) {
                selectedAnswerList = {
                  displayNameMap: { en: '', ja: '' }
                }
              }
              if (questionType === QuestionTypeEnums.choice) {
                selectedAnswerList = { options: [] }
              }
              if (questionType === QuestionTypeEnums.date) {
                selectedAnswerList = { selectedDate: '' }
              }
              if (questionType === QuestionTypeEnums.time) {
                selectedAnswerList = { selectedTime: '' }
              }

              const answer = answerList[refId]

              question.selectedAnswerList = { ...selectedAnswerList, ...answer }
              return question
            })
          return section
        })
    return survey
  }

  return {
    createSurveyFeedback,
    updateSurveyFeedback,
    parseDataUpdateFeedback,
    parseFormFeedbackSurvey
  }
}

export default useSurveyFeedback
