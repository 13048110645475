import { useTranslation } from 'react-i18next'

import { Flex } from 'antd'

export default function GeneralComment({
  listGeneralComment
}: {
  listGeneralComment: any
}) {
  const { t } = useTranslation()
  return (
    <div className="w-full">
      <Flex className="h-[auto] bg-[#ffffff] text-[#545454]">
        <div className="w-[25%] flex items-center justify-between">
          <div className="ml-[7px]">
            <span className="font-bold">{t('testResult.generalComment')}</span>
          </div>
          <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
        </div>
        {listGeneralComment.map((item: any, index: any) => (
          <div
            key={index}
            className="w-[25%] h-[auto] flex justify-between items-center"
          >
            <div className="w-[97%] h-[auto] ml-[11px]">
              <p className="text-wrap">{item.comment}</p>
            </div>
            <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
          </div>
        ))}
      </Flex>
      <div className="w-full h-[1px] bg-[#BFC6CB]"></div>
    </div>
  )
}
