import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, Navigate, Outlet, useLocation } from 'react-router-dom'

import { Flex, Layout, Typography } from 'antd'
import i18n from 'i18next'

import { useAuth } from '../../context/AuthProvider'
import { useRolePermissions } from '../../hooks/role-permission/usePermissions'
import styles from './HomeLayout.module.scss'
import { GlobalOutlined } from '@ant-design/icons'
import { ReactComponent as AvatarNoImageIcon } from 'assets/svgs/avatarNoImageIcon.svg'
import useClickOutSide from 'hooks/useClickOutSide'

const { Sider, Content } = Layout

interface Props {
  redirectPath?: string
}

let languagesEnum = [
  {
    label: '日本語',
    language: 'ja'
  },
  {
    label: 'Tiếng Việt',
    language: 'vi'
  },
  {
    label: 'English',
    language: 'en'
    // disable: true
  }
]

const HomeLayout = ({ redirectPath = '/login' }: Props) => {
  const { show, setShow, nodeRef } = useClickOutSide()
  const { t } = useTranslation()
  const auth = useAuth()
  const location = useLocation()
  const { hasPermissions } = useRolePermissions()
  const [currentLanguage, setCurrentLanguage] = React.useState(
    i18n.language ?? 'ja'
  )

  useEffect(() => {
    i18n.changeLanguage(currentLanguage)
    const languageClass = `language-${i18n.language}`
    document.body.classList.remove('language-vi', 'language-ja')
    document.body.classList.add(languageClass)
  }, [currentLanguage])

  const handleChangeLanguage = (language: string) => {
    setCurrentLanguage(language)

    setShow(false)
  }

  const getTextLanguage = () => {
    let language = languagesEnum.filter(
      (item: any) => item.language === currentLanguage
    )

    return <>{language?.[0]?.label ?? ''}</>
  }

  if (!auth?.token) {
    return <Navigate to={redirectPath} replace />
  }

  let menus: any = [
    {
      key: 1,
      label: t('menu.customer'),
      permission: '',
      href: '/',
      disable: false
    },
    {
      key: 2,
      label: t('menu.testResultList'),
      href: '/customer-management',
      permission: 'customer_management',
      second: true
    },
    {
      key: 3,
      label: t('menu.answerList'),
      href: '/consultant-management',
      permission: 'medical_survey_feedback',
      second: true
    },
    {
      key: 4,
      label: t('menu.consultant'),
      permission: '',
      href: '/survey-list'
    },
    {
      key: 5,
      label: t('menu.setting'),
      href: '/setting',
      permission: '',
      disable: true
    },
    {
      key: 6,
      label: t('menu.basicSetting'),
      href: '/basic-setting',
      permission: '',
      second: true
    },
    {
      key: 7,
      label: t('menu.inspectionSetting'),
      href: '/inspection-item-settings',
      permission: 'setting_inspection',
      second: true
    },
    {
      key: 8,
      label: t('menu.judgementSetting'),
      href: '/judgement-setting',
      permission: '',
      second: true
    },
    {
      key: 9,
      label: t('menu.admin'),
      permission: 'setting_role',
      href: '/admin-list',
      second: true
    }
  ]

  const activeDefault = ['customer_management', 'medical_survey_feedback']
  let setActive = false
  let defaultHref: any = false

  for (let permission of activeDefault) {
    if (hasPermissions(permission)) {
      setActive = true
      menus = menus.map(function (item: any) {
        if (item?.permission === permission) {
          defaultHref = item.href
          return { ...item, active: true }
        }
        return item
      })

      break
    }
  }

  if (!setActive) {
    menus = menus.map(function (item: any) {
      if (item?.href === '/survey-list') {
        return { ...item, active: true }
      }
      return item
    })
  }

  return (
    <Layout>
      <Sider
        className={styles.container_layout}
        trigger={null}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 300,
          paddingLeft: 26,
          paddingTop: 20
        }}
      >
        <div className="pl-1 mb-10">
          <Link to={'/my-page'} className="w-max block">
            <AvatarNoImageIcon />
          </Link>
        </div>

        <Flex vertical className="justify-between flex-1">
          <div>
            {menus.map((menu: any, index: any) => {
              const defaultClass: string =
                'font-bold text-[16px] !text-white min-h-[34px] flex items-center pr-1.5'
              const hiddenClass: string = hasPermissions(menu.permission)
                ? 'block'
                : 'hidden'
              const activeClass: string = menu?.active ? 'default-active' : ''
              const disableClass: string = menu?.disable
                ? 'pointer-events-none'
                : ''
              const activatedClass = (
                activated: boolean,
                href: string = ''
              ): string => {
                if (activated) {
                  if (href === '/') {
                    if (location.pathname === href)
                      return defaultHref
                        ? 'active-sub-menu'
                        : 'active-sub-menu pointer-events-none'

                    return 'pointer-events-none'
                  }

                  return 'active'
                } else {
                  // active survey menu
                  if (
                    href.startsWith('/survey') &&
                    location.pathname.startsWith('/survey')
                  ) {
                    return 'active'
                  }

                  if (href === '/' && location.pathname === href)
                    return defaultHref
                      ? 'active-sub-menu'
                      : 'active-sub-menu pointer-events-none'
                }

                return ''
              }

              const menuTo = (href: any) => {
                return href !== '/' ? href : (defaultHref ?? '/')
              }

              return menu?.second && menu.second ? (
                <NavLink
                  key={index}
                  to={menu.href}
                  className={`${defaultClass} ${hiddenClass} ${activeClass} pl-3`}
                >
                  - {menu.label}
                </NavLink>
              ) : (
                <NavLink
                  key={index}
                  to={menuTo(menu.href)}
                  className={({ isActive }) =>
                    `${defaultClass} ${hiddenClass} ${activeClass} ${disableClass} ${activatedClass(isActive, menu.href)} pl-1 block`
                  }
                >
                  {menu.label}
                </NavLink>
              )
            })}
          </div>
          <Flex
            align="center"
            gap={8}
            className="ml-[-26px] px-4 py-2 bg-[rgba(255,255,255,0.1)] cursor-pointer mb-40 relative"
            onClick={() => setShow(!show)}
            ref={nodeRef}
          >
            <GlobalOutlined className="text-white" />
            <Typography className="text-white">{getTextLanguage()}</Typography>

            <div
              className={`absolute max-h-[120px] overflow-y-auto top-full left-0 w-full rounded-sm bg-[rgba(255,255,255,0.1)] z-10 ${
                show ? '' : 'hidden'
              }`}
            >
              {languagesEnum.map((item, idx) => (
                <div
                  key={idx}
                  className="px-4 py-2 cursor-pointer hover:bg-[#b6cedf] text-white"
                  onClick={() => {
                    // if (item.disable) return
                    handleChangeLanguage(item.language)
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </Flex>
        </Flex>
      </Sider>
      <Layout className="bg-[#F0F3F7] ml-[200px]">
        <Content
          style={{
            padding: '20px 10px',
            overflow: 'auto',
            minHeight: '100vh',
            width: '100%'
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default HomeLayout
