import React, { useState } from 'react'
import { FormProvider, UseFieldArrayUpdate, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Select, Tooltip, Typography } from 'antd'

import { useGroupModal } from '../../../../hooks/useUserGroup'
import { isArrayEmpty } from '../../../../utilities/helpers'
import { InputForm } from '../../../elements/InputForm'
import { TextAreaForm } from '../../../elements/TextAreaForm'
import { CaretDownOutlined, QuestionCircleFilled } from '@ant-design/icons'
import CustomModal from 'components/modals'

type Props = {
  subTenantList: any
  onCancel: () => void
  update: UseFieldArrayUpdate<any, 'subTenantList'>
}

export const SubTenantEditModal = ({
  onCancel,
  update,
  subTenantList
}: Props) => {
  const { t } = useTranslation()
  const { showEditModal, selectedIndex, setSelectedIndex, isModeEdit } =
    useGroupModal()
  const [group, setGroup] = useState<any>(
    selectedIndex ? subTenantList[selectedIndex] : {}
  )

  const options = subTenantList.map((subGroup: any, index: number) => {
    return { label: subGroup.name, value: index }
  })

  const methods = useForm<any>({
    defaultValues: {
      phoneNumber: '',
      note: ''
    }
  })

  React.useEffect(() => {
    if (selectedIndex !== undefined) {
      setGroup(subTenantList[selectedIndex])

      if (isModeEdit) {
        methods.reset(subTenantList[selectedIndex])
      } else {
        methods.reset({
          phoneNumber: '',
          note: ''
        })
      }
    }

    return () => {}
  }, [selectedIndex, subTenantList])

  const handleOk = () => {
    //
  }

  const onSubmit = (data: any) => {
    if (selectedIndex === undefined) return
    onCancel() //-> to closed dialog
    const { phoneNumber, note } = data

    const selectedSubTenant = subTenantList[selectedIndex]
    const { additionalInfo } = selectedSubTenant

    console.log({
      ...selectedSubTenant,
      phoneNumber,
      note,
      additionalInfo: { ...additionalInfo, status: 'activated' }
    })
    update(selectedIndex, {
      ...selectedSubTenant,
      phoneNumber,
      note,
      additionalInfo: { ...additionalInfo, status: 'activated' }
    })
  }

  return (
    <CustomModal open={showEditModal} onOk={handleOk} onCancel={onCancel}>
      <Flex vertical className="p-[16px] gap-[26px]">
        <Typography className="text-[20px] text-[#545454] font-bold self-center">
          {t('contactRegistration')}
        </Typography>
        <Typography className="text-[14px] self-center">
          {t('content.enterContactInformation')}
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex vertical className="px-[60px] gap-[28px]">
              <Flex vertical className="gap-[10px]">
                <Typography className="font-bold">
                  {t('basicSetting.familyName')}
                </Typography>
                <Select
                  placeholder={t('placeholder.pleaseSelect')}
                  className="h-[30px]"
                  value={
                    isArrayEmpty(subTenantList) ? undefined : selectedIndex
                  }
                  onChange={(value) => setSelectedIndex(value)}
                  suffixIcon={
                    <CaretDownOutlined
                      color="#137695"
                      className="text-[#137695] text-[14px]"
                    />
                  }
                >
                  {options.map((option: any) => (
                    <Select.Option value={option.value} key={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Flex>
              <Flex vertical className="gap-[10px]">
                <Typography className="font-bold">
                  {t('lable.phone')}
                </Typography>
                <InputForm
                  name="phoneNumber"
                  rules={{
                    maxLength: 15
                  }}
                  // onInput={handlePhoneNumberInput}
                  defaultValue={isModeEdit ? group?.phoneNumber : ''}
                />
              </Flex>
              <Flex vertical className="gap-[10px]">
                <Flex>
                  <Typography className="font-bold">
                    {t('content.cautionNote')}
                  </Typography>
                  <Tooltip title={t('popover.notesArePublic')}>
                    <QuestionCircleFilled className="text-[#B4B4B4] ml-[3px]" />
                  </Tooltip>
                </Flex>
                <TextAreaForm
                  name="note"
                  className="p-[10px] scrollbar-custom"
                  rows={4}
                  style={{ resize: 'none' }}
                  defaultValue={isModeEdit ? group?.note : ''}
                />
              </Flex>
            </Flex>
            <Flex className="justify-center gap-[12px] mt-[12px]">
              <Button
                type="primary"
                htmlType="submit"
                className="w-[180px] font-bold"
                autoInsertSpace={false}
              >
                {t('button.registration')}
              </Button>
              <Button
                className="font-bold w-[180px] border-[#137695] text-[#137695]"
                onClick={onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </CustomModal>
  )
}
