import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Flags from 'react-world-flags'

import { Flex, Select, Typography } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'

type Props = {
  name: string
  placeholder?: string
}

const SelectPhoneNumber: FC<Props> = ({ name, placeholder }) => {
  const { control } = useFormContext()

  const input = document.createElement('input')
  const iti = intlTelInput(input, { excludeCountries: ['ac'] })

  const options = iti['countries'].map((country: any) => ({
    label: (
      <Flex align="center">
        <Flags
          code={country.iso2}
          style={{
            marginRight: '11px',
            height: '17px',
            width: '26px',
            border: '0.1px solid #E9E9E9'
          }}
        />
        <Typography className="text-[14px] text-[#545454]">
          +{country.dialCode}
        </Typography>
      </Flex>
    ),
    value: `${country.dialCode}-${country.name}`,
    key: country.iso2
  }))

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          options={options}
          suffixIcon={
            <CaretDownOutlined
              style={{ color: '#137695', pointerEvents: 'none' }}
            />
          }
          {...field}
          placeholder={placeholder}
          defaultValue={'81-Japan'}
          className="h-[30px] w-[120px]"
        />
      )}
    />
  )
}

export default SelectPhoneNumber
