import { useEffect, useState } from 'react'

import {
  FILTER_TEST_ITEM_MASTER,
  variableProps
} from '../graphql/TestItemMaster/filter'
import { testItemMasterList } from '../models/testItemMaster'
import { useLazyQuery } from '@apollo/client'
import { Endpoint } from 'utilities/apolloClient'

const variablesInitFilter: variableProps = {
  filter: '',
  paginationInput: { page: 0, size: -1 }
}

const useTestItemMaster = () => {
  const [itemMasterList, setTestItemMasterList] = useState<
    testItemMasterList[]
  >([])

  const [variablesFilter, setVariablesFilter] =
    useState<variableProps>(variablesInitFilter)

  const [loadTestItemMasterList, { called, loading, data }] = useLazyQuery(
    FILTER_TEST_ITEM_MASTER,
    {
      variables: variablesFilter,
      context: { version: Endpoint.CHECKUP_CORE },
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first' // Used for subsequent executions
    }
  )

  useEffect(() => {
    if (!loading && data && data.hasOwnProperty('filterTestItemMaster')) {
      const {
        filterTestItemMaster: { payload }
      } = data

      const commentSettingData: testItemMasterList[] = []
      for (let comment of payload) {
        commentSettingData.push({
          data: comment
        })
      }

      setTestItemMasterList(commentSettingData)
    }
  }, [loading])

  return {
    itemMasterList,
    variablesFilter,
    setVariablesFilter,
    setTestItemMasterList,
    loadTestItemMasterList,
    loading
  }
}

export default useTestItemMaster
