import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery } from '@apollo/client'
import { AGGREGATION_FILTER } from 'graphql/AggregationFilter/aggregationFilter'
import { FILTER_ITEM_MASTER } from 'graphql/ItemMaster/filterItemMaster'

const useTestItemMaster = () => {
  const [queryTestItemMaster, { loading, error, data }] =
    useLazyQuery(FILTER_ITEM_MASTER)
  const [queryAggregation] = useLazyQuery(AGGREGATION_FILTER)

  const getListItemMaster = async (variables: any) => {
    try {
      const result = await queryTestItemMaster({
        variables: {
          filter: '',
          page: 0,
          size: -1,
          ...variables
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  const getListItemMasterByMultipleLanguages = async (
    variables: any,
    page: number = 0,
    size: number = -1
  ) => {
    try {
      let result: any = await queryAggregation({
        variables: {
          collection: 'testItemMaster',
          page: page,
          size: size,
          request: [
            ...variables,
            {
              type: 'SORT',
              criteria: {
                field: '_id',
                direction: 'desc'
              }
            },
            {
              type: 'GROUP',
              criteria: {
                field: 'refId'
              }
            },
            {
              type: 'ADD_FIELDS',
              criteria: {
                jpDocument: {
                  $first: {
                    $filter: {
                      input: '$data',
                      as: 'doc',
                      cond: {
                        $or: [
                          { $eq: ['$$doc.additionalInfo.key2', 'jp'] },
                          { $eq: ['$$doc.additionalInfo.key2', ''] },
                          {
                            $eq: [
                              { $type: '$$doc.additionalInfo.key2' },
                              'missing'
                            ]
                          }
                        ]
                      }
                    }
                  }
                },
                enDocument: {
                  $first: {
                    $filter: {
                      input: '$data',
                      as: 'doc',
                      cond: {
                        $eq: ['$$doc.additionalInfo.key2', 'en']
                      }
                    }
                  }
                },
                viDocument: {
                  $first: {
                    $filter: {
                      input: '$data',
                      as: 'doc',
                      cond: {
                        $eq: ['$$doc.additionalInfo.key2', 'vi']
                      }
                    }
                  }
                }
              }
            },
            {
              type: 'PROJECT',
              criteria: {
                enDocument: true,
                jpDocument: true,
                viDocument: true
              }
            }
          ]
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      result = result?.data?.commonAggregationFilter?.payload
      if (!Array.isArray(result)) result = []
      result = result.map((item: any) => {
        let customItem: any = {
          lang: {}
        }

        if (item?.viDocument) {
          customItem = { ...customItem, ...item.viDocument }
          customItem.lang.vi = item?.viDocument?.displayName
        }

        if (item?.enDocument) {
          customItem = { ...customItem, ...item.enDocument }
          customItem.lang.en = item?.enDocument?.displayName
        }

        if (item?.jpDocument) {
          customItem = { ...customItem, ...item.jpDocument }
          customItem.lang.ja = item?.jpDocument?.displayName
        }

        return customItem
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getListItemMaster,
    getListItemMasterByMultipleLanguages,
    data,
    loading,
    error
  }
}

export default useTestItemMaster
