import { DocumentNode, gql } from '@apollo/client'

export const UPDATE_MEDICAL_CHECKUP: DocumentNode = gql`
  mutation updateMedicalCheckup($request: [UpdateMedicalCheckup]!) {
    updateMedicalCheckup(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        medicalCheckupMasterRef
        checkupUserRef
        additionalInfo
        review
        createdDate
        createdBy
      }
    }
  }
`
