import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Tabs, Typography } from 'antd'

import AdministratorList from './components/AdministratorList'
import RolePermissionTab from './components/RolePermissionTab'
import { ReactComponent as CrownIcon } from 'assets/svgs/crown.svg'

const { Title, Paragraph } = Typography

export default function AdminList() {
  const { t } = useTranslation()
  const [tab, setTab] = useState<string>('1')

  const titleTab: { [key: string]: ReactNode } = {
    '1': (
      <Flex className="items-center">
        {t('content.permissionSettings1')}{' '}
        <CrownIcon className="inline-block w-[17px]" />
        {t('content.permissionSettings2')}
      </Flex>
    ),
    '2': t('content.editPermissionSettings')
  }

  const dataTabs = [
    {
      key: '1',
      label: t('administratorList'),
      children: <AdministratorList />
    },
    {
      key: '2',
      label: t('roleSettings'),
      children: <RolePermissionTab />
    }
  ]

  return (
    <Flex vertical className="min-w-[1700px] py-4.5">
      <Title level={2} className="tracking-widest text-base font-bold">
        {t('adminSettings')}
      </Title>
      <Flex
        vertical
        className="bg-white p-2.5 shadow-custom min-h-full rounded-[5px] text-sm text-default"
      >
        <Title
          level={3}
          className="tracking-[1.4px] text-primary font-bold text-sm mb-0"
        >
          {t('permissionSettings')}
        </Title>
        <Paragraph className="mt-[17px] mb-0">{titleTab[tab]}</Paragraph>
        <Tabs
          defaultActiveKey="1"
          items={dataTabs}
          className="tab-admin-list mt-[21px]"
          onChange={(tab) => setTab(tab)}
        />
      </Flex>
    </Flex>
  )
}
