import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CustomModal from 'components/modals'

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onOk: () => void
}

const LogoutModal = ({ isModalOpen = false, onCancel, onOk }: Props) => {
  const { t } = useTranslation()

  const handleOk = () => {
    onOk()
  }

  return (
    <CustomModal open={isModalOpen} onOk={handleOk} onCancel={onCancel}>
      <Flex
        vertical
        className="pt-[16px] pb-[26px] text-center items-center justify-center"
      >
        <Typography className="text-[20px] text-[#545454] font-bold leading-none tracking-[1px]">
          {t('content.logoutConfirmation')}
        </Typography>

        <Typography className="leading-none mt-[154px] tracking-[.7px]">
          {t('content.logOut')}
        </Typography>
        <Typography className="leading-none mt-2 tracking-[.7px]">
          {t('content.isThatOkay')}
        </Typography>

        <Flex className="justify-center gap-[12px] mt-[149px]">
          <Button
            type="primary"
            autoInsertSpace={false}
            onClick={() => handleOk()}
            className="w-[180px] bg-[#137695] font-bold tracking-[.7px]"
          >
            {t('logout')}
          </Button>
          <Button
            className="w-[180px] border-[#137695] text-[#137695] font-bold tracking-[.7px]"
            onClick={onCancel}
          >
            {t('button.cancel')}
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  )
}

export default LogoutModal
