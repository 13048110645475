import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { Flex, Input, Typography } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { ReactComponent as PenEditIcon } from 'assets/svgs/pen.svg'
import { ReactComponent as SaveIcon } from 'assets/svgs/save.svg'
import useClickOutSide from 'hooks/useClickOutSide'
import { resolvePath } from 'utilities/helpers'

const DropdownCustom = ({
  validate,
  setValue,
  triggerValidation,
  name,
  dropdownLabel = 'Select your job',
  options,
  changeComment
}: {
  validate?: any
  setValue: any
  triggerValidation: any
  name: any
  dropdownLabel: string
  options: any
  changeComment: any
}) => {
  const { show, setShow, nodeRef } = useClickOutSide()
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const [label, setLabel] = useState(dropdownLabel)
  const [labelChange, setLabelChange] = useState<any>(null)
  const [edit, setEdit] = useState<boolean>(false)
  const dropdownValue = useWatch({
    control,
    name: name
  })

  const handleClickDropdownItem = (e: any) => {
    setLabel(e.target.textContent)
    setValue(name, e.target.textContent)
    setShow(false)
    setLabelChange(null)
  }

  const handleLabelChange = (e: any) => {
    setLabelChange(e.target.value)
  }

  const handleSave = () => {
    if (labelChange !== null) {
      setLabel(labelChange)
      setValue(name, labelChange)
    }
    setEdit(false)
  }
  useEffect(() => {
    setLabel(dropdownValue)
  }, [dropdownValue])

  useEffect(() => {
    if (changeComment) {
      let value = options && options.length > 0 ? options?.[0]?.value : ''

      setLabel(value)
      setValue(name, value)
      setLabelChange(null)
    }
  }, [options])

  return (
    <div className="relative" ref={nodeRef}>
      <div
        className={`p-4 border ${errors?.[name] || resolvePath(name, errors) ? 'border-error' : 'border-gray-100'} bg-white flex items-center justify-between`}
      >
        <Flex className="items-center justify-between w-full gap-5">
          <Flex className="items-center gap-4 w-full flex-1">
            {!edit && (
              <Typography className="flex-1 whitespace-pre-wrap">
                {label}
              </Typography>
            )}
            {edit && (
              <TextArea
                className="w-full flex-1"
                autoSize={{ minRows: 1 }}
                value={labelChange ?? label}
                onChange={handleLabelChange}
              />
            )}
            {!edit && (
              <PenEditIcon
                className="w-[15px] cursor-pointer"
                onClick={() => setEdit(true)}
              />
            )}
            {edit && (
              <SaveIcon
                className="w-[17px] cursor-pointer"
                onClick={handleSave}
              />
            )}
          </Flex>
          <CaretDownOutlined
            color="#137695"
            className="text-[#545454] text-[16px]"
            onClick={() => setShow(!show)}
          />
        </Flex>
      </div>
      <div
        className={`absolute max-h-[100px] overflow-y-auto top-full left-0 w-full rounded-lg bg-white z-10 ${
          show ? '' : 'hidden'
        }`}
      >
        {options.map((item: any) => (
          <div
            key={item.value}
            className="p-4 cursor-pointer hover:bg-gray-100"
            onClick={handleClickDropdownItem}
            data-value={item.value}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DropdownCustom
