import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Typography
} from 'antd'

import { ConsultantResultList } from '../../components/screens/ConsultantManagement/ConsultantResultList'
import { SelectDropdown } from '../../components/screens/ConsultantManagement/SelectDropdown'
import { useSubTenantList } from '../../hooks/tenant/useTenantList'
import useConsultantResults from '../../hooks/useConsultantResults'
import HealthCheckModal from './components/HealthCheckModal'
import { ReactComponent as SearchOutlined } from 'assets/svgs/SearchOutlined.svg'
import { CONSULTANT_SORT_TYPE, GENDER } from 'configs/constant'
import { isNumber } from 'utilities/helpers'

type DataForm = {
  idOrNameUser: string
  department: string[]
  ageStart: number
  ageEnd: number
  refId: 'asc' | 'desc'
  age: 'asc' | 'desc'
  birthday: 'asc' | 'desc'
  fullName: 'asc' | 'desc'
  feedbackStatus: 'asc' | 'desc'
  createdDateTime: 'asc' | 'desc'
  title: 'asc' | 'desc'
  examDate: 'asc' | 'desc'
  gender: any[]
  page: number
  perPage: number
  sortBy?: string
  orderBy?: string
  status?: string
}

export default function ConsultantManagement() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const genderOptions = [
    { label: t('customerDetail.header.male'), value: GENDER.MALE },
    { label: t('customerDetail.header.female'), value: GENDER.FEMALE }
  ]

  const {
    loading,
    totalElement,
    totalByType,
    dataShow: data,
    handleSearchData
  } = useConsultantResults() // Use the custom hook

  const { subTenantCodes } = useSubTenantList()
  const departments = subTenantCodes.map((s: string) => {
    return { label: s, value: s }
  })
  let location = useLocation()

  const methods = useForm<DataForm>({
    defaultValues: {
      idOrNameUser: '',
      department: [],
      refId: 'asc',
      age: 'asc',
      birthday: 'asc',
      page: 0,
      perPage: 100,
      sortBy: '',
      orderBy: '',
      status: ''
    }
  })

  const { control, getValues, setValue, resetField } = methods

  const onSearch = async () => {
    let idOrNameUser = getValues('idOrNameUser')
    let department = getValues('department')
    let gender = getValues('gender')
    let ageStart: any = getValues('ageStart')
    let ageEnd: any = getValues('ageEnd')
    let page: any = getValues('page')
    let perPage: any = getValues('perPage')
    let sortBy = getValues('sortBy')
    let orderBy = getValues('orderBy')
    let status = getValues('status')
    const newParams = new URLSearchParams(searchParams)

    if (idOrNameUser) {
      newParams.set('idOrNameUser', idOrNameUser)
    } else {
      newParams.delete('idOrNameUser')
    }

    if (department && department.length > 0) {
      newParams.set('department', department.join(','))
    } else {
      newParams.delete('department')
    }

    if (gender && gender.length > 0) {
      newParams.set('gender', gender.join(','))
    } else {
      newParams.delete('gender')
    }

    if (isNumber(ageStart)) {
      newParams.set('ageStart', ageStart)
    } else {
      newParams.delete('ageStart')
    }

    if (isNumber(ageEnd)) {
      newParams.set('ageEnd', ageEnd)
    } else {
      newParams.delete('ageEnd')
    }

    if (sortBy && orderBy) {
      newParams.set('sortBy', sortBy)
      newParams.set('orderBy', orderBy)
    } else {
      newParams.delete('sortBy')
      newParams.delete('orderBy')
    }

    newParams.set('page', page + 1)
    newParams.set('perPage', perPage)

    if (status) {
      newParams.set('status', status)
    } else {
      newParams.delete('status')
    }

    setSearchParams(newParams)
  }

  useEffect(() => {
    handleFilterByUrl()
  }, [location.search])

  const handleFilterByUrl = async () => {
    const searchParamsUrl = new URLSearchParams(location.search)
    let idOrNameUser = searchParamsUrl.get('idOrNameUser')
    let department: any = searchParamsUrl.get('department')
    if (department) department = department.split(',')

    let gender: any = searchParamsUrl.get('gender')
    if (gender) gender = [gender]

    let ageStart: any = searchParamsUrl.get('ageStart')
      ? Number(searchParamsUrl.get('ageStart'))
      : null
    let ageEnd: any = searchParamsUrl.get('ageEnd')
      ? Number(searchParamsUrl.get('ageEnd'))
      : null
    let page = searchParamsUrl.get('page')
      ? Number(searchParamsUrl.get('page'))
      : 1
    let perPage = searchParamsUrl.get('perPage')
      ? Number(searchParamsUrl.get('perPage'))
      : 100
    let sortBy =
      searchParamsUrl.get('sortBy') ??
      CONSULTANT_SORT_TYPE.SURVEY_FEEDBACK_CREATE_AT
    let orderBy = searchParamsUrl.get('orderBy') ?? 'desc'
    let status = searchParamsUrl.get('status')

    if (idOrNameUser) {
      setValue('idOrNameUser', idOrNameUser)
    } else {
      resetField('idOrNameUser')
    }

    if (department && department.length > 0) {
      setValue('department', department)
    } else {
      resetField('department')
    }

    if (gender && gender.length > 0) {
      setValue('gender', gender)
    } else {
      resetField('gender')
    }

    if (isNumber(ageStart)) {
      setValue('ageStart', ageStart)
    } else {
      resetField('ageStart')
    }
    if (isNumber(ageEnd)) {
      setValue('ageEnd', ageEnd)
    } else {
      resetField('ageEnd')
    }

    setValue('sortBy', sortBy)
    setValue('orderBy', orderBy)
    setValue('page', page - 1)
    setValue('perPage', perPage)

    if (status) {
      setValue('status', status)
    } else {
      resetField('status')
    }

    await handleSearchData({
      idOrNameUser: idOrNameUser,
      department: department,
      gender: gender,
      ageStart: ageStart,
      ageEnd: ageEnd,
      sortBy: sortBy,
      orderBy: orderBy,
      page: page,
      perPage: perPage,
      status: status
    })
  }

  const [showHealthCheck, setShowHealthCheck] = useState<boolean>(false)

  const [surveyFeedbackData, setSurveyFeedbackData] = useState<any>([])

  const navigateInterviewResultCreationPage = () => {
    navigate({
      pathname: '/interview-result-create',
      search: createSearchParams({ t: '2', userRefId: '' }).toString()
    })
  }

  const onChangePerPage = async () => {
    setValue('page', 0)

    await onSearch()
  }

  const onChangePage = async (value: number) => {
    setValue('page', value - 1)

    await onSearch()
  }

  const goToFirstPage = async () => {
    setValue('page', 0)

    await onSearch()
  }

  const goToLastPage = async () => {
    const totalPages = Math.ceil(totalElement / getValues('perPage'))
    setValue('page', totalPages - 1)

    await onSearch()
  }

  const toggleSortWithUser = async (field: CONSULTANT_SORT_TYPE) => {
    let sortBy = getValues('sortBy')
    let orderBy = getValues('orderBy')
    if (!orderBy) orderBy = 'asc'

    if (field === sortBy) {
      orderBy = orderBy === 'asc' ? 'desc' : 'asc'
    }

    setValue('sortBy', field)
    setValue('orderBy', orderBy)

    await onSearch()
  }

  const handleKeyDownDefault = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const changeStatusSurveyFeedBack = async (status: any) => {
    setValue('page', 0)
    setValue('status', status)

    await onSearch()
  }

  return (
    <FormProvider {...methods}>
      <Flex vertical className="min-w-[1680px] mt-[-12px] gap-2">
        <HealthCheckModal
          isModalOpen={showHealthCheck}
          onCancel={() => setShowHealthCheck(false)}
          surveyFeedbackList={surveyFeedbackData}
        />
        <Row justify="space-between">
          <Col span={20}>
            <Form className="" onKeyDown={handleKeyDownDefault}>
              <Form.Item className="inline-block mr-6 mb-0">
                <Typography.Text strong className="text-[16px]">
                  {t('menu.answerList')}
                </Typography.Text>
              </Form.Item>
              <Form.Item
                name="keyword"
                className="inline-block mb-0 mr-2"
                rules={[{ required: false }]}
              >
                <Controller
                  name="idOrNameUser"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      prefix={<SearchOutlined />}
                      placeholder={t('placeholder.idname')}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                name="dropdown"
                className="inline-block mb-0 mr-2"
                rules={[{ required: false }]}
              >
                <Flex className="relative mr-[6px] w-[200px] cursor-pointer">
                  <SelectDropdown departments={departments} name="department" />
                </Flex>
              </Form.Item>

              <Form.Item name="gender" className="inline-block mr-4 mb-0">
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <Flex align="center">
                      <Typography.Text strong className="text-[14px] mr-2">
                        {t('lable.gender')}
                      </Typography.Text>
                      <Checkbox.Group
                        {...field}
                        options={genderOptions}
                        value={field.value}
                        onChange={(selectedValues) => {
                          if (selectedValues.length === 2) {
                            selectedValues = selectedValues.filter(
                              (item) => item !== getValues('gender')?.[0]
                            )
                          }

                          field.onChange(selectedValues)
                        }}
                      />
                    </Flex>
                  )}
                />
              </Form.Item>

              <Form.Item name="age" className="inline-block mr-4 mb-0">
                <Flex align="center">
                  <Typography.Text strong className="text-[14px] mr-2">
                    {t('lable.age')}
                  </Typography.Text>
                  <Controller
                    name="ageStart"
                    control={control}
                    render={({ field }) => (
                      <InputNumber {...field} min={0} max={999} />
                    )}
                  />
                  <Typography.Text strong className="mx-1">
                    {t('consultant.separator')}
                  </Typography.Text>
                  <Controller
                    name="ageEnd"
                    control={control}
                    render={({ field }) => (
                      <InputNumber {...field} min={0} max={999} />
                    )}
                  />
                </Flex>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                className="font-bold border-none shadow-none"
                onClick={() => {
                  setValue('page', 0)
                  onSearch()
                }}
              >
                {t('placeholder.search')}
              </Button>
            </Form>
          </Col>
          <Col span={4}>
            <div style={{ textAlign: 'right' }}>
              <Space size="small">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  className="invisible w-[100px] bg-gradient-to-r from-[#137695] to-[#93D1CA] font-bold border-none shadow-none"
                >
                  {t('button.export_CSV')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  className="h-[30px] bg-gradient-to-r from-[#137695] to-[#93D1CA] font-bold border-none shadow-none"
                  onClick={() => navigateInterviewResultCreationPage()}
                >
                  {t('questionnaire.button.create')}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
        <ConsultantResultList
          totalByType={totalByType}
          data={data}
          setShowHealthCheck={setShowHealthCheck}
          setSurveyData={setSurveyFeedbackData}
          changeStatusSurveyFeedBack={changeStatusSurveyFeedBack}
          totalElement={totalElement}
          onGoToFirstPage={goToFirstPage}
          onGoToLastPage={goToLastPage}
          onChangePerPage={onChangePerPage}
          pageSize={getValues('perPage')}
          onChangePage={onChangePage}
          currentPage={getValues('page')}
          loading={loading}
          toggleSortWithUser={toggleSortWithUser}
          status={getValues('status')}
        />
      </Flex>
    </FormProvider>
  )
}
