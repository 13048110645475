import { DocumentNode, gql } from '@apollo/client'

export const params = {
  request: [
    {
      medicalCheckupMasterRef: '8a4712ca-953f-4abe-be6b-a9ceaf9d94db',
      checkupUserRef: '8a4712ca-953f-4abe-be6b-a9ceaf9d97hg',
      additionalInfo: {
        key: 'value'
      },
      review: '',
      visibility: {
        clientCode: 'checkup-core-tenant-2',
        tenantCodes: ['admin', 'Y'],
        subTenantCodes: ['GTR', 'YY']
      }
    },
    {
      medicalCheckupMasterRef: '8a4712ca-953f-4abe-be6b-a9ceaf9d94db',
      checkupUserRef: '8a4712ca-953f-4abe-be6b-a9ceaf9d97hg',
      additionalInfo: {
        key: 'value'
      },
      review: ''
    }
  ]
}

export const CREATE_MEDICAL_CHECKUP: DocumentNode = gql`
  mutation addMedicalCheckup($request: [CreateMedicalCheckup]!) {
    addMedicalCheckup(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        medicalCheckupMasterRef
        checkupUserRef
        additionalInfo
        review
      }
    }
  }
`
