import { DocumentNode, gql } from '@apollo/client'

export interface ConsultantVariableProps {
  page: number
  size: number
  filter: string
  sortBy: string
}

interface VariableProps {
  page: number
  size: number
  filter: string
}

export const FILTER_ALL: VariableProps = {
  page: 0,
  size: -1,
  filter: ''
}

export const FILTER_CHECKUP_USER: DocumentNode = gql`
  query filterCheckupUser(
    $filter: String!
    $page: Int!
    $size: Int!
    $sortBy: String
  ) {
    filterCheckupUser(
      page: $page
      size: $size
      filter: $filter
      sortBy: $sortBy
    ) {
      status
      message
      timestamp
      totalElements
      currentPageNumber
      totalPages
      payload {
        refId
        additionalInfo
        gender
        birthday
        createdBy
        createdDate
        status
      }
    }
  }
`

export const CONSULTANT_FILTER_CHECKUP_USER: DocumentNode = gql`
  query filterCheckupUser(
    $sortBy: String
    $filter: String!
    $page: Int!
    $size: Int!
  ) {
    filterCheckupUser(
      sortBy: $sortBy
      page: $page
      size: $size
      filter: $filter
    ) {
      status
      message
      timestamp
      totalElements
      payload {
        refId
        vendorAssignedRefId
        gender
        birthday
        additionalInfo
        visibility {
          # clientCode
          tenantCodes
          subTenantCodes
        }
        createdDate
        createdBy
      }
    }
  }
`

export const UPDATE_CHECKUP_USER: DocumentNode = gql`
  mutation updateCheckupUser($request: [UpdateCheckupUser]!) {
    updateCheckupUser(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        vendorAssignedRefId
        gender
        birthday
        additionalInfo
        deviceInputRefId
        visibility {
          tenantCodes
          subTenantCodes
        }
        status
        createdDate
        createdBy
      }
    }
  }
`
