import React, { useState } from 'react'
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Col,
  Flex,
  Row,
  Space,
  Tag,
  TimePicker,
  Tooltip,
  Typography
} from 'antd'

import {
  DEFAULT_QUESTION_SETTINGS,
  QUESTION_TYPES
} from '../../../hooks/sample/survey'
import { cloneQuestion, initQuestion } from '../../../hooks/survey/useSurvey'
import {
  ChoiceTypeEnums,
  QuestionTypeEnums,
  StatusEnums,
  SubsectionTypePartial
} from '../../../models/survey'
import { DatePickerForm } from '../../elements/DatePickerForm'
import { InputForm } from '../../elements/InputForm'
import { RadioGroupForm } from '../SurveyCreateUpdate/RadioGroupForm'
import { MultipleAnswerCard } from './MultipleAnswerCard'
import { TextAnswerCard } from './TextAnswerCard'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { ReactComponent as CopyIcon } from 'assets/svgs/copy–268.svg'
import { ReactComponent as TrashIcon } from 'assets/svgs/trash.svg'

interface Props {
  fieldName: string
  subsection: Partial<SubsectionTypePartial>
  index: number
  prevMax: { maxOrder: number; maxNo: number }
  append: any
  disabledRemove: boolean
  remove: UseFieldArrayRemove
}

export const SubsectionItemCreateCard = ({
  subsection,
  fieldName,
  index,
  prevMax,
  append,
  disabledRemove,
  remove
}: Props) => {
  const { t } = useTranslation()
  const [questionType, setQuestionType] = useState<string>('')
  const { watch, setValue, getValues } = useFormContext()
  const displayEnglish = watch('displayEnglish')

  React.useEffect(() => {
    const { questionType, setting } = subsection
    let answerType = ''
    if (questionType === QuestionTypeEnums.choice) {
      const choiceType = setting?.choiceType
      if (
        ChoiceTypeEnums.radiobutton === choiceType ||
        ChoiceTypeEnums.dropdown === choiceType
      ) {
        answerType = ChoiceTypeEnums.radiobutton
      }
      if (choiceType === ChoiceTypeEnums.checkbox) {
        answerType = ChoiceTypeEnums.checkbox
      }
    }

    if (
      questionType === QuestionTypeEnums.text ||
      questionType === QuestionTypeEnums.date ||
      questionType === QuestionTypeEnums.time
    ) {
      answerType = questionType
    }

    setQuestionType(answerType)
  }, [])

  const handleChangeQuestionType = (answer: any, checked: boolean) => {
    const { key } = answer
    if (!checked) return
    setQuestionType(key)
    const prevQuestion = getValues(fieldName)
    // console.log({ key, prevQuestion })

    if ([ChoiceTypeEnums.radiobutton, ChoiceTypeEnums.dropdown].includes(key)) {
      setValue(fieldName, {
        ...prevQuestion,
        questionType: QuestionTypeEnums.choice,
        setting: {
          isMultiple: false,
          choiceType: key,
          status: StatusEnums.active
        }
      })
    }

    if ([ChoiceTypeEnums.checkbox].includes(key)) {
      setValue(fieldName, {
        ...prevQuestion,
        questionType: QuestionTypeEnums.choice,
        setting: {
          isMultiple: true,
          choiceType: ChoiceTypeEnums.checkbox,
          status: StatusEnums.active
        }
      })
    }

    if ([QuestionTypeEnums.text].includes(key)) {
      setValue(fieldName, {
        ...prevQuestion,
        questionType: key,
        setting: DEFAULT_QUESTION_SETTINGS[QuestionTypeEnums.text]
      })
    }

    if ([QuestionTypeEnums.date].includes(key)) {
      setValue(fieldName, {
        ...prevQuestion,
        questionType: key,
        setting: DEFAULT_QUESTION_SETTINGS[QuestionTypeEnums.date]
      })
    }

    if ([QuestionTypeEnums.time].includes(key)) {
      setValue(fieldName, {
        ...prevQuestion,
        questionType: key,
        setting: DEFAULT_QUESTION_SETTINGS[QuestionTypeEnums.time]
      })
    }
  }

  const renderAnswers = (questionType: string) => {
    switch (questionType) {
      case QuestionTypeEnums.text:
        return <TextAnswerCard fieldName={fieldName} className="w-[50%]" />
      case QuestionTypeEnums.date:
        return (
          <DatePickerForm
            name="datepicker"
            disabled
            placeholder=""
            className="h-[30px] max-w-[20em] border-[1px] !border-[#137695]"
          />
        )
      case QuestionTypeEnums.time:
        return (
          <TimePicker
            disabled
            placeholder=""
            className="h-[30px] max-w-[20em] border-[1px] !border-[#137695]"
          />
        )
      default:
        return <MultipleAnswerCard fieldName={fieldName} />
    }
  }

  const onCopy = () => {
    append(cloneQuestion(getValues(fieldName), prevMax))
  }

  return (
    <Flex vertical className={`question-item-box mt-1 gap-[0px]`}>
      <Row>
        <Col span={12} className="self-center">
          <Typography.Text strong style={{ color: '#707070' }}>
            {t('questionnaire.helperText.enterQuestion')}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Flex gap="middle" className="btn-controller float-end">
            <>
              <Tooltip placement="top" title={t('popover.copy')}>
                <Button
                  className="border-transparent"
                  shape="circle"
                  onClick={() => onCopy()}
                  icon={<CopyIcon />}
                />
              </Tooltip>
            </>
            <Tooltip placement="top" title={t('button.delete')}>
              <Button
                className="border-transparent"
                shape="circle"
                disabled={disabledRemove}
                onClick={() => remove(index)}
                icon={<TrashIcon width="16" />}
              />
            </Tooltip>
            <RadioGroupForm fieldName={`${fieldName}.isRequired`} />
            <Button
              type="primary"
              icon={<PlusOutlined style={{ color: '#FFF' }} />}
              size="middle"
              className="shadow-none rounded"
              onClick={() => append(initQuestion(t, prevMax))}
            />
          </Flex>
        </Col>
      </Row>
      <Flex className="mt-2 p-1 pl-0 w-full min-h-[90px] border border-[#BDCBD5]">
        <div className="w-[3%] justify-center flex items-center">
          <MoreOutlined style={{ color: '#BDCBD5' }} className="text-4xl" />
        </div>
        <div className="h-[auto] w-[97%] justify-center items-center">
          <InputForm
            name={`${fieldName}.displayNameMap.ja`}
            className="w-[99.2%] h-[30px] pr-[5px] mt-1"
            placeholder={t('questionnaire.placeholder.enterQuestion')}
            defaultValue={subsection?.displayNameMap?.ja ?? ''}
            showErrorMessage
          />
          {Boolean(displayEnglish) && (
            <InputForm
              name={`${fieldName}.displayNameMap.en`}
              className="w-[99.2%] h-[30px] pr-[5px] mt-2"
              placeholder={t('questionnaire.placeholder.enterEnglishName')}
              defaultValue={subsection?.displayNameMap?.en ?? ''}
              showErrorMessage
            />
          )}
        </div>
      </Flex>
      <div className="flex-answer-box">
        <Flex gap="small" vertical className="p-2 pl-16 gap-[8px]">
          <Typography.Text
            strong
            style={{ color: '#545454' }}
            className="text-[14px]"
          >
            {t('questionnaire.label.typeQuestion')}
          </Typography.Text>

          <Space size="middle" wrap>
            {QUESTION_TYPES.map((question, index) => (
              <Tag.CheckableTag
                className={`tag-answer rounded-2xl min-w-36 text-center`}
                key={index}
                checked={questionType === question.key}
                onChange={(checked) =>
                  handleChangeQuestionType(question, checked)
                }
              >
                <Typography.Text className="text-[14px]">
                  {t(`questionnaire.label.${question.title}`)}
                </Typography.Text>
              </Tag.CheckableTag>
            ))}
          </Space>

          {questionType !== QuestionTypeEnums.text && (
            <Typography.Text
              strong
              style={{ color: '#545454' }}
              className="text-[14px] mt-2.5"
            >
              {t('questionnaire.label.addAnswerQuestion')}
            </Typography.Text>
          )}

          <Flex vertical gap="middle" className="gap-[8px]">
            {renderAnswers(questionType)}
          </Flex>
        </Flex>
      </div>
    </Flex>
  )
}
