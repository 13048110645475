import { DocumentNode, gql } from '@apollo/client'

interface VariableProps {
  page: number
  size: number
  filter: string
}

export const FILTER_ALL: VariableProps = {
  page: 0,
  size: -1,
  filter: ''
}

export const FILTER_MEDICAL_CHECKUP_MASTER: DocumentNode = gql`
  query filterMedicalCheckupMasterDetails(
    $filter: String!
    $page: Int!
    $size: Int!
  ) {
    filterMedicalCheckupMasterDetails(
      page: $page
      size: $size
      filter: $filter
    ) {
      status
      message
      timestamp
      totalElements
      totalPages
      currentPageNumber
      payload {
        medicalCheckupMaster {
          refId
          associatedTestItemMasters
          associatedPatientInfoMasters
          additionalInfo
          createdDate
          createdBy
        }
        testItemCategory {
          refId
          shortName
          associatedTestItemMasterRefIds
        }
        patientInfoMasters {
          refId
          fieldName
          fieldType
        }
      }
    }
  }
`
