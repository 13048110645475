import { Button, Flex, Pagination } from 'antd'

import styles from './Pagination.module.scss'
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons'

export const PaginationCustom = ({
  onChangePage,
  onGoToFirstPage,
  onGoToLastPage,
  total,
  pageSize,
  currentPage
}: {
  onChangePage: Function
  onGoToFirstPage: Function
  onGoToLastPage: Function
  total: number
  pageSize: number
  currentPage: number
}) => {
  const totalPages = Math.ceil(total / pageSize)
  const itemRender = (
    page: number,
    type: string,
    originalElement: React.ReactNode
  ) => {
    if (type === 'prev') {
      return (
        <LeftOutlined
          style={{
            color: currentPage > 0 ? '#137695' : '#CDD6DD'
          }}
        />
      )
    }
    if (type === 'next') {
      return (
        <RightOutlined
          style={{
            color:
              totalPages > 1 && currentPage < totalPages - 1
                ? '#137695'
                : '#CDD6DD'
          }}
        />
      )
    }

    return originalElement
  }
  if (total === 0) {
    return <></>
  }
  return (
    <Flex justify="center">
      <Button
        style={{ background: 'none' }}
        className="rounded-none"
        onClick={() => onGoToFirstPage()}
        disabled={currentPage === 0}
        icon={
          <DoubleLeftOutlined
            style={{
              color: currentPage === 0 ? '#CDD6DD' : '#137695'
            }}
          />
        }
      />
      <Pagination
        className={styles.pagination_wrapper}
        total={total}
        pageSize={pageSize}
        onChange={(item) => onChangePage(item)}
        itemRender={itemRender}
        current={currentPage + 1}
      />
      <Button
        style={{ background: 'none' }}
        className="rounded-none"
        onClick={() => onGoToLastPage()}
        disabled={currentPage === totalPages - 1}
        icon={
          <DoubleRightOutlined
            style={{
              color: currentPage === totalPages - 1 ? '#CDD6DD' : '#137695'
            }}
          />
        }
      />
    </Flex>
  )
}
