import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Col, Flex, Popover, Typography } from 'antd'
import { t } from 'i18next'

import { QuestionCircleFilled } from '@ant-design/icons'
import DropdownCustom from 'components/dropdown/DropdownCustom'
import { InputForm } from 'components/elements/InputForm'
import { SelectCustom } from 'components/elements/Select'
import { TextAreaForm } from 'components/elements/TextAreaForm'
import { VALUE_WITH_NO_DATA } from 'configs/constant'

const comprehensiveJudgment: any = '03.0001'
const generalComments: any = '03.0152'
const metabolicSyndromeAssessment: any = '03.0004'
const specificHealthGuidance: any = '1.00069'
const healthGuidance: any = '03.0005'

export default function InstructionsAndComments({
  setValue,
  triggerValidation,
  fixedCategory,
  overallVerdict,
  changeCommentOverallVerdict,
  setChangeCommentOverallVerdict,
  language
}: {
  setValue: any
  triggerValidation: any
  fixedCategory: any
  overallVerdict: any
  changeCommentOverallVerdict: any
  setChangeCommentOverallVerdict: any
  language: any
}) {
  const { watch } = useFormContext()
  const [category, setCategory] = useState<any>([])
  const [comment, setComment] = useState<any>([])

  const judgementTitle = category?.[comprehensiveJudgment]?.refId
    ? watch(category[comprehensiveJudgment].refId)
    : undefined

  useEffect(() => {
    if (judgementTitle === undefined) return
    const dataComment = overallVerdict
      .map(function (item: any) {
        if (!judgementTitle || !judgementTitle.trim()) return null
        if (item?.additionalInfo?.judgementTitle !== judgementTitle) {
          return null
        }
        return {
          value: item?.additionalInfo?.judgementComment,
          text: item?.additionalInfo?.judgementComment
        }
      })
      .filter((item: any) => item !== null)

    setComment(dataComment)
  }, [judgementTitle])

  useEffect(() => {
    if (fixedCategory && fixedCategory.length > 0) {
      const dataCategory = fixedCategory.reduce((data: any, item: any) => {
        if (item?.code?.coding?.[0]?.code) {
          data[item?.code?.coding?.[0]?.code] = item
        }
        return data
      }, {})
      setCategory(dataCategory)
    }
  }, [fixedCategory])

  return (
    <>
      {category?.[comprehensiveJudgment] && (
        <Flex
          className="bg-primary px-1.5 pt-[15px] pb-3 mt-2.5"
          id={category[comprehensiveJudgment].refId}
        >
          <Col flex="29.3%" className="flex items-center gap-8">
            <Typography className="font-bold text-white">
              {category[comprehensiveJudgment].lang?.[language] ??
                VALUE_WITH_NO_DATA}
            </Typography>
            <Popover
              placement="top"
              content={
                <div className="p-3 w-[330px] bg-[#545454] text-white rounded">
                  {t('content.comprehensiveJudgmentEdit')}
                </div>
              }
              overlayClassName="popover-permission"
            >
              <QuestionCircleFilled className="w-[14px] text-white" />
            </Popover>
          </Col>
          <Col flex="auto" className="flex items-center">
            <InputForm
              name={category[comprehensiveJudgment].refId}
              className="w-[29.4%] mr-[26px]"
              onInput={() => {
                if (!changeCommentOverallVerdict)
                  setChangeCommentOverallVerdict(true)
              }}
            />
          </Col>
        </Flex>
      )}
      {category?.[generalComments] && (
        <>
          <Flex
            className="bg-primary text-white gap-1 px-1.5 pt-[14px] pb-3 mt-6"
            id={category[generalComments].refId}
          >
            <Typography className="font-bold text-white">
              {category[generalComments].lang?.[language] ?? VALUE_WITH_NO_DATA}
            </Typography>
            <Popover
              placement="top"
              content={
                <div className="p-3 w-[330px] bg-[#545454] text-white rounded">
                  {t('content.commentsDisplayAutomatically')}
                  {t('content.generalCommentsEdit')}
                </div>
              }
              overlayClassName="popover-permission"
            >
              <QuestionCircleFilled className="w-[14px] text-white" />
            </Popover>
          </Flex>
          <div className="w-full mt-2 min-h-[160px] border border-solid border-[#C6C6C6]">
            <DropdownCustom
              name={category[generalComments].refId}
              setValue={setValue}
              triggerValidation={triggerValidation}
              dropdownLabel=""
              options={comment}
              changeComment={changeCommentOverallVerdict}
            />
          </div>
        </>
      )}
      <Flex className="mt-[21px] gap-3">
        {category?.[metabolicSyndromeAssessment] && (
          <Col
            flex="50.4%"
            className="flex items-center justify-between bg-primary pl-1.5 pr-2.5 pt-[14px] pb-3"
            id={category[metabolicSyndromeAssessment].refId}
          >
            <Typography className="font-bold text-white">
              {category[metabolicSyndromeAssessment].lang?.[language] ??
                VALUE_WITH_NO_DATA}
            </Typography>
            <SelectCustom
              name={category[metabolicSyndromeAssessment].refId}
              className="w-[40.65%]"
              defaultValue={'該当'}
              options={[
                { label: '該当', value: '該当' },
                { label: '非該当', value: '非該当' }
              ]}
            />
          </Col>
        )}
        {category?.[specificHealthGuidance] && (
          <Col
            flex="auto"
            className="flex items-center bg-primary justify-between pl-[27px] pr-4 pt-[14px] pb-3"
            id={category[specificHealthGuidance].refId}
          >
            <Typography className="font-bold text-white">
              {category[specificHealthGuidance].lang?.[language] ??
                VALUE_WITH_NO_DATA}
            </Typography>
            <SelectCustom
              name={category[specificHealthGuidance].refId}
              className="w-[40.65%]"
              defaultValue={'該当'}
              options={[
                { label: '該当', value: '該当' },
                { label: '非該当', value: '非該当' }
              ]}
            />
          </Col>
        )}
      </Flex>
      {category?.[healthGuidance] && (
        <>
          <Flex
            className="bg-primary gap-12 px-1.5 pt-[14px] pb-3 mt-[26px]"
            id={category[healthGuidance].refId}
          >
            <Typography className="font-bold text-white">
              {category[healthGuidance].lang?.[language] ?? VALUE_WITH_NO_DATA}
            </Typography>
            <Typography className="font-bold text-white">
              {category[healthGuidance].lang?.en ?? VALUE_WITH_NO_DATA}
            </Typography>
          </Flex>
          <TextAreaForm
            name={category[healthGuidance].refId}
            className="w-full mt-3 min-h-[200px]"
            rows={8}
          />
        </>
      )}
    </>
  )
}
