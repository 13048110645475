import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { Button, Flex, Typography } from 'antd'
import { TFunction } from 'i18next'

import { CustomDatePicker } from '../../../../components/elements/CustomDatePicker'
import LocationSetting from '../../../../components/screens/BasicSetting/components/LocationSetting'
import SelectPhoneNumber from '../SelectPhoneNumber'
import InputLabel from './InputLabel'
import TextArea from 'antd/es/input/TextArea'
import { InputForm } from 'components/elements/InputForm'
import { InputForm as EditForm } from 'models/customer'

export interface CustomerDetailEditFormMethods {
  onSubmit: () => void
}

type Props = {
  t: TFunction<'translation', undefined>
  initialData?: EditForm
  onSubmit: (data: EditForm) => void
}

const CustomerDetailEditForm = forwardRef<CustomerDetailEditFormMethods, Props>(
  ({ t, initialData, onSubmit }: Props, ref) => {
    const methods = useForm<EditForm>({
      defaultValues: {
        id: initialData?.id,
        patientId: initialData?.patientId,
        firstName: initialData?.firstName,
        lastName: initialData?.lastName,
        firstNameKana: initialData?.firstNameKana,
        lastNameKana: initialData?.lastNameKana,
        fullName: initialData?.fullName,
        gender: initialData?.gender,
        birthday: initialData?.birthday,
        zipCode: initialData?.zipCode,
        province: initialData?.province,
        country: initialData?.country,
        apartment: initialData?.apartment,
        stateName: initialData?.stateName,
        district: initialData?.district,
        detailedAddress: initialData?.detailedAddress,
        dialCodePhone: initialData ? initialData.dialCodePhone : '81-Japan',
        phone: initialData?.phone,
        dialCodeEmergencyContact: initialData
          ? initialData.dialCodeEmergencyContact
          : '81-Japan',
        emergencyContact: initialData?.emergencyContact,
        email: initialData?.email,
        typeOfInsurance: initialData?.typeOfInsurance,
        numberOfInsurance: initialData?.numberOfInsurance,
        insuranceSymbol: initialData?.insuranceSymbol,
        insuranceNumber: initialData?.insuranceNumber,
        collaborativeApplication: initialData?.collaborativeApplication,
        userID: initialData?.userID,
        applicationStartDate: initialData?.applicationStartDate,
        note: initialData?.note,
        address: initialData?.address
      }
    })

    const [selectedGender, setSelectedGender] = useState<boolean>(
      initialData?.gender === 'MALE' || initialData?.gender === undefined
    )

    useImperativeHandle(ref, () => ({
      onSubmit: () => {
        methods.handleSubmit(onSubmit)()
      }
    }))

    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value
      e.currentTarget.value = inputValue.replace(/[^0-9]/g, '')
    }

    const handleFormSubmit = (data: any) => {
      onSubmit(data)
    }

    return (
      <div className="">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            {/*1*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.id')}
                required
                className="h-[36px] w-[160px] font-bold"
              />
              <InputForm
                name="patientId"
                rules={{
                  required: true,
                  maxLength: 50
                }}
                className="ml-2 h-[30px] w-[400px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*2 + 3*/}
            <Flex className="mb-[6px]">
              <Flex>
                <InputLabel
                  label={t('customerRegister.label.firstName')}
                  required
                  className="h-[48px] w-[160px] font-bold"
                />
                <div className="ml-2">
                  <InputForm
                    name="firstName"
                    rules={{
                      required: true,
                      maxLength: 50
                    }}
                    className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                  />
                  <Typography className="text-[10px] tracking-[0.5px]">
                    {t('customerRegister.notice.enterEnglish')}
                  </Typography>
                </div>
              </Flex>

              <Flex className="ml-[30px]">
                <InputLabel
                  label={t('customerRegister.label.lastName')}
                  required
                  className="h-[48px] w-[160px] font-bold"
                />
                <div className="ml-2">
                  <InputForm
                    name="lastName"
                    rules={{
                      required: true,
                      maxLength: 50
                    }}
                    className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                  />
                  <Typography className="text-[10px] tracking-[0.5px]">
                    {t('customerRegister.notice.enterEnglish')}
                  </Typography>
                </div>
              </Flex>
            </Flex>

            {/*4 + 5*/}
            <Flex className="mb-[6px]">
              <Flex>
                <InputLabel
                  label={t('customerRegister.label.firstNameKana')}
                  className="h-[48px] w-[160px] font-bold"
                />
                <div className="ml-2">
                  <InputForm
                    rules={{
                      maxLength: 50
                    }}
                    name="firstNameKana"
                    className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                  />
                  <Typography className="text-[10px] tracking-[0.5px]">
                    {t('customerRegister.notice.enterEnglish')}
                  </Typography>
                </div>
              </Flex>

              <Flex className="ml-[30px]">
                <InputLabel
                  label={t('customerRegister.label.lastNameKana')}
                  className="h-[48px] w-[160px] font-bold"
                />
                <div className="ml-2">
                  <InputForm
                    rules={{
                      maxLength: 50
                    }}
                    name="lastNameKana"
                    className="h-[30px] w-[400px] px-3 tracking-[1.4px]"
                  />
                  <Typography className="text-[10px] tracking-[0.5px]">
                    {t('customerRegister.notice.enterEnglish')}
                  </Typography>
                </div>
              </Flex>
            </Flex>

            {/*19*/}
            <Flex align="center" className="mb-[6px] h-full">
              <InputLabel
                label={t('customerRegister.label.gender')}
                className="h-[36px] w-[160px] font-bold"
              />

              <div>
                <Flex align="center" className="ml-2 min-h-[49px] w-[200px]">
                  <Button
                    autoInsertSpace={false}
                    onClick={() => {
                      setSelectedGender(true)
                      methods.setValue('gender', 'MALE')
                    }}
                    className="w-[100px] rounded-l-[5px] border-r-0"
                    style={{
                      backgroundColor: selectedGender ? '#137695' : '',
                      color: selectedGender ? 'white' : '',
                      border: selectedGender ? '#137695' : ''
                    }}
                  >
                    {t('lable.male')}
                  </Button>
                  <Button
                    autoInsertSpace={false}
                    onClick={() => {
                      setSelectedGender(false)
                      methods.setValue('gender', 'FEMALE')
                    }}
                    style={{
                      backgroundColor: !selectedGender ? '#137695' : '',
                      color: !selectedGender ? 'white' : '',
                      border: !selectedGender ? '#137695' : ''
                    }}
                    className="w-[100px] rounded-r-[5px] border-l-0"
                  >
                    {t('lable.female')}
                  </Button>
                </Flex>
              </div>
            </Flex>

            {/*6*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.birthday')}
                required
                className="h-[36px] w-[160px] font-bold"
              />
              <CustomDatePicker
                name="birthday"
                format="YYYY/MM/DD"
                rules={{
                  required: true
                }}
                placeholder={t('customerRegister.placeholder.selectDate')}
                className="ml-2 h-[30px] w-[200px] border-[1px] border-[#137695]"
              />
            </Flex>

            {/*7 + 8 + 9 + 10*/}
            <Flex className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.address')}
                className="h-[215px] w-[160px] font-bold"
              />

              <div className="ml-2" />
              <LocationSetting />
            </Flex>

            {/*11*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.phoneNumber')}
                className="mr-2 h-[36px] w-[160px] font-bold"
              />
              <SelectPhoneNumber name="dialCodePhone" />
              <InputForm
                rules={{
                  maxLength: 15
                }}
                name="phone"
                onInput={handleInput}
                placeholder={t(
                  'customerRegister.placeholder.enterWithoutHyphens'
                )}
                className="ml-2 h-[30px] w-[273px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*12*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.emergencyContactNumber')}
                className="mr-2 h-[36px] w-[160px] font-bold"
              />
              <SelectPhoneNumber name="dialCodeEmergencyContact" />
              <InputForm
                rules={{
                  maxLength: 15
                }}
                name="emergencyContact"
                onInput={handleInput}
                placeholder={t(
                  'customerRegister.placeholder.enterWithoutHyphens'
                )}
                className="ml-2 h-[30px] w-[273px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*13*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.email')}
                className="h-[36px] w-[160px] font-bold"
              />
              <InputForm
                rules={{
                  maxLength: 50,
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: ''
                  }
                }}
                name="email"
                className="ml-2 h-[30px] w-[400px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*14*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.typeOfInsurance')}
                className="h-[36px] w-[160px] font-bold"
              />
              <InputForm
                rules={{
                  maxLength: 50
                }}
                name="typeOfInsurance"
                className="ml-2 h-[30px] w-[400px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*15*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.numberOfInsurance')}
                className="h-[36px] w-[160px] font-bold"
              />
              <InputForm
                rules={{
                  maxLength: 50
                }}
                name="numberOfInsurance"
                onInput={handleInput}
                className="ml-2 h-[30px] w-[122px] px-3 tracking-[1.4px]"
              />
            </Flex>

            {/*16 + 17*/}
            <Flex className="mb-[6px]">
              <Flex align="center" className="mr-[5px]">
                <InputLabel
                  label={t('customerRegister.label.insuranceSymbol')}
                  className="h-[36px] w-[160px] font-bold"
                />
                <InputForm
                  rules={{
                    maxLength: 50
                  }}
                  name="insuranceSymbol"
                  onInput={handleInput}
                  className="ml-2 h-[30px] w-[122px] px-3 tracking-[1.4px]"
                />
              </Flex>

              <Flex align="center">
                <InputLabel
                  label={t('customerRegister.label.insuranceNumber')}
                  className="h-[36px] w-[146px] font-bold"
                />
                <InputForm
                  rules={{
                    maxLength: 50
                  }}
                  name="insuranceNumber"
                  onInput={handleInput}
                  className="ml-[5px] h-[30px] w-[122px] px-3 tracking-[1.4px]"
                />
              </Flex>
            </Flex>

            {/*18*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.collaborativeApplication')}
                className="h-[36px] w-[160px] font-bold"
              />
              {methods.getValues('collaborativeApplication') ? (
                <></>
              ) : (
                <Flex
                  justify="center"
                  align="center"
                  className="ml-2 h-[26px] w-[103px] rounded-[13px] bg-[#BDCBD5] text-white"
                >
                  {t('customerRegister.label.none')}
                </Flex>
              )}
            </Flex>

            {/*19*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.userID')}
                className="h-[36px] w-[160px] font-bold"
              />
              {methods.getValues('userID') ? (
                <InputForm
                  name="userID"
                  className="ml-2 h-[30px] w-[122px] px-3 tracking-[1.4px]"
                />
              ) : (
                <div className="ml-3 h-[1.5px] w-[10px] bg-[#545454]" />
              )}
            </Flex>

            {/*20*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.applicationStartDate')}
                className="h-[36px] w-[160px] font-bold"
              />
              <div className="ml-3 h-[1.5px] w-[10px] bg-[#545454]" />
            </Flex>

            {/*21*/}
            <Flex align="center" className="mb-[6px]">
              <InputLabel
                label={t('customerRegister.label.note')}
                className="h-[138px] w-[160px] font-bold"
              />

              <Controller
                name="note"
                control={methods.control}
                rules={{
                  maxLength: 400
                }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    className="ml-2 h-[138px] w-[400px] px-3"
                  />
                )}
              />
            </Flex>
          </form>
        </FormProvider>
      </div>
    )
  }
)

export default CustomerDetailEditForm
