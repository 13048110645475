import React, { useEffect, useRef } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { Button, Col, Flex, Row, Typography } from 'antd'

// Needed for typing
import { InputForm } from '../../components/elements/InputForm'
import { openNotification } from '../../components/widgets/Notification'
import { useRuleLogical } from '../../hooks/rule-logical/useRuleLogical'
import { Editor } from '@monaco-editor/react'
import * as monaco from 'monaco-editor'

export const FunctionEditor = () => {
  const { handleCreate } = useRuleLogical()
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)

  const debounce = (fn: { (): void; (): void }, ms: number | undefined) => {
    let timer: string | number | NodeJS.Timeout | undefined
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => fn(), ms)
    }
  }

  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor
  ) => {
    editorRef.current = editor
  }

  useEffect(() => {
    const handleResize = debounce(() => {
      if (editorRef.current) {
        editorRef.current.layout()
      }
    }, 100) // Adjust the debounce timing as necessary.

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    function hideError(e: { message: string }) {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    }

    window.addEventListener('error', hideError)
    return () => {
      window.addEventListener('error', hideError)
    }
  }, [])

  const methods = useForm<any>({
    defaultValues: {
      sourceCode:
        '# input function:\n' +
        'def funcFPG(values):\n' +
        '    if getattr(values, "frg") <= {p1}:\n' +
        '        return "A"\n' +
        '    \n' +
        '    if getattr(values, "HbA1c") <= {p2}:\n' +
        '        return "B"\n' +
        '\n' +
        '    return "X"',
      p1: '',
      p2: ''
    }
  })

  const { handleSubmit, control } = methods

  const onSubmit = async (formData: any) => {
    const p1 = parseFloat(formData.p1)
    const p2 = parseFloat(formData.p2)
    const sourceCode = formData.sourceCode
      .replace('{p1}', isNaN(p1) ? 0 : p1)
      .replace('{p2}', isNaN(p2) ? 0 : p2)

    await handleCreate(btoa(sourceCode))
    openNotification({
      type: 'success',
      title: 'success',
      message: ''
    })
  }

  return (
    <Flex
      vertical
      className="size-full bg-white rounded-[5px] shadow p-[10px] gap-[8px] mb-5"
      style={{
        boxShadow: '0px 3px 5px #00000029'
      }}
    >
      <Typography className="font-bold text-[16px]">Function Editor</Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex vertical gap={16} className="justify-center items-center">
            <Flex gap={16}>
              <Flex className="border w-[25vw]">
                <Controller
                  name="sourceCode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Editor
                      height="80vh"
                      language="python"
                      value={value}
                      onChange={(newValue) => onChange(newValue)}
                      onMount={handleEditorDidMount}
                      options={{
                        wordWrap: 'on',
                        fontSize: 16,
                        minimap: {
                          enabled: false
                        }
                      }}
                    />
                  )}
                />
              </Flex>

              <Flex vertical gap="large">
                <Typography className="font-bold text-[16px]">
                  Parameters
                </Typography>
                <Row className="items-center">
                  <Col>
                    <Flex gap="middle" justify="flex-start" align="center">
                      <Col span={6}>
                        <Typography className="">P1</Typography>
                      </Col>
                      <Col>
                        <InputForm name="p1" />
                      </Col>
                    </Flex>
                  </Col>
                </Row>
                <Row className="items-center">
                  <Col>
                    <Flex gap="middle" justify="flex-start" align="center">
                      <Col span={6}>
                        <Typography className="">P2</Typography>
                      </Col>
                      <Col>
                        <InputForm name="p2" />
                      </Col>
                    </Flex>
                  </Col>
                </Row>
              </Flex>
            </Flex>

            <Button type="primary" htmlType="submit" className="w-[180px]">
              保存
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  )
}
