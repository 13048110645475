export type LangType = {
  en: string | null
  ja: string | null
}

export type IconType = {
  status: string
  name: string
  file: string
}

export type SettingQuestionTextType = {
  status: string,
  minLength: number,
  maxLength: number
}

export type SettingQuestionChoiceType = {
  isMultiple: boolean,
  choiceType: string,
  status: string
}

export type SettingDateTimeType = Partial<SettingDateType | SettingTimeType>

export type SettingDateType = {
  fromDate: string
  toDate: string
  format: string
  status: string
}

export type SettingTimeType = {
  fromTime: string
  toTime: string
  format: string
  status: string
}

export type QuestionChoiceOptionType = {
  order: number,
  status: string,
  no?: string
  displayNameMap: LangType
}

export enum FormTypeEnums {
  survey = 'SURVEY',
  quiz = 'QUIZ',
}

export enum SurveyStatusEnums {
  draft = 'DRAFT',
  assigned = 'ASSIGNED',
  completed = 'COMPLETED',
  deleted = 'DELETED',
}

export enum StatusEnums {
  active = 'ACTIVE',
  deleted = 'DELETED',
}

export enum SubsectionTypeEnums {
  question = 'QUESTION',
  description = 'DESCRIPTION',
  media_file = 'MEDIA_FILE',
}

export enum ChoiceTypeEnums {
  checkbox = 'CHECKBOX',
  dropdown = 'DROPDOWN',
  radiobutton = 'RADIO_BUTTON',
}

export enum QuestionTypeEnums {
  text = 'TEXT',
  choice = 'CHOICE',
  date = 'DATE',
  time = 'TIME',
  scale = 'SCALE',
  likert = 'LIKERT',
  file_upload = 'FILE_UPLOAD',
}

export type SurveyType = {
  refId?: string
  code: string
  formType: string
  surveyStatus: string
  validFromDate?: string
  validToDate?: string
  displayNameMap: LangType
  createdBy: string
  sectionList: SectionType[]
}

export type SubsectionType =
// SubsectionTypeDescription
// | SubsectionTypeMediaFile
  | SubsectionTypeQuestionText
  | SubsectionTypeQuestionChoice
  | SubsectionTypeDateTime

export type SubsectionRefType = string[]

export type SectionType = {
  refId?: string
  order: number
  status: string
  title: LangType
  description?: LangType
  icon?: IconType
  subsectionList: SubsectionType[]
}

export type SubsectionTypeDescription = {
  refId?: string
  order: number
  subsectionType: string
  status: string
  title: LangType
  description: LangType
}

export type SubsectionTypeMediaFile = {
  refId?: string
  order: number
  subsectionType: string
  status: string
  mediaType: string
  name: string
  file: string
}

export type SubsectionTypeQuestionText = {
  refId?: string
  order: number
  subsectionType: string
  status: string
  questionType: string
  isRequired?: boolean
  no: string
  displayNameMap: LangType
  setting: SettingQuestionTextType
}

export type SubsectionTypeQuestionChoice = {
  refId?: string
  order: number
  subsectionType: string
  status: string
  questionType: string
  isRequired?: boolean
  no?: string
  displayNameMap: LangType
  setting: SettingQuestionChoiceType
  options: QuestionChoiceOptionType[]
}

export type SubsectionTypeDateTime = {
  refId?: string
  order: number
  subsectionType: string
  status: string
  questionType: string
  isRequired?: boolean
  no?: string
  displayNameMap: LangType
  setting: SettingDateTimeType
}

export type SubsectionTypePartial = {
  refId: string
  order: number
  subsectionType: string
  status: string
  questionType: string
  isRequired: boolean
  no: string
  displayNameMap: LangType
  setting: {
    status: string
    minLength: number
    maxLength: number
    isMultiple: boolean
    choiceType: string
    fromTime: string
    toTime: string
    fromDate: string
    toDate: string
    format: string
  }
  options: QuestionChoiceOptionType[]
}
