import React, { FC } from 'react'

import { Button, Flex, Typography } from 'antd'
import { TFunction } from 'i18next'

import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals/CustomModal'

type Props = {
  t: TFunction<'translation', undefined>
  isOpen: boolean
  data?: {
    id?: string
    fullName?: string
  }
  onCancel: () => void
}

const DeletionNotice: FC<Props> = ({ t, isOpen, onCancel, data }) => {
  return (
    <CustomModal
      open={isOpen}
      onOk={() => {}}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="pb-6 pt-4">
          <div>
            <Typography className="mb-[114px] flex justify-center text-[20px] font-bold tracking-[1px]">
              {t('customerDetail.modals.deletionNotice.deletedCustomer')}
            </Typography>
            <Typography className="flex justify-center tracking-[1px]">
              {t(
                'customerDetail.modals.deletionNotice.informationDeletedCustomer'
              )}
            </Typography>
            <div className="mt-[58px]">
              <Flex>
                <InputLabel
                  label={t('customerDetail.modals.fullName')}
                  className="h-[30px] w-[148px]"
                />
                <Flex align="center" className="ml-3">
                  <Typography className="mr-[52px]">
                    {data?.fullName}
                  </Typography>
                </Flex>
              </Flex>
              <Flex className="mt-2">
                <InputLabel label="ID" className="h-[30px] w-[148px]" />
                <Typography className="ml-3 flex items-center">
                  {data?.id}
                </Typography>
              </Flex>
            </div>
            <Flex justify="center" className="mt-[110px]">
              <Button
                type="default"
                htmlType="button"
                size="large"
                onClick={onCancel}
                className="h-[30px] w-[180px] rounded-none border-[1px] border-[#137695] bg-white p-0 text-center text-[14px] font-bold text-[#137695] shadow-none"
              >
                {t('customerDetail.modals.deletionNotice.back')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default DeletionNotice
