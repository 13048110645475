import React, { useState } from 'react'

import { STATUS_CODE_SUCCESS } from '../../configs/constant'
import { FILTER_SURVEY_DETAIL } from '../../graphql/Survey/filter'
import { Endpoint } from '../../utilities/apolloClient'
import { useQuery } from '@apollo/client'

// HARD-CODE
const FIXED_SURVEY_CODE = 'survey-basic-3'

export const useFixedSurvey = () => {
  const [fixedSection, setFixedSection] = useState<any>({})

  const { loading, data: response } = useQuery(FILTER_SURVEY_DETAIL, {
    variables: { filter: `(eq,STRING,code,${FIXED_SURVEY_CODE})` },
    context: { version: Endpoint.SURVEY_CORE }
  })

  React.useEffect(() => {
    if (!loading && response) {
      const {
        filterSurvey: { statusCode, payload }
      } = response
      if (statusCode === STATUS_CODE_SUCCESS && payload.length) {
        const { sectionList } = payload[0]
        const section = { ...sectionList[0], id: sectionList[0].refId } // first section
        section.subsectionList.sort((a: any, b: any) => a.order - b.order)
        setFixedSection(section)
      }
    }
  }, [response])

  return {
    fixedSection
  }
}
