import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex } from 'antd'

import { initOptionAnswer } from '../../../hooks/survey/useSurvey'
// import { ChoiceTypeEnums } from '../../../models/survey'
import { InputForm } from '../../elements/InputForm'
// import { AddAnswerQuestionItemCard } from './AddAnswerQuestionItemCard'
// import AddQuestionIcon from './AddQuestionIcon'
import { PlusOutlined } from '@ant-design/icons'
import { ReactComponent as MinusCircleOutlined } from 'assets/svgs/MinusCircleOutlined.svg'

interface Props {
  fieldName: string
  option: any
  append: any
  remove: any
  choiceType: string
  index: number
  fieldsCount: number
}

const AnswerCard = ({
  choiceType,
  fieldName,
  fieldsCount,
  option,
  append,
  remove,
  index
}: Props) => {
  const { t } = useTranslation()

  // const [withChildQuestion, setWithChildQuestion] = useState<boolean>(false)

  return (
    <Flex vertical>
      <fieldset name={fieldName} key={option.id} className="flex items-center">
        <div className="absolute left-[-2.3em]">
          {/*{choiceType === ChoiceTypeEnums.checkbox ? (*/}
          {/*  <Checkbox defaultChecked={false} />*/}
          {/*) : (*/}
          {/*  <Radio defaultChecked={false} />*/}
          {/*)}*/}
        </div>
        <InputForm
          name={`${fieldName}.displayNameMap.ja`}
          className="w-[50%] mr-5"
          // placeholder={t('questionnaire.label.answer')}
          defaultValue={option.displayNameMap.ja}
        />
        {fieldsCount > 1 && (
          <Button
            type="text"
            shape="circle"
            size="small"
            onClick={() => remove()}
            icon={<MinusCircleOutlined />}
            className="bg-[#BFC6CB] mr-5 btn-control-item btn-control-item-minus"
          />
        )}
        {index + 1 === fieldsCount && (
          <Button
            type="text"
            shape="circle"
            size="small"
            onClick={() => append()}
            icon={<PlusOutlined style={{ color: '#FFF' }} />}
            className="bg-[#137695] btn-control-item btn-control-item-plus"
          />
        )}
      </fieldset>
      {
        // withChildQuestion
        //   ? <AddAnswerQuestionItemCard answer={option.displayNameMap.ja} />
        //   : <AddQuestionIcon onClick={() => setWithChildQuestion(prev => !prev)} />
      }
    </Flex>
  )
}

export const MultipleAnswerCard = ({ fieldName }: { fieldName: string }) => {
  const { control, watch } = useFormContext()
  const { setting } = watch(fieldName)

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${fieldName}.options`
  })
  const maxOrder = Math.max(...Array.from(fields, (o: any) => o.order))
  const maxNo = Math.max(...Array.from(fields, (o: any) => o.no))
  // console.log(`MultipleAnswerCard ${fieldName}`, { maxOrder, maxNo })

  return (
    <Flex vertical className="gap-[8px] free-questionnaire-card relative">
      {fields.map((option: any, index: number) => {
        const answerFieldName: string = `${fieldName}.options.${index}`
        return (
          <AnswerCard
            choiceType={setting?.choiceType}
            fieldName={answerFieldName}
            option={option}
            key={index}
            index={index}
            fieldsCount={fields.length}
            append={() => append(initOptionAnswer({ maxOrder, maxNo }))}
            remove={() => remove(index)}
          />
        )
      })}
    </Flex>
  )
}
