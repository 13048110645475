import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Typography } from 'antd'

import CustomModal from 'components/modals'
import { adminSample } from 'models/admin'
import { ReactComponent as CheckedIcon } from 'assets/svgs/checkedIcon.svg'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
}

const CreateAdminSuccessModal = ({ isModalOpen = false, onCancel }: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //
  }

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={handleOk}>
      <Flex vertical className="text-center pt-4 px-11 pb-[38px]">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('addAdministratorSuccess')}
        </Typography>
        <CheckedIcon
          className="mt-[52px] m-auto w-[40px] h-[40px]"
        />
        <Paragraph className="mt-[23px] mb-0">
          {t('content.addAdministratorSuccess')}
        </Paragraph>
        <Row className="mt-11 gap-3 items-center">
          <Col flex="148px">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
              {t('lable.customerName')}
            </Paragraph>
          </Col>
          <Col>
            <Paragraph className="mb-0">{adminSample.full_name}</Paragraph>
          </Col>
        </Row>
        <Row className="mt-1.5 gap-3 items-center">
          <Col flex="148px">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
              {t('lable.mailAddress')} <br />
              <Typography className="text-xs p-0">{t('lable.id')}</Typography>
            </Paragraph>
          </Col>
          <Col>
            <Paragraph className="mb-0">{adminSample.email}</Paragraph>
          </Col>
        </Row>
        <Row className="mt-1.5 gap-3 items-center">
          <Col flex="148px">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
              {t('role')}
            </Paragraph>
          </Col>
          <Col>
            <Paragraph className="mb-0">{adminSample.role}</Paragraph>
          </Col>
        </Row>
        <Row className="mt-1.5 gap-3 items-center">
          <Col flex="148px">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
              {t('affiliation')}
            </Paragraph>
          </Col>
          <Col>
            <Paragraph className="mb-0 whitespace-pre-wrap">
              {adminSample.affiliation}
            </Paragraph>
          </Col>
        </Row>
        <Button
          className="w-[180px] text-primary bg-white py-2 leading-none font-bold mt-[28px] m-auto border-primary"
          onClick={onCancel}
        >
          {t('button.close')}
        </Button>
      </Flex>
    </CustomModal>
  )
}

export default CreateAdminSuccessModal
