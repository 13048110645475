import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Radio, Typography } from 'antd'

import { StyledDivError as StyledDiv } from '../../styled'

type Props = {
  options: any[]
  fieldName: string
  required: boolean
  question?: any
}

export const RadioGroupForm = ({ required, fieldName, options }: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: required,
        validate: (value: string[], _: any) => {
          return required
            ? value && value.filter((v) => v !== '').length > 0
            : true
        }
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...restField } = field

        return (
          <StyledDiv status={error ? 'error' : ''}>
            <Radio.Group
              className="w-full flex-wrap gap-2.5 flex"
              value={value && value.length > 0 ? value[0] : ''}
              onChange={({ target: { value } }) => onChange([value])}
              {...restField}
            >
              {options.map((option: any, idx: number) => (
                <Radio key={idx} className="w-[32%] mr-0" value={option.refId}>
                  <Typography className="font-bold tracking-[0.7px]">
                    {option?.displayNameMap?.ja}
                  </Typography>
                </Radio>
              ))}
            </Radio.Group>
          </StyledDiv>
        )
      }}
    />
  )
}
