import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Flex, Row } from 'antd'

import { weekdayOptions } from '../../../configs/constant'
import { CheckboxGroupForm } from './components/CheckboxGroupForm'
import LocationSetting from './components/LocationSetting'
import TimeSetting from './components/TimeSetting'
import { InputForm } from 'components/elements/InputForm'
import { InputLabel, InputLabelMedium } from 'components/elements/InputLabel'
import { RadioCustom } from 'components/elements/RadioForm'
import { WORK_SCHEDULE_TYPE, WORK_TYPES } from 'models/setting'

const BasicSettingForm = () => {
  const { t } = useTranslation()

  return (
    <Flex vertical gap={6}>
      <Row gutter={12} className="items-center">
        <Col flex="203px">
          <InputLabelMedium label={t('basicSetting.formLabel.name')} required />
        </Col>
        <Col flex="auto">
          <InputForm
            name="name"
            rules={{
              required: true,
              minLength: 1,
              maxLength: 100
            }}
            className="max-w-[400px]"
          />
        </Col>
      </Row>

      <Row gutter={12} className="items-center">
        <Col flex="203px">
          <InputLabelMedium
            label={t('basicSetting.formLabel.kana_name')}
            required
          />
        </Col>
        <Col flex="auto">
          <InputForm
            name="kanaName"
            rules={{
              required: true,
              minLength: 1,
              maxLength: 100
            }}
            className="max-w-[400px]"
          />
        </Col>
      </Row>

      <Row gutter={12} className="items-center">
        <Col flex="203px">
          <InputLabelMedium
            label={t('basicSetting.formLabel.clinic_name')}
            required
          />
        </Col>
        <Col flex="auto">
          <InputForm
            name="clinicName"
            rules={{
              required: true,
              minLength: 1,
              maxLength: 50
            }}
            className="max-w-[400px]"
          />
        </Col>
      </Row>

      <Row gutter={12} className="items-center">
        <Col flex="203px">
          <InputLabelMedium
            label={t('basicSetting.formLabel.clinic_kana_name')}
            required
          />
        </Col>
        <Col flex="auto">
          <InputForm
            name="clinicKanaName"
            rules={{
              required: true,
              minLength: 1,
              maxLength: 50
            }}
            className="max-w-[400px]"
          />
        </Col>
      </Row>

      <Row gutter={12}>
        <Col flex="203px">
          <InputLabel
            label={t('basicSetting.formLabel.location')}
            align="center"
          />
        </Col>
        <LocationSetting />
      </Row>

      <Row gutter={12}>
        <Col flex="203px">
          <InputLabel label={t('basicSetting.businessDay')} align="center" />
        </Col>

        <Col flex="auto" className="gap-[14px] flex flex-col">
          <Row gutter={12}>
            <Col flex="100px">{t('basicSetting.formLabel.setting')}</Col>

            <Col flex="auto">
              <RadioCustom
                name="workScheduleType"
                options={WORK_TYPES}
                defaultValue={WORK_SCHEDULE_TYPE.fixedDay}
                className="flex flex-col gap-2"
              />
            </Col>
          </Row>

          <Row gutter={12}>
            <Col flex="100px">{t('basicSetting.dayOfWeekSetting')}</Col>
            <Col flex="auto">
              <CheckboxGroupForm name="workdays" options={weekdayOptions} />
            </Col>
          </Row>

          <Row gutter={12} className="!flex-nowrap" wrap={false}>
            <Col flex="100px" className="mt-[6px]">
              {t('basicSetting.timeSetting')}
            </Col>

            <Col flex="auto">
              <TimeSetting />
            </Col>
          </Row>
        </Col>
      </Row>
    </Flex>
  )
}

export default BasicSettingForm
