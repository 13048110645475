import { useLazyQuery } from '@apollo/client'
import { FILTER_ITEM_CATEGORY } from 'graphql/ItemCategory/filterItemCategory'
import { FILTER_ITEM_MASTER } from 'graphql/ItemMaster/filterItemMaster'
import { Endpoint } from 'utilities/apolloClient'

interface variableProps {
  page: number
  size: number
  filter: string
}

const variablesItemMaster: variableProps = {
  filter: '(like,STRING,additionalInfo.key1,カテゴリマスタ)',
  page: 0,
  size: 10
}

const useItemCategoryList = () => {
  const [queryItemCategoryList] = useLazyQuery(FILTER_ITEM_CATEGORY, {
    context: { version: Endpoint.CHECKUP_CORE },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first' // Used for subsequent executions
  })

  const loadItemCategoryList = async (variablesFilter: any) => {
    try {
      const response = await queryItemCategoryList({
        variables: variablesFilter,
        context: { version: Endpoint.CHECKUP_CORE }
      })

      const {
        data: {
          filterTestItemCategory: { status, payload }
        }
      } = response

      if (status === 'S0000') {
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    loadItemCategoryList
  }
}

const useItemMasterList = () => {
  const [queryItemMasterList] = useLazyQuery(FILTER_ITEM_MASTER, {
    variables: variablesItemMaster,
    context: { version: Endpoint.CHECKUP_CORE },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first' // Used for subsequent executions
  })

  const loadItemMasterList = async (variablesFilter: any) => {
    try {
      const response = await queryItemMasterList({
        variables: variablesFilter,
        context: { version: Endpoint.CHECKUP_CORE }
      })

      const {
        data: {
          filterTestItemMaster: { status, payload }
        }
      } = response

      if (status === 'S0000') {
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    loadItemMasterList
  }
}

export { useItemCategoryList, useItemMasterList }
