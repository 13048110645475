import qs from 'qs'

import {
  API_LOGIN,
  API_USER,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_CLIENT_SECRET,
  KEYCLOAK_URL,
  REALM_API_TOKEN
} from '../configs/api'
import networkAdapter from '../utilities/networkAdapter'
import { STORAGE_TOKEN_CLIENT_KEY } from 'configs/constant'

export const TOKEN_PROVIDER_ENUM = {
  api: 'API',
  keycloak: 'KEYCLOAK'
}

export interface LoginKeycloakProps {
  username: string
  password: string
}

export interface LoginApiProps {
  email: string
  password: string
}

export const loginApi = (credential: LoginApiProps) => {
  return networkAdapter.post(API_LOGIN, JSON.stringify(credential))
}
export const loginClient = () => {
  return networkAdapter.postClient(
    REALM_API_TOKEN,
    qs.stringify({
      client_id: KEYCLOAK_CLIENT_ID,
      client_secret: KEYCLOAK_CLIENT_SECRET,
      grant_type: 'client_credentials'
    }),
    {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }
  )
}

export const loginKeycloak = async ({
  username,
  password
}: LoginKeycloakProps) => {
  const url = `${KEYCLOAK_URL}/${REALM_API_TOKEN}`

  const headers = new Headers()
  headers.append('Content-Type', 'application/x-www-form-urlencoded')
  headers.append('Accept', 'application/json')

  const urlencoded = new URLSearchParams()
  urlencoded.append('grant_type', 'password')
  urlencoded.append('client_id', KEYCLOAK_CLIENT_ID)
  urlencoded.append('client_secret', KEYCLOAK_CLIENT_SECRET)
  urlencoded.append('username', username)
  urlencoded.append('password', password)

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: headers,
    body: urlencoded,
    redirect: 'manual'
  }

  try {
    const response = await fetch(url, requestOptions)
    return await response.json()
  } catch (error) {
    return console.error(error)
  }
}

export const logoutKeycloak = async (userId: any) => {
  const url = `${KEYCLOAK_URL}/${API_USER}/${userId}/logout`
  return networkAdapter.postClient(url, null)
}

export const deleteAccountKeycloak = async (userId: any) => {
  const url = `${KEYCLOAK_URL}/${API_USER}/${userId}`
  return networkAdapter.deleteClient(url)
}

export const userInfoUpdate = async (userId: any, data: any) => {
  const url = `${KEYCLOAK_URL}/${API_USER}/${userId}`
  const token = localStorage.getItem(STORAGE_TOKEN_CLIENT_KEY)

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json')
  headers.append('Authorization', `Bearer ${token}`)

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data)
  }

  try {
    const response = await fetch(url, requestOptions)
    return response
  } catch (error) {
    return console.error(error)
  }
}

export const getUserInfo = async (userId: any) => {
  const url = `${KEYCLOAK_URL}/${API_USER}/${userId}`
  return networkAdapter.getClient(url)
}
