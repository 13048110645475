import React, { useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Alert, Button, Col, Flex, Layout, Row, Typography } from 'antd'

import { CheckableTagForm } from '../../components/elements/CheckableTagForm'
import { InputForm } from '../../components/elements/InputForm'
import { HiddenEnglishDisplay } from '../../components/elements/Popconfirm/HiddenEnglishDisplay'
import { HiddenTooltipPopconfirm } from '../../components/elements/Popconfirm/HiddenTooltipPopconfirm'
import { UnhideTooltipPopconfirm } from '../../components/elements/Popconfirm/UnhideTooltipPopconfirm'
import { RadioCustom } from '../../components/elements/RadioForm'
import CreateSucceededModal from '../../components/modals/CreateSucceededModal'
import {
  QuestionItemCard,
  QuestionItemHiddenCard,
  SectionItemCreateCard
} from '../../components/screens/Questionnaires'
import {
  DeleteModal,
  DisplayNameEnToggle,
  DraftedMessageBox
} from '../../components/screens/SurveyCreateUpdate'
import { openNotification } from '../../components/widgets/Notification'
import { useAuth } from '../../context/AuthProvider'
import { SAMPLE_SURVEY } from '../../hooks/sample/survey'
import { useFixedSurvey } from '../../hooks/survey'
import {
  TypeSchemas as SurveyInputForm,
  schemas
} from '../../hooks/survey/schemas'
import useSurvey, {
  initSubsectionList,
  parseDepartments,
  validatedSurveyCreate
} from '../../hooks/survey/useSurvey'
import { useSubTenantList } from '../../hooks/tenant/useTenantList'
import { useQuestionnaires } from '../../hooks/useQuestionnaires'
import {
  StatusEnums,
  SubsectionTypeEnums,
  SurveyStatusEnums
} from '../../models/survey'
import { getUserByToken } from '../../utilities/helpers'
import SurveyCreateConfirmation from '../SurveyCreateConfirmation'
import './SurveyCreation.css'
import { yupResolver } from '@hookform/resolvers/yup'
import Sider from 'antd/es/layout/Sider'
import { Content } from 'antd/es/layout/layout'
import { ReactComponent as MenuFoldOutlined } from 'assets/svgs/MenuFoldOutlined.svg'
import { ReactComponent as ArrowRight } from 'assets/svgs/arrow-right.svg'

const RadioOptions = [
  { label: 'ON', value: 1 },
  { label: 'OFF', value: 0 }
]

const DISPLAY_CATEGORY_ON: number = 1

export default function SurveyCreation() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [drafted, setDrafted] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const { hiddenQuestionnaires, setHiddenQuestionnaires } = useQuestionnaires()
  const [user] = useState<any>(useAuth()?.user ?? getUserByToken())

  const { subTenantCodes } = useSubTenantList()
  const departments = parseDepartments(subTenantCodes)

  const { fixedSection } = useFixedSurvey()
  const {
    confirmed,
    setConfirmed,
    addSurvey,
    openCreatedDraftModal,
    setOpenCreatedDraftModal,
    sectionList
  } = useSurvey()

  const handleOk = () => {
    setOpenCreatedDraftModal(false)
    navigate('/survey-list')
  }

  // initial section
  sectionList[0].subsectionList = initSubsectionList(t)

  const methods = useForm<SurveyInputForm>({
    defaultValues: {
      departments: [],
      title: '',
      displayCategories: DISPLAY_CATEGORY_ON,
      displayEnglish: 1,
      sectionList: sectionList,
      fixedSection: fixedSection
    },
    resolver: yupResolver(schemas)
  })

  const {
    handleSubmit,
    trigger,
    reset,
    formState: { isValid, errors },
    control,
    getValues
  } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sectionList'
  })

  const { fields: fixedSubsectionList, update } = useFieldArray({
    control,
    name: 'fixedSection.subsectionList'
  })

  React.useEffect(() => {
    if (fixedSection) {
      reset(
        {
          departments: [],
          title: '',
          displayCategories: DISPLAY_CATEGORY_ON,
          displayEnglish: 1,
          sectionList: sectionList,
          fixedSection: fixedSection
        },
        { keepDefaultValues: true }
      )
    }
  }, [fixedSection])

  React.useEffect(() => {
    return () => {
      // componentWillUnmount
      setHiddenQuestionnaires([])
    }
  }, [])

  const onSubmit = async (formData: SurveyInputForm) => {
    const {
      departments,
      title,
      sectionList,
      fixedSection,
      displayCategories,
      displayEnglish
    } = formData
    let survey = {
      ...SAMPLE_SURVEY,
      displayNameMap: { ja: title },
      departments,
      sectionList,
      displayCategories,
      displayEnglish,
      createdBy: user.name ?? user.preferred_username
    }

    survey = validatedSurveyCreate(survey, fixedSection)

    await addSurvey({ variables: { surveyInputs: [survey] } }).then(
      (survey: any) => {
        let payload = survey?.data?.addSurvey?.payload
        if (
          Array.isArray(payload) &&
          payload[0]?.created?.surveyStatus === SurveyStatusEnums.draft
        ) {
          setDrafted(true)
        }
      }
    )
  }

  const onSubmitCreate = async () => {
    const {
      departments,
      title,
      sectionList,
      fixedSection,
      displayCategories,
      displayEnglish
    } = getValues()
    let survey = {
      ...SAMPLE_SURVEY,
      displayNameMap: { ja: title },
      surveyStatus: SurveyStatusEnums.assigned,
      departments,
      sectionList,
      displayCategories,
      displayEnglish,
      createdBy: user.name ?? user.preferred_username
    }

    survey = validatedSurveyCreate(survey, fixedSection)
    // console.log({ survey })
    return addSurvey({ variables: { surveyInputs: [survey] } })
  }

  const navigateSurveyCreateConfirmation = () => {
    // Manually triggers form or input validation.
    console.log(getValues())
    trigger().then(() => {
      console.log({ errors })
    })

    if (!isValid) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.requiredFormFields')
      })
      return
    }

    setConfirmed(true)
  }

  const backEdit = () => {
    setConfirmed(false)
  }

  return (
    <>
      <CreateSucceededModal
        title={t('questionnaire.label.saveDraftTitle')}
        body={t('questionnaire.label.saveDraftMessage')}
        open={openCreatedDraftModal}
        onOk={handleOk}
        onCancel={handleOk}
        button={t('button.close')}
      />

      <DeleteModal
        open={openDeleteModal}
        title={getValues('title')}
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={() => {}}
        onOk={() => navigate('/survey-list')}
      />

      {confirmed ? (
        <SurveyCreateConfirmation
          formData={getValues()}
          goBack={backEdit}
          onSubmit={onSubmitCreate}
        />
      ) : (
        <Flex
          vertical
          gap="middle"
          className="min-w-[1500px] questionnaire-creation"
        >
          <Flex gap="middle" className="items-center">
            <Typography.Text
              className="mr-12 text-[16px]"
              strong
              style={{ color: '#545454' }}
            >
              {t('questionnaire.create')}
            </Typography.Text>
            <Typography.Text>
              {t('questionnaire.helperText.selectMedicalDepartmentProduct')}
            </Typography.Text>
          </Flex>

          {drafted && (
            <Flex
              gap="middle"
              className="items-center"
              align="center"
              justify="space-between"
            >
              <Alert
                message={<DraftedMessageBox />}
                type="error"
                showIcon={false}
                className="font-bold p-1 px-10"
                style={{ backgroundColor: '#FFF', borderColor: '#137695' }}
              />
            </Flex>
          )}

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} id="hook-form">
              <Flex vertical gap="middle" className="ml-3">
                <Flex gap="middle">
                  <Typography.Text strong className="text-primary">
                    {t('questionnaire.departmentSelect')}
                  </Typography.Text>
                </Flex>
                <Flex gap="middle" align="start" vertical>
                  <Flex
                    gap="middle"
                    vertical
                    justify="flex-start"
                    align="center"
                    wrap
                    className={`department-list-tags flex-row`}
                  >
                    <CheckableTagForm
                      name="departments"
                      tags={departments}
                      title="departmentName"
                    />
                  </Flex>
                </Flex>
                <Flex vertical gap="small">
                  <Row>
                    <Col span={10}>
                      <Flex gap="middle" justify="flex-start" align="center">
                        <Col span={6}>
                          <Typography className="float-right">
                            {t('questionnaire.title')}
                          </Typography>
                        </Col>
                        <Col span={12}>
                          <InputForm
                            name="title"
                            placeholder={t('questionnaire.placeholder.title')}
                          />
                        </Col>
                      </Flex>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Flex
                        gap="middle"
                        justify="flex-start"
                        align="center"
                        className="h-[30px]"
                      >
                        <Col span={6}>
                          <Typography className="float-right">
                            {t('questionnaire.label.questionCategories')}
                          </Typography>
                        </Col>
                        <Col span={18}>
                          <RadioCustom
                            className="font-bold"
                            name="displayCategories"
                            // defaultValue={`${displayCategories}`}
                            options={RadioOptions}
                          />
                        </Col>
                      </Flex>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Flex
                        className="h-[30px]"
                        gap="middle"
                        justify="flex-start"
                        align="center"
                      >
                        <Col span={6}>
                          <Typography className="float-right">
                            {t('questionnaire.label.englishDisplay')}
                          </Typography>
                        </Col>
                        <div className="flex align-middle items-center">
                          <Col className="">
                            <DisplayNameEnToggle name="displayEnglish" />
                          </Col>
                          <HiddenEnglishDisplay />
                        </div>
                      </Flex>
                    </Col>
                  </Row>
                </Flex>
              </Flex>

              <Layout className="questionnaire-layout min-h-screen shadow-md rounded-r">
                <Sider
                  collapsible
                  trigger={null}
                  breakpoint="lg"
                  collapsed={collapsed}
                  collapsedWidth={50}
                  width={400}
                  className="sider"
                  onBreakpoint={(broken) => {
                    if (broken) setCollapsed(broken)
                  }}
                >
                  <Flex
                    gap="middle"
                    justify="space-between"
                    align="center"
                    className="p-[10px] gap-[8px]"
                  >
                    <Typography.Text
                      strong
                      className={`${collapsed ? 'hidden' : 'block'} text-primary text-[16px]`}
                    >
                      {t('questionnaire.label.hiddenList')}
                      <UnhideTooltipPopconfirm />
                    </Typography.Text>
                    <Button
                      type="text"
                      icon={<MenuFoldOutlined />}
                      onClick={() => setCollapsed(!collapsed)}
                    />
                  </Flex>
                  <Flex className={`${collapsed ? 'hidden' : 'block'}`}>
                    {hiddenQuestionnaires.map((question: any, idx: number) => {
                      const index = fixedSubsectionList.findIndex(
                        (subsection: any) => subsection.refId === question.refId
                      )
                      return (
                        <QuestionItemHiddenCard
                          key={idx}
                          index={index}
                          question={question}
                          update={update}
                        />
                      )
                    })}
                  </Flex>
                </Sider>
                <Layout className="bg-white rounded-r-lg">
                  <Content className="p-[12px]">
                    <Flex
                      gap="small"
                      className="bg-[#EFF3F6] p-[8px] gap-[0px] justify-between"
                    >
                      <Flex vertical>
                        <Typography.Text
                          strong
                          className="text-primary text-[16px]"
                        >
                          {t('questionnaire.label.fixedList')}
                          <HiddenTooltipPopconfirm />
                        </Typography.Text>
                        <Typography.Text className="">
                          {t('questionnaire.helperText.fixedList')}
                        </Typography.Text>
                      </Flex>
                      <Flex className="items-center mr-2">
                        <ArrowRight />
                      </Flex>
                    </Flex>

                    {[...fixedSubsectionList]
                      .filter((s: any) => s.status === StatusEnums.active)
                      .map((subsection: any, idx: number) => {
                        const { subsectionType, refId, id } = subsection
                        const index = fixedSubsectionList.findIndex(
                          (question: any) => refId === question.refId
                        )
                        const fieldName = `fixedSection.subsectionList.${index}`
                        if (subsectionType === SubsectionTypeEnums.question) {
                          return (
                            <fieldset name={fieldName} key={id}>
                              <QuestionItemCard
                                fieldName={fieldName}
                                question={subsection}
                                index={index}
                                indexActive={idx}
                                update={update}
                              />
                            </fieldset>
                          )
                        } else {
                          // TODO render other type
                          return null
                        }
                      })}

                    <Flex
                      gap="small"
                      className="bg-[#EFF3F6] p-2 my-2.5 gap-[0px] justify-between"
                    >
                      <Flex vertical>
                        <Typography.Text
                          strong
                          className="text-primary text-[16px]"
                        >
                          {t('questionnaire.label.freeQuestion')}
                        </Typography.Text>
                        <Typography.Text className="">
                          {t('questionnaire.helperText.freeQuestion')}
                        </Typography.Text>
                      </Flex>
                      <Flex className="items-center mr-2">
                        <ArrowRight />
                      </Flex>
                    </Flex>

                    {fields.map((section, index) => {
                      const fieldName = `sectionList.${index}`
                      const maxOrder = Math.max(
                        ...Array.from(fields, (o) => o.order)
                      )
                      return (
                        <fieldset name={fieldName} key={section.id}>
                          <SectionItemCreateCard
                            maxOrder={maxOrder}
                            append={append}
                            remove={remove}
                            section={section}
                            disabledRemove={fields.length === 1}
                            fieldName={fieldName}
                            index={index}
                          />
                        </fieldset>
                      )
                    })}
                    <Flex
                      gap="middle"
                      className="my-[5rem]"
                      justify="flex-start"
                      align="center"
                    >
                      <Button
                        type="primary"
                        size="middle"
                        onClick={() => navigateSurveyCreateConfirmation()}
                        className="shadow-none w-[180px] font-bold"
                        autoInsertSpace={false}
                      >
                        {t('button.save')}
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        htmlType="submit"
                        form="hook-form"
                        size="middle"
                        className="shadow-none w-[180px] font-bold"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                          })
                        }}
                      >
                        {t('button.saveDraft')}
                      </Button>
                      <Button
                        type="default"
                        size="middle"
                        onClick={() => setOpenDeleteModal(true)}
                        className="shadow-none w-[180px] font-bold text-[#df2475] border-[#df2475] btn-primary-hover"
                        autoInsertSpace={false}
                      >
                        {t('button.delete')}
                      </Button>
                    </Flex>
                  </Content>
                </Layout>
              </Layout>
            </form>
          </FormProvider>
        </Flex>
      )}
    </>
  )
}
