import { useState } from 'react'

import { STATUS_CODE_SUCCESS } from '../../configs/constant'
import {
  FILTER_SURVEY_DETAIL,
  FILTER_SURVEY_LIST,
  VariableProps
} from '../../graphql/Survey/filter'
import { surveyList } from '../../models/surveyList'
import { Endpoint } from '../../utilities/apolloClient'
import { extractDepartmentsFromCode } from './useSurvey'
import { useLazyQuery } from '@apollo/client'
import { format } from 'date-fns'
import { setVerbosity } from 'ts-invariant'
import { useBetween } from 'use-between'

setVerbosity('silent')

const variablesInitFilter: VariableProps = {
  filter:
    '(in,STRING,surveyStatus,ASSIGNED,COMPLETED,DRAFT);(like,STRING,displayNameMap.ja,)',
  sortBy: '(desc,createdDate)',
  paginationInput: { page: 0, size: 100 }
}

export const parseSurveyList = ({ surveyList }: { surveyList: any[] }) => {
  let surveyData: surveyList[] = []
  for (const survey of surveyList) {
    const departments =
      extractDepartmentsFromCode(survey.code).join('、') ?? 'ー'
    surveyData.push({
      key: survey.refId,
      refId: survey.refId,
      status: survey.surveyStatus,
      title: survey?.displayNameMap?.ja ?? '問診票タイトル',
      departments: departments,
      author: survey.createdBy,
      createdDate: survey.createdDate
        ? format(survey.createdDate, 'yyyy-MM-dd')
        : survey.createdDate
    })
  }

  return surveyData
}

const useSurveyList = () => {
  const [querySurveyList, { loading }] = useLazyQuery(FILTER_SURVEY_LIST, {
    variables: variablesInitFilter,
    context: { version: Endpoint.SURVEY_CORE },
    fetchPolicy: 'network-only' // Used for first execution
  })
  const [querySurveyDetailList] = useLazyQuery(FILTER_SURVEY_DETAIL, {
    context: { version: Endpoint.SURVEY_CORE },
    fetchPolicy: 'network-only' // Used for first execution
  })

  const loadSurveyList = async (
    variablesFilter: any,
    withPaginate: boolean = false
  ) => {
    try {
      const response = await querySurveyList({
        variables: variablesFilter,
        context: { version: Endpoint.SURVEY_CORE }
      })

      const {
        data: {
          filterSurvey: { statusCode, payload, totalElements }
        }
      } = response

      // console.log({ statusCode, payload })
      if (statusCode === STATUS_CODE_SUCCESS) {
        const surveyList = parseSurveyList({ surveyList: payload })
        if (withPaginate) {
          return { surveyList, totalElements }
        }

        return surveyList
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchSurveyDetailList = async (variables: any) => {
    try {
      const response = await querySurveyDetailList({ variables })

      const {
        data: {
          filterSurvey: { statusCode, payload }
        }
      } = response

      if (statusCode === STATUS_CODE_SUCCESS) {
        return payload[0]
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    loading,
    fetchSurveyDetailList,
    querySurveyList,
    loadSurveyList
  }
}

const useShareSurveyFilter = () => {
  const [variablesFilter, setVariablesFilter] =
    useState<VariableProps>(variablesInitFilter)
  const [selectedDepartments, setSelectedDepartments] = useState<any[]>([])

  const resetVariablesFilter = () => {
    setVariablesFilter(variablesInitFilter)
  }

  return {
    resetVariablesFilter,
    variablesFilter,
    selectedDepartments,
    setSelectedDepartments,
    setVariablesFilter
  }
}

export const useSurveyFilter = () => useBetween(useShareSurveyFilter)

export default useSurveyList
