import { UseFieldArrayUpdate } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Table, TableProps, Typography } from 'antd'

import { useGroupModal } from '../../../hooks/useUserGroup'
import DeleteModal from './components/DeteteModal'
import { SubTenantEditModal } from './components/SubTenantEditModal'
import editIcon from 'assets/imgs/edit.svg'
import trashIcon from 'assets/imgs/trash.svg'

interface DataType {
  key: string
  name: string
  phoneNumber: string
  listIndex: number
}

type Props = {
  subTenantList: any[]
  update: UseFieldArrayUpdate<any, 'subTenantList'>
}

export const SubTenantForm = ({ subTenantList, update }: Props) => {
  const {
    showDeleteModal,
    setShowDeleteModal,
    setShowEditModal,
    selectedIndex,
    setSelectedIndex,
    setIsModeEdit
  } = useGroupModal()

  const { t } = useTranslation()

  let dataSource = subTenantList
    .map((subTenant: any, index: number) => {
      return { ...subTenant, key: index, listIndex: index }
    })
    .filter((sub) => {
      const { additionalInfo } = sub
      if (!additionalInfo?.status) return true
      return additionalInfo.status !== 'deleted'
    })

  const columns: TableProps<DataType>['columns'] = [
    {
      title: () => (
        <Typography className="text-[14px] text-center border-t-1 font-bold">
          {t('basicSetting.familyName')}
        </Typography>
      ),
      dataIndex: 'name',
      key: 'name',
      className: '!p-2 w-[200px]'
    },
    {
      title: () => (
        <Typography className="text-[14px] font-bold text-center">
          {t('lable.phone')}
        </Typography>
      ),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      className: '!p-2'
    },
    {
      title: () => (
        <Typography className="text-[14px] font-bold text-center">
          {t('basicSetting.operation')}
        </Typography>
      ),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      className: '!p-2 w-[200px]',
      render: (_, record, __) => (
        <Flex justify="center" gap={32}>
          <img
            className="self-center cursor-pointer"
            src={editIcon}
            onClick={() => {
              setSelectedIndex(record.listIndex)
              setIsModeEdit(true)
              setShowEditModal(true)
            }}
            alt=""
          />

          <img
            className="self-center cursor-pointer"
            src={trashIcon}
            onClick={() => {
              setSelectedIndex(record.listIndex)
              setShowDeleteModal(true)
            }}
            alt=""
          />
        </Flex>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        className="group-setting-table-bg-thead td-table-center"
      />
      <DeleteModal
        isModalOpen={showDeleteModal}
        subTenantList={subTenantList}
        update={update}
        onCancel={() => setShowDeleteModal(false)}
      />
      <SubTenantEditModal
        subTenantList={subTenantList}
        update={update}
        onCancel={() => setShowEditModal(false)}
      />
    </>
  )
}
