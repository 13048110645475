import { useLazyQuery, useMutation } from '@apollo/client'
import { CREATE_TEST_RESULT } from 'graphql/TestResult/createTestResult'
import { FILTER_TEST_RESULT } from 'graphql/TestResult/filterTestResult'
import { UPDATE_TEST_RESULT } from 'graphql/TestResult/updateTestResult'
import { Endpoint } from 'utilities/apolloClient'

export const useCreateTestResult = () => {
  const [createTestResultMutation, { data, loading, error }] =
    useMutation(CREATE_TEST_RESULT)

  const createTestResult = async (request: any) => {
    try {
      const result = await createTestResultMutation({
        variables: { request: request },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    createTestResult,
    data,
    loading,
    error
  }
}

export const useUpdateTestResult = () => {
  const [updateTestResultMutation, { data, loading, error }] =
    useMutation(UPDATE_TEST_RESULT)

  const updateTestResult = async (request: any) => {
    try {
      const result = await updateTestResultMutation({
        variables: { request: request },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only'
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    updateTestResult,
    data,
    loading,
    error
  }
}

const useTestResult = () => {
  const [queryTestResult, { loading, error, data }] =
    useLazyQuery(FILTER_TEST_RESULT)

  const getListTestResult = async (variables: any) => {
    try {
      const result = await queryTestResult({
        variables: {
          filter: '',
          sortBy: '',
          page: 0,
          size: -1,
          ...variables
        },

        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first' // Used for subsequent executions
      })

      return result
    } catch (err) {
      console.error(err)
    }
  }

  return {
    getListTestResult,
    data,
    loading,
    error
  }
}

export default useTestResult
