import React, { useState } from 'react'

import { STATUS_CODE_SUCCESS } from '../../configs/constant'
import {
  EVALUATE_MUTATION,
  POST_RULE_LOGICAL
} from '../../graphql/rule-logical/add'
import { FILTER_RULE_LOGICAL } from '../../graphql/rule-logical/filter'
import { Endpoint } from '../../utilities/apolloClient'
import { useMutation, useQuery } from '@apollo/client'
import { useBetween } from 'use-between'

const useShareRule = () => {
  const [ruleFunc, setRuleFunc] = useState<any>({
    refId: '',
    sourceCode: ''
  })

  return {
    ruleFunc,
    setRuleFunc
  }
}

export const useRuleFunc = () => useBetween(useShareRule)

export const useRuleLogical = () => {
  const { setRuleFunc } = useRuleFunc()
  const { loading, data: ruleLogical } = useQuery(FILTER_RULE_LOGICAL, {
    context: {
      version: Endpoint.RULE
    }
  })
  const [createMutation] = useMutation(POST_RULE_LOGICAL, {
    variables: { sourceCode: '' },
    context: {
      version: Endpoint.RULE
    }
  })
  const [evaluateMutation] = useMutation(EVALUATE_MUTATION, {
    variables: { frg: 0, HbA1c: 0 },
    context: {
      version: Endpoint.RULE
    }
  })

  const handleCreate = async (sourceCode: string) => {
    try {
      const response: any = await createMutation({
        variables: { sourceCode }
      })
      console.log('[handleUpdate] response', response)
      const {
        data: {
          postRuleLogic: { status, payload }
        }
      } = response

      if (status === STATUS_CODE_SUCCESS) {
        setRuleFunc({
          refId: '',
          sourceCode: atob(sourceCode)
        })
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleEvaluate = async (frg: number, HbA1c: number) => {
    try {
      const response: any = await evaluateMutation({
        variables: { frg, HbA1c }
      })
      console.log('[handleEvaluate] response', response)
      const {
        data: {
          evaluateLogic: { status, payload }
        }
      } = response

      if (status === STATUS_CODE_SUCCESS) {
        return payload
      }
    } catch (err) {
      console.error(err)
    }
  }

  React.useEffect(() => {
    if (!loading && ruleLogical) {
      const {
        getRuleLogical: { status, payload }
      } = ruleLogical

      if (status === STATUS_CODE_SUCCESS) {
        setRuleFunc({
          refId: payload.refId,
          sourceCode: atob(payload.sourceCode)
        })
      }
    }
  }, [loading, ruleLogical])

  return {
    handleCreate,
    handleEvaluate
  }
}
