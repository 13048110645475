import { openNotification } from '../components/widgets/Notification'
import {
  API_BASE_URL,
  API_CLIENT_CODE,
  API_RESERVATION_URL,
  API_RULE_URL,
  API_SURVEY_URL
} from '../configs/api'
import {
  clearLocalStorage,
  getAccessToken,
  getTenantLocal,
  getValidToken
} from './helpers'
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ApolloLink } from '@apollo/client/link/core'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'

export enum Endpoint {
  SURVEY_CORE = 'survey',
  CHECKUP_CORE = 'checkup',
  RULE = 'rule',
  RESERVATION = 'reservation'
}

const checkupCoreEndpoint = new HttpLink({
  uri: `${API_BASE_URL}/graphql`
})
const surveyCoreEndpoint = new HttpLink({
  uri: `${API_SURVEY_URL}`
})
const reservationEndpoint = new HttpLink({
  uri: `${API_RESERVATION_URL}`
})
const ruleEndpoint = new HttpLink({
  uri: `${API_RULE_URL}`
})

const httpLink = ApolloLink.split(
  ({ getContext }) => getContext().version === Endpoint.SURVEY_CORE,
  surveyCoreEndpoint,
  ApolloLink.split(
    ({ getContext }) => getContext().version === Endpoint.RULE,
    ruleEndpoint,
    ApolloLink.split(
      ({ getContext }) => getContext().version === Endpoint.RESERVATION,
      reservationEndpoint,
      checkupCoreEndpoint
    )
  )
)

/**
 * Add request headers
 * @ref https://www.apollographql.com/docs/react/networking/authentication#header
 * @returns
 */
const authLink: ApolloLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getValidToken(getAccessToken() ?? '')
  if (!token) {
    if (window.location.pathname.startsWith('/rule-logical')) {
      return headers
    }
    Sentry.captureMessage('[getValidToken] Token expired.')

    clearLocalStorage()
    window.location.href = '/login'
  }

  const { tenant, subtenant } = getTenantLocal()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'TENANT-CODE': tenant,
      'SUB-TENANT-CODE': subtenant,
      'CLIENT-CODE': API_CLIENT_CODE
    }
  }
})

/**
 * Add error handling
 * @ref https://www.apollographql.com/docs/react/data/error-handling
 * @returns void
 */
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      Sentry.captureException(graphQLErrors)

      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
      for (let err of graphQLErrors) {
        switch (err?.extensions?.classification) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case 'UNAUTHENTICATED':
            // Modify the operation context with a new token
            const oldHeaders = operation.getContext().headers
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: () => {
                  console.warn('TODO get new access_token')
                }
              }
            })
            // Retry the request, returning the new observable
            return forward(operation)
          default:
            openNotification({
              type: 'error',
              title: 'commonError',
              message: ''
            })
            break
        }
      }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      Sentry.captureException(networkError)

      console.error(`[Network error]: ${networkError}`)
      if (Object.hasOwn(networkError, 'result')) {
        const result: any = Object.getOwnPropertyDescriptor(
          networkError,
          'result'
        )?.value
        // console.log(result)

        switch (result.message) {
          case 'Unauthorized':
            clearLocalStorage()
            window.location.href = '/login'
            break
          case 'InternalServerError':
            openNotification({
              type: 'error',
              title: 'commonError',
              message: result.message
            })
            // Retry the request, returning the new observable
            return forward(operation)
          default:
            openNotification({
              type: 'error',
              title: 'commonError',
              message: ''
            })
            break
        }
      }
    }
  }
)

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    addTypename: false
  })
})
