import { UPDATE_SURVEY } from '../../graphql/Survey/update'
import { QuestionTypeEnums, StatusEnums } from '../../models/survey'
import { Endpoint } from '../../utilities/apolloClient'
import { extractPureCode } from './useSurvey'
import { useMutation } from '@apollo/client'

export const validatedSurveyUpdate = (survey: any, fixedSection: any) => {
  let code = extractPureCode(survey.code)
  if (survey.departments.filter((d: any) => d !== '').length > 0) {
    const strDepartments = `[department:${survey.departments.filter((d: any) => d !== '').join(',')}]`
    code = `${code}|${strDepartments}`
  }
  survey.code = `${code}`

  let { sectionList, displayCategories, displayEnglish } = survey

  let sectionListUpdate: any[] = []
  if (fixedSection && fixedSection.subsectionList) {
    sectionListUpdate.push(fixedSection)
  }
  sectionListUpdate.push(...sectionList)

  sectionListUpdate = sectionListUpdate.map((section: any, index: number) => {
    section.order = index + 1
    delete section.id
    if (!section.subsectionList) return section

    section.subsectionList.map((question: any) => {
      delete question.id
      if (
        question.questionType === QuestionTypeEnums.text ||
        question.questionType === QuestionTypeEnums.date ||
        question.questionType === QuestionTypeEnums.time
      ) {
        question.options = []
        // question.setting = DEFAULT_QUESTION_SETTINGS[QuestionTypeEnums.text]
      }
      return question
    })

    section.subsectionList
      .filter((s: any) => s.status === StatusEnums.active)
      .map((question: any, idx: number) => {
        question.order = idx + 1
        return question
      })

    return section
  })

  delete survey.departments
  delete survey.createdDate
  delete survey.displayEnglish
  delete survey.displayCategories

  return {
    ...survey,
    sectionList: sectionListUpdate,
    additionalInfo: {
      displayCategories,
      displayEnglish
    }
  }
}

export const useUpdateSurvey = () => {
  const [updateSurveyMutation] = useMutation(UPDATE_SURVEY, {
    variables: { surveyInput: {} },
    context: { version: Endpoint.SURVEY_CORE }
  })

  const updateSurvey = async (survey: any) => {
    try {
      const response: any = await updateSurveyMutation({
        variables: { surveyInput: survey },
        context: { version: Endpoint.SURVEY_CORE }
      })
      // console.log('updateSurvey response', response)
      const {
        data: {
          updateSurvey: { payload }
        }
      } = response
      return payload
    } catch (err) {
      console.error(err)
    }
  }

  return {
    updateSurvey
  }
}
