import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'
import i18n from 'i18next'

import { InputForm } from '../../../components/elements/InputForm'
import { useRoles, useRolesPermissions } from '../../../hooks/useRoles'
import { InputFormType, schema } from './AddRoleModel'
import SelectCountry from './SelectCountry'
import { yupResolver } from '@hookform/resolvers/yup'

const PopoverTitlePermissionEdit = ({ role }: { role: any }) => {
  const { t } = useTranslation('')
  const { onUpdateRole } = useRoles()
  const { setRolePermissions } = useRolesPermissions()
  const currentLocale = i18n.language

  const methods = useForm<InputFormType>({
    defaultValues: {
      name: role[currentLocale] ?? '',
      country: currentLocale
    },
    resolver: yupResolver(schema)
  })

  const { handleSubmit, watch, reset } = methods
  const selectedCountry = watch('country')

  useEffect(() => {
    // console.log('selected country', selectedCountry)
    reset({
      name: role[selectedCountry] ?? '',
      country: selectedCountry
    })
  }, [reset, role, selectedCountry])

  const onSubmit = (data: InputFormType) => {
    const newRole = {
      ...role,
      // description: data.name,
      [data.country]: data.name
    }

    onUpdateRole(newRole)
    setRolePermissions((prev) => {
      const _prev = prev.filter((r) => r.id !== newRole.id)
      return [..._prev, newRole]
    })
  }

  const handleKeyDownDefault = (event: any) => {
    if (event.key === 'Enter') {
      onSubmit(methods.getValues())
    }
  }

  return (
    <Flex vertical gap={8} className="w-[245px] bg-[#545454] p-3">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={handleKeyDownDefault}
        >
          <Typography className="text-white font-bold text-xs">
            {t('selectLanguage')}
          </Typography>
          <SelectCountry name="country" className="h-[28px] w-full" />
          <Typography className="text-white font-bold text-xs mt-2">
            {t('enterRole')}
          </Typography>
          <div className="bg-white leading-none">
            <InputForm name="name" className="w-full border-0" />
          </div>
        </form>
      </FormProvider>
    </Flex>
  )
}

export default PopoverTitlePermissionEdit
