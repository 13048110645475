import styled, { css } from 'styled-components'

export const StyledDivError = styled.div<{ status: string }>`
  ${(props) =>
    props.status === 'error' &&
    css`
      & .ant-checkbox-inner {
        border-color: #ff4d4f;
      }

      & .ant-radio-inner {
        border-color: #ff4d4f;
      }
    `}
`