import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Radio } from 'antd'

import { SizeType } from 'antd/es/config-provider/SizeContext'

type Props = {
  className?: string
  defaultValue?: string
  name: string
  options: {
    label: string
    value: number | string
  }[]
  size?: SizeType
}

export const RadioCustom = ({
  className = '',
  defaultValue,
  name,
  options = []
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <Radio.Group
            className={className}
            {...field}
            value={field.value ?? defaultValue}
          >
            {options.map((option: any) => (
              <Radio key={option.value} value={option.value}>
                {t(option.label)}
              </Radio>
            ))}
          </Radio.Group>
        )
      }}
    />
  )
}
