import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ForgotPassWord.module.scss";

export default function ForgotPassWord() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleForgotPasswprd = () => {
    navigate("/password-verify-code");
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t("forgotPassword")}</h3>
      <form action="" className={styles["form-submit"]}>
        <div className={styles["box-input"]}>
          <label htmlFor="input">{t("lable.emailRegister")}</label>
          <input type="text" placeholder="メールアドレス" id="input" />
        </div>
        <button
          type="submit"
          className={styles["btn-submit"]}
          onClick={handleForgotPasswprd}
        >
          {t("sendEmail")}
        </button>
      </form>
    </div>
  );
}
