import { useState } from 'react'

import { DELETE_SURVEY } from '../../graphql/Survey/deletes'
import { Endpoint } from '../../utilities/apolloClient'
import { useMutation } from '@apollo/client'
import { useBetween } from 'use-between'

export const useDeleteSurvey = () => {
  const [deleteSurveyMutation] = useMutation(DELETE_SURVEY, {
    variables: { surveyRefIds: [] },
    context: { version: Endpoint.SURVEY_CORE }
  })

  const deleteSurvey = async (refId: string) => {
    try {
      const response = await deleteSurveyMutation({
        variables: { surveyRefIds: [refId] },
        context: { version: Endpoint.SURVEY_CORE }
      })
      // console.log('deleteSurveyMutation response', response)

      const {
        data: {
          deleteSurvey: { statusCode, payload }
        }
      } = response
      return { statusCode, payload }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    deleteSurvey,
    deleteSurveyMutation
  }
}

const useShareModalState = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const resetIsModalVisible = () => {
    setIsModalVisible(false)
  }

  return {
    resetIsModalVisible,
    isModalVisible,
    setIsModalVisible
  }
}

export const useModalState = () => useBetween(useShareModalState)
