import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

const InputLabel: FC<{
  label: string
  className?: string
  required?: boolean
}> = ({ label, className, required }) => {
  const { t } = useTranslation()

  return (
    <Flex
      justify="space-between"
      align="center"
      className={`bg-[#F0F3F7] px-[7px] ${className}`}
    >
      <Typography className={`text-[14px] tracking-[1.4px] text-[#545454]`}>
        {label}
      </Typography>
      {required && (
        <Typography
          className={`flex h-[22px] px-1 text-nowrap items-center justify-center bg-[#DF2475] text-[12px] font-bold text-white`}
        >
          {t('lable.required')}
        </Typography>
      )}
    </Flex>
  )
}

export default InputLabel
