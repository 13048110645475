import { DocumentNode, gql } from '@apollo/client'

// 1.05 Create survey with all subsection types
export const params = {
  surveyInputs: [
    {
      code: 'survey-basic-1',
      formType: 'SURVEY',
      surveyStatus: 'COMPLETED',
      validFromDate: '2023-10-19',
      validToDate: '2023-10-19',
      displayNameMap: {
        en: 'Human capital Survey',
        ja: '猫は犬よりも優れています'
      },
      createdBy: '11508963493098088971697671282167',
      sectionList: [
        {
          order: 1,
          status: 'ACTIVE',
          title: {
            en: 'Smoking',
            ja: '猫は犬よりも優れています'
          },
          description: {
            en: 'smoking queries',
            ja: '猫は犬よりも優れています'
          },
          icon: {
            status: 'ACTIVE',
            name: 'red-squareicon.png',
            file: 'iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mP8z8BQz0AEYBxVSF+FABJADveWkH6oAAAAAElFTkSuQmCC'
          },
          subsectionList: [
            {
              order: 1,
              subsectionType: 'DESCRIPTION',
              status: 'ACTIVE',
              title: {
                en: 'Smoking',
                ja: '猫は犬よりも優れています'
              },
              description: {
                en: 'smoking queries',
                ja: '猫は犬よりも優れています'
              }
            },
            {
              order: 2,
              subsectionType: 'MEDIA_FILE',
              status: 'ACTIVE',
              mediaType: 'IMAGE',
              name: 'red-square.png',
              file: 'iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mP8z8BQz0AEYBxVSF+FABJADveWkH6oAAAAAElFTkSuQmCC'
            },
            {
              order: 3,
              subsectionType: 'QUESTION',
              status: 'ACTIVE',
              questionType: 'TEXT',
              isRequired: true,
              no: '1',
              displayNameMap: {
                en: 'What is your experience with smoking',
                ja: 'What is your experience with smoking'
              },
              setting: {
                status: 'ACTIVE',
                minLength: 10,
                maxLength: 100
              }
            },
            {
              order: 4,
              subsectionType: 'QUESTION',
              status: 'ACTIVE',
              no: '2',
              questionType: 'CHOICE',
              displayNameMap: {
                en: 'Do you smoke?',
                ja: 'Do you smoke?'
              },
              isRequired: true,
              setting: {
                isMultiple: true,
                choiceType: 'RADIO_BUTTON',
                status: 'ACTIVE'
              },
              options: [
                {
                  order: 1,
                  status: 'ACTIVE',
                  no: '1',
                  displayNameMap: {
                    en: 'yes',
                    ja: 'yes'
                  }
                },
                {
                  order: 2,
                  status: 'ACTIVE',
                  no: '2',
                  displayNameMap: {
                    en: 'no',
                    ja: 'no'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export const CREATE_SURVEY: DocumentNode = gql`
  mutation ($surveyInputs: [SurveyInput]!) {
    addSurvey(surveyInputs: $surveyInputs) {
      statusCode
      message
      timestamp
      payload {
        isCreated
        refId
        reason
        created {
          refId
          code
          formType
          surveyStatus
          validFromDate
          validToDate
          createdBy
          createdDate
          additionalInfo
          displayNameMap {
            en
            ja
          }
          sectionList {
            refId
            order
            status
            title {
              en
              ja
            }
            description {
              en
              ja
            }
            icon {
              refId
              url
              name
            }
            subsectionList {
              refId
              order
              subsectionType
              status
              mediaType
              no
              url
              name
              file
              title {
                en
                ja
              }
              description {
                en
                ja
              }
              questionType
              displayNameMap {
                en
                ja
              }
              isRequired
              setting {
                refId
                status
                no
                displayNameMap {
                  en
                  ja
                }
                minLength
                maxLength
                fromDate
                toDate
                fromTime
                toTime
                maxSizeInKB
                noOfFiles
                startPoint
                endPoint
                slice
                scaleType
                isMultiple
                choiceType
                startLabel
                endLabel
                format
              }
              options {
                refId
                order
                status
                no
                displayNameMap {
                  en
                  ja
                }
              }
              statements {
                refId
                order
                status
                no
                displayNameMap {
                  en
                  ja
                }
              }
            }
          }
        }
      }
    }
  }
`
