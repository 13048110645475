import React from 'react'
import { Button, Flex, Typography } from 'antd'
import { ReactComponent as EyeShowIcon } from 'assets/svgs/eyeNoHiddenIcon.svg'
import { StatusEnums } from '../../../models/survey'
import { useQuestionnaires } from '../../../hooks/useQuestionnaires'

interface Props {
  question: any,
  update?: any,
  index?: number,
}

export const QuestionItemHiddenCard = ({ update, index, question }: Props) => {
  const { setHiddenQuestionnaires } = useQuestionnaires()

  const unHiddenQuestionnaires = () => {
    setHiddenQuestionnaires(prevQuests => prevQuests.filter(item => item.refId !== question.refId))
    question.status = StatusEnums.active
    update(index, question)
  }

  return (
    <Flex
      gap="middle"
      justify="space-between"
      align="start"
      className="m-2 p-2 bg-white rounded"
    >
      <Typography.Text style={{ color: '#707070' }}>
        {question.displayNameMap.ja}
      </Typography.Text>
      <Button
        className="border-transparent"
        shape="circle"
        size="small"
        onClick={() => unHiddenQuestionnaires()}
        icon={<EyeShowIcon />}
      />
    </Flex>
  )
}
