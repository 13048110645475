import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Radio } from 'antd'

type Props = {
  fieldName: string
}

export const RadioGroupRequiredForm = ({ fieldName }: Props) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        const { onChange, value, ...restField } = field
        return (
          <Radio.Group
            value={value}
            onChange={({ target: { value } }) => onChange(Boolean(value))}
            buttonStyle="solid"
            {...restField}
          >
            <Radio.Button value={true}>{t('lable.required')}</Radio.Button>
            <Radio.Button value={false}>{t('lable.any')}</Radio.Button>
          </Radio.Group>
        )
      }}
    />
  )
}
