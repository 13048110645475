import { FEEDBACK_STATUS } from 'configs/constant'
import { ConsultantResult } from 'models/consultantResult'

// Logic calculate age
function calculateAge(date: string) {
  const birthDate = new Date(date)
  const currentDate = new Date()

  let age = currentDate.getFullYear() - birthDate.getFullYear()
  const monthDifference = currentDate.getMonth() - birthDate.getMonth()
  const dayDifference = currentDate.getDate() - birthDate.getDate()

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--
  }

  return age ?? 0
}

function getListIDCustomer(list: Array<ConsultantResult>) {
  if (list.length !== 0 && list) {
    const dataIDs = list.map((customer) => {
      return customer.id
    })
    const uniqueDataIDs: string[] = Array.from(new Set(dataIDs))
    return uniqueDataIDs
  } else {
    return []
  }
}

function getTotalAnswerConsultantManagement(
  list: Array<ConsultantResult>,
  status?: any
) {
  let total = 0
  if (list && list.length > 0) {
    list.forEach((item) => {
      if (status) {
        if (item.statusEnum === status) {
          total++
        }
      } else {
        if (
          item.statusEnum !== FEEDBACK_STATUS.COMPLETED &&
          item.statusEnum !== FEEDBACK_STATUS.ASSIGNED
        ) {
          total++
        }
      }
    })
  }
  return total
}

function getListConsultantManagementWithFilter(
  list: Array<ConsultantResult>,
  isFilterAnswered: number,
  filterDepartments: string,
  filterGenderTypeSelect: string,
  ageEnd: number,
  ageStart: number,
  refId: string
) {
  let listFilterWithDepartments: ConsultantResult[] = list
  // filter list in client when have filterDepartments
  if (filterGenderTypeSelect !== '') {
    if (filterGenderTypeSelect === 'MALE') {
      listFilterWithDepartments = listFilterWithDepartments.filter(
        (item) => item.gender === '男'
      )
    }
    if (filterGenderTypeSelect !== 'MALE') {
      listFilterWithDepartments = listFilterWithDepartments.filter(
        (item) => item.gender !== '男'
      )
    }
  }
  if (
    (ageStart !== undefined && ageStart !== null) ||
    (ageEnd !== undefined && ageEnd !== null)
  ) {
    listFilterWithDepartments = listFilterWithDepartments.filter(
      (item) => item.age >= ageStart && item.age <= ageEnd
    )
  }
  if (refId !== undefined || refId !== '') {
    listFilterWithDepartments = listFilterWithDepartments.filter(
      (item) =>
        item.id.toLowerCase().includes(refId.toLowerCase()) ||
        item.name.toLowerCase().includes(refId.toLowerCase())
    )
  }
  const listFilter = filterDepartments === '' ? list : listFilterWithDepartments

  // status 0, 1, 2 || 0 is all, 1 not answered, 2 is answered
  if (isFilterAnswered === 0) {
    return listFilter
  } else if (isFilterAnswered === 1) {
    return listFilter.filter(
      (item) => item.statusEnum !== FEEDBACK_STATUS.COMPLETED
    )
  } else {
  }
  return listFilter.filter(
    (item) => item.statusEnum === FEEDBACK_STATUS.COMPLETED
  )
}

export {
  calculateAge,
  getListIDCustomer,
  getTotalAnswerConsultantManagement,
  getListConsultantManagementWithFilter
}
