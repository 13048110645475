import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import { ConfigProvider, Spin, notification } from 'antd'
import dayjs from 'dayjs'
import { RecoilRoot } from 'recoil'

import './App.css'
import { ProtectedRoute } from './components/ProtectedRoute'
import './configs/recoil'
import AppApolloProvider from './context/ApolloProvider'
import AuthProvider from './context/AuthProvider'
import PublicLayout from './layouts/PublicLayout'
import ConsultantManagement from './pages/ConsultantManagement'
import CustomerManagement from './pages/CustomerManagement'
import CustomerRegister from './pages/CustomerRegister'
import InterviewResultCreate from './pages/InterviewResultCreate'
import JudgementSetting from './pages/JudgementSetting'
import RuleLogical from './pages/RuleLogical'
import SurveyCreation from './pages/SurveyCreation'
import SurveyEdit from './pages/SurveyEdit'
import SurveyList from './pages/SurveyList'
import en from 'antd/locale/en_US'
import ja from 'antd/locale/ja_JP'
import vi from 'antd/locale/vi_VN'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import 'dayjs/locale/vi'
import { useRolePermissions } from 'hooks/role-permission/usePermissions'
import AuthenticationLayout from 'layouts/AuthenticationLayout'
import HomeLayout from 'layouts/HomeLayout/HomeLayout'
import AdminList from 'pages/AdminList'
import BasicSetting from 'pages/BasicSetting'
import CustomerDetail from 'pages/CustomerDetail'
import ForgotPassWord from 'pages/ForgotPassWord'
import InspectionItemSetting from 'pages/InspectionItemSetting'
import Login from 'pages/Login'
import MyPage from 'pages/MyPage'
import PasswordResetSuccess from 'pages/PasswordResetSuccess'
import PasswordVerifyCode from 'pages/PasswordVerifyCode'
import ResetPassword from 'pages/ResetPassword'
import SurveyDetail from 'pages/SurveyDetail'
import { loadFonts } from 'utilities/registerFont'

const language: any = {
  vi: vi,
  ja: ja,
  en: en
}

function App() {
  const { i18n } = useTranslation()

  notification.config({
    closeIcon: false,
    duration: 3,
    maxCount: 5
  })

  useEffect(() => {
    dayjs.locale(i18n.language ?? 'ja')
  }, [i18n.language])

  useEffect(() => {
    // call load font when start app
    const loadFontLocal = async () => {
      try {
        await loadFonts()
      } catch (e) {
        console.error("load font error", e)
      }
    }
    loadFontLocal()
  }, [])

  return (
    <AppApolloProvider>
      <ConfigProvider
        locale={language[i18n.language ?? 'ja']}
        theme={{
          token: {
            colorPrimary: '#137695',
            colorTextPlaceholder: '#D9E0E5',
            colorTextBase: '#545454',
            borderRadius: 2,
            controlHeight: 30,
            colorBgMask: '#00000057'
          },
          components: {
            Button: {
              colorPrimary: '#137695'
            },
            Input: {
              colorPrimary: '#137695'
            },
            Typography: {
              colorPrimary: '#137695'
            },
            Radio: {
              buttonColor: '#BDCBD5',
              buttonSolidCheckedHoverBg: '#DF2475',
              buttonSolidCheckedBg: '#DF2475'
            },
            Tree: {
              nodeHoverBg: 'transparent'
            },
            Layout: {
              siderBg:
                'transparent linear-gradient(180deg, #127594 0%, #93D1CA 100%) 0% 0% no-repeat padding-box;'
            }
          }
        }}
      >
        <RecoilRoot>
          <React.Suspense
            fallback={
              <div className="flex justify-center items-center h-[100vh] w-full">
                <Spin spinning={true} />
              </div>
            }
          >
            <AuthProvider>
              <Routes>
                <Route path="/" element={<HomeLayout />}>
                  <Route index element={<DefaultDisplay />} />
                  <Route path="setting" element={<></>} />
                  <Route
                    path="consultant-management"
                    element={
                      <ProtectedRoute
                        permission="medical_survey_feedback"
                        children={<ConsultantManagement />}
                      />
                    }
                  />
                  <Route
                    path="survey/:refid/detail"
                    element={<SurveyDetail />}
                  />
                  <Route path="survey/:refid/edit" element={<SurveyEdit />} />
                  <Route path="survey-creation" element={<SurveyCreation />} />
                  <Route path="basic-setting" element={<BasicSetting />} />
                  <Route
                    path="inspection-item-settings"
                    element={
                      <ProtectedRoute
                        permission="setting_inspection"
                        children={<InspectionItemSetting />}
                      />
                    }
                  />
                  <Route
                    path="judgement-setting"
                    element={<JudgementSetting />}
                  />
                  <Route path="inspection-setting" element={<></>} />
                  <Route
                    path="admin-list"
                    element={
                      <ProtectedRoute
                        permission="setting_role"
                        children={<AdminList />}
                      />
                    }
                  />
                  <Route
                    path="interview-result-create"
                    element={<InterviewResultCreate />}
                  />
                  <Route
                    path="customer-management"
                    element={
                      <ProtectedRoute
                        permission="customer_management"
                        children={<CustomerManagement />}
                      />
                    }
                  />
                  <Route path="customer-detail" element={<CustomerDetail />} />
                  <Route
                    path="customer-register"
                    element={<CustomerRegister />}
                  />
                  <Route path="my-page" element={<MyPage />} />
                  <Route path="survey-list" element={<SurveyList />} />
                </Route>
                <Route element={<AuthenticationLayout />}>
                  <Route path="/login" element={<Login />}></Route>
                  <Route
                    path="/forgot-password"
                    element={<ForgotPassWord />}
                  ></Route>
                  <Route
                    path="/password-verify-code"
                    element={<PasswordVerifyCode />}
                  ></Route>
                  <Route
                    path="/reset-password"
                    element={<ResetPassword />}
                  ></Route>
                  <Route
                    path="/password-reset-success"
                    element={<PasswordResetSuccess />}
                  ></Route>
                </Route>
                <Route element={<PublicLayout />}>
                  <Route path="/rule-logical" element={<RuleLogical />} />
                </Route>
              </Routes>
            </AuthProvider>
          </React.Suspense>
        </RecoilRoot>
      </ConfigProvider>
    </AppApolloProvider>
  )
}

const DefaultDisplay = () => {
  const { hasPermissions } = useRolePermissions()

  if (hasPermissions('customer_management')) return <CustomerManagement />
  if (hasPermissions('medical_survey_feedback')) return <ConsultantManagement />

  return <SurveyList />
}

export default App
