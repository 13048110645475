import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox } from 'antd'

type Props = {
  className?: string
  name: string
}

export const CheckboxForm = ({ className = '', name }: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { value, ...restField } = field

        return <Checkbox checked={value} className={className} {...restField} />
      }}
    />
  )
}
