import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'

import { DatePicker, GetProps } from 'antd'
import dayjs from 'dayjs'

import { SizeType } from 'antd/es/config-provider/SizeContext'
import { ReactComponent as DatePickerIcon } from 'assets/svgs/date-picker.svg'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

type Props = {
  className?: string
  name: string
  placeholder?: string
  disabled?: boolean
  size?: SizeType
  picker?: any
  format?: string
  minDate?: string
  maxDate?: string
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
}

export const CustomDatePicker = ({
  className = '',
  name,
  placeholder,
  disabled = false,
  size = 'small',
  format = 'YYYY/MM/DD',
  rules
}: Props) => {
  const { control } = useFormContext()

  type RangePickerProps = GetProps<typeof DatePicker.RangePicker>
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current >= dayjs().startOf('day')
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...restField } = field
        return (
          <DatePicker
            disabledDate={disabledDate}
            className={className}
            size={size}
            suffixIcon={
              <DatePickerIcon className="mr-[-7px] h-[30px] w-[30px]" />
            }
            format={format}
            disabled={disabled}
            status={error ? 'error' : ''}
            placeholder={placeholder}
            onChange={(date, dateString) => onChange(dateString)}
            value={value ? dayjs(value, format) : null}
            {...restField}
          />
        )
      }}
    />
  )
}
