import { Navigate, Outlet } from 'react-router-dom'
import styles from './AuthenticationLayout.module.scss'
import { useAuth } from '../../context/AuthProvider'

interface Props {
  redirectPath?: string;
  children?: React.ReactNode;
}

export default function AuthenticationLayout({ redirectPath = '/', children }: Props) {
  const auth = useAuth()

  if (auth?.token) {
    return <Navigate to={redirectPath} replace />
  }

  return (
    <div className={styles.container}>
      {children}
      <Outlet />
    </div>
  )
}
