import { useEffect, useState } from 'react'

import { FILTER_SURVEY, VariableProps } from '../graphql/Survey/filter'
import { Endpoint } from '../utilities/apolloClient'
import { forceTypeBoolean } from '../utilities/helpers'
import { useLazyQuery } from '@apollo/client'

const variablesInitFilter: VariableProps = {
  filter: '(like,STRING,displayNameMap.ja,)',
  sortBy: '',
  paginationInput: { page: 0, size: 100 }
}
const useSurveyForFeedback = () => {
  const [surveyForFeedbackList, setSurveyListForFeedbackList] = useState<any[]>(
    []
  )
  const [variablesFilter, setVariablesFilter] =
    useState<VariableProps>(variablesInitFilter)

  const [loadSurveyList, { loading, data }] = useLazyQuery(FILTER_SURVEY, {
    variables: variablesFilter,
    context: { version: Endpoint.SURVEY_CORE },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first' // Used for subsequent executions
  })

  useEffect(() => {
    if (!loading && data && data.hasOwnProperty('filterSurvey')) {
      const {
        filterSurvey: { payload }
      } = data

      // For Feedback
      const surveyForFeedback: any[] = []
      for (let survey of payload) {
        const { additionalInfo } = survey
        const surveyData = {
          ...survey,
          displayCategories:
            forceTypeBoolean(additionalInfo?.displayCategories) ?? false,
          displayEnglish:
            forceTypeBoolean(additionalInfo?.displayEnglish) ?? false
        }
        surveyForFeedback.push({
          data: surveyData
        })
      }
      setSurveyListForFeedbackList(surveyForFeedback)
    }
  }, [loading])

  return {
    variablesFilter,
    setVariablesFilter,
    loadSurveyList,
    loading,
    surveyForFeedbackList
  }
}

export default useSurveyForFeedback
