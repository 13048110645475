import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'

import { TimePicker } from 'antd'
import dayjs from 'dayjs'

import { SizeType } from 'antd/es/config-provider/SizeContext'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { IntRange } from 'rc-picker/lib/interface'

dayjs.extend(customParseFormat)

type Props = {
  className?: string
  name: string
  placeholder?: string
  defaultOpenValue?: string
  defaultValue?: string
  disabled?: boolean
  size?: SizeType
  picker?: any
  format?: string
  minDate?: string
  maxDate?: string
  minuteStep?: IntRange<1, 59>
  showSecond?: boolean
  showNow?: boolean
  needConfirm?: boolean
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
}

export const TimePickerForm = ({
  className = '',
  name,
  placeholder,
  disabled = false,
  size = 'small',
  format = 'HH:mm:ss',
  defaultValue = '09:00:00',
  defaultOpenValue = '09:00:00',
  minuteStep,
  showSecond,
  showNow,
  needConfirm,
  minDate,
  maxDate,
  rules
}: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...restField } = field
        return (
          <TimePicker
            className={className}
            size={size}
            format={format}
            disabled={disabled}
            status={error ? 'error' : ''}
            placeholder={placeholder}
            defaultOpenValue={dayjs(defaultOpenValue, format)}
            minDate={dayjs(minDate, format)}
            maxDate={dayjs(maxDate, format)}
            onChange={(date, dateString) => onChange(dateString)}
            value={value ? dayjs(value, format) : null}
            minuteStep={minuteStep}
            showSecond={showSecond}
            showNow={showNow}
            needConfirm={needConfirm}
            {...restField}
          />
        )
      }}
    />
  )
}
