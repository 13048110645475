import React from 'react'

import { Flex, Input } from 'antd'

// import AddQuestionIcon from './AddQuestionIcon'

interface Props {
  fieldName: string
  className?: string
}

export const TextAnswerCard = ({ fieldName, className = '' }: Props) => {
  return (
    <Flex vertical className="gap-[2px] free-questionnaire-card relative">
      <Input.TextArea
        className={className}
        rows={2}
        style={{ resize: 'none' }}
      />
      {/*<AddQuestionIcon onClick={() => {*/}
      {/*  console.log('AddQuestion')*/}
      {/*}} />*/}
    </Flex>
  )
}
