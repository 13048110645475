import { DocumentNode, gql } from '@apollo/client'

export const params = {
  "surveyRefIds": [
    "2916465546170387931699259912932"
  ]
}

export const DELETE_SURVEY: DocumentNode = gql`
mutation deleteSurvey($surveyRefIds: [String]!) {
  deleteSurvey(surveyRefIds: $surveyRefIds) {
    statusCode
    message
    timestamp
    payload {
      isDeleted
      refId
      reason
    }
  }
}
`
