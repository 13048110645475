import { useEffect, useState } from 'react'

import { Col, Flex, Row, Typography } from 'antd'

import { DatePickerCustom } from 'components/elements/DatePickerForm'
import { InputCustom } from 'components/elements/InputForm'
import { VALUE_WITH_NO_DATA } from 'configs/constant'

export default function PatientInfoForm({
  language,
  medicalCheckupMasterInfo
}: {
  language: any
  medicalCheckupMasterInfo: any
}) {
  const [formValues, setFormValues] = useState<any>({})

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value
    }))
  }

  const renderInputType = (item: any) => {
    if (item.readingType === 'DATE') {
      return (
        <DatePickerCustom
          name={item.refId}
          className="w-full"
          value={formValues ? formValues[item.refId] : ''}
          onChange={handleInputChange}
          disabled={item?.disable}
        />
      )
    }

    return (
      <InputCustom
        name={item.refId}
        value={formValues ? formValues[item.refId] : ''}
        onChange={handleInputChange}
        disabled={item?.disable}
      />
    )
  }

  return (
    <Flex className="gap-[5%] pr-[9.4%] flex-wrap">
      {medicalCheckupMasterInfo.map((item: any) => (
        <div className="w-[47%]" key={item.refId}>
          <Row className="mt-1.5 items-center text-left">
            <Col flex="105px" className="font-bold">
              <Typography>
                {item?.lang?.[language] ?? VALUE_WITH_NO_DATA}
              </Typography>
            </Col>
            <Col flex="auto">{renderInputType(item)}</Col>
          </Row>
        </div>
      ))}
    </Flex>
  )
}
