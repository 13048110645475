import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox, Typography } from 'antd'

import { StyledDivError as StyledDiv } from '../../styled'

type Props = {
  options: any[]
  fieldName: string
  required: boolean
  question?: any
}

export const CheckboxGroupForm = ({ required, fieldName, options }: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: required,
        validate: (value: string[], _: any) => {
          return required
            ? value.filter((v: string) => v !== '').length > 0
            : true
        }
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...restField } = field
        return (
          <StyledDiv status={error ? 'error' : ''}>
            <Checkbox.Group
              className="w-full flex-wrap gap-2.5 flex border-error"
              onChange={onChange}
              value={value}
              {...restField}
            >
              {options.map((option: any, idx: number) => (
                <Checkbox
                  key={idx}
                  className="w-[32%] mr-0"
                  value={option.refId}
                >
                  <Typography className="font-bold tracking-[0.7px]">
                    {option?.displayNameMap?.ja}
                  </Typography>
                </Checkbox>
              ))}
            </Checkbox.Group>
          </StyledDiv>
        )
      }}
    />
  )
}
