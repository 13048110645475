import React, { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Input, Row } from 'antd'
import { HttpStatusCode } from 'axios'

import { getAddressByZipCode } from '../../../../services/AddressService'
import { InputForm } from '../../../elements/InputForm'
import { InputLabelNormal } from '../../../elements/InputLabel'
import { SelectCustom } from '../../../elements/Select'
import { openNotification } from '../../../widgets/Notification'
import { COUNTRY } from 'models/setting'
import { createProvinceList } from 'utilities/helpers'

const LocationJapan = () => {
  const { setValue, getValues, control } = useFormContext()
  const { t } = useTranslation()
  const buttonSearchRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null)
  const provinceList: { value: string; label: string }[] = createProvinceList(t)
  const handleZipCode = async (zipCode: string) => {
    await getAddressByZipCode(zipCode)
      .then((response) => response.json())
      .then((response: any) => {
        const { data, code } = response
        if (code !== HttpStatusCode.Ok) {
          openNotification({
            type: 'error',
            title: t('customerDetail.notifications.zipCodeNotFound'),
            message: ''
          })
          return
        }
        setValue('province', data?.pref ?? '')
        setValue('district', data?.address ?? '')
      })
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('text')
    if (pasteData.length === 7 && /^\d{7}$/.test(pasteData)) {
      e.preventDefault()

      setValue('zipCode', pasteData)
      buttonSearchRef.current?.focus()
    }
  }

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value
    e.currentTarget.value = inputValue.replace(/[^0-9]/g, '')
  }
  return (
    <>
      <Row gutter={6} className="items-center">
        <Col flex="230px" className={`text-[#545454E0] mr-2`}>
          {t('basicSetting.formLabel.zip_code')}
        </Col>
        <Col flex="auto">
          <Row className="flex gap-[6px] align-middle">
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  inputMode="numeric"
                  maxLength={7}
                  onInput={handleInput}
                  onPaste={handlePaste}
                  className="h-[30px] w-[15rem] px-3 tracking-[1.4px]"
                />
              )}
            />

            <Button
              ref={buttonSearchRef}
              type="primary"
              onClick={() => {
                handleZipCode(`${getValues('zipCode')}`).then()
              }}
              className="h-[30px] min-w-[80px] rounded-[3px] bg-[#137695] text-sm font-bold text-white shadow-none"
            >
              {t('customerRegister.button.addressSearch')}
            </Button>
          </Row>
        </Col>
      </Row>

      <Row gutter={6} className="items-center">
        <Col flex="230px" className={`text-[#545454E0] mr-2`}>
          {t('basicSetting.formLabel.prefectures')}
        </Col>
        <Col flex="auto">
          <SelectCustom
            name="province"
            className="w-[15rem]"
            options={provinceList}
          />
        </Col>
      </Row>

      <Row gutter={6} className="items-center">
        <Col flex="230px" className="mr-2">
          <InputLabelNormal
            bg={false}
            label={`${t('basicSetting.formLabel.prefixAddress')}1(${t('basicSetting.formLabel.district')})`}
            required
          />
        </Col>
        <Col flex="auto">
          <InputForm
            rules={{
              required: true,
              minLength: 1,
              maxLength: 50
            }}
            name="district"
            className="max-w-[25rem]"
          />
        </Col>
      </Row>

      <Row gutter={6} className="items-center">
        <Col flex="230px" className="mr-2">
          <InputLabelNormal
            bg={false}
            label={`${t('basicSetting.formLabel.prefixAddress')}2(${t('basicSetting.formLabel.address')})`}
            required
          />
        </Col>
        <Col flex="auto">
          <InputForm
            rules={{
              required: true,
              minLength: 1,
              maxLength: 50
            }}
            name="address"
            className="max-w-[25rem]"
          />
        </Col>
      </Row>
      <Row gutter={6} className="items-center">
        <Col flex="230px" className="mr-2">
          <InputLabelNormal
            bg={false}
            label={`${t('basicSetting.formLabel.prefixAddress')}3(${t('basicSetting.formLabel.apartment')})`}
          />
        </Col>
        <Col flex="auto">
          <InputForm
            rules={{
              minLength: 1,
              maxLength: 150
            }}
            name="apartment"
            className="max-w-[25rem]"
          />
        </Col>
      </Row>
    </>
  )
}

const LocationOtherJapan = () => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <>
      <Row gutter={6} className="items-center">
        <Col flex="230px" className="mr-2">
          <InputLabelNormal
            bg={false}
            label={`${t('basicSetting.formLabel.prefixAddress')}1(${t('basicSetting.formLabel.apartment')})`}
          />
        </Col>
        <Col flex="auto">
          <InputForm
            rules={{
              minLength: 1,
              maxLength: 150
            }}
            name="apartment"
            className="max-w-[25rem]"
          />
        </Col>
      </Row>
      <Row gutter={6} className="items-center">
        <Col flex="230px" className="mr-2">
          <InputLabelNormal
            bg={false}
            label={`${t('basicSetting.formLabel.prefixAddress')}2(${t('basicSetting.formLabel.address')})`}
            required
          />
        </Col>
        <Col flex="auto">
          <InputForm
            rules={{
              minLength: 1,
              maxLength: 50,
              required: true
            }}
            name="address"
            className="max-w-[25rem]"
          />
        </Col>
      </Row>

      <Row gutter={6} className="items-center">
        <Col flex="230px" className="mr-2">
          <InputLabelNormal
            bg={false}
            label={`${t('basicSetting.formLabel.prefixAddress')}3(${t('basicSetting.formLabel.district')})`}
            required
          />
        </Col>
        <Col flex="auto">
          <InputForm
            rules={{
              required: true,
              minLength: 1,
              maxLength: 50
            }}
            name="district"
            className="max-w-[25rem]"
          />
        </Col>
      </Row>

      <Row gutter={6} className="items-center">
        <Col flex="230px" className={`text-[#545454E0] mr-2`}>
          {t('basicSetting.formLabel.stateName')}
        </Col>
        <Col flex="auto">
          <InputForm
            rules={{
              minLength: 1,
              maxLength: 50
            }}
            name="stateName"
            className="max-w-[25rem]"
          />
        </Col>
      </Row>

      <Row gutter={6} className="items-center">
        <Col flex="230px" className={`text-[#545454E0] mr-2`}>
          {t('basicSetting.formLabel.zip_code')}
        </Col>
        <Col flex="auto">
          <Row className="flex gap-[6px] align-middle">
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  inputMode="numeric"
                  className="h-[30px] w-[25rem] px-3 tracking-[1.4px]"
                />
              )}
            />
          </Row>
        </Col>
      </Row>
    </>
  )
}

const LocationSetting = () => {
  const { t } = useTranslation()

  const { watch, getValues } = useFormContext()

  const countryList: { value: string; label: string }[] = [
    { value: COUNTRY.JAPAN, label: t('basicSetting.country.ja') },
    { value: COUNTRY.VIETNAM, label: t('basicSetting.country.vi') }
  ]

  return (
    <Col flex="auto" className="gap-[6px] flex flex-col">
      <Row gutter={6} className="items-center">
        <Col flex="230px" className="mr-2">
          <InputLabelNormal
            bg={false}
            label={t('basicSetting.formLabel.country')}
            required
          />
        </Col>

        <Col flex="auto">
          <SelectCustom
            rules={{
              required: true
            }}
            name="country"
            className="w-[15rem]"
            defaultValue={getValues('country')}
            options={countryList}
          />
        </Col>
      </Row>

      <>
        {watch('country') === COUNTRY.JAPAN ? (
          <LocationJapan />
        ) : (
          <LocationOtherJapan />
        )}
      </>
    </Col>
  )
}

export default LocationSetting
