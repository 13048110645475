import { DocumentNode, gql } from '@apollo/client'

interface VariableProps {
  page: number
  size: number
  filter: string
}

export const FILTER_ALL: VariableProps = {
  page: 0,
  size: -1,
  filter: ''
}

export const FILTER_ITEM_MASTER: DocumentNode = gql`
  query filterTestItemMaster($filter: String!, $page: Int!, $size: Int!) {
    filterTestItemMaster(page: $page, size: $size, filter: $filter) {
      status
      message
      timestamp
      totalElements
      payload {
        refId
        shortName
        unitOfMeasure
        readingType
        referenceValues
        additionalInfo
        code {
          coding {
            system
            code
          }
        }
        visibility {
          tenantCodes
          subTenantCodes
        }
        displayName
        createdDate
        createdBy
      }
    }
  }
`
