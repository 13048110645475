// define constant here
export const AND_CONJUNCTION: string = ';'
export const STORAGE_TOKEN_KEY = 'TOKEN'
export const STORAGE_TENANT_KEY = '@tenant'
export const STORAGE_TOKEN_CLIENT_KEY = 'TOKEN_CLIENT'
export const STORAGE_REFRESH_TOKEN_KEY = 'REFRESH_TOKEN'
export const PAGE_SIZE = 20
export const STATUS_CODE_SUCCESS = 'S0000'
export const STATUS_CODE_ALREADY_EXISTS = 'E40091'
export const PHONE_REGEX = /[^0-9]/g

export const PATIENT_ID_CODE = '1.00004'

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum FEEDBACK_STATUS {
  ASSIGNED = 'ASSIGNED',
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED'
}

export enum FEEDBACK_LIST_TOTAL {
  ALL = 'ALL',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  UNANSWERED = 'UNANSWERED'
}

export const VALUE_WITH_NO_DATA = '-'

export enum CONSULTANT_SORT_TYPE {
  USER_ID = 'refId',
  USER_NAME = 'name',
  USER_DATE_OF_BIRTH = 'birthday',
  USER_AGE = 'age',
  USER_GENDER = 'gender',
  SURVEY_FEEDBACK_TITLE = 'title',
  SURVEY_FEEDBACK_EXAM_DATE = 'examDate',
  SURVEY_FEEDBACK_STATUS = 'feedbackStatus',
  SURVEY_FEEDBACK_CREATE_AT = 'createdDateTime'
}

export enum MEDICAL_CHECKUP_STATUS {
  NOT_COMPATIBLE = 0,
  RESULTS_IN_PROGRESS = 1,
  AWAITING_JUDGEMENT = 2,
  JUDGMENT_COMPLETE = 3,
  SUBMITTED = 4
}

export const MEDICAL_CHECKUP_STATUS_TEXT: { [key: number]: string } = {
  0: 'customerManagement.result.incompatible',
  1: 'customerManagement.result.creating',
  2: 'customerManagement.result.waiting',
  3: 'customerManagement.result.ended',
  4: 'customerManagement.result.sent'
}

export const weekdayOptions = [
  { label: 'day.short_monday', value: 'Monday', order: 0 },
  { label: 'day.short_tuesday', value: 'Tuesday', order: 1 },
  { label: 'day.short_wednesday', value: 'Wednesday', order: 2 },
  { label: 'day.short_thursday', value: 'Thursday', order: 3 },
  { label: 'day.short_friday', value: 'Friday', order: 4 },
  { label: 'day.short_saturday', value: 'Saturday', order: 5 },
  { label: 'day.short_sunday', value: 'Sunday', order: 6 },
  { label: 'day.short_holiday', value: 'Holiday', order: 7 }
]

export const WeekDayLabels = {
  Monday: 'day.monday',
  Tuesday: 'day.tuesday',
  Wednesday: 'day.wednesday',
  Thursday: 'day.thursday',
  Friday: 'day.friday',
  Saturday: 'day.saturday',
  Sunday: 'day.sunday',
  Holiday: 'day.holiday'
}
