import { DocumentNode, gql } from '@apollo/client'

interface VariableProps {
  page: number
  size: number
  filter: string
}

export const FILTER_ALL: VariableProps = {
  page: 0,
  size: -1,
  filter: ''
}

export const FILTER_ITEM_CATEGORY: DocumentNode = gql`
  query filterTestItemCategory(
    $filter: String!
    $page: Int!
    $size: Int!
    $sortBy: String
  ) {
    filterTestItemCategory(
      page: $page
      size: $size
      filter: $filter
      sortBy: $sortBy
    ) {
      status
      message
      timestamp
      payload {
        refId
        displayName
        shortName
        displayName
        associatedTestItemMasterRefIds
        createdDate
        createdBy
      }
    }
  }
`
