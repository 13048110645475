import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Radio } from 'antd'

import { forceTypeBoolean } from '../../../utilities/helpers'

type Props = {
  fieldName: string
}

export const RadioGroupForm = ({ fieldName }: Props) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        const { onChange, value, ...restField } = field
        return (
          <Radio.Group
            value={value}
            onChange={({ target: { value } }) =>
              onChange(forceTypeBoolean(value))
            }
            buttonStyle="solid"
            {...restField}
          >
            <Radio.Button
              value={true}
              style={{ fontSize: 14, fontWeight: 'bold' }}
            >
              {t('lable.required')}
            </Radio.Button>
            <Radio.Button
              value={false}
              style={{ fontSize: 14, fontWeight: 'bold' }}
            >
              {t('lable.any')}
            </Radio.Button>
          </Radio.Group>
        )
      }}
    />
  )
}
