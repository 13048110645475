import React from 'react'

import { Spin } from 'antd'

type Props = {
  open?: boolean
  loading: boolean
}

const SpinLoading = ({ loading }: Props) => {
  return (
    <>
      <Spin spinning={loading}/>
    </>
  )
}

export default SpinLoading
