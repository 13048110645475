import axios from 'axios'
import qs from 'qs'

import {
  API_BACKEND_URL,
  API_BASE_URL,
  API_CLIENT_CODE,
  KEYCLOAK_URL
} from '../configs/api'
import { getAccessToken, getAccessTokenClient, getTenantLocal } from './helpers'
import * as Sentry from '@sentry/react'
import { openNotification } from 'components/widgets/Notification'

export const axiosInstance: any = axios.create({
  baseURL: `${API_BASE_URL}/`,
  timeout: 60000
})

export const axiosClient: any = axios.create({
  baseURL: `${KEYCLOAK_URL}/`,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 60000
})

export const axiosBackend: any = axios.create({
  baseURL: `${API_BACKEND_URL}/`,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 60000
})

/**
 * get request headers
 * @returns
 */

axiosInstance.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    const token = getAccessToken()
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    config.headers['Content-Type'] = 'application/json'

    const { tenant, subtenant } = getTenantLocal()
    config.headers['TENANT-CODE'] = tenant
    config.headers['SUB-TENANT-CODE'] = subtenant
    config.headers['CLIENT-CODE'] = API_CLIENT_CODE

    return config
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error)
  }
)

/**
 * get request headers
 * @returns
 */

axiosBackend.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    const token = getAccessToken()
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }

    config.headers['Content-Type'] = 'application/json'

    const { tenant, subtenant } = getTenantLocal()
    config.headers['TENANT-CODE'] = tenant
    config.headers['SUB-TENANT-CODE'] = subtenant
    config.headers['CLIENT-CODE'] = API_CLIENT_CODE

    return config
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error)
  }
)
/**
 * get request headers
 * @returns
 */

axiosClient.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    const token = getAccessTokenClient()
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }

    return config
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// define error handle
axiosInstance.interceptors.response.use(
  (response: any) => {
    const { data } = response
    // handle error code
    switch (data.error_code) {
      default:
        break
    }
    return response.data
  },
  async (error: any) => {
    Sentry.captureException(error)

    const { response } = error

    showError(response)

    return response && response.data ? response : Promise.reject(error)
  }
)

// define error handle
axiosClient.interceptors.response.use(
  (response: any) => {
    const { data } = response
    // handle error code
    switch (data.error_code) {
      default:
        break
    }
    return response.data
  },
  async (error: any) => {
    Sentry.captureException(error)

    const { response } = error

    showError(response)

    return response && response.data ? response : Promise.reject(error)
  }
)

// define error handle
axiosBackend.interceptors.response.use(
  (response: any) => {
    const { data } = response
    // handle error code
    switch (data.error_code) {
      default:
        break
    }
    return response.data
  },
  async (error: any) => {
    const { response } = error
    Sentry.captureException(error)

    showError(response)

    return response && response.data ? response : Promise.reject(error)
  }
)

const showError = (response: any) => {
  let showMsg = true

  if (window.location.pathname.startsWith('/login')) {
    return
  }

  if (response?.status === 401) {
    localStorage.clear()
    if (!window.location.pathname.startsWith('/login')) {
      window.location.href = '/login'
    } else {
      showMsg = false
    }
  }

  showMsg &&
    openNotification({
      type: 'error',
      title: 'commonError',
      message: ''
    })
}

export default {
  /**
   * post request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   * @param headers
   */
  post: (endpoint: string, data: any, headers?: any) => {
    return axiosInstance.post(endpoint, data, headers)
  },

  /**
   * post request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   * @param headers
   */
  postClient: (endpoint: string, data: any, headers?: any) => {
    return axiosClient.post(endpoint, data, headers)
  },

  /**
   * post request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   * @param headers
   */
  postBackend: (endpoint: string, data: any, headers?: any) => {
    return axiosBackend.post(endpoint, data, headers)
  },

  /**
   * put
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  put: (endpoint: string, data: any) => {
    return axiosInstance.put(endpoint, qs.stringify(data), {})
  },

  /**
   * putClient
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  putClient: (endpoint: string, data: any) => {
    return axiosClient.put(endpoint, qs.stringify(data))
  },

  /**
   * get request
   * @param {string} endpoint
   * @param {*} params All parameter need to pass for server
   */
  get: (endpoint: string, params: any = {}) => {
    return axiosInstance.get(endpoint, {
      params,
      paramsSerializer: {
        encode: qs.parse,
        serialize: qs.stringify
      }
    })
  },

  /**
   * get request
   * @param {string} endpoint
   * @param {*} params All parameter need to pass for server
   */
  getClient: (endpoint: string, params: any = {}) => {
    return axiosClient.get(endpoint, {
      params,
      paramsSerializer: {
        encode: qs.parse,
        serialize: qs.stringify
      }
    })
  },

  /**
   * delete request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  delete: (endpoint: string, data: any) => {
    return axiosInstance.delete(endpoint, {
      data
    })
  },

  /**
   * delete request
   * @param {string} endpoint
   */
  deleteClient: (endpoint: string) => {
    return axiosClient.delete(endpoint)
  },

  /**
   * patch request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  patch: (endpoint: string, data: any) => {
    return axiosInstance.patch(endpoint, data)
  },

  /**
   * upload file
   * @param {string} endpoint
   * @param {*} agrs All parameter need to pass for server
   * @param {string} method
   * @param {boolean} formDataParsed
   */
  uploadFile: (
    endpoint: string,
    agrs: any = {},
    method: string = 'post',
    formDataParsed: boolean = false
  ) => {
    let formData = agrs
    if (!formDataParsed) {
      formData = new FormData()
      Object.keys(agrs).forEach((item) => {
        formData.append(item, agrs[item])
      })
    }
    return axiosInstance[method](endpoint, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
}
