import { useEffect, useRef, useState } from 'react'

import { DELETE_SURVEY_FEEDBACK } from '../graphql/SurveyFeedback/deletes'
import {
  INTERVIEW_FILTER_SURVEY_FEEDBACK,
  VariableProps
} from '../graphql/SurveyFeedback/filter'
import { IInterviewList } from '../models/surveyListFeedback'
import { Endpoint } from '../utilities/apolloClient'
import { useLazyQuery, useMutation } from '@apollo/client'
import { PAGE_SIZE, STATUS_CODE_SUCCESS } from 'configs/constant'
import { format } from 'date-fns'

const variablesInitFilter: VariableProps = {
  filter: '',
  sortBy: '(desc,createdDateTime)',
  paginationInput: { page: 0, size: PAGE_SIZE }
}
const useInterviewList = (userRefId: string) => {
  const previousUserRefId = useRef<string>('')
  const variablesFilterRefId = {
    ...variablesInitFilter,
    filter: `(like,STRING,userRefId,${userRefId});(ne,STRING,feedbackStatus,DELETED);`
  }
  const [interviewList, setInterviewList] = useState<IInterviewList[]>([])
  const [variablesFilter, setVariablesFilter] =
    useState<VariableProps>(variablesFilterRefId)
  const [totalInterview, setTotalInterview] = useState<number>(0)

  const [loadInterviewList, { called, loading, data }] = useLazyQuery(
    INTERVIEW_FILTER_SURVEY_FEEDBACK,
    {
      variables: variablesFilter,
      context: { version: Endpoint.SURVEY_CORE },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache'
    }
  )

  // reload when change customer ID
  const onReloadId = (userIdRef: string) => {
    if (
      previousUserRefId.current !== '' &&
      previousUserRefId.current !== userIdRef
    ) {
      setVariablesFilter((prev) => {
        return {
          ...prev,
          filter: `(like,STRING,userRefId,${userIdRef});(ne,STRING,feedbackStatus,DELETED);`,
          page: 0
        }
      })
    }
    previousUserRefId.current = userIdRef
  }
  useEffect(() => {
    !called && loadInterviewList()

    if (!loading && data && data.hasOwnProperty('filterSurveyFeedback')) {
      const {
        filterSurveyFeedback: { payload, totalElements, statusCode }
      } = data
      const surveyDataFeedback: IInterviewList[] = []
      if (statusCode === STATUS_CODE_SUCCESS && payload)
        for (let surveyFeedback of payload) {
          surveyDataFeedback.push({
            key: surveyFeedback.refId,
            id: surveyFeedback.refId,
            createdDateTime: surveyFeedback.createdDateTime
              ? format(
                  new Date(`${surveyFeedback.createdDateTime}`),
                  'yyyy-MM-dd'
                )
              : '-',
            resultId: '',
            answerMethod: '',
            feedbackStatus: surveyFeedback.feedbackStatus
          })
        }
      setTotalInterview(totalElements)
      setInterviewList(surveyDataFeedback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return {
    totalInterview,
    interviewList,
    variablesFilter,
    setVariablesFilter,
    setInterviewList,
    loadInterviewList,
    loading,
    onReloadId
  }
}

const useDeleteInterView = () => {
  const [deleteSurveyFeedbackMutation, { data, loading, error }] = useMutation(
    DELETE_SURVEY_FEEDBACK
  )
  const deleteInterview = async (id: string) => {
    try {
      await deleteSurveyFeedbackMutation({
        variables: { surveyFeedbackRefIds: [id] },
        context: { version: Endpoint.SURVEY_CORE },
        fetchPolicy: 'network-only'
      })
    } catch (err) {
      console.error(err)
    }
  }
  return {
    deleteInterview,
    data,
    loading,
    error
  }
}

export { useInterviewList, useDeleteInterView }
