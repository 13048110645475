import { Font } from '@react-pdf/renderer'
import RobotoBold from 'assets/fonts/Roboto-Bold.ttf'
import Roboto from 'assets/fonts/Roboto-Regular.ttf'
import YuGothicUI from 'assets/fonts/YuGothic-Regular-01.ttf'
import YuGothicUIBold from 'assets/fonts/YuGothicUI-Bold-02.ttf'

// Register font YuGothicUI
Font.register({
  family: 'YuGothicUI',
  fonts: [{ src: YuGothicUI, fontStyle: 'normal', fontWeight: 'normal' }]
})

// Register font YuGothicUIBold
Font.register({
  family: 'YuGothicUIBold',
  fonts: [{ src: YuGothicUIBold, fontStyle: 'normal', fontWeight: 'bold' }]
})

// Register font Roboto
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: Roboto,
      fontStyle: 'normal',
      fontWeight: 'normal'
    }
  ]
})

// Register font RobotoBold
Font.register({
  family: 'RobotoBold',
  fonts: [
    {
      src: RobotoBold,
      fontStyle: 'normal',
      fontWeight: 'bold'
    }
  ]
})

// export func to load fonts
export const loadFonts = async () => {
  // return promise all to load all fonts
  return Promise.all([
    Font.load({ fontFamily: 'YuGothicUI' }),
    Font.load({ fontFamily: 'YuGothicUIBold' }),
    Font.load({ fontFamily: 'Roboto' }),
    Font.load({ fontFamily: 'RobotoBold' })
  ])
}
