import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DatePicker, DatePickerProps, GetProps } from 'antd'
import dayjs from 'dayjs'

import styles from './CustomDateRangePicker.module.scss'
import { ReactComponent as Picker } from 'assets/svgs/date-picker.svg'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const dateFormat = 'YYYY-MM-DD'

type Props = {
  setDates: (data: [string, string] | undefined) => void
  initialStartDate?: string
  initialEndDate?: string
}

const CustomDateRangePicker: FC<Props> = ({
  setDates,
  initialStartDate,
  initialEndDate
}) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [startDate, setStartDate] = React.useState<any>(null)
  const [endDate, setEndDate] = React.useState<any>(null)
  useEffect(() => {
    if (initialStartDate) {
      setStartDate(dayjs(initialStartDate, dateFormat).startOf('day'))
    } else {
      setStartDate(null)
    }
    if (initialEndDate) {
      setEndDate(dayjs(initialEndDate, dateFormat).endOf('day'))
    } else {
      setEndDate(null)
    }

    if (initialStartDate && initialEndDate) {
      setDates([
        dayjs(initialStartDate).format(`${dateFormat}T00:00:00`),
        dayjs(initialEndDate).format(`${dateFormat}T23:59:59`)
      ])
    } else {
      setDates(undefined)
    }
  }, [initialStartDate, initialEndDate, setDates])

  type RangePickerProps = GetProps<typeof DatePicker.RangePicker>
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().startOf('day')
  }

  const handleChange = (props: any) => {
    if (props) {
      const [start, end] = props
      const startDate = dayjs(start, dateFormat).startOf('day')
      const endDate = dayjs(end, dateFormat).endOf('day')
      setStartDate(startDate)
      setEndDate(endDate)
      setDates([
        startDate.format(`${dateFormat}T00:00:00.000+00:00`),
        endDate.format(`${dateFormat}T23:59:59.000+00:00`)
      ])
    } else {
      setStartDate(null)
      setEndDate(null)
      setDates(undefined)
    }
  }

  const customFormat: DatePickerProps['format'] = (value) =>
    `${value.format(dateFormat)}`
  const getClassName = () => {
    console.log(lang)
    return lang === 'vi' ? 'w-[235px]' : lang === 'ja' ? 'w-[215px]' : 'w-[230px]';
  };
  return (
    <DatePicker.RangePicker
      disabledDate={disabledDate}
      placement="topLeft"
      suffixIcon={<Picker className="mr-[-11px] h-[28px] w-[28px]" />}
      placeholder={[
        t('placeholder.selectCheckupPeriod'),
        t('placeholder.choice')
      ]}
      className={`${getClassName()} ${styles.dateRangeInput} ml-2 h-[30px] border-[1px] border-[#137695]`}
      separator={endDate ? t('consultant.separator') : ''}
      popupClassName={styles.dateRangePicker}
      onChange={(dateString) => handleChange(dateString)}
      value={[startDate, endDate]}
      format={customFormat}
    />
  )
}

export default CustomDateRangePicker
