import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ReactComponent as EyeNoHidden } from 'assets/svgs/eye-blue-37.svg'
import { ReactComponent as EyeHidden } from 'assets/svgs/eye-hidden14132.svg'
import { Button } from 'antd'

const DisplayNameEnToggle = ({ name }: { name: string }) => {
  const { control, setValue } = useFormContext()
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value } = field
          return (
            <>
              {
                Boolean(value)
                  ? <Button
                    shape="circle"
                    size="small"
                    type="text"
                    onClick={() => {
                      setValue(name, 0)
                    }}
                    icon={<EyeNoHidden className="w-[22px] text-primary" />}
                  />
                  : <Button
                    shape="circle"
                    size="small"
                    type="text"
                    onClick={() => {
                      setValue(name, 1)
                    }}
                    icon={<EyeHidden className="w-[22px]" />}
                  />
              }
            </>
          )
        }}
      />
    </>
  )
}

export default DisplayNameEnToggle
