import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
}

const DeleteAdminSuccessModal = ({ isModalOpen = false, onCancel }: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //todo
  }

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={handleOk}>
      <Flex vertical className="text-center pt-4 pb-[52px]">
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('deleteAdmin')}
        </Typography>

        <Paragraph className="mt-[177px] mb-0">
          {t('deleteAdminSuccess')}
        </Paragraph>

        <Button
          className="w-[180px] text-primary bg-white py-2 leading-none font-bold mt-[141px] m-auto border-primary"
          onClick={onCancel}
        >
          {t('button.close')}
        </Button>
      </Flex>
    </CustomModal>
  )
}

export default DeleteAdminSuccessModal
