export type Admin = {
  key: string
  fullName?: string
  email?: string
  sex?: string
  role?: string
  affiliation?: string
  currentOwner?: boolean
}

export type Permission = {
  key: string
  name: string
  subName: string
  groupName: string
  permissionKey: string
}

export type Role = {
  id: string
  name: string
  description?: string
  permissions: string[]
  ja?: string
  en?: string
  vi?: string
  order?: number
}

export type PermissionTableColumns = {
  title?: string | React.ReactNode
  dataIndex: string
  key: string
  width: string
}

export type PermissionTableDataSource = {
  key: string | React.ReactNode
  groupName: string
  subName?: string
  name: string
}

export const permissionListSample = [
  {
    key: '4',
    name: 'permission.listMedicalQuestionaireResponse',
    subName: '',
    groupName: 'permission.medicalQuestionaire',
    permissionKey: 'medical_survey_feedback'
  },
  {
    key: '7',
    name: 'permission.listTestItem',
    subName: 'menu.inspectionSetting',
    groupName: 'menu.setting',
    permissionKey: 'setting_inspection'
  },
  {
    key: '8',
    name: 'permission.addAdmin',
    subName: 'permissionSettings',
    groupName: 'menu.setting',
    permissionKey: 'setting_role'
  },
  {
    key: '9',
    name: 'permission.listCustomer',
    subName: '',
    groupName: 'menu.customer',
    permissionKey: 'customer_management'
  }
]

export const adminSample = {
  full_name: 'サブスク 花子',
  email: 'sample@xxxx.xxx.jp',
  role: '医院',
  affiliation:
    'グループＡクリニック, グループＢクリニック, グループＣクリニック'
}
