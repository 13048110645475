import React, { FC, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputNumber,
  Select,
  Typography
} from 'antd'
import { TFunction } from 'i18next'

import { CaretDownOutlined } from '@ant-design/icons'
import { pdf } from '@react-pdf/renderer'
import { ReactComponent as Search } from 'assets/svgs/SearchOutlined.svg'
import CustomDateRangePicker from 'components/elements/CustomDateRangePicker'
import { openNotification } from 'components/widgets/Notification'
import { MEDICAL_CHECKUP_MASTER_EXAMPLE } from 'configs/api'
import { useTenantList } from 'hooks/tenant/useTenantList'
import useMedicalCheckup from 'hooks/useMedicalCheckup'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import useTestItemCategory from 'hooks/useTestItemCategory'
import useTestItemMaster from 'hooks/useTestItemMaster'
import useTestResult from 'hooks/useTestResult'
import { GENDER } from 'models/customer'
import { patientMedicalCheckup } from 'models/resultPreview'
import ResultPreviewPdf from 'pages/CustomerDetail/components/modals/exportPdf/ResultPreviewPdf'
import {
  getValueCategoryWithMedicalCheckup,
  isNumber,
  isObjectEmpty
} from 'utilities/helpers'

type InputForm = {
  fullName: string
  startDate: string
  endDate: string
  doctorName: string | undefined
  gender: string | undefined
  startAge: number | undefined
  endAge: number
}

type Props = {
  t: TFunction<'translation', undefined>
  selectOptions: { key: string; value: string; label: string }[]
  listIdSelected: any
  queryParams: URLSearchParams
  setIsExportData: any
}

const { Option } = Select

const Widget: FC<Props> = ({
  t,
  selectOptions,
  queryParams,
  listIdSelected,
  setIsExportData
}) => {
  const location = useLocation()
  const { tenant } = useTenantList()
  const methods = useForm<InputForm>({
    defaultValues: {
      fullName: queryParams.get('fullName') || '',
      startAge: queryParams.get('startAge')
        ? Number(queryParams.get('startAge')) || 0
        : undefined,
      endAge: Number(queryParams.get('endAge')) || undefined,
      gender: queryParams.get('gender') || undefined,
      doctorName: queryParams.get('doctorName') || undefined
    }
  })

  const startDateParam = queryParams.get('startDate')
  const endDateParam = queryParams.get('endDate')

  const navigate = useNavigate()

  const [selectedGender, setSelectedGender] = useState<GENDER[]>([])
  const [dates, setDates] = useState<[string, string]>()

  useEffect(() => {
    if (
      queryParams.get('gender') === GENDER.Male ||
      queryParams.get('gender') === GENDER.Female
    ) {
      setSelectedGender([queryParams.get('gender') as GENDER])
    }
  }, [])

  useEffect(() => {
    const startDate = queryParams.get('startDate') || undefined
    const endDate = queryParams.get('endDate') || undefined

    const params = Array.from(queryParams.keys())
    const isOnlyPageAndSize =
      params.length === 2 && params.includes('page') && params.includes('size')

    if (isOnlyPageAndSize) {
      methods.reset({
        fullName: '',
        startAge: undefined,
        endAge: undefined,
        gender: undefined,
        doctorName: ''
      })
      setSelectedGender([])
    } else {
      methods.reset({
        fullName: queryParams.get('fullName') || '',
        startDate: queryParams.get('startDate') || '',
        endDate: queryParams.get('endDate') || '',
        doctorName: queryParams.get('doctorName') || undefined,
        gender: queryParams.get('gender') || undefined,
        startAge: queryParams.get('startAge')
          ? Number(queryParams.get('startAge')) || 0
          : undefined,
        endAge: queryParams.get('endAge')
          ? Number(queryParams.get('endAge'))
          : undefined
      })

      if (
        queryParams.get('gender') === GENDER.Male ||
        queryParams.get('gender') === GENDER.Female
      ) {
        setSelectedGender([queryParams.get('gender') as GENDER])
      } else {
        setSelectedGender([])
      }

      if (startDate && endDate) {
        setDates([`${startDate}`, `${endDate}`])
      }
    }
  }, [location.search])

  const handleSubmit = () => {
    const values = methods.getValues()

    if (values.fullName) {
      queryParams.set('fullName', values.fullName)
    } else {
      queryParams.delete('fullName')
    }

    if (isNumber(values.startAge)) {
      queryParams.set('startAge', `${values.startAge}`)
    } else {
      queryParams.delete('startAge')
    }

    if (isNumber(values.endAge)) {
      queryParams.set('endAge', `${values.endAge}`)
    } else {
      queryParams.delete('endAge')
    }

    if (values.gender && values.gender.length) {
      queryParams.set('gender', values.gender)
    } else {
      queryParams.delete('gender')
    }

    if (values.doctorName) {
      queryParams.set('doctorName', values.doctorName)
    } else {
      queryParams.delete('doctorName')
    }

    if (dates) {
      queryParams.set('startDate', dates[0])
      queryParams.set('endDate', dates[1])
    } else {
      queryParams.delete('startDate')
      queryParams.delete('endDate')
    }

    queryParams.set('page', '1')
    navigate(`/customer-management?${queryParams.toString()}`)
  }

  const checkboxOptions = [
    { label: t('customerDetail.header.male'), value: GENDER.Male },
    { label: t('customerDetail.header.female'), value: GENDER.Female }
  ]

  const handleCheckboxChange = (checkedValues: GENDER[]) => {
    if (checkedValues.length > 1) {
      const newCheckedValue = checkedValues.find(
        (value) => !selectedGender.includes(value)
      )
      if (newCheckedValue) {
        setSelectedGender([newCheckedValue])
        methods.setValue('gender', newCheckedValue)
      }
    } else {
      setSelectedGender(checkedValues)
      methods.setValue('gender', checkedValues[0])
    }
  }

  const handleSelectChange = (value: string | undefined) => {
    methods.setValue('doctorName', value)
  }

  const { loadMedicalCheckup } = useMedicalCheckup()
  const { getListTestResult } = useTestResult()
  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()
  const { getListItemCategory } = useTestItemCategory()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()

  const getItemMasterCategory = (
    ItemMasterRefIds: any,
    listItemMaster: any
  ) => {
    const data = []
    for (let itemMaster of listItemMaster) {
      if (
        ItemMasterRefIds.includes(itemMaster?.refId) &&
        itemMaster?.additionalInfo?.key1 === '検査項目マスター'
      ) {
        data.push(itemMaster)
      }
    }
    return data
  }

  const getCommentCategory = async (ItemMasterRefIds: any) => {
    const dataItemMasterFetch = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'refId',
              operator: 'in',
              value: ItemMasterRefIds
            },
            {
              field: 'additionalInfo.key1',
              operator: 'eq',
              value: 'カテゴリマスタ'
            }
          ]
        }
      }
    ])

    return dataItemMasterFetch
  }

  const fetchFirstMedicalCheckupMaster = async (
    dataMedicalCheckupMaster: any = null
  ) => {
    let data = null
    if (dataMedicalCheckupMaster && !isObjectEmpty(dataMedicalCheckupMaster)) {
      data = dataMedicalCheckupMaster
    } else {
      //  data = await getFirstMedicalCheckupMaster()
      data = await getMedicalCheckupMastersById(MEDICAL_CHECKUP_MASTER_EXAMPLE)
    }

    let categoryOrder =
      data?.medicalCheckupMaster?.additionalInfo?.categoryOrder ?? {}

    const arrItemMasterRefId =
      data?.medicalCheckupMaster?.associatedTestItemMasters ?? []

    const listItemMaster = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'refId',
              operator: 'in',
              value: arrItemMasterRefId
            }
          ]
        }
      }
    ])

    const dataCategoryFetch = await getListItemCategory({
      // filter: `(in,STRING,associatedTestItemMasterRefIds,${arrItemMasterRefId.join(',')});(eq,STRING,status,PUBLISHED)`
      filter: `(in,STRING,refId,13215064603757287001726460393637,88665023344512015941726460435377,83216780091146409801726460484181,66863821368653047771726460525201,45797661172382888381726460564253,50303274715688017811726460592767,35872960772656501091726460641343,6157615577841498081726460663928,75393933929457064601726460690362,15761740032421589841726460728026,88765061765373168031726460770519,24127498591334101701726460803388,50069000893238963771726460828814,48850182716056353011726460846236);(eq,STRING,status,PUBLISHED)`, //hard code
      sortBy: '(desc,refId)'
    })

    let dataCategory = dataCategoryFetch?.data?.filterTestItemCategory?.payload

    dataCategory = Object.values(
      dataCategory.reduce((acc: any, obj: any) => {
        if (!acc[obj.refId] || acc[obj.refId].createdDate < obj.createdDate) {
          acc[obj.refId] = obj
        }
        return acc
      }, {})
    )

    let categoryFormat = []

    for (let itemCategory of dataCategory) {
      let itemMasterCategory = getItemMasterCategory(
        itemCategory?.associatedTestItemMasterRefIds,
        listItemMaster
      )

      if (itemMasterCategory && itemMasterCategory.length > 0) {
        const dataCommentCategory = await getCommentCategory(
          itemCategory?.associatedTestItemMasterRefIds
        )

        let idItemMasterCategory = dataCommentCategory.filter(function (
          item: any
        ) {
          return item?.additionalInfo?.key3 !== '所見コメント'
        })

        let comment = dataCommentCategory.filter(function (item: any) {
          return item?.additionalInfo?.key3 === '所見コメント'
        })

        comment = comment.filter(function (item: any) {
          return !(
            item?.additionalInfo?.isHidden === 1 ||
            item?.additionalInfo?.isDeleted === 1
          )
        })

        categoryFormat.push({
          refId: itemCategory?.refId,
          shortName: itemCategory?.shortName,
          associatedTestItemMasterRefIds:
            itemCategory?.associatedTestItemMasterRefIds,
          itemMaster: itemMasterCategory,
          comment: comment,
          idItemMaster: idItemMasterCategory?.[0]?.refId,
          lang: idItemMasterCategory?.[0]?.lang,
          order: categoryOrder?.[itemCategory?.refId] ?? 9999
        })
      }
    }
    return categoryFormat.sort((a: any, b: any) => a.order - b.order)
  }

  const handleExportPdf = async (selectedRows: any) => {
    setIsExportData(true)

    const patientNoMedicalCheckup: any = []
    const patientMedicalCheckupIds: any = []
    if (selectedRows && selectedRows[0]) {
      const fetchData = async () => {
        const patientDetailIds: any = []
        for (let patientDetail of selectedRows) {
          patientDetailIds.push(patientDetail?.id)
        }
        const filterMedicalCheckup = await loadMedicalCheckup({
          variables: {
            filter: `(in,STRING,checkupUserRef,${patientDetailIds});(ne,STRING,additionalInfo.status,DELETED);(eq,STRING,status,PUBLISHED)`,
            page: 0,
            size: -1,
            sortBy: '(desc,createdDate)'
          }
        })
        const patientHaveMedicalCheckup: any = []
        for (let patientDetail of selectedRows) {
          let count = 0
          for (let medicalCheckup of filterMedicalCheckup.data
            .filterMedicalCheckupDetails.payload) {
            if (
              patientDetail?.id ===
              medicalCheckup?.medicalCheckup?.checkupUserRef
            ) {
              count = 1
            }
          }
          if (count === 0) {
            patientNoMedicalCheckup.push(patientDetail?.name)
          } else {
            patientHaveMedicalCheckup.push(patientDetail)
          }
        }

        if (patientNoMedicalCheckup[0]) {
          let message = ''
          for (let i = 0; i < patientNoMedicalCheckup.length; i++) {
            if (i > 0) {
              message += '; '
            }
            message += patientNoMedicalCheckup[i]
          }
          openNotification({
            type: 'error',
            title: t('errors.errorOccurred'),
            message: t('errors.patientNoMedicalCheckup') + message
          })

          return
        }
        const listPatientMedicalCheckup: patientMedicalCheckup[] = []

        for (let patientDetail of selectedRows) {
          let count = 0
          let medicalCheckupList: any = []
          for (let medicalCheckup of filterMedicalCheckup.data
            .filterMedicalCheckupDetails.payload) {
            if (
              patientDetail?.id ===
              medicalCheckup?.medicalCheckup?.checkupUserRef
            ) {
              count += 1
              if (count <= 3) {
                medicalCheckupList.push(medicalCheckup)
                patientMedicalCheckupIds.push(
                  medicalCheckup?.medicalCheckup?.refId
                )
              }
            }
          }
          listPatientMedicalCheckup.push({
            patientDetail: patientDetail,
            medicalCheckupList: medicalCheckupList
          })
        }

        const getMedicalCheckupMaster = await getMedicalCheckupMastersById(
          listPatientMedicalCheckup[0]?.medicalCheckupList[0]?.medicalCheckup
            ?.medicalCheckupMasterRef
        )
        let categoryFormat = await fetchFirstMedicalCheckupMaster(
          getMedicalCheckupMaster
        )

        const infoFixedCategory = [
          {
            code: '03.0001',
            name: 'comprehensiveJudgment'
          },
          {
            code: '03.0152',
            name: 'generalComments'
          },
          {
            code: '03.0004',
            name: 'metabolicSyndromeAssessment'
          },
          {
            code: '1.00069',
            name: 'specificHealthGuidance'
          },
          {
            code: '1.00003',
            name: 'consultationDate'
          }
        ]

        let fixedDataCategory: any =
          await getFixedDataCategory(infoFixedCategory)

        const getListTestResults: any = await getListTestResult({
          filter: `(in,STRING,medicalCheckupRefId,${patientMedicalCheckupIds});(eq,STRING,status,PUBLISHED)`,
          page: 0,
          size: -1,
          sortBy: '(desc,createdDate)'
        })

        const testResultsListConvert: any = Array.isArray(
          getListTestResults?.data?.filterTestResult?.payload
        )
          ? getListTestResults.data.filterTestResult.payload
          : []

        let dataPdfAll: any = []

        for (let patientMedicalCheckup of listPatientMedicalCheckup) {
          let customerPdf =
            selectedRows.find(
              (user: any) =>
                user?.id === patientMedicalCheckup?.patientDetail?.id
            ) ?? {}

          let dataPdf = handleDataPdf(
            patientMedicalCheckup.medicalCheckupList,
            testResultsListConvert,
            categoryFormat,
            fixedDataCategory,
            infoFixedCategory
          )

          let dataPdfCustom = {
            category: categoryFormat,
            customer: customerPdf,
            data: dataPdf
          }

          dataPdfAll.push(dataPdfCustom)
        }

        for (let pdfFileDetail of dataPdfAll) {
          try {
            const currentDate = new Date()
            const currentTimeString = currentDate
              .toLocaleString()
              .replace(/\s/g, '')
            let fileNamePDF = `${pdfFileDetail?.customer?.patientId}-${currentTimeString}.pdf`
            const blob = await pdf(
              <ResultPreviewPdf pdfResult={pdfFileDetail} tenant={tenant} />
            ).toBlob()
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileNamePDF
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } catch (error) {
            console.error('Failed to generate and download PDF:', error)
          }
        }
      }
      await fetchData()
    } else {
      openNotification({
        type: 'error',
        title: t('errors.errorOccurred'),
        message: t('errors.selectPatient')
      })
    }

    setIsExportData(false)
  }

  const handleDataPdf = (
    medicalCheckupIds: any,
    testResults: any,
    dataCategory: any,
    fixedDataCategory: any,
    infoFixedCategory: any
  ) => {
    let result: any = []

    for (let i = 0; i < 3; i++) {
      if (!medicalCheckupIds?.[i]) {
        result.push({})
        continue
      }

      let medicalCheckup = medicalCheckupIds?.[i]?.medicalCheckup

      let testResultByMediCalCheckup = testResults.filter(
        (item: any) => item.medicalCheckupRefId === medicalCheckup?.refId
      )

      let medicalCheckupResult: any = {
        id: medicalCheckup?.refId,
        doctorName: testResultByMediCalCheckup?.[0]?.valueBy,
        category: getValueCategoryWithMedicalCheckup(
          medicalCheckup?.refId,
          dataCategory,
          testResultByMediCalCheckup
        )
      }

      //fixedCategory
      for (let fixedCategory of infoFixedCategory) {
        let find = testResultByMediCalCheckup.find(
          (item: any) =>
            medicalCheckup?.refId === item?.medicalCheckupRefId &&
            fixedDataCategory?.[fixedCategory.code]?.refId ===
              item?.itemMasterRefId
        )

        medicalCheckupResult[fixedCategory.name] = find?.value
      }

      result.push(medicalCheckupResult)
    }

    return result
  }

  const getFixedDataCategory = async (infoFixedCategory: any) => {
    const arrCodes = [...infoFixedCategory]
    const arrCodesMap = arrCodes.map((item) => item.code)

    const dataFixedCategory = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'code.coding.code',
              operator: 'in',
              value: arrCodesMap
            }
          ]
        }
      }
    ])

    const dataCategoryByCode = dataFixedCategory.reduce(
      (data: any, item: any) => {
        if (item?.code?.coding?.[0]?.code) {
          data[item?.code?.coding?.[0]?.code] = item
        }
        return data
      },
      {}
    )

    return dataCategoryByCode
  }

  return (
    <Flex justify="space-between" className="text-[14px] text-[#545454]">
      <FormProvider {...methods}>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit()
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        >
          <Flex className="flex justify-center items-center">
            <Typography className="flex items-center text-[16px] font-bold tracking-[1.6px]">
              {t('customerManagement.listOfTestResults')}
            </Typography>

            <Flex className="ml-[7px]">
              <Flex className="relative">
                <Controller
                  name="fullName"
                  control={methods.control}
                  rules={{
                    maxLength: 50
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t('customerManagement.placeholder.idOrName')}
                      className="h-[30px] w-[250px] pl-[32px]"
                    />
                  )}
                />
                <Search className="absolute left-2 top-[7px] h-4 w-4" />
              </Flex>

              <CustomDateRangePicker
                setDates={setDates}
                initialStartDate={startDateParam ? startDateParam : undefined}
                initialEndDate={endDateParam ? endDateParam : undefined}
              />

              <Controller
                name="doctorName"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={field.value ? field.value : undefined}
                    onChange={(value) => {
                      methods.setValue('doctorName', value)
                      handleSelectChange(value)
                    }}
                    placeholder={t(
                      'customerManagement.placeholder.selectDoctor'
                    )}
                    suffixIcon={
                      <CaretDownOutlined
                        style={{ color: '#137695', pointerEvents: 'none' }}
                      />
                    }
                    className="ml-[10px] h-[30px] w-[200px]"
                  >
                    {selectOptions.map((option) => (
                      <Option key={option.key} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </Flex>

            <div className="flex justify-center items-center">
              <Flex align="center" className="ml-[10px]">
                <Typography className="font-bold text-nowrap">
                  {t('customerManagement.table.gender')}
                </Typography>

                <div className="min-w-36">
                  <Controller
                    name="gender"
                    control={methods.control}
                    render={({ field }) => (
                      <Checkbox.Group
                        {...field}
                        options={checkboxOptions}
                        value={selectedGender}
                        onChange={handleCheckboxChange}
                        className="ml-[10px]"
                      />
                    )}
                  />
                </div>
              </Flex>

              <Flex align="center">
                <Typography className="font-bold text-nowrap">
                  {t('customerManagement.table.age')}
                </Typography>
                <Controller
                  name="startAge"
                  control={methods.control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      min={0}
                      max={999}
                      className="ml-[6px] h-[30px] w-[50px]"
                    />
                  )}
                />
                <Typography className="mx-[2px]">～</Typography>
                <Controller
                  name="endAge"
                  control={methods.control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      min={0}
                      max={999}
                      className="h-[30px] w-[50px]"
                    />
                  )}
                />
              </Flex>

              <Button
                type="primary"
                htmlType="submit"
                autoInsertSpace={false}
                className="mx-1 h-[30px] w-[84px] rounded-none border-none bg-[#137695] p-0 text-center text-[14px] font-bold text-white shadow-none"
              >
                {t('customerManagement.actions.search')}
              </Button>
            </div>
          </Flex>
        </form>
      </FormProvider>

      <Flex className="flex justify-center items-center">
        <Button
          type="primary"
          className="mx-1 rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-[14px] font-bold text-white shadow-none"
          onClick={() => navigate('/interview-result-create')}
        >
          {t('customerManagement.button.createResult')}
        </Button>

        <Button
          type="primary"
          className="mx-1 rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-[14px] font-bold text-white shadow-none"
          onClick={() => {
            handleExportPdf(listIdSelected)
          }}
        >
          {t('customerManagement.button.printResult')}
        </Button>

        <Button
          type="primary"
          className="mx-1 rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] text-[14px] font-bold text-white shadow-none"
          onClick={() => navigate('/customer-register')}
        >
          {t('customerManagement.button.registerCustomer')}
        </Button>

        {/* <Button
          type="primary"
          className="ml-[7px] h-[30px] w-[90px] rounded-[3px] border-none bg-gradient-to-r from-[#137695] to-[#93D1CA] p-0 text-center text-[14px] font-bold text-white shadow-none"
          onClick={() => {}}
        >
          {t('customerManagement.button.csv')}
        </Button> */}
      </Flex>
    </Flex>
  )
}

export default Widget
