import { useTranslation } from 'react-i18next'

import { Button, Typography } from 'antd'

import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onOk: () => void
}

const SaveMedicalQuestionnaireSuccess = ({
  isModalOpen = false,
  onCancel,
  onOk
}: Props) => {
  const { t } = useTranslation('')
  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={onOk}>
      <div className="text-center pt-4 pb-[40px]">
        <Typography className="text-xl font-bold tracking-[1px] leading-none">
          {t('saveComplete')}
        </Typography>

        <Paragraph className="mt-[165px] mb-0">
          {t('questionnaire.helperText.saveComplete')}
        </Paragraph>

        <Button
          className="w-[180px] h-[30px] text-primary bg-white font-bold mt-[145px] m-auto border-primary"
          onClick={onOk}
        >
          {t('button.close')}
        </Button>
      </div>
    </CustomModal>
  )
}

export default SaveMedicalQuestionnaireSuccess
