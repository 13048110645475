import React, { FC } from 'react'

import { Tabs, TabsProps, Typography } from 'antd'
import { TFunction } from 'i18next'

import CustomerDetailTable from './CustomerDetailTable'
import InterviewList from './InterviewList'
import styles from './InterviewList.module.scss'
import CustomerDetailEditForm, {
  CustomerDetailEditFormMethods
} from './forms/CustomerDetailEditForm'
import CustomerDetailInformationForm from './forms/CustomerDetailInformationForm'

enum TAB {
  BASIC_INFORMATION = '1',
  RESULT_LIST = '2',
  INTERVIEW_LIST = '3'
}

type Props = {
  t: TFunction<'translation', undefined>
  dataForm: any
  dataTable: any
  tab: string
  setTab: (tab: string) => void
  isOpenEditForm: boolean
  onChange: (tab: string) => void
  childRef: React.RefObject<CustomerDetailEditFormMethods>
  onSubmitChild: (data: any) => void
  page: any
  size: any
  totalRecords: any
  setPage: any
  loadingMedical: any
  language: any
}

const CustomerDetailContent: FC<Props> = ({
  t,
  dataForm,
  dataTable,
  tab,
  setTab,
  isOpenEditForm,
  onChange,
  childRef,
  onSubmitChild,
  page,
  size,
  totalRecords,
  setPage,
  loadingMedical,
  language
}) => {
  const onChangeTab = (tab: string) => {
    setTab(tab)
    onChange(tab)
  }

  const items: TabsProps['items'] = [
    {
      key: TAB.BASIC_INFORMATION,
      label: (
        <Typography className="w-[180px] text-center text-[14px]">
          {t('customerDetail.basicInformation')}
        </Typography>
      ),
      children: (
        <>
          {isOpenEditForm ? (
            <CustomerDetailEditForm
              t={t}
              ref={childRef}
              initialData={dataForm}
              onSubmit={onSubmitChild}
            />
          ) : (
            <CustomerDetailInformationForm t={t} data={dataForm} />
          )}
        </>
      )
    },
    {
      key: TAB.RESULT_LIST,
      label: (
        <Typography className="w-[180px] text-center text-[14px]">
          {t('customerDetail.resultList')}
        </Typography>
      ),
      children: (
        <>
          <CustomerDetailTable
            t={t}
            data={dataTable}
            customer={dataForm}
            page={page}
            size={size}
            totalRecords={totalRecords}
            setPage={setPage}
            loadingMedical={loadingMedical}
            language={language}
          />
        </>
      )
    },
    {
      key: TAB.INTERVIEW_LIST,
      label: (
        <Typography className="w-[180px] text-center text-[14px]">
          {t('customerDetail.interviewList')}
        </Typography>
      ),
      children:
        dataForm !== undefined ? (
          <InterviewList
            userRefId={dataForm && dataForm.id}
            patientName={dataForm && dataForm.firstName + dataForm.lastName}
            t={t}
          />
        ) : (
          <></>
        )
    }
  ]

  return (
    <div className="min-h-[700px] min-w-[1700px] rounded-[8px] bg-white px-[10px] pb-[26px] pt-[6px] text-[14px] text-[#545454] drop-shadow-md">
      <Tabs
        activeKey={tab}
        items={items}
        onChange={onChangeTab}
        className={styles.tab_wrapper}
      />
    </div>
  )
}

export default CustomerDetailContent
