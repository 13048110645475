import React, { FC, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import { WeekDayLabels } from '../../../../configs/constant'
import {
  DAY_TYPE,
  DAY_TYPE_VALUES,
  WeekOptions
} from '../../../../models/setting'
import { getAttr, isArrayEmpty } from '../../../../utilities/helpers'
import { CheckboxForm } from '../../../elements/CheckboxForm'
import { InputForm } from '../../../elements/InputForm'
import { SelectCustom } from '../../../elements/Select'
import { TimePickerForm } from '../../../elements/TimePickerForm'
import { ReactComponent as CloseIcon } from 'assets/imgs/close.svg'
import { ReactComponent as PlusIcon } from 'assets/imgs/plus.svg'

type Props = {
  workday?: any
  isBusinessDay?: boolean
  fieldsCount?: number
  append?: any
  remove?: any
  index?: any
  fieldName: string
}

const INITIAL_BIWEEKLY_DAY = {
  week: '1',
  typeDay: '1',
  start: '09:00',
  end: '20:00'
}

const SpecificDay: FC<Props> = ({ fieldName, workday }) => {
  const { t } = useTranslation()
  const [biweekly, setBiweekly] = useState(false)
  const { control } = useFormContext()
  const {
    fields: biweeklyTimes,
    append,
    remove
  } = useFieldArray({
    control,
    name: `${fieldName}.biweekly`
  })

  return (
    <Flex vertical gap={6}>
      <Flex gap={10}>
        <div className="w-[4px] min-w-1 min-h-[30px] bg-[#F0F3F7]" />

        <Typography className="mt-[6px] min-w-[42px] w-16">
          {t(getAttr(WeekDayLabels, workday.workday))}
        </Typography>

        <Flex vertical className="flex-1">
          <Flex gap={10}>
            <TimePickerForm
              name={`${fieldName}.start`}
              className="w-[10rem] ml-[-1px]"
              format="HH:mm"
              defaultValue={workday.start}
              showSecond={false}
              minuteStep={30}
              showNow={false}
              needConfirm={false}
              disabled={biweekly}
              placeholder={t('placeholder.selectTime')}
            />
            <Typography className="self-center">～</Typography>
            <TimePickerForm
              name={`${fieldName}.end`}
              className="w-[10rem] ml-[-1px]"
              format="HH:mm"
              defaultValue={workday.end}
              showSecond={false}
              minuteStep={30}
              showNow={false}
              needConfirm={false}
              disabled={biweekly}
              placeholder={t('placeholder.selectTime')}
            />
            <Button
              type="text"
              className="px-1"
              onClick={() => {
                setBiweekly(!biweekly)
                if (isArrayEmpty(biweeklyTimes)) {
                  append(INITIAL_BIWEEKLY_DAY)
                }
              }}
            >
              <Typography className="font-bold underline text-[#137695]">
                +{t('basicSetting.biweeklySetting')}
              </Typography>
            </Button>
          </Flex>

          {biweekly &&
            biweeklyTimes.map((time: any, index: number) => {
              const biweeklyFieldName = `${fieldName}.biweekly.${index}`
              return (
                <BiweeklyTimesSetting
                  key={time.id}
                  index={index}
                  fieldName={biweeklyFieldName}
                  isBusinessDay={time.typeDay === DAY_TYPE_VALUES.businessDay}
                  fieldsCount={biweeklyTimes.length}
                  append={() => append(INITIAL_BIWEEKLY_DAY)}
                  remove={() => remove(index)}
                />
              )
            })}
        </Flex>
      </Flex>
    </Flex>
  )
}

const BiweeklyTimesSetting: FC<Props> = ({
  fieldName,
  fieldsCount = 0,
  isBusinessDay = false,
  append,
  remove,
  index
}) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const [isWorkingDay, setIsWorkingDay] = useState<boolean>(isBusinessDay)

  return (
    <Flex className="mt-[10px]">
      <Button
        type="primary"
        className="rounded-[16px] mr-[34px] w-[10rem]"
        onClick={() => {
          setIsWorkingDay(!isWorkingDay)
          setValue(
            `${fieldName}.typeDay`,
            isWorkingDay
              ? DAY_TYPE_VALUES.closedDay
              : DAY_TYPE_VALUES.businessDay
          )
        }}
        style={{
          background: isWorkingDay ? '' : '#D9E0E5'
        }}
      >
        {isWorkingDay ? t(DAY_TYPE.businessDay) : t(DAY_TYPE.closedDay)}
      </Button>

      <InputForm name={`${fieldName}.typeDay`} className="hidden" />

      <CheckboxForm
        name={`${fieldName}.active`}
        className="mr-[10px] scale-125"
      />

      <SelectCustom
        name={`${fieldName}.week`}
        className="w-[8rem] mr-[8px]"
        options={WeekOptions}
      />

      <TimePickerForm
        name={`${fieldName}.start`}
        className="w-[8rem]"
        format="HH:mm"
        defaultValue="09:00"
        showSecond={false}
        minuteStep={30}
        showNow={false}
        needConfirm={false}
        disabled={!isWorkingDay}
        placeholder={t('placeholder.selectTime')}
      />
      <Typography className="self-center mx-[10px]">～</Typography>
      <TimePickerForm
        name={`${fieldName}.end`}
        className="w-[8rem] mr-[10px]"
        defaultValue="20:00"
        format="HH:mm"
        showSecond={false}
        minuteStep={30}
        showNow={false}
        needConfirm={false}
        disabled={!isWorkingDay}
        placeholder={t('placeholder.selectTime')}
      />

      <Flex align="center">
        {fieldsCount > 1 && (
          <Button
            type="text"
            shape="circle"
            size="small"
            onClick={() => remove()}
            icon={<CloseIcon />}
            className="bg-[#BFC6CB] mr-5"
          />
        )}

        {index + 1 === fieldsCount && (
          <Button
            type="text"
            shape="circle"
            size="small"
            disabled={fieldsCount === 5}
            onClick={() => append()}
            icon={<PlusIcon />}
            className="bg-[#137695]"
          />
        )}
      </Flex>
    </Flex>
  )
}

export default SpecificDay
