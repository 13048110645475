import React from 'react'

import { Button, Modal, Row, Typography } from 'antd'

import styles from './CreateSucceededModal.module.scss'

type Props = {
  title?: string
  body?: string
  button?: string
  btnClassName?: string
  open?: boolean
  width?: number
  onOk: () => void
  onCancel: () => void
}

const CreateSucceededModal = ({
  title = '',
  body = '',
  button = '閉じる',
  btnClassName,
  open = true,
  width = 600,
  onOk,
  onCancel
}: Props) => {
  return (
    <Modal
      centered
      title={null}
      open={open}
      onOk={onOk}
      width={width}
      footer={null}
      closable={false}
      className={styles.modal_wrapper}
    >
      <Row className="bg-gradient-to-r from-[#347a91] to-[#60acb0] w-full h-4 rounded-t-lg" />
      <div className="pt-4 pb-[51px] text-center">
        <Typography.Title level={4} className="text-[#545454] mb-0">
          {title}
        </Typography.Title>

        <Typography className="text-[#545454] mb-0 mt-[165px]">
          {body}
        </Typography>

        <Button
          onClick={onCancel}
          className={`font-bold mt-[134px] ${btnClassName}`}
        >
          {button}
        </Button>
      </div>
    </Modal>
  )
}

export default CreateSucceededModal
