import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./PasswordVerifyCode.module.scss";

export default function PasswordVerifyCode() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleVerifyCode = () => {
    navigate("/reset-password");
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t("passwordVerifyCode")}</h3>
      <form action="" className={styles["form-submit"]}>
        <div className={styles["box-input"]}>
          <label htmlFor="input">{t("lable.passwordVerifyCode")}</label>
          <input type="text" placeholder="二段階認証コード" id="input" />
        </div>
        <button
          type="submit"
          className={styles["btn-submit"]}
          onClick={handleVerifyCode}
        >
          {t("send")}
        </button>
      </form>
    </div>
  );
}
