import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Tooltip, Typography } from 'antd'

import { EyeInvisibleOutlined, QuestionCircleFilled } from '@ant-design/icons'

const ConfirmBox = () => {
  const { t } = useTranslation()

  return (
    <Flex vertical className="">
      <Typography.Text className="text-[11px] text-white">
        ・
        <EyeInvisibleOutlined style={{ color: '#fff' }} className="text-base" />
        {t('questionnaire.helperText.hiddenTooltip')}
        <br />
        {t('questionnaire.helperText.hiddenTooltip2')}
      </Typography.Text>
      <Typography.Text className="text-[11px] text-white">
        {t('questionnaire.helperText.hiddenTooltip3')}
      </Typography.Text>
    </Flex>
  )
}
export const HiddenTooltipPopconfirm = () => {
  return (
    <span>
      <Tooltip
        title={<ConfirmBox />}
        color="#000000CC"
        overlayStyle={{
          maxWidth: '30rem'
        }}
        overlayInnerStyle={{
          borderRadius: '5px'
        }}
      >
        <QuestionCircleFilled style={{ color: '#8E9EAA', marginLeft: '3px' }} />
      </Tooltip>
    </span>
  )
}
