import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import CustomModal from 'components/modals'
import {
  itemSettingsCategory,
  settingsCategoryExample
} from 'models/inspectionSetting'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onSuccess: () => void
}

const NoteHiddenSettingModal = ({
  isModalOpen = false,
  onCancel,
  onSuccess
}: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //todo
    onSuccess()
    onCancel()
  }

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={handleOk}>
      <Flex
        vertical
        className="text-center pt-[17px] pr-[14px] pb-[19px] pl-[7px]"
      >
        <Typography className="text-xl font-bold tracking-[1px]">
          {t('NoteHiddenSetting')}
        </Typography>
        <ExclamationCircleFilled className="mt-[43px] m-auto w-[40px] text-error" />
        <Paragraph className="mt-[43px] mb-0 text-error">
          {t('content.NoteHiddenSetting')}
        </Paragraph>
        <Typography className="mt-[43px] tracking-[.7px] text-left">
          {t('CurrentlySelectedCategory')}
        </Typography>
        <div className="text-left pl-2 mt-[15px]">
          <Flex className="bg-[#EFF3F6] border border-solid border-[#CDD6DD] py-2 pl-[11px]">
            <Col flex="57.8%">
              <Typography className="leading-none tracking-[1.4px]">
                {settingsCategoryExample.name}
              </Typography>
            </Col>
            <Col flex="auto" className="font-normal">
              <Typography className="leading-none tracking-[1.4px]">
                {settingsCategoryExample.content}
              </Typography>
            </Col>
          </Flex>
          {settingsCategoryExample.items.length > 0 &&
            settingsCategoryExample.items.map((item: itemSettingsCategory) => (
              <Flex className="pl-[11px] pt-2">
                <Col flex="57.8%">
                  <Typography className="tracking-[1.4px] leading-4">
                    {item.name}
                  </Typography>
                </Col>
                <Col flex="auto" className="font-normal">
                  <Typography className="tracking-[1.4px] leading-4">
                    {item.content}
                  </Typography>
                </Col>
              </Flex>
            ))}
        </div>

        <Flex className="justify-center gap-[12px] mt-[75px]">
          <Button
            type="primary"
            className="w-[180px] font-bold"
            htmlType="submit"
            onClick={handleOk}
          >
            {t('button.keep')}
          </Button>
          <Button
            className="w-[180px] border-[#137695] text-[#137695] font-bold"
            onClick={onCancel}
          >
            {t('button.cancel')}
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  )
}

export default NoteHiddenSettingModal
