import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Col, Flex, Row, Typography } from 'antd'

import { InputForm } from '../../components/elements/InputForm'
import { useRuleLogical } from '../../hooks/rule-logical/useRuleLogical'

export const FunctionEvaluate = () => {
  const { handleEvaluate } = useRuleLogical()
  const [evaluate, setEvaluate] = useState<string>('')

  const methods = useForm<any>({
    defaultValues: {
      frg: '90',
      hbA1c: '50'
    }
  })

  const { handleSubmit } = methods

  const onSubmit = async (formData: any) => {
    console.log(formData)

    const result = await handleEvaluate(
      parseFloat(formData.frg),
      parseFloat(formData.hbA1c)
    )

    setEvaluate(result.evaluate)
  }

  return (
    <Flex
      vertical
      className="size-full bg-white rounded-[5px] shadow p-[10px] gap-[8px] mb-5"
      style={{
        boxShadow: '0px 3px 5px #00000029'
      }}
    >
      <Typography className="font-bold text-[16px]">
        Function Evaluate
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex vertical gap={16} className="justify-center items-center">
            <Flex gap={16}>
              <Flex vertical gap="large">
                <Typography className="font-bold text-center text-[16px]">
                  項目
                </Typography>
                <Row className="items-center">
                  <Col>
                    <Flex gap="middle" justify="flex-start" align="center">
                      <Col span={12}>
                        <Typography className="">
                          FPG（血漿）空腹時血糖
                        </Typography>
                      </Col>
                      <Col>
                        <InputForm name="frg" className="" />
                      </Col>
                    </Flex>
                  </Col>
                </Row>
                <Row className="items-center">
                  <Col>
                    <Flex gap="middle" justify="flex-start" align="center">
                      <Col span={12}>
                        <Typography className="">
                          FPG（血漿）空腹時血糖
                        </Typography>
                      </Col>
                      <Col>
                        <InputForm name="hbA1c" className="" />
                      </Col>
                    </Flex>
                  </Col>
                </Row>
              </Flex>
            </Flex>

            <Flex
              vertical
              className="bg-[#64bad3] p-[30px] w-[30%] flex-wrap items-center"
            >
              <Typography className="font-bold text-white text-[16px]">
                判定結果は
              </Typography>
              <Typography className="font-bold text-white text-[16px]">
                {evaluate}
              </Typography>
            </Flex>

            <Button type="primary" htmlType="submit" className="w-[180px]">
              Evaluate
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  )
}
