export const settingsCategoryExample = {
  name: 'カテゴリーA',
  content: 'CategoryA',
  items: [
    {
      name: '検査項目A',
      content: 'texttexttexttexttexttexttext'
    },
    {
      name: '検査項目A',
      content: 'texttexttexttexttexttexttext'
    }
  ]
}

export type settingsCategory = {
  name: string
  content: string
  items: itemSettingsCategory[]
}

export type itemSettingsCategory = {
  name: string
  content: string
}
