import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'

import useCustomer from '../../hooks/useCustomer'
import CustomerDetailContent from './components/CustomerDetailContent'
import CustomerDetailHeader from './components/CustomerDetailHeader'
import { CustomerDetailEditFormMethods } from './components/forms/CustomerDetailEditForm'
import Delete from './components/modals/Delete'
import DeletionNotice from './components/modals/DeletionNotice'
import {
  STATUS_CODE_ALREADY_EXISTS,
  STATUS_CODE_SUCCESS
} from 'configs/constant'
import { useGetCheckupUsers } from 'hooks/useCheckupUser'
import { CustomerDetail as CustomerData } from 'hooks/useCustomer'
import useMedicalCheckup from 'hooks/useMedicalCheckup'
import { useMedicalCheckupMaster } from 'hooks/useMedicalCheckupMaster'
import useTestItemCategory from 'hooks/useTestItemCategory'
import useTestItemMasters from 'hooks/useTestItemMaster'
import useTestResult from 'hooks/useTestResult'
import { TAB } from 'models/customer'
import {
  MedicalCheckup,
  listResultTestResult,
  result
} from 'models/medicalCheckup'
import DuplicateIdError from 'pages/CustomerRegister/components/modals/DuplicateIdError'
import {
  checkTestResultValue,
  getLanguage,
  isObjectEmpty,
  mergeItemMaster
} from 'utilities/helpers'

export default function CustomerDetail() {
  const { t } = useTranslation()
  let language = getLanguage()
  const { state } = useLocation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    onFilterCustomer,
    onUpdateCustomer,
    onDeleteCustomer,
    onCreateCustomer,
    handleDataCustomerDetail,
    onUpdateDataCustomerByNewId
  } = useCustomer()
  const { loadMedicalCheckup } = useMedicalCheckup()
  const { getCheckupUsers } = useGetCheckupUsers()

  const [isOpenEditForm, setOpenEditForm] = useState<boolean>(false)
  const [isOpenDeleteCustomerModal, setOpenDeleteCustomerModal] =
    useState<boolean>(false)
  const [isOpenDeleteNoticeModal, setOpenDeleteNoticeModal] =
    useState<boolean>(false)
  const [tab, setTab] = useState<string>(TAB.BASIC_INFORMATION)
  const [customerData, setCustomerData] = useState<CustomerData>()
  const [count, setCount] = useState<number>(0)
  const [currentIndex, setCurrentIndex] = useState<number>(
    state !== null ? state.location.index : 0
  )
  const [isFirstRecord, setFirstRecord] = useState<boolean>(false)
  const [isLastRecord, setLastRecord] = useState<boolean>(false)
  const [medicalCheckupData, setMedicalCheckupData] = useState<any>([])

  const [page, setPage] = useState<number>(1)
  const [size, setSize] = useState<number>(20)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [dataDuplicateId, setDataDuplicateId] = useState<any>(null)

  const ids = state !== null ? state.location.ids : []

  const search = location.search
  const searchParams = new URLSearchParams(search)
  const idCustomer = searchParams.get('id')
  const currentTabId = searchParams.get('currentTab')

  useEffect(() => {
    const search = {
      currentTab: currentTabId ?? TAB.BASIC_INFORMATION
    }
    setTab(search.currentTab)
  }, [search])

  const handleNextRecord = () => {
    if (ids.length - 1 > currentIndex) {
      setMedicalCheckupData([])
      navigate(
        `/customer-detail?id=${ids[currentIndex + 1]}&currentTab=${currentTabId}`,
        {
          state: {
            openListResultTab: tab,
            location: {
              ids: state.location.ids,
              index: currentIndex + 1
            }
          }
        }
      )
      setCurrentIndex(currentIndex + 1)
      setFirstRecord(false)
      setOpenEditForm(false)
    }
  }

  const handlePreviousRecord = () => {
    if (currentIndex !== 0 && ids.length - 1 >= currentIndex) {
      setMedicalCheckupData([])
      navigate(
        `/customer-detail?id=${ids[currentIndex - 1]}&currentTab=${currentTabId}`,
        {
          state: {
            openListResultTab: tab,
            location: {
              ids: state.location.ids,
              index: currentIndex - 1
            }
          }
        }
      )
      setCurrentIndex(currentIndex - 1)
      setLastRecord(false)
      setOpenEditForm(false)
    }
  }

  const childRef = useRef<CustomerDetailEditFormMethods>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (currentIndex === 0) {
        setFirstRecord(true)
        setLastRecord(false)
      }
      if (ids.length - 1 === currentIndex) {
        setFirstRecord(false)
        setLastRecord(true)
      }
      if (currentIndex === 0 && ids.length === 0) {
        setFirstRecord(true)
        setLastRecord(true)
      }
      try {
        const params = new URLSearchParams(window.location.search)
        const id = params.get('id')
        const filteredCustomer = await onFilterCustomer({
          filter: `(eq,STRING,status,PUBLISHED);(like,STRING,refId,${id})`,
          page: 0,
          size: 1
        })
        setCustomerData(filteredCustomer)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData().then()
  }, [currentIndex, count])

  const { getListTestResult } = useTestResult()
  const { getListItemCategory } = useTestItemCategory()
  const { getListItemMasterByMultipleLanguages } = useTestItemMasters()

  const { getMedicalCheckupMaster } = useMedicalCheckupMaster()
  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        let filterMedicalCheckup: any = await loadMedicalCheckup({
          variables: {
            filter: `(like,STRING,checkupUserRef,${customerData?.id});(ne,STRING,additionalInfo.status,DELETED);(eq,STRING,status,PUBLISHED)`,
            page: page - 1,
            size: size,
            sortBy: '(desc,createdDate)'
          }
        })
        setTotalRecords(
          filterMedicalCheckup.data.filterMedicalCheckupDetails.totalElements
        )

        const medicalCheckupListConvert = Array.isArray(
          filterMedicalCheckup?.data?.filterMedicalCheckupDetails?.payload
        )
          ? filterMedicalCheckup.data.filterMedicalCheckupDetails.payload
          : []

        const medicalCheckupIds: any = medicalCheckupListConvert.map(
          (item: any) => item?.medicalCheckup?.refId
        )

        const getListTestResults: any = await getListTestResult({
          filter: `(in,STRING,medicalCheckupRefId,${medicalCheckupIds});(eq,STRING,status,PUBLISHED)`,
          page: 0,
          size: -1,
          sortBy: '(desc,createdDate)'
        })

        const testResultsListConvert = Array.isArray(
          getListTestResults?.data?.filterTestResult?.payload
        )
          ? getListTestResults.data.filterTestResult.payload
          : []

        const listTestItemMasterConsultDate: any = []
        const listTestItemMasterCategory: any = []

        for (let testResult of testResultsListConvert) {
          if (testResult?.additionalInfo?.key1 === '基本情報マスター') {
            if (
              !listTestItemMasterConsultDate.includes(
                testResult?.itemMasterRefId
              )
            )
              listTestItemMasterConsultDate.push(testResult?.itemMasterRefId)

            continue
          }

          if (!listTestItemMasterCategory.includes(testResult?.itemMasterRefId))
            listTestItemMasterCategory.push(testResult?.itemMasterRefId)
        }

        let dataItemMasterConsultDate: any =
          await getListItemMasterByMultipleLanguages([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'in',
                    value: listTestItemMasterConsultDate
                  },
                  {
                    field: 'code.coding.code',
                    operator: 'eq',
                    value: '1.00003'
                  }
                ]
              }
            }
          ])

        dataItemMasterConsultDate = dataItemMasterConsultDate?.[0]

        let medicalCheckupMasterIds = medicalCheckupListConvert.map(
          (item: any) => item?.medicalCheckup?.medicalCheckupMasterRef
        )

        const dataMedicalCheckUpMasterFetch = await getMedicalCheckupMaster({
          filter: `(in,STRING,refId,${medicalCheckupMasterIds})`,
          page: 0,
          size: -1
        })

        const medicalCheckUpMasterNewUsed = dataMedicalCheckUpMasterFetch.find(
          (item: any) =>
            item?.medicalCheckupMaster?.refId ===
            medicalCheckupListConvert?.[0]?.medicalCheckup
              ?.medicalCheckupMasterRef
        )

        let categoryOrder =
          medicalCheckUpMasterNewUsed?.medicalCheckupMaster?.additionalInfo
            ?.categoryOrder ?? {}

        let listItemMasterIdMedicalCheckupMaster: any = []

        if (Array.isArray(dataMedicalCheckUpMasterFetch)) {
          listItemMasterIdMedicalCheckupMaster =
            dataMedicalCheckUpMasterFetch.map((item: any) => [
              ...item.medicalCheckupMaster.associatedTestItemMasters
            ])

          listItemMasterIdMedicalCheckupMaster =
            listItemMasterIdMedicalCheckupMaster.flat()

          listItemMasterIdMedicalCheckupMaster = Array.from(
            new Set(listItemMasterIdMedicalCheckupMaster)
          )
        }

        let listItemMaster = await getListItemMasterByMultipleLanguages([
          {
            type: 'MATCH',
            criteria: {
              and: [
                {
                  field: 'refId',
                  operator: 'in',
                  value: listItemMasterIdMedicalCheckupMaster
                }
              ]
            }
          }
        ])

        const dataCategoryFetch = await getListItemCategory({
          // filter: `(in,STRING,associatedTestItemMasterRefIds,${listItemMasterIdMedicalCheckupMaster});(eq,STRING,status,PUBLISHED)`
          filter: `(in,STRING,refId,13215064603757287001726460393637,88665023344512015941726460435377,83216780091146409801726460484181,66863821368653047771726460525201,45797661172382888381726460564253,50303274715688017811726460592767,35872960772656501091726460641343,6157615577841498081726460663928,75393933929457064601726460690362,15761740032421589841726460728026,88765061765373168031726460770519,24127498591334101701726460803388,50069000893238963771726460828814,48850182716056353011726460846236);(eq,STRING,status,PUBLISHED)`, //hard code
          sortBy: '(desc,refId)'
        })

        let dataCategory =
          dataCategoryFetch?.data?.filterTestItemCategory?.payload

        if (!Array.isArray(dataCategory)) dataCategory = []

        dataCategory = mergeItemMaster(dataCategory)

        let categoryFormat = []

        for (let itemCategory of dataCategory) {
          let itemMasterCategory = getItemMasterCategory(
            itemCategory?.associatedTestItemMasterRefIds,
            listItemMaster
          )

          if (itemMasterCategory && itemMasterCategory.length > 0) {
            const dataCommentCategory = await getCommentCategory(
              itemCategory?.associatedTestItemMasterRefIds
            )

            let idItemMasterCategory = dataCommentCategory.filter(function (
              item: any
            ) {
              return item?.additionalInfo?.key3 !== '所見コメント'
            })

            categoryFormat.push({
              refId: itemCategory?.refId,
              shortName: itemCategory?.shortName,
              associatedTestItemMasterRefIds:
                itemCategory?.associatedTestItemMasterRefIds,
              itemMaster: itemMasterCategory,
              comment: dataCommentCategory.filter(function (item: any) {
                return item?.additionalInfo?.key3 === '所見コメント'
              }),
              idItemMaster: idItemMasterCategory?.[0]?.refId,
              lang: idItemMasterCategory?.[0]?.lang,
              order: categoryOrder?.[itemCategory?.refId] ?? 9999
            })
          }
        }

        categoryFormat.sort((a: any, b: any) => a.order - b.order)

        const listTestResultComment: any = []
        const listConsultDate: any = {}

        for (let testResult of testResultsListConvert) {
          if (testResult?.additionalInfo?.key1 === 'カテゴリマスタ') {
            listTestResultComment.push({
              key: testResult.refId,
              id: testResult.refId,
              idItemMaster: testResult.itemMasterRefId,
              medicalCheckupId: testResult.medicalCheckupRefId,
              comment: testResult.value,
              doctorName: testResult.valueBy,
              evaluation: testResult.evaluation,
              key1: 'カテゴリマスタ'
            })
          } else {
            if (dataItemMasterConsultDate?.refId === testResult.itemMasterRefId)
              listConsultDate[testResult.medicalCheckupRefId] = testResult.value
          }
        }

        const itemMasterIdEvaluation =
          await getListItemMasterByMultipleLanguages([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'code.coding.code',
                    operator: 'eq',
                    value: '03.0001'
                  }
                ]
              }
            }
          ])

        let listTestResultEvaluation: any = await getListTestResult({
          filter: `(in,STRING,medicalCheckupRefId,${medicalCheckupIds});(like,STRING,itemMasterRefId,${itemMasterIdEvaluation?.[0].refId});(eq,STRING,status,PUBLISHED)`,
          page: 0,
          size: -1
        })

        listTestResultEvaluation =
          listTestResultEvaluation?.data?.filterTestResult?.payload

        if (!Array.isArray(listTestResultEvaluation))
          listTestResultEvaluation = []

        let dataFetchMedicalCheckup: MedicalCheckup[] = []
        for (let medicalCheckup of medicalCheckupListConvert) {
          let resultCategory: result[] = []
          let consultantDateResult: string =
            listConsultDate?.[medicalCheckup?.medicalCheckup?.refId] ?? ''
          let doctorNameResult: string = ''
          let evaluationResult: string = ''
          let categoryName: string = ''

          for (let testResult of listTestResultEvaluation) {
            if (
              testResult.medicalCheckupRefId ===
              medicalCheckup.medicalCheckup.refId
            ) {
              evaluationResult = testResult.value
            }
          }

          for (let testResultCommentItem of listTestResultComment) {
            if (
              medicalCheckup.medicalCheckup.refId ===
              testResultCommentItem.medicalCheckupId
            ) {
              for (let categoryFormatDetail of categoryFormat) {
                if (
                  categoryFormatDetail.idItemMaster ===
                  testResultCommentItem.idItemMaster
                ) {
                  categoryName = categoryFormatDetail.lang
                }
              }
              resultCategory.push({
                idTestResult: testResultCommentItem.id,
                idItemMaster: testResultCommentItem.idItemMaster,
                categoryName: categoryName,
                comment: testResultCommentItem.comment,
                evaluation: testResultCommentItem.evaluation
              })
              doctorNameResult = testResultCommentItem.doctorName
            }
          }

          let listResultTestResult: listResultTestResult[] = []
          for (let testResult of testResultsListConvert) {
            if (
              testResult?.medicalCheckupRefId ===
              medicalCheckup.medicalCheckup.refId
            ) {
              for (let categoryFormatDetail of categoryFormat) {
                for (let testItemFormat of categoryFormatDetail.itemMaster) {
                  if (
                    testResult?.value &&
                    testResult?.itemMasterRefId === testItemFormat.refId
                  ) {
                    if (
                      !checkTestResultValue(
                        testResult,
                        testItemFormat,
                        customerData
                      )
                    ) {
                      listResultTestResult.push({
                        idTestResult: testResult?.refId,
                        idItemMaster: testResult?.itemMasterRefId,
                        categoryName: categoryFormatDetail.lang,
                        unit: testItemFormat.unitOfMeasure,
                        displayName: testItemFormat.lang,
                        value: testResult?.value
                      })
                    }
                  }
                }
              }
            }
          }
          dataFetchMedicalCheckup.push({
            key: medicalCheckup.medicalCheckup.refId,
            id: medicalCheckup.medicalCheckup.refId,
            status:
              medicalCheckup.medicalCheckup?.additionalInfo?.healthCheckStatus,
            listIds: medicalCheckupIds,
            formatCategory: categoryFormat,
            consultationDate: consultantDateResult
              ? consultantDateResult.split('T')[0].replace(/-/g, '/')
              : '',
            registrationDate: medicalCheckup.medicalCheckup.createdDate
              .split('T')[0]
              .replace(/-/g, '/'),
            result: resultCategory,
            doctorName: doctorNameResult,
            evaluation: evaluationResult,
            listResultTestData: listResultTestResult
          })
        }
        setMedicalCheckupData(dataFetchMedicalCheckup)
      } catch (error) {
        console.error(error)
      }
    }
    if (customerData && !isObjectEmpty(customerData)) {
      fetchData().then(() => {
        setLoading(false)
      })
    }
  }, [customerData?.id, page])

  useEffect(() => {
    setOpenEditForm(state?.isOpenEditForm === true)
  }, [state?.openListResultTab])

  const handleDeleteCustomer = async () => {
    await onDeleteCustomer(customerData).then(() => {
      setOpenDeleteCustomerModal(false)
      setOpenDeleteNoticeModal(true)
    })
  }

  const getCommentCategory = async (ItemMasterRefIds: any) => {
    let dataItemMasterFetch = await getListItemMasterByMultipleLanguages([
      {
        type: 'MATCH',
        criteria: {
          and: [
            {
              field: 'refId',
              operator: 'in',
              value: ItemMasterRefIds
            },
            {
              field: 'additionalInfo.key1',
              operator: 'eq',
              value: 'カテゴリマスタ'
            }
          ]
        }
      }
    ])

    return dataItemMasterFetch
  }

  const getItemMasterCategory = (
    ItemMasterRefIds: any,
    listItemMaster: any
  ) => {
    const data = []
    for (let itemMaster of listItemMaster) {
      if (
        ItemMasterRefIds.includes(itemMaster?.refId) &&
        itemMaster?.additionalInfo?.key1 === '検査項目マスター'
      ) {
        data.push(itemMaster)
      }
    }

    return data
  }

  const handleUpdateCustomer = () => {}

  const handleChildFormSubmit = async (data: any) => {
    if (data.id === data.patientId) {
      await onUpdateCustomer(data).then(() => {
        setOpenEditForm(false)
        setCount(count + 1)
      })

      return
    }

    const newCustomer = handleDataCustomerDetail(data)

    const res = await onCreateCustomer({
      ...newCustomer,
      refId: data.patientId
    })

    if (res?.addCheckupUser?.status === STATUS_CODE_SUCCESS) {
      await onUpdateDataCustomerByNewId(data.id, data.patientId)

      await onDeleteCustomer(data)

      setOpenEditForm(false)
      setCount(count + 1)
      navigate(
        `/customer-detail?id=${data.patientId}&currentTab=${TAB.BASIC_INFORMATION}`,
        {
          state: {
            openListResultTab: tab,
            location: {
              ids: ids
                ? ids.map((item: any) =>
                    item === data.id ? data.patientId : item
                  )
                : [],
              index: currentIndex
            }
          }
        }
      )
    }

    if (res?.addCheckupUser?.status === STATUS_CODE_ALREADY_EXISTS) {
      const checkUser = await getCheckupUsers({
        filter: `(eq,STRING,refId,${data.patientId})`,
        page: 0,
        size: 1,
        sortBy: '(desc,createdDate)'
      })

      if (checkUser?.[0]) setDataDuplicateId(checkUser[0])
    }
  }

  const handleSubmit = async () => {
    setTab(TAB.BASIC_INFORMATION)
    if (isOpenEditForm) {
      childRef.current?.onSubmit()
    }

    if (!isOpenEditForm) {
      handleUpdateCustomer()
    }
  }

  return (
    <div className="text-[14px] text-[#545454]">
      <Typography className="mb-4 text-[16px] font-bold tracking-[1.6px]">
        {t('customerDetail.customerDetail')}
      </Typography>

      <div className="mb-[14px]">
        <CustomerDetailHeader
          t={t}
          setTab={setTab}
          data={customerData}
          isOpenEditForm={isOpenEditForm}
          onOpenEditForm={() => setOpenEditForm(!isOpenEditForm)}
          onNext={handleNextRecord}
          onPrevious={handlePreviousRecord}
          isFirstRecord={isFirstRecord}
          isLastRecord={isLastRecord}
        />
      </div>
      <div>
        {medicalCheckupData && (
          <CustomerDetailContent
            t={t}
            tab={tab}
            setTab={setTab}
            dataForm={customerData}
            dataTable={medicalCheckupData}
            isOpenEditForm={isOpenEditForm}
            onChange={(value) => {
              navigate(
                `/customer-detail?id=${idCustomer}&currentTab=${value}`,
                {
                  state: {
                    openListResultTab: tab,
                    location: {
                      ids: ids,
                      index: currentIndex + 1
                    }
                  }
                }
              )
              setOpenEditForm(false)
            }}
            childRef={childRef}
            onSubmitChild={handleChildFormSubmit}
            page={page}
            size={size}
            totalRecords={totalRecords}
            setPage={setPage}
            loadingMedical={loading}
            language={language}
          />
        )}
      </div>

      {isOpenEditForm ? (
        <Flex className="mt-6">
          <Button
            type="primary"
            htmlType="submit"
            autoInsertSpace={false}
            onClick={handleSubmit}
            className="mr-[8px] h-[33px] w-[180px] rounded-[3px] bg-[#137695] p-0 text-center text-[14px] font-bold text-white shadow-none"
          >
            {t('customerDetail.keep')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            autoInsertSpace={false}
            onClick={() => setOpenDeleteCustomerModal(true)}
            className="h-[33px] w-[180px] rounded-[3px] bg-[#DF2475] p-0 text-center text-[14px] font-bold text-white shadow-none"
          >
            {t('customerDetail.delete')}
          </Button>
        </Flex>
      ) : (
        <></>
      )}

      {customerData !== null ? (
        <div>
          <Delete
            t={t}
            isOpen={isOpenDeleteCustomerModal}
            data={{
              id: customerData?.id,
              fullName: customerData?.fullName
            }}
            onDelete={handleDeleteCustomer}
            onCancel={() => setOpenDeleteCustomerModal(false)}
          />

          <DeletionNotice
            t={t}
            isOpen={isOpenDeleteNoticeModal}
            data={{
              id: customerData?.id,
              fullName: customerData?.fullName
            }}
            onCancel={() => {
              setOpenDeleteNoticeModal(false)
              navigate('/customer-management')
            }}
          />
        </div>
      ) : null}

      <DuplicateIdError
        t={t}
        isOpen={dataDuplicateId}
        onCancel={() => setDataDuplicateId(null)}
        data={dataDuplicateId}
        isCreate={false}
      />
    </div>
  )
}
