import { useState } from 'react'

import { Role } from '../models/admin'
import { LocaleKeys, TLocales } from '../pages/AdminList/country'
import {
  roleCreate,
  roleDetail,
  roleList,
  roleUpdate,
  roleUpdateBatch
} from '../services/KeycloakService'
import { shortUuid } from '../utilities/helpers'
import { useBetween } from 'use-between'

const EXCLUDED_NAME: string = 'uma_protection'

const useShareRolesPermissions = () => {
  const [rolePermissions, setRolePermissions] = useState<Role[]>([])
  const [edit, setEdit] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  return {
    open,
    setOpen,
    edit,
    setEdit,
    rolePermissions,
    setRolePermissions
  }
}

export const useRolesPermissions = () => useBetween(useShareRolesPermissions)

const useShareRoles = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [roles, setRoles] = useState<Role[] | []>([])
  const [maxOrder, setMaxOrder] = useState<number>(0)

  const onGetRoles = async () => {
    setLoading(true)

    let rolesList: any[] | Promise<any[]> = await roleList()
    if (!rolesList || !(rolesList instanceof Array)) {
      return
    }

    rolesList = rolesList
      ?.filter((role: any) => !role.name.includes(EXCLUDED_NAME))
      .map((r) => {
        const { attributes } = r
        if (!attributes) {
          r['attributes'] = {}
        }
        return r
      })

    let _roles: Role[] = []

    for (const role of rolesList) {
      const res = await roleDetail(role.id)
      const {
        // description,
        attributes: { permissions: attrPerms, order: attrOrder, ...localeRest }
      } = res

      for (const localeProp in localeRest) {
        if (LocaleKeys.includes(localeProp as TLocales)) {
          role[localeProp] = localeRest[localeProp][0]
        }
      }

      role['permissions'] = attrPerms ?? []
      if (attrOrder) {
        role['order'] = Number(attrOrder[0])
      } else {
        role['order'] = 0
      }

      // const { id, name, permissions, order } = role
      _roles.push({ ...role })
    }

    // sort roles by sort number
    _roles = _roles.sort((a: any, b: any) => a.order - b.order)

    const _maxOrder: number = Math.max(
      ...Array.from(_roles, (o: any) => Number(o.order))
    )

    setMaxOrder(_maxOrder)
    setRoles(_roles)
    setLoading(false)
  }

  const onAssignRolesPermissions = async (rolePermissions: any[]) => {
    setLoading(true)

    let roleData: any[] = []
    for (const role of rolePermissions) {
      const { id, name, description, permissions, order, ...localeRest } = role

      let localeNames: any = {}
      for (const localeProp in localeRest) {
        if (LocaleKeys.includes(localeProp as TLocales)) {
          localeNames[localeProp] = [localeRest[localeProp]]
        }
      }

      roleData.push({
        id,
        name,
        description,
        attributes: { order: [`${order}`], permissions, ...localeNames }
      })
    }

    for (let role of roleData) {
      setRoles((prevRoles) =>
        prevRoles.map((r) => {
          if (r.id === role.id) {
            return { ...r, permissions: role.attributes.permissions }
          }
          return r
        })
      )
    }

    await roleUpdateBatch(roleData)

    if (rolePermissions.length > 0) {
      onGetRoles().then()
    }
  }

  const onAddRole = async ({
    name,
    country
  }: {
    name: string
    country: string
  }) => {
    setLoading(true)
    const nameUid: string = shortUuid()
    await roleCreate(nameUid, { [country]: [name] })

    // assign nextOrder to new role
    let createdRole = await rolesFilter(nameUid)
    const roleData = {
      ...createdRole,
      attributes: {
        permissions: [],
        order: [`${maxOrder + 1}`],
        [country]: [name]
      }
    }
    await roleUpdate(nameUid, roleData)

    onGetRoles().then()

    setLoading(false)
  }

  const onUpdateRole = (role: any) => {
    const { id, ...roleRest } = role

    setRoles((prevRoles) =>
      prevRoles.map((r) => {
        if (r.id === id) {
          console.log({ ...r, ...roleRest })
          return { ...r, ...roleRest }
        }
        return r
      })
    )
  }

  const onUpdateRoleApi = async (name: string, role: any) => {
    setLoading(true)
    const { id, name: roleName, description, attributes } = role

    await roleUpdate(name, { id, name: roleName, description, attributes })
    onGetRoles().then()

    setLoading(false)
  }

  const rolesFilter = async (name: string) => {
    let rolesList = await roleList({ search: name })
    if (!rolesList || !(rolesList instanceof Array)) {
      return
    }

    return rolesList[0]
  }

  const onClearError = () => {
    setError('')
  }

  return {
    loading,
    setLoading,
    error,
    roles,
    setRoles,
    onGetRoles,
    onAddRole,
    onUpdateRole,
    onAssignRolesPermissions,
    onClearError
  }
}

export const useRoles = () => useBetween(useShareRoles)
