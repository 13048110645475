import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Typography } from 'antd'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm } from 'components/elements/InputForm'
import { InputLabel } from 'components/elements/InputLabel'
import { SelectCustom } from 'components/elements/Select'
import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onSuccess: () => void
}

export type DataForm = {
  full_name: string
  id_or_name: string
  email: string
  role: string
  affiliation: string
}

const schema = yup
  .object()
  .shape({
    full_name: yup.string().required(),
    id_or_name: yup.string().required(),
    email: yup.string().required(),
    role: yup.string().required(),
    affiliation: yup.string().required()
  })
  .required()

const CreateAdminModal = ({
  isModalOpen = false,
  onCancel,
  onSuccess
}: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //todo
  }

  const methods = useForm<DataForm>({
    defaultValues: {
      full_name: '',
      id_or_name: '',
      email: ''
    },
    resolver: yupResolver(schema)
  })

  const { handleSubmit } = methods

  const onSubmit = (data: DataForm) => {
    // TODO call api
    onCancel()
    onSuccess()
  }

  return (
    <CustomModal open={isModalOpen} onOk={handleOk} onCancel={onCancel}>
      <Flex vertical className="p-5 pt-4 text-center">
        <Typography className="text-[20px] text-[#545454] font-bold leading-none">
          {t('addAdministrator')}
        </Typography>
        <Paragraph className="tracking-[.7px] mt-[26px] mb-0">
          {t('content.addAdministrator')}
        </Paragraph>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-11 gap-3">
              <Col flex="148px">
                <InputLabel
                  label={t('lable.customerName')}
                  required
                  align="center"
                />
              </Col>
              <Col className="gap-[14px] flex flex-col w-full text-left">
                <Row>
                  <Col className="flex-1">
                    <Paragraph className="mb-2">
                      {t('notRegisterDoctor')}
                    </Paragraph>
                    <InputForm
                      name="full_name"
                      placeholder={t('placeholder.name')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="flex-1">
                    <Paragraph className="mb-2">
                      {t('registerDoctor')}
                    </Paragraph>
                    <InputForm
                      name="id_or_name"
                      placeholder={t('placeholder.id_or_name')}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-1.5 items-center gap-3 text-left">
              <Col flex="148px">
                <InputLabel
                  align="center"
                  className="pr-0"
                  label={
                    <>
                      {t('lable.mailAddress')} <br />
                      <Typography className="text-xs p-0">
                        {t('lable.id')}
                      </Typography>
                    </>
                  }
                  required
                />
              </Col>
              <Col flex="auto">
                <InputForm
                  name="email"
                  className="max-w-[400px]"
                  placeholder={''}
                />
              </Col>
            </Row>
            <Row className="mt-1.5 items-center gap-3 text-left">
              <Col flex="148px">
                <InputLabel label={t('role')} required />
              </Col>
              <Col flex="auto">
                <SelectCustom
                  name="role"
                  className="w-full h-[32px]"
                  placeholder={t('placeholder.pleaseSelect')}
                  options={[
                    { label: 'テキスト 1', value: 1 },
                    { label: 'テキスト 2', value: 2 },
                    { label: 'テキスト 3', value: 3 },
                    { label: 'テキスト 4', value: 4 }
                  ]}
                />
              </Col>
            </Row>
            <Row className="mt-1.5 items-center gap-3 text-left">
              <Col flex="148px">
                <InputLabel label={t('affiliation')} required />
              </Col>
              <Col flex="auto">
                <SelectCustom
                  name="affiliation"
                  className="w-full h-[32px]"
                  placeholder={t('placeholder.pleaseSelect')}
                  options={[
                    { label: 'テキスト 1', value: 1 },
                    { label: 'テキスト 2', value: 2 },
                    { label: 'テキスト 3', value: 3 },
                    { label: 'テキスト 4', value: 4 }
                  ]}
                />
              </Col>
            </Row>

            <Flex className="justify-center gap-[12px] mt-10">
              <Button
                type="primary"
                className="w-[180px] font-bold"
                htmlType="submit"
              >
                {t('button.registration')}
              </Button>
              <Button
                className="w-[180px] border-[#137695] text-[#137695] font-bold"
                onClick={onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </CustomModal>
  )
}

export default CreateAdminModal
