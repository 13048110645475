import { DocumentNode, gql } from '@apollo/client'

export interface variableProps {
  filter: string
  paginationInput?: paginationInput
}

export interface paginationInput {
  page: number
  size: number
}

export const FILTER_ALL: variableProps = {
  filter: ''
}

export const FILTER_TEST_ITEM_CATEGORY: DocumentNode = gql`
  query filterTestItemCategory(
    $filter: String!
    $page: Int!
    $size: Int!
    $sortBy: String
    ){
  filterTestItemCategory(page: $page, size: $size, filter: $filter, sortBy: $sortBy) {
    status
    message
    timestamp
    payload {
          refId
          status
          shortName
          displayName
          associatedTestItemMasterRefIds
          createdDate
          createdBy 
          }
      }
  }
`
