import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ResetPassword.module.scss";

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleResetPassword = () => {
    navigate("/password-reset-success");
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t("resetPassword")}</h3>
      <form action="" className={styles["form-submit"]}>
        <div className={styles["box-input"]}>
          <label htmlFor="new-password">{t("lable.newPassword")}</label>
          <input
            type="text"
            placeholder={t("placeholder.newPassword")}
            id="new-password"
          />
          <input
            type="text"
            placeholder={t("placeholder.confirmNewPassword")}
            id="input"
          />
        </div>
        <button
          type="submit"
          className={styles["btn-submit"]}
          onClick={handleResetPassword}
        >
          {t("passwordRegistration")}
        </button>
      </form>
    </div>
  );
}
