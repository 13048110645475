import React, { FC } from 'react'

import { Button, Flex, Typography } from 'antd'
import { TFunction } from 'i18next'

import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals/CustomModal'
import { IInterviewList } from 'models/surveyListFeedback'

type Props = {
  t: TFunction<'translation', undefined>
  isOpen: boolean
  isLoadingDelete: boolean
  dataInterview?: IInterviewList
  onDelete: () => void
  onCancel: () => void
}

const DeleteMedicalQuestion: FC<Props> = ({
  t,
  isOpen,
  isLoadingDelete,
  onDelete,
  onCancel,
  dataInterview
}) => {
  return (
    <CustomModal
      open={isOpen}
      onOk={() => {}}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="pb-6 pt-4">
          <div>
            <Typography className="flex justify-center text-[20px] font-bold tracking-[1px]">
              {t('customerDetail.deleteMedicalQuestion')}
            </Typography>
            <Flex justify="center" className="mb-[24px] mt-[76px]">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#DF2475] text-[30px] text-white">
                !
              </div>
            </Flex>
            <Typography className="flex justify-center tracking-[1px]">
              {t('customerDetail.modals.delete.isItOK')}
            </Typography>
            <Typography className="flex justify-center tracking-[1px] text-[#F5006B]">
              {t('customerDetail.modals.delete.canNotRestore')}
            </Typography>

            <div className="mt-[36px]">
              <Flex>
                <InputLabel
                  label={t('customerDetail.answerDate')}
                  className="h-[30px] w-[148px]"
                />
                <Flex align="center" className="ml-3">
                  <Typography className="mr-[52px]">
                    {dataInterview?.createdDateTime ?? ''}
                  </Typography>
                </Flex>
              </Flex>

              <Flex className="mt-2">
                <InputLabel
                  label={t('customerDetail.answerMethod')}
                  className="h-[30px] w-[148px]"
                />
                <Typography className="ml-3 flex items-center">
                  {dataInterview?.answerMethod ?? ''}
                </Typography>
              </Flex>
            </div>

            <Flex justify="center" className="mt-[85px]">
              <Button
                loading={isLoadingDelete}
                type="primary"
                htmlType="submit"
                size="large"
                onClick={onDelete}
                className="mr-3 h-[30px] w-[180px] rounded-none bg-[#DF2475] p-0 text-center text-[14px] font-bold text-white shadow-none"
              >
                {t('customerDetail.delete')}
              </Button>
              <Button
                type="default"
                htmlType="button"
                size="large"
                onClick={onCancel}
                className="mr-[8px] h-[30px] w-[180px] rounded-none border-[1px] border-[#DF2475] bg-white p-0 text-center text-[14px] font-bold text-[#DF2475] shadow-none"
              >
                {t('customerDetail.modals.delete.cancel')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default DeleteMedicalQuestion
