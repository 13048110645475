import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Popover, Typography } from 'antd'

import transferIcon from '../../assets/pngs/transferIcon.png'
import CategoryAndItemList from './CategoryList'
import CollapsibleList from './CollapsibleList'
import ItemSettingCategoryList from './components/ItemSettingCategoryList'
import NoteHiddenSettingModal from './components/NoteHiddenSettingModal'
import SettingSuccessModal from './components/SettingSuccessModal'
import { QuestionCircleFilled } from '@ant-design/icons'
import { ReactComponent as EyeNoHidden } from 'assets/svgs/eye-blue-37.svg'
import { ReactComponent as EyeHidden } from 'assets/svgs/eye-hidden14132.svg'
import { MEDICAL_CHECKUP_MASTER_EXAMPLE } from 'configs/api'
import { useGetMedicalCheckupMasterDetails } from 'hooks/useMedicalCheckupMaster'
import useTestItemCategory from 'hooks/useTestItemCategory'
import useTestItemMaster from 'hooks/useTestItemMaster'
import { getLanguage, mergeItemMaster } from 'utilities/helpers'

export default function InspectionItemSetting() {
  const { t } = useTranslation()
  let language = getLanguage()
  const { getMedicalCheckupMastersById } = useGetMedicalCheckupMasterDetails()
  const { getListItemCategory } = useTestItemCategory()
  const { getListItemMasterByMultipleLanguages } = useTestItemMaster()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showNoteHiddenSettingModal, setShowNoteHiddenSettingModal] =
    useState(false)
  const [isAddNewCategory, setIsAddNewCategory] = useState(false)
  const [isAddNewItemCategory, setIsAddNewItemCategory] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [categoryShow, setCategoryShow] = useState<any>(null)
  const [categoryHidden, setCategoryHidden] = useState<any>(null)
  const [showInspectionDescription, setShowInspectionDescription] =
    useState(true)

  const showModalSave = () => {
    setIsModalVisible(true)
  }

  const handleSaveSuccess = () => {
    setIsAddNewCategory(false)
    setIsAddNewItemCategory(false)
    setShowUpdate(false)
    setShowInspectionDescription(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      const dataMedicalCheckupMaster = await getMedicalCheckupMastersById(
        MEDICAL_CHECKUP_MASTER_EXAMPLE
      )

      const arrItemMasterRefId =
        dataMedicalCheckupMaster?.medicalCheckupMaster
          ?.associatedTestItemMasters ?? []
      let categoryOrder =
        dataMedicalCheckupMaster?.medicalCheckupMaster?.additionalInfo
          ?.categoryOrder ?? {}

      const dataCategoryFetch = await getListItemCategory({
        // filter: `(eq,STRING,status,PUBLISHED)`
        filter: `(in,STRING,refId,13215064603757287001726460393637,88665023344512015941726460435377,83216780091146409801726460484181,66863821368653047771726460525201,45797661172382888381726460564253,50303274715688017811726460592767,35872960772656501091726460641343,6157615577841498081726460663928,75393933929457064601726460690362,15761740032421589841726460728026,88765061765373168031726460770519,24127498591334101701726460803388,50069000893238963771726460828814,48850182716056353011726460846236);(eq,STRING,status,PUBLISHED)`, //hard code
        sortBy: '(desc,refId)'
      })

      let dataCategory =
        dataCategoryFetch?.data?.filterTestItemCategory?.payload

      dataCategory = mergeItemMaster(dataCategory)

      let dataItemMasterFetch: any = await getListItemMasterByMultipleLanguages(
        []
      )

      let dataItemMaster = dataItemMasterFetch.filter(function (item: any) {
        return item?.additionalInfo?.key1 === '検査項目マスター'
      })

      let itemsShow = JSON.parse(JSON.stringify(dataCategory))
      let itemsHidden = JSON.parse(JSON.stringify(dataCategory))

      for (let itemMaster of dataItemMaster) {
        if (arrItemMasterRefId.includes(itemMaster.refId)) {
          pushItemCategory(itemsShow, itemMaster)
          continue
        }

        pushItemCategory(itemsHidden, itemMaster)
      }

      itemsShow = filterDisplayData(itemsShow, dataItemMasterFetch)

      itemsShow = itemsShow
        .map((category: any) => {
          category.order = categoryOrder?.[category?.refId] ?? 9999
          return category
        })
        .sort((a: any, b: any) => a.order - b.order)

      setCategoryShow(itemsShow)

      itemsHidden = filterDisplayData(itemsHidden, dataItemMasterFetch)

      setCategoryHidden(itemsHidden)
    }
    fetchData()
  }, [])

  const filterDisplayData = (dataCategory: any, dataItemMaster: any) => {
    dataCategory = dataCategory.filter(function (item: any) {
      return item.items && item.items.length > 0
    })

    dataCategory = dataCategory.map(function (category: any) {
      let dataCommentCategory = dataItemMaster.filter(function (item: any) {
        return (
          category.associatedTestItemMasterRefIds.includes(item.refId) &&
          item?.additionalInfo?.key1 === 'カテゴリマスタ' &&
          item?.additionalInfo?.key3 !== '所見コメント'
        )
      })

      return {
        ...category,
        code: dataCommentCategory?.[0]?.code,
        lang: dataCommentCategory?.[0]?.lang
      }
    })

    return dataCategory
  }

  const pushItemCategory = (categories: any, itemMaster: any) => {
    for (let category of categories) {
      if (category.associatedTestItemMasterRefIds.includes(itemMaster.refId)) {
        if (category.items) {
          category.items.push(itemMaster)
          continue
        }

        category.items = [itemMaster]
      }
    }
  }

  return (
    <div className="ml-[-2px] pt-2">
      <Typography className="text-[16px] font-bold pl-2.5 leading-none">
        {t('preConfiguration')}
      </Typography>

      <Flex className="w-full mt-2 pl-[21px] justify-between">
        <Flex className="items-center">
          <Typography className="font-bold text-[#137695]">
            {t('inspectionItemRegistration')}
          </Typography>

          {/* <Input
            placeholder={t('placeholder.search')}
            prefix={<SearchOutlinedIcon className="w-4 mr-1" />}
            className="h-[30px] w-[400px] py-1 px-3 ml-[35px]"
          /> */}

          <Typography className="pl-[15px]">
            {t('displayEnglishName')}
          </Typography>

          {showInspectionDescription && (
            <EyeNoHidden
              className="w-[22px] ml-[5px]"
              onClick={() => setShowInspectionDescription((prev) => !prev)}
            />
          )}

          {!showInspectionDescription && (
            <EyeHidden
              className="w-[22px] ml-[5px]"
              onClick={() => setShowInspectionDescription((prev) => !prev)}
            />
          )}
          <Popover
            placement="bottom"
            content={
              <div className="p-2.5 w-[330px] bg-[#545454] text-white rounded">
                {t('popover.hideEnglish')}
              </div>
            }
            overlayClassName="popover-permission"
          >
            <QuestionCircleFilled className="w-[14px] ml-1.5 text-[#B4B4B4]" />
          </Popover>
        </Flex>

        <Flex className="gap-4 invisible">
          {!showUpdate && (
            <Button
              className="h-[30px] w-[120px] font-bold text-primary border border-primary"
              autoInsertSpace={false}
              onClick={() => setShowUpdate(true)}
            >
              {t('button.edit')}
            </Button>
          )}
          <Button
            type="primary"
            className="h-[30px] w-[120px] font-bold"
            onClick={showModalSave}
            autoInsertSpace={false}
          >
            {t('keepButton')}
          </Button>
        </Flex>
      </Flex>

      <Flex className="mt-[11px] w-full">
        <Flex className="w-[12.037%]">
          <ItemSettingCategoryList
            category={categoryShow}
            language={language}
          />
        </Flex>

        <div className="w-[41.045%]">
          <CollapsibleList
            showInspectionDescription={showInspectionDescription}
            showUpdate={showUpdate}
            setShowNoteHiddenSettingModal={setShowNoteHiddenSettingModal}
            category={categoryShow}
            language={language}
          />
        </div>

        <Flex className="flex-1">
          <img src={transferIcon} className="w-[78.9%] m-auto" />
        </Flex>

        <Flex className="w-[41.339%]">
          <CategoryAndItemList
            isAddNewCategory={isAddNewCategory}
            setIsAddNewCategory={setIsAddNewCategory}
            isAddNewItemCategory={isAddNewItemCategory}
            setIsAddNewItemCategory={setIsAddNewItemCategory}
            showInspectionDescription={showInspectionDescription}
            showUpdate={showUpdate}
            category={categoryHidden}
            language={language}
          />
        </Flex>
      </Flex>

      <SettingSuccessModal
        isModalOpen={isModalVisible}
        onSuccess={handleSaveSuccess}
        onCancel={handleCancel}
      />
      <NoteHiddenSettingModal
        isModalOpen={showNoteHiddenSettingModal}
        onCancel={() => setShowNoteHiddenSettingModal(false)}
        onSuccess={() => setShowNoteHiddenSettingModal(false)}
      />
    </div>
  )
}
