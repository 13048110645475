import i18n, { TFunction } from 'i18next'
import { v4 as uuidv4 } from 'uuid'

import {
  AND_CONJUNCTION,
  GENDER,
  STORAGE_TENANT_KEY,
  STORAGE_TOKEN_CLIENT_KEY,
  STORAGE_TOKEN_KEY
} from '../configs/constant'
import { format } from 'date-fns'
import { jwtDecode } from 'jwt-decode'
import short from 'short-uuid'

// bind url with params
export const bindParams = (str: string, params: any = {}) => {
  let result = str
  for (let key in params) {
    result = result.replace(new RegExp(`:${key}`, 'g'), params[key])
  }
  return result
}

export const isNumber = (value: any) => {
  return typeof value === 'number' && !isNaN(value)
}

export const getEnvVar = (envKey: string) => {
  const envVar = getAttr(window?.env, envKey)

  if (!envVar || envVar === '') {
    return getAttr(process.env, envKey)
  }

  return envVar
}

export const loadTenantList = () => {
  let tenant_list: any[] = []

  try {
    tenant_list = JSON.parse(getEnvVar('REACT_APP_API_TENANT_LIST'))
  } catch (e) {
    console.error('Invalid JSON string: ', e)
  }

  return tenant_list
}

export const findValidTenant = (name: string) => {
  return loadTenantList().find((tenant: any) => tenant?.tenant === name)
}

export const getCurrentDateFormatted = () => {
  const now = new Date()

  return format(now, "yyyy-MM-dd'T'HH:mm:ss")
}

export const groupByKey = (array: any[], key: string) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj)
    })
  }, {})
}

export const scrollToErrorField = ({ errorFields }: any) => {
  const el = document.getElementById(errorFields[0].name[0]) as HTMLElement
  el.scrollIntoView()
  window.scrollTo(0, window.scrollY - el.offsetHeight - 300)
}

export const str2json = (str: string) => {
  if (!str) return ''

  try {
    return JSON.parse(str)
  } catch (e) {
    console.error('Invalid JSON string:', e)
  }
}

export const json2str = (json: any) => {
  if (!json) return ''

  try {
    return JSON.stringify(json)
  } catch (e) {
    console.error('Invalid JSON string:', e)
  }
}

export const sortByObj = (sortBy: any) => {
  const sortObj: any = {}
  if (!sortBy) return sortObj

  const _sortBy = sortBy.split(AND_CONJUNCTION)
  for (const _sort of _sortBy) {
    const s = _sort.replace('(', '').replace(')', '').split(',')
    const key = s[1]
    sortObj[key] = s[0].toUpperCase()
  }

  return sortObj
}

export const isArrayEmpty = (array: any) => {
  return Array.isArray(array) && array.length === 0
}

export const getAttributes = (
  attributes: any,
  key: string,
  defaultValue: any = ''
) => {
  return attributes[key] ? attributes[key][0] : defaultValue
}

export const getAttr = (object: any, key: string, defaultValue: any = '') => {
  if (!object) {
    return defaultValue
  }

  return object[key] ? object[key] : defaultValue
}

export const resolvePath = (path: any, obj = {}, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev: any, curr: any) => prev?.[curr], obj)
}

export const validPerPage = (size: any) => {
  if (!size) return 100 // default size
  if (isNaN(size)) return 0
  if (size <= 0) return 1
  if (size > 500) return 500

  return size
}

export const validPage = (page: any) => {
  if (!page) return 0 // default page
  if (isNaN(page)) return 0
  if (page < 0) return 0
  if (page > 500) return 500

  return page
}

export default function isArrayEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1 === arr2) return true

  if (!arr1 || !arr2 || (typeof arr1 !== 'object' && typeof arr2 !== 'object'))
    return arr1 === arr2

  // This means the elements are objects
  // If they are not the same type of objects
  // @ts-expect-error required
  if (arr1.prototype !== arr2.prototype) return false

  const keys = Object.keys(arr1)
  if (keys.length !== Object.keys(arr2).length) return false

  // Check recursively for every key in both
  return keys.every((k: any) => isArrayEqual(arr1[k], arr2[k]))
}

export const uuid = (dashes = true) => {
  if (!dashes) return uuidv4().replace(/-/gi, '')
  return uuidv4()
}

export const shortUuid = () => {
  return short.generate()
}

export const isObjectEmpty = (objectName: any = {}) => {
  return Object.keys(objectName).length === 0
}

export const getValidToken = (token: string) => {
  if (token === '') return null
  let decodedToken = jwtDecode(token)
  let exp = decodedToken?.exp ?? 0
  let currentDate = new Date()

  // JWT exp is in seconds
  if (exp * 1000 < currentDate.getTime()) {
    console.error('Token expired.')
    return null
  }

  return token
}

export const decodedToken = (
  token: string | null | undefined,
  key: string = 'sub'
): string => {
  if (!token || token === '' || token === 'undefined') return ''
  let _decodedToken: any
  try {
    _decodedToken = jwtDecode(token)
  } catch (e) {
    removeAccessToken()
  }

  return _decodedToken && _decodedToken[key]
    ? (_decodedToken[key] as string)
    : ''
}

// sleep time expects milliseconds
export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const forceTypeBoolean = (
  value: string | null | boolean
): boolean | null => {
  if (typeof value === 'boolean') {
    return value
  }

  if (typeof value === 'string') {
    return value === 'true' || value === '1'
  }

  return Boolean(value)
}

export const isTruthy = (value: string | null | boolean): boolean => {
  value = forceTypeBoolean(value)
  return !!value
}

export const isLoggedIn = () => {
  return !!getValidToken(getAccessToken() || '')
}

export const getAccessToken = () => {
  return localStorage.getItem(STORAGE_TOKEN_KEY)
}
export const getTenantLocal = () => {
  const jsonValue = localStorage.getItem(STORAGE_TENANT_KEY)
  if (jsonValue != null) return JSON.parse(jsonValue)
  return {}
}

export const getAccessTokenClient = () => {
  return localStorage.getItem(STORAGE_TOKEN_CLIENT_KEY)
}

export const clearLocalStorage = () => {
  return localStorage.clear()
}

export const removeAccessToken = () => {
  return localStorage.removeItem(STORAGE_TOKEN_KEY)
}

export const removeClientAccessToken = () => {
  return localStorage.removeItem(STORAGE_TOKEN_CLIENT_KEY)
}

export const setAccessToken = (payload: { access_token: string }) => {
  localStorage.setItem(STORAGE_TOKEN_KEY, payload.access_token)
}

export const setClientAccessToken = (access_token: string) => {
  localStorage.setItem(STORAGE_TOKEN_CLIENT_KEY, access_token)
}

export const getUserByToken = () => {
  const token = getAccessToken()
  return token ? jwtDecode(token) : null
}

export const mergeItemMasterByLanguage = (data: any) => {
  return Object.values(
    data.reduce((acc: any, obj: any) => {
      const refId = obj.refId
      const isEnglish = obj.additionalInfo && obj.additionalInfo.key2 === 'en'

      if (!acc[refId]) {
        acc[refId] = { ...obj }
        if (isEnglish) {
          acc[refId].displayNameEn = obj.displayName
          acc[refId].displayNameEnCreatedDate = obj.createdDate
          acc[refId].createdDate = null
        }
      } else {
        if (isEnglish) {
          if (
            !acc[refId].displayNameEnCreatedDate ||
            acc[refId].displayNameEnCreatedDate < obj.createdDate
          ) {
            acc[refId].displayNameEn = obj.displayName
            acc[refId].displayNameEnCreatedDate = obj.createdDate
          }
        } else {
          if (
            !acc[refId].createdDate ||
            acc[refId].createdDate < obj.createdDate
          ) {
            acc[refId] = { ...acc[refId], ...obj }
          }
        }
      }

      return acc
    }, {})
  )
}

export const mergeItemMaster = (data: any) => {
  return Object.values(
    data.reduce((acc: any, obj: any) => {
      if (!acc[obj.refId] || acc[obj.refId].createdDate < obj.createdDate) {
        acc[obj.refId] = obj
      }
      return acc
    }, {})
  )
}

export const filterNewItemList = (
  data: any,
  key: any,
  compare: any,
  dataByKey = false
) => {
  let result = data.reduce((acc: any, obj: any) => {
    if (!acc[obj[key]] || acc[obj[key]][compare] < obj[compare]) {
      acc[obj[key]] = obj
    }
    return acc
  }, {})

  return dataByKey ? result : Object.values(result)
}

export const preventEnterKeyDefault = (event: any) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    event.stopPropagation()
  }
}

export const getObjectByIdItemMaster = (category: any, gender: any, t: any) => {
  let result: any = {}
  if (!Array.isArray(category)) return {}

  for (let dataCategory of category) {
    result[dataCategory.idItemMaster] = dataCategory.evaluation
    if (Array.isArray(dataCategory?.itemMaster)) {
      for (let item of dataCategory.itemMaster) {
        result[item.refId] = item.value
        let referenceValue = getReferenceValue(item, gender, t)

        result[item.refId + '_referenceValues'] = referenceValue
      }
    }
  }

  return result
}

export const getValueCategoryWithMedicalCheckup = (
  medicalCheckupId: any,
  dataCategory: any,
  testResults: any
) => {
  let arrCategory = JSON.parse(JSON.stringify(dataCategory))
  let result: any = []

  for (let category of arrCategory) {
    let testResultCategory = testResults.find(
      (item: any) =>
        medicalCheckupId === item?.medicalCheckupRefId &&
        category?.idItemMaster === item?.itemMasterRefId
    )
    category.value = testResultCategory?.value
    category.evaluation = testResultCategory?.evaluation

    // items master
    let itemMasters = category?.itemMaster
    for (let itemMaster of itemMasters) {
      let testResultItemMaster = testResults.find(
        (item: any) =>
          medicalCheckupId === item?.medicalCheckupRefId &&
          itemMaster?.refId === item?.itemMasterRefId
      )
      itemMaster.value = testResultItemMaster?.value
    }
    category.itemMaster = itemMasters

    result.push(category)
  }

  return result
}

const getReferenceValue = (
  itemMaster: any,
  gender: any = null,
  t: any,
  value: any = 'A'
) => {
  let dataConstraints = itemMaster.referenceValues.filter((itemValue: any) => {
    return (
      itemValue?.constraints &&
      (itemValue?.constraints?.gender.includes(GENDER.MALE) ||
        itemValue?.constraints?.gender.includes(GENDER.FEMALE))
    )
  })

  let data = itemMaster.referenceValues.find(function (item: any) {
    if (gender && dataConstraints.length > 0)
      return (
        item.evaluation === value && item?.constraints?.gender.includes(gender)
      )

    return item.evaluation === value
  })

  if (!data) {
    return ''
  }

  let readingType = itemMaster.readingType

  if (readingType === 'STRING') return data?.textValue ?? ''

  return handleReferenceValue(data, t)
}

export const checkTestResultValue = (
  testResult: any,
  itemMaster: any,
  customer: any
) => {
  let dataConstraints = itemMaster.referenceValues.filter((itemValue: any) => {
    return (
      itemValue?.constraints &&
      (itemValue?.constraints?.gender.includes(GENDER.MALE) ||
        itemValue?.constraints?.gender.includes(GENDER.FEMALE))
    )
  })

  let data = itemMaster.referenceValues.find(function (item: any) {
    if (customer?.gender && dataConstraints.length > 0)
      return (
        item.evaluation === 'A' &&
        item?.constraints?.gender.includes(customer?.gender)
      )

    return item.evaluation === 'A'
  })

  if (!data) return true

  let readingType = itemMaster.readingType

  if (readingType === 'STRING') return data?.textValue === testResult?.value

  return !(
    testResult.value < data?.lowerLimit || testResult.value > data?.upperLimit
  )
}

export const getTenantAddress = (tenant: any) => {
  let zipCode = tenant?.additionalInfo?.zipCode
  let zipCodePart1 = zipCode ? zipCode.slice(0, 3) : ''
  let zipCodePart2 = zipCode ? zipCode.slice(3, 7) : ''

  return `〒${zipCodePart1}-${zipCodePart2}${tenant?.additionalInfo?.province ?? ''}${tenant?.additionalInfo?.district ?? ''}${tenant?.additionalInfo?.address ?? ''}`
}

export const getStrKeyByData = (data: Array<any>, key: string) => {
  let result = data.reduce((acc: any, item: any) => {
    if (item?.[key]) {
      acc = acc === '' ? acc + item?.[key] : acc + ',' + item?.[key]
    }

    return acc
  }, '')

  return result
}

export const getLanguage = () => {
  return i18n.language
}

export const formatInternationalPhone = (
  countryCode: any,
  phoneNumber: any
) => {
  if (!countryCode || !phoneNumber) return ''

  const dialCode = countryCode.split('-')[0]

  const formattedPhone = phoneNumber.startsWith('0')
    ? phoneNumber.slice(1)
    : phoneNumber

  return `+${dialCode}${formattedPhone}`
}

export const handleReferenceValue = (referenceValue: any, t: any) => {
  if (referenceValue?.lowerLimit == 0)
    return referenceValue?.upperLimit + t('lable.comeDown')

  if (referenceValue?.upperLimit == 9999)
    return referenceValue?.lowerLimit + t('lable.above')

  return referenceValue?.lowerLimit + '-' + referenceValue?.upperLimit
}

export const createProvinceList = (t: TFunction<'translation', undefined>) => {
  return [
    { value: '', label: t('questionnaire.placeholder.pleaseSelect') },
    { value: '北海道', label: t('prefectures.hokkaido') },
    { value: '青森県', label: t('prefectures.aomori') },
    { value: '岩手県', label: t('prefectures.iwate') },
    { value: '宮城県', label: t('prefectures.miyagi') },
    { value: '秋田県', label: t('prefectures.akita') },
    { value: '山形県', label: t('prefectures.yamagata') },
    { value: '福島県', label: t('prefectures.fukushima') },
    { value: '茨城県', label: t('prefectures.ibaraki') },
    { value: '栃木県', label: t('prefectures.tochigi') },
    { value: '群馬県', label: t('prefectures.gunma') },
    { value: '埼玉県', label: t('prefectures.saitama') },
    { value: '千葉県', label: t('prefectures.chiba') },
    { value: '東京都', label: t('prefectures.tokyo') },
    { value: '神奈川県', label: t('prefectures.kanagawa') },
    { value: '新潟県', label: t('prefectures.niigata') },
    { value: '富山県', label: t('prefectures.toyama') },
    { value: '石川県', label: t('prefectures.ishikawa') },
    { value: '福井県', label: t('prefectures.fukui') },
    { value: '山梨県', label: t('prefectures.yamanashi') },
    { value: '長野県', label: t('prefectures.nagano') },
    { value: '岐阜県', label: t('prefectures.gifu') },
    { value: '静岡県', label: t('prefectures.shizuoka') },
    { value: '愛知県', label: t('prefectures.aichi') },
    { value: '三重県', label: t('prefectures.mie') },
    { value: '滋賀県', label: t('prefectures.shiga') },
    { value: '京都府', label: t('prefectures.kyoto') },
    { value: '大阪府', label: t('prefectures.osaka') },
    { value: '兵庫県', label: t('prefectures.hyogo') },
    { value: '奈良県', label: t('prefectures.nara') },
    { value: '和歌山県', label: t('prefectures.wakayama') },
    { value: '鳥取県', label: t('prefectures.tottori') },
    { value: '島根県', label: t('prefectures.shimane') },
    { value: '岡山県', label: t('prefectures.okayama') },
    { value: '広島県', label: t('prefectures.hiroshima') },
    { value: '山口県', label: t('prefectures.yamaguchi') },
    { value: '徳島県', label: t('prefectures.tokushima') },
    { value: '香川県', label: t('prefectures.kagawa') },
    { value: '愛媛県', label: t('prefectures.ehime') },
    { value: '高知県', label: t('prefectures.kochi') },
    { value: '福岡県', label: t('prefectures.fukuoka') },
    { value: '佐賀県', label: t('prefectures.saga') },
    { value: '長崎県', label: t('prefectures.nagasaki') },
    { value: '熊本県', label: t('prefectures.kumamoto') },
    { value: '大分県', label: t('prefectures.oita') },
    { value: '宮崎県', label: t('prefectures.miyazaki') },
    { value: '鹿児島県', label: t('prefectures.kagoshima') },
    { value: '沖縄県', label: t('prefectures.okinawa') }
  ]
}

export const findProvinceLabel = (
  key: string | null | undefined,
  list: { value: string; label: string }[]
): string => {
  if (!key) return ''

  const province = list.find((item) => item.value === key)
  return province ? province.label : ''
}
