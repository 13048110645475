import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Flex, Tabs } from 'antd'
import { Typography } from 'antd'

import InspectionResultCreate from './components/InspectionResultCreate'
import SurveyFeedbackCreate from './components/SurveyFeedbackCreate'

const InspectionResultCreateTab: string = '1'
const SurveyFeedbackCreateTab: string = '2'

export default function InterviewResultCreate() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const [tab, setTab] = useState<string>(() => {
    const t = searchParams.get('t')
    return t === SurveyFeedbackCreateTab || t === InspectionResultCreateTab
      ? t
      : InspectionResultCreateTab
  })

  const dataTabs = [
    {
      key: InspectionResultCreateTab,
      label: (
        <Typography
          className="w-[120px] text-center text-[14px] font-bold"
          style={{
            color: tab === InspectionResultCreateTab ? '#137695' : '#545454'
          }}
        >
          {t('lable.inspectionResult')}
        </Typography>
      ),
      children: <InspectionResultCreate />
    },
    {
      key: SurveyFeedbackCreateTab,
      label: (
        <Typography
          className="w-[120px] text-center text-[14px] font-bold"
          style={{
            color: tab === SurveyFeedbackCreateTab ? '#137695' : '#545454'
          }}
        >
          {t('lable.questionnaire')}
        </Typography>
      ),
      children: <SurveyFeedbackCreate />
    }
  ]

  return (
    <Flex vertical className="py-4.5">
      <Typography.Title
        level={2}
        className="tracking-widest text-base font-bold"
      >
        {t('interviewResultCreate')}
      </Typography.Title>
      <Flex
        vertical
        className="bg-white p-2.5 shadow-custom rounded-[5px] h-auto text-sm text-default"
      >
        <Tabs
          size="small"
          defaultActiveKey={tab}
          activeKey={tab}
          items={dataTabs}
          className="customer-detail-tab tab-inspection-result-create"
          onChange={(newTab) => {
            setTab(newTab)
            const newParams = new URLSearchParams(searchParams)
            newParams.set('t', newTab)
            setSearchParams(newParams)
          }}
        />
      </Flex>
    </Flex>
  )
}
