import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Tabs, Typography } from 'antd'

import styles from './JudgementSetting.module.scss'
import CommentSettings from './components/CommentSettings'
import JudgmentSettings from './components/JudgmentSettings'
import ScreenLoader from 'components/loading/ScreenLoader'

export default function JudgementSetting() {
  const { t } = useTranslation()
  const [tab, setTab] = useState<string>('1')
  const [loading, setLoading] = useState<boolean>(false)
  const dataTabs = [
    {
      key: '1',
      label: t('menu.judgementSetting'),
      children: <JudgmentSettings />
    },
    {
      key: '2',
      label: t('judgementSetting.commentSetting'),
      children: <CommentSettings setLoadingScreen={setLoading} />
    }
  ]
  return (
    <div className="min-w-[1700px] relative">
      {loading && <ScreenLoader />}
      <Typography className="text-[#545454] text-[16px] font-bold mb-4">
        {t('basicSetting.basicSettingTitle')}
      </Typography>
      <Flex vertical className={styles.judgement_content}>
        <Tabs
          defaultActiveKey="1"
          items={dataTabs}
          className="tab-admin-list mt-[21px]"
          onChange={(tab) => setTab(tab)}
        />
      </Flex>
    </div>
  )
}
