import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Col, Flex, Typography } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { ReactComponent as FemaleIcon } from 'assets/svgs/female.svg'
import { ReactComponent as MaleIcon } from 'assets/svgs/male.svg'
import { ReactComponent as PenEditIcon } from 'assets/svgs/pen.svg'
import { ReactComponent as SaveIcon } from 'assets/svgs/save.svg'
import {
  InputCustom,
  InputForm,
  InputNumberCustom
} from 'components/elements/InputForm'
import { SelectCustom } from 'components/elements/Select'
import { GENDER, VALUE_WITH_NO_DATA } from 'configs/constant'
import useAutoDiagnostics from 'hooks/useAutoDiagnostics'
import useClickOutSide from 'hooks/useClickOutSide'
import useDebounce from 'hooks/useDebounce'
import {
  handleReferenceValue,
  isObjectEmpty,
  resolvePath
} from 'utilities/helpers'

const { Paragraph } = Typography

type Props = {
  data: any
  setValue: any
  triggerValidation: any
  dataTestResult: any
  handleChangeRatingValue: any
  user: any
  language: any
}

const Comment = ({
  setValue,
  name,
  dropdownLabel = '',
  options = [],
  changeComment
}: {
  setValue: any
  name: any
  dropdownLabel: string
  options: any
  changeComment: any
}) => {
  const { show, setShow, nodeRef } = useClickOutSide()
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const [label, setLabel] = useState(dropdownLabel)
  const [labelChange, setLabelChange] = useState<any>(null)
  const [edit, setEdit] = useState<boolean>(false)
  const dropdownValue = useWatch({
    control,
    name: name
  })

  const handleClickDropdownItem = (e: any) => {
    setLabel(e.target.textContent)
    setValue(name, e.target.textContent)
    setShow(false)
    setLabelChange(null)
  }

  const handleLabelChange = (e: any) => {
    setLabelChange(e.target.value)
  }

  const handleSave = () => {
    if (labelChange !== null) {
      setLabel(labelChange)
      setValue(name, labelChange)
    }
    setEdit(false)
  }

  useEffect(() => {
    setLabel(dropdownValue)
  }, [dropdownValue])

  useEffect(() => {
    if (changeComment) {
      let value = options && options.length > 0 ? options?.[0]?.label : ''

      setLabel(value)
      setValue(name, value)
      setLabelChange(null)
    }
  }, [options])

  return (
    <div className="relative" ref={nodeRef}>
      <div
        className={`border ${errors?.[name] || resolvePath(name, errors) ? 'border-error' : 'border-gray-100'} bg-white flex items-center pl-[7px] pr-2.5 min-h-[32px]`}
      >
        <Flex className="items-center w-full gap-2">
          <Flex className="items-center justify-between flex-1 gap-3">
            {!edit && (
              <Typography className="min-h-[14px] flex-1 whitespace-pre-wrap">
                {label}
              </Typography>
            )}
            {edit && (
              <TextArea
                className="w-full reset-outline px-0 flex-1"
                value={labelChange ?? label}
                autoSize={{ minRows: 1 }}
                onChange={handleLabelChange}
              />
            )}
            {!edit && (
              <PenEditIcon
                className="w-[15px] cursor-pointer"
                onClick={() => setEdit(true)}
              />
            )}
            {edit && (
              <SaveIcon
                className="w-[17px] cursor-pointer"
                onClick={handleSave}
              />
            )}
            <InputForm name={name} className="hidden" />
          </Flex>
          <CaretDownOutlined
            color="#137695"
            className="text-[#545454] text-[16px]"
            onClick={() => setShow(!show)}
          />
        </Flex>
      </div>
      <div
        className={`absolute max-h-[100px] overflow-y-auto top-full left-0 w-full bg-white z-10 ${
          show ? '' : 'hidden'
        }`}
      >
        {options.map((item: any, index: any) => (
          <div
            key={index}
            className="py-2 pl-[7px] pr-2.5 cursor-pointer hover:bg-gray-100 leading-none"
            onClick={handleClickDropdownItem}
            data-value={item.value}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default function InspectionResultByType({
  data,
  setValue,
  triggerValidation,
  dataTestResult,
  handleChangeRatingValue,
  user,
  language
}: Props) {
  const { watch, getValues } = useFormContext()
  const judgementTitle = watch(data.refId)

  const [formValues, setFormValues] = useState<any>({})
  const [categoryRefId, setCategoryRefId] = useState<any>(null)
  const [itemMasterRefIds, setItemMasterRefIds] = useState<any>([])
  const [comment, setComment] = useState<any>([])
  const [changeCommentCategory, setChangeCommentCategory] = useState<any>(false)
  const { t } = useTranslation()

  useEffect(() => {
    const comment = data.comment
      .map(function (item: any) {
        if (!judgementTitle || !judgementTitle.trim()) return null
        if (item?.additionalInfo?.judgementTitle !== judgementTitle) {
          return null
        }
        return {
          value: item?.additionalInfo?.judgementComment,
          label: item?.additionalInfo?.judgementComment
        }
      })
      .filter((item: any) => item !== null)

    setComment(comment)
  }, [judgementTitle])
  const { handleAutomaticDiagnostic } = useAutoDiagnostics()
  const formValuesDebounce = useDebounce(formValues)

  function getInput(item: any) {
    if (item.readingType === 'STRING') {
      let options = []
      let optionsCheck: any = []
      for (let data of item.referenceValues) {
        if (data?.textValue && !optionsCheck.includes(data.textValue)) {
          options.push({
            label: data?.textValue,
            value: data?.textValue
          })

          optionsCheck.push(data?.textValue)
        }
      }

      if (options.length < 1) {
        return (
          <InputCustom
            name={item.refId}
            className="w-[29.4%] mr-5"
            value={formValues[item.refId]}
          />
        )
      }

      if (getValues(item.refId)) {
        options.unshift({
          label: '',
          value: ''
        })
      }

      return (
        <SelectCustom
          name={item.refId}
          className="w-[29.4%] mr-5"
          onChange={handleInputChange}
          options={options}
        />
      )
    }
    return (
      <InputNumberCustom
        type="number"
        name={item.refId}
        className="w-[29.4%] mr-5"
        onChange={item.referenceValues.length > 1 ? handleInputChange : null}
        step={0.1}
        value={formValues[item.refId]}
        min={0}
      />
    )
  }

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value
    }))
  }

  useEffect(() => {
    const runDiagnostics = async () => {
      try {
        if (!formValuesDebounce || Object.keys(formValuesDebounce).length === 0)
          return

        for (let x of itemMasterRefIds) {
          if (!formValuesDebounce?.[x]) {
            return
          }
        }

        const dataDiagnostic = data.itemMaster
          .map((item: any) => {
            if (!itemMasterRefIds.includes(item.refId)) return null

            return {
              refId: item.refId,
              displayName: item?.displayName,
              value:
                item?.readingType === 'NUMERICAL'
                  ? parseFloat(formValues?.[item.refId])
                  : formValues?.[item.refId]
            }
          })
          .filter((item: any) => item !== null)

        const handleDiagnostic = await handleAutomaticDiagnostic({
          customer: { gender: user?.gender ?? GENDER.MALE },
          payload: [
            {
              refId: data.refId,
              items: dataDiagnostic
            }
          ]
        })

        const valueDiagnostic = handleDiagnostic?.payload?.[0]?.evaluation?.[0]
        if (valueDiagnostic) {
          if (!changeCommentCategory) setChangeCommentCategory(true)

          setValue(categoryRefId, valueDiagnostic)
          handleChangeRatingValue(categoryRefId, valueDiagnostic)

          triggerValidation(categoryRefId)
        }
      } catch (err) {
        console.error(err)
      }
    }

    runDiagnostics()
  }, [formValuesDebounce])

  useEffect(() => {
    if (data?.itemMaster && data.itemMaster.length > 0) {
      const listItemMasterRefIds = data.itemMaster
        .map((item: any) => {
          if (item.referenceValues.length < 1) return null
          return item.refId
        })
        .filter((item: any) => item !== null)

      setItemMasterRefIds(listItemMasterRefIds)
    }

    if (data?.refId) setCategoryRefId(data.refId)
  }, [data])

  useEffect(() => {
    if (dataTestResult && itemMasterRefIds && itemMasterRefIds.length > 0) {
      let updatedValues = formValues
      for (let name of itemMasterRefIds) {
        updatedValues[name] = dataTestResult?.[name]?.value
      }
      setFormValues(updatedValues)
    }
  }, [dataTestResult, itemMasterRefIds])

  function checkReferenceValue(item: any) {
    if (
      !item?.referenceValues ||
      item.referenceValues.length < 1 ||
      item?.readingType !== 'NUMERICAL'
    )
      return null

    let showData = item.referenceValues.filter((item: any) => {
      return item?.evaluation === 'A'
    })

    let dataConstraints = showData.filter((item: any) => {
      return (
        item?.constraints &&
        item?.constraints?.gender.includes(user?.gender ?? GENDER.MALE)
      )
    })

    if (dataConstraints && dataConstraints.length > 0) {
      let isMale = isObjectEmpty(user) || user?.gender === GENDER.MALE
      return (
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            {dataConstraints.map((item: any) => (
              <Paragraph className="font-normal mb-0" key={item.refId}>
                {handleReferenceValue(item, t)}
              </Paragraph>
            ))}
          </div>

          {isMale && <MaleIcon className="w-[22px] mr-4" />}
          {!isMale && <FemaleIcon className="w-[22px] mr-4" />}
        </div>
      )
    }

    return (
      <div className="flex flex-col gap-1">
        {showData.map((item: any) => (
          <Paragraph className="font-normal mb-0" key={item.refId}>
            {handleReferenceValue(item, t)}
          </Paragraph>
        ))}
      </div>
    )
  }

  return (
    <div>
      <Flex
        className="bg-[#eff3f6] pb-3 pt-3.5 pl-1.5 pr-5 font-bold leading-none items-center"
        id={data.refId}
      >
        <Col flex="16.9%">
          <Typography>
            {data?.lang?.[language] ?? VALUE_WITH_NO_DATA}
          </Typography>
          <Paragraph className="font-normal mb-0">
            {data?.lang?.en ?? VALUE_WITH_NO_DATA}
          </Paragraph>
        </Col>
        <Col flex="12.4%" className="text-right pr-3">
          {t('testResult.evaluation')}
        </Col>
        <Col flex="auto" className="flex items-center">
          <InputForm
            name={data.refId}
            className="w-[29.4%] mr-[26px]"
            onInput={(e: any) => {
              if (!changeCommentCategory) setChangeCommentCategory(true)

              handleChangeRatingValue(data.refId, e?.target?.value)
            }}
          />
          <Typography className="whitespace-nowrap">
            {t('testResult.comment')}
          </Typography>
          <div className="flex-1 ml-3">
            <Comment
              name={`${data.refId}_comment`}
              options={comment}
              dropdownLabel=""
              setValue={setValue}
              changeComment={changeCommentCategory}
            />
          </div>
        </Col>
      </Flex>
      {/* {renderData(data)} */}
      {data?.itemMaster &&
        data.itemMaster.map((item: any) => (
          <Flex
            className="py-3 pl-1.5 pr-5 font-bold leading-none items-center"
            key={item.refId}
          >
            <Col flex="16.9%">
              <Typography>
                {item?.lang?.[language] ?? VALUE_WITH_NO_DATA}
              </Typography>
              <Paragraph className="font-normal mb-0">
                {item?.lang?.en ?? VALUE_WITH_NO_DATA}
              </Paragraph>
            </Col>
            <Col flex="12.4%" className="font-normal">
              {checkReferenceValue(item)}
            </Col>
            <Col flex="auto" className="flex items-end">
              {getInput(item)}
              <Typography className="whitespace-nowrap">
                {item?.unitOfMeasure !== 'uom' ? item?.unitOfMeasure : ''}
              </Typography>
            </Col>
          </Flex>
        ))}
    </div>
  )
}
