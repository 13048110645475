import { useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

// import SubgroupCreateModal from '../../components/screens/BasicSetting/components/SubgroupCreateModal'
import { SubTenantEditModal } from '../../components/screens/BasicSetting/components/SubTenantEditModal'
import { openNotification } from '../../components/widgets/Notification'
import { useTenantList } from '../../hooks/tenant/useTenantList'
import {
  parseDataUpdate,
  parseFormUpdate,
  useTenantUpdate
} from '../../hooks/tenant/useTenantUpdate'
import { useGroupModal } from '../../hooks/useUserGroup'
import {
  COUNTRY,
  DEFAULT_SPECIFIED_DAY_TIMES,
  WORK_SCHEDULE_TYPE
} from '../../models/setting'
import { groupByKey } from '../../utilities/helpers'
import {
  BasicSettingForm,
  SubTenantForm
} from 'components/screens/BasicSetting'
import SaveSuccessModal from 'components/screens/BasicSetting/components/SaveSuccessModal'

const tenantInitial = {
  name: '',
  kanaName: '',
  clinicName: '',
  clinicKanaName: '',
  zipCode: '',
  country: COUNTRY.JAPAN,
  province: '',
  district: '',
  address: '',
  apartment: '',
  stateName: '',
  workScheduleType: WORK_SCHEDULE_TYPE.fixedDay,
  workdays: [],
  fixedDayStartTime: '09:00',
  fixedDayEndTime: '20:00',
  specifiedDayTimes: DEFAULT_SPECIFIED_DAY_TIMES,
  subTenantList: []
}

export default function BasicSetting() {
  const { t } = useTranslation()

  const { tenant } = useTenantList()
  const { handleUpdate } = useTenantUpdate()
  const { setShowEditModal, setIsModeEdit, setSelectedIndex, showEditModal } =
    useGroupModal()
  const [tenantForm, setTenantForm] = useState<any>(tenantInitial)

  // const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState(false)

  const methods = useForm<any>({
    defaultValues: tenantForm
  })

  const { control } = methods
  const { fields: subTenantList, update: updateSubTenant } = useFieldArray({
    control,
    name: 'subTenantList'
  })

  useEffect(() => {
    if (tenant) {
      const data = parseFormUpdate(tenant)
      setTenantForm(data)
      methods.reset(data)
    }
  }, [tenant])

  const onSubmit = async (data: any) => {
    // validate specifiedDayTimes
    const { specifiedDayTimes } = data
    const activatedDayTimes = specifiedDayTimes.filter((d: any) => d.active)
    for (let activatedDay of activatedDayTimes) {
      if (!activatedDay.biweekly) continue
      const weeks = groupByKey(activatedDay.biweekly, 'week')

      for (const duplicateWeek of Object.values(weeks)) {
        if (
          duplicateWeek &&
          Array.isArray(duplicateWeek) &&
          duplicateWeek.length > 1
        ) {
          console.log(duplicateWeek)
          openNotification({
            type: 'error',
            title: t('commonError'),
            message: t('errors.duplicateBiweeklySettings')
          })
          return
        }
      }
    }

    // console.log('formData', { ...data, subTenantList: subTenantList })
    const tenantData = parseDataUpdate(tenant, {
      ...data,
      subTenantList: subTenantList
    })

    const response: any = await handleUpdate(tenantData)
    if (!response) {
      return
    }

    setIsShowModal(true)
  }

  return (
    <Flex vertical gap={6} className="min-w-[1150px]">
      <Typography className="text-[#545454] text-[16px] font-bold">
        {t('basicSetting.basicSettingTitle')}
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex
            vertical
            className="bg-white rounded-[5px] shadow-md shadow-[#00000029] p-[10px] pb-10 gap-[44px]"
          >
            <Flex vertical gap={16}>
              <Typography className="text-[14px] text-primary font-bold">
                {t('basicSetting.basicSetting')}
              </Typography>
              <Typography className="text-[14px]">
                {t('basicSetting.basicSettingNote')}
              </Typography>

              <BasicSettingForm />
            </Flex>
          </Flex>
        </form>
      </FormProvider>

      <div className="mt-2 bg-[#fff] rounded-[5px] p-2 shadow-md shadow-[#00000029]">
        <Flex vertical gap={8}>
          <Typography className="text-[14px] text-primary font-bold">
            {t('basicSetting.contactSetting')}
          </Typography>

          <Flex justify="space-between" className="mt-2">
            <Typography className="text-[14px]">
              {t('basicSetting.contactSettingNote')}
            </Typography>
            <Button
              type="primary"
              size="middle"
              className="min-w-[100px] font-bold"
              onClick={() => {
                setSelectedIndex(undefined)
                setShowEditModal(true)
                setIsModeEdit(false)
              }}
            >
              {t('button.register')}
            </Button>
          </Flex>

          <div className="border-t-[1px] border-[#f0f0f0]">
            <SubTenantForm
              update={updateSubTenant}
              subTenantList={subTenantList}
            />
          </div>
        </Flex>
      </div>

      {showEditModal && (
        <SubTenantEditModal
          subTenantList={subTenantList}
          update={updateSubTenant}
          onCancel={() => setShowEditModal(false)}
        />
      )}

      {isShowModal && (
        <SaveSuccessModal
          onCancel={() => setIsShowModal(false)}
          isModalOpen={isShowModal}
        />
      )}

      <div className="mt-[30px]">
        <Button
          autoInsertSpace={false}
          type="primary"
          htmlType="submit"
          className="w-[180px] font-bold"
          onClick={methods.handleSubmit(onSubmit)}
        >
          {t('button.keep')}
        </Button>
      </div>
    </Flex>
  )
}
