import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CreateSucceededModal from '../../../components/modals/CreateSucceededModal'
import { useModalState } from '../../../hooks/survey/useDeleteSurvey'
import { ReactComponent as ExclamationCircleFilledIcon } from 'assets/svgs/exclamationCircleFilled.svg'
import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  open?: boolean
  title: string
  onCancel: () => void
  onOk: () => void
  onConfirm: () => void
}

const DeleteModal = ({
  open,
  title = '',
  onCancel,
  onConfirm,
  onOk
}: Props) => {
  const { t } = useTranslation('')
  const { isModalVisible, resetIsModalVisible } = useModalState()

  const handleConfirm = () => {
    onConfirm() // call api

    // setIsModalVisible(true)
    onCancel() // to hidden modal
  }

  React.useEffect(() => {
    return () => {
      // componentWillUnmount
      resetIsModalVisible()
    }
  }, [])

  return (
    <>
      <CreateSucceededModal
        title={t('questionnaire.label.deleteSucceeded')}
        body={t('questionnaire.helperText.deleteSucceeded')}
        open={isModalVisible}
        onOk={() => onOk()}
        onCancel={() => onOk()}
        button={t('button.close')}
      />

      <CustomModal open={open} onCancel={onCancel} onOk={handleConfirm}>
        <div className="text-center pt-4 pb-[30px] px-[51px]">
          <Typography className="text-xl font-bold tracking-[1px] leading-none">
            {t('questionnaire.delete')}
          </Typography>
          <ExclamationCircleFilledIcon className="mt-[79px] m-auto w-[40px] h-[40px]" />
          <Paragraph className="mt-[23px] mb-0 text-error leading-none">
            {t('questionnaire.helperText.delete')}
          </Paragraph>
          <Paragraph className="mb-0 text-error leading-none mt-2">
            {t('content.cannotRestoreForm')}
          </Paragraph>
          <div className="mt-[51px] gap-3 items-center flex text-left">
            <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0 w-[148px]">
              {t('questionnaire.title')}
            </Paragraph>

            <Paragraph className="mb-0 flex-1">{title}</Paragraph>
          </div>

          <Flex className="justify-center gap-[12px] mt-[76px]">
            <Button
              type="primary"
              className="w-[180px] font-bold bg-error hover:!bg-error"
              htmlType="submit"
              onClick={() => handleConfirm()}
            >
              {t('button.delete')}
            </Button>
            <Button
              className="w-[180px] border-[#137695] text-[#137695] font-bold"
              onClick={onCancel}
            >
              {t('button.cancel')}
            </Button>
          </Flex>
        </div>
      </CustomModal>
    </>
  )
}

export default DeleteModal
