import { DocumentNode, gql } from '@apollo/client'

export const params = {
  request: [
    {
      userRefId: '1cfbf8bc-7476-4875-b6fd-e56b79d11a94',
      vendorAssignedRefId: '1cfbf8bc-7476-4875-b6fd-e56b79d12b89',
      gender: 'MALE',
      birthday: '1995-05-26',
      additionalInfo: {
        key: 'sshx123'
      },
      visibility: {
        clientCode: 'checkup-core-tenant-2',
        tenantCodes: ['admin'],
        subTenantCodes: ['abc123', 'GTR']
      }
    },
    {
      userRefId: '1cfbf8bc-7476-4875-b6fd-e56b79d11a94',
      vendorAssignedRefId: '1cfbf8bc-7476-4875-b6fd-e56b79d12b89',
      gender: 'MALE',
      birthday: '1995-05-26',
      additionalInfo: {
        key: 'mvci123'
      }
    }
  ]
}

export const CREATE_CHECKUP_USER: DocumentNode = gql`
  mutation addCheckupUser($request: [CreateCheckupUser]!) {
    addCheckupUser(request: $request) {
      status
      message
      timestamp
      payload {
        refId
        vendorAssignedRefId
        gender
        birthday
        additionalInfo
        createdDate
        createdBy
      }
    }
  }
`
