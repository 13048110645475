import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TFunction } from 'i18next'

import { openNotification } from '../../components/widgets/Notification'
import { CREATE_SURVEY } from '../../graphql/Survey/create'
import { Department } from '../../models/department'
import { QuestionTypeEnums, SurveyStatusEnums } from '../../models/survey'
import { Endpoint } from '../../utilities/apolloClient'
import { uuid } from '../../utilities/helpers'
import {
  SAMPLE_OPTION_CHOICE,
  SAMPLE_SECTION,
  createSampleQuestionChoice
} from '../sample/survey'
import { useMutation } from '@apollo/client'

export const cloneSection = (section: any, maxOrder: number) => {
  return { ...section, refId: uuid(), order: maxOrder + 1 }
}

export const initSection = (
  t: TFunction<['translation', ...string[]], undefined>,
  maxOrder: number
) => {
  const SAMPLE_QUESTION_CHOICE = createSampleQuestionChoice(t)
  const section = { ...SAMPLE_SECTION, refId: uuid(), order: maxOrder + 1 }
  section.subsectionList = [{ ...SAMPLE_QUESTION_CHOICE, refId: uuid() }]
  return section
}

export const initSubsectionList = (
  t: TFunction<['translation', ...string[]], undefined>
) => {
  const SAMPLE_QUESTION_CHOICE = createSampleQuestionChoice(t)
  return [{ ...SAMPLE_QUESTION_CHOICE, refId: uuid() }]
}

export const cloneQuestion = (
  question: any,
  {
    maxOrder,
    maxNo
  }: {
    maxOrder: number
    maxNo: number
  }
) => {
  return {
    ...question,
    refId: uuid(),
    order: maxOrder + 1,
    no: `${maxNo + 1}`
  }
}

export const initQuestion = (
  t: TFunction<['translation', ...string[]], undefined>,
  {
    maxOrder,
    maxNo
  }: {
    maxOrder: number
    maxNo: number
  }
) => {
  const SAMPLE_QUESTION_CHOICE = createSampleQuestionChoice(t)
  return {
    ...SAMPLE_QUESTION_CHOICE,
    refId: uuid(),
    order: maxOrder + 1,
    no: `${maxNo + 1}`
  }
}

export const initOptionAnswer = ({
  maxOrder,
  maxNo
}: {
  maxOrder: number
  maxNo: number
}) => {
  return { ...SAMPLE_OPTION_CHOICE, order: maxOrder + 1, no: `${maxNo + 1}` }
}

export const validatedSurveyCreate = (survey: any, fixedSection: any) => {
  survey.code = `${survey.code}-${Number(new Date())}`
  if (survey.departments.filter((d: any) => d !== '').length > 0) {
    const strDepartments = `[department:${survey.departments.filter((d: any) => d !== '').join(',')}]`
    survey.code = `${survey.code}|${strDepartments}`
  }

  let { sectionList, displayCategories, displayEnglish } = survey

  let sectionListCreate: any[] = []

  if (fixedSection.subsectionList) {
    fixedSection.subsectionList.sort((a: any, b: any) => a.order - b.order)
    sectionListCreate.push(fixedSection)
  }
  sectionListCreate.push(...sectionList)

  sectionListCreate = sectionListCreate.map((section: any, index: number) => {
    delete section.refId
    delete section.id
    section.order = index + 1
    section.subsectionList.map((question: any, idx: number) => {
      delete question.refId
      delete question.id
      question.order = idx + 1 // reorder
      if (
        question.questionType === QuestionTypeEnums.text ||
        question.questionType === QuestionTypeEnums.date ||
        question.questionType === QuestionTypeEnums.time
      ) {
        delete question.options
        delete question.setting.refId
        // question.setting = DEFAULT_QUESTION_SETTINGS[QuestionTypeEnums.text]
      }

      if (question.questionType === QuestionTypeEnums.choice) {
        delete question.setting.refId
        question.options.map((option: any) => {
          delete option.refId
          return option
        })
      }
      return question
    })

    return section
  })

  delete survey.departments
  delete survey.displayEnglish
  delete survey.displayCategories

  return {
    ...survey,
    sectionList: sectionListCreate,
    additionalInfo: {
      displayCategories,
      displayEnglish
    }
  }
}

export const extractDepartmentsFromCode = (
  code: string,
  conjunction = '|[department:'
) => {
  return code.includes(conjunction)
    ? code
        .split(conjunction)[1]
        .replace(']', '')
        .split(',')
        .filter((d: any) => d !== '')
    : []
}

export const extractPureCode = (
  code: string,
  conjunction = '|[department:'
) => {
  return code.includes(conjunction) ? code.split(conjunction)[0] : code
}

export const parseDepartments = (departmentList: any[]): Department[] => {
  const departments: Department[] = []
  const now = new Date()
  for (let i = 0; i < departmentList.length; i++) {
    departments.push({
      id: departmentList[i],
      departmentName: departmentList[i],
      deleted: false,
      order: i,
      createdAt: now.toLocaleString(),
      modifiedAt: now.toLocaleString()
    })
  }

  return departments
}

const useSurvey = () => {
  const { t } = useTranslation()
  const sectionList: any[] = [SAMPLE_SECTION]
  const [openCreatedDraftModal, setOpenCreatedDraftModal] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const [addSurvey, { data: createResponse, loading, error }] = useMutation(
    CREATE_SURVEY,
    {
      variables: { surveyInputs: [] },
      context: { version: Endpoint.SURVEY_CORE }
    }
  )

  useEffect(() => {
    if (!loading && !error && createResponse) {
      const {
        addSurvey: { payload }
      } = createResponse
      if (payload.length > 0) {
        if (!payload[0].isCreated) {
          openNotification({
            type: 'error',
            title: t('commonError'),
            message: payload[0].reason
          })
        }

        if (payload[0]?.created?.surveyStatus === SurveyStatusEnums.draft) {
          setOpenCreatedDraftModal(true)
        }
      }

      // console.info({ createResponse })
    }
  }, [error, loading, createResponse])

  return {
    sectionList,
    setOpenCreatedDraftModal,
    openCreatedDraftModal,
    confirmed,
    setConfirmed,
    addSurvey,
    error,
    loading
  }
}

export default useSurvey
