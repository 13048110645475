import React from 'react'

import { Flex, Typography } from 'antd'

import { ChoiceTypeEnums, QuestionTypeEnums } from '../../../models/survey'
import { DatePickerForm } from '../../elements/DatePickerForm'
import { TimePickerForm } from '../../elements/TimePickerForm'
import {
  CheckboxGroupForm,
  DropdownChoiceForm,
  InputTextAreaForm,
  RadioGroupForm
} from '../SurveyFeedback'

interface Props {
  fieldName: string
  question: any
  showEng?: boolean
}

const QuestionTypeChoice = ({ fieldName, question }: Props) => {
  const {
    isRequired,
    setting: { choiceType },
    options
  } = question
  const inputFieldName: string = `${fieldName}.selectedAnswerList.options`

  return (
    <div className="pl-2">
      {choiceType === ChoiceTypeEnums.radiobutton && (
        <RadioGroupForm
          required={isRequired}
          fieldName={inputFieldName}
          options={options}
          question={question}
        />
      )}
      {choiceType === ChoiceTypeEnums.checkbox && (
        <CheckboxGroupForm
          required={isRequired}
          fieldName={inputFieldName}
          options={options}
          question={question}
        />
      )}
      {choiceType === ChoiceTypeEnums.dropdown && (
        <DropdownChoiceForm
          required={isRequired}
          fieldName={inputFieldName}
          options={options}
          question={question}
        />
      )}
    </div>
  )
}

const QuestionTypeDatePicker = ({ fieldName, question }: Props) => {
  const {
    isRequired,
    setting: { fromDate, toDate, format }
  } = question
  const inputFieldName = `${fieldName}.selectedAnswerList.selectedDate`

  return (
    <DatePickerForm
      name={inputFieldName}
      rules={{
        required: isRequired,
        validate: (value: string, _: any) => {
          return isRequired ? !(value <= fromDate || value >= toDate) : true
        }
      }}
      className="w-[15em] h-[30px]"
      format={format}
      minDate={fromDate}
      maxDate={toDate}
    />
  )
}

const QuestionTypeTimePicker = ({ fieldName, question }: Props) => {
  const {
    isRequired,
    setting: { fromTime, toTime, format }
  } = question
  const inputFieldName = `${fieldName}.selectedAnswerList.selectedTime`

  return (
    <TimePickerForm
      name={inputFieldName}
      rules={{
        required: isRequired,
        validate: (value: string, _: any) => {
          return isRequired ? !(value <= fromTime || value >= toTime) : true
        }
      }}
      className="h-[30px] w-[15em]"
      format={format}
      minDate={fromTime}
      maxDate={toTime}
    />
  )
}

export const QuestionItemFeedback = ({
  fieldName,
  question,
  showEng
}: Props) => {
  const { isRequired, displayNameMap, questionType } = question

  return (
    <div className="mt-2.5">
      <Flex className="bg-[#F0F3F7] min-h-[52px] py-[9px] items-center">
        <Flex className="min-w-[8px] items-center gap-2">
          <Typography className="font-bold">{/*{order}*/}</Typography>
          {isRequired && (
            <Typography className="text-[#DF2475] text-lg self-start pb-2.5 font-bold">
              {/***/}
            </Typography>
          )}
        </Flex>
        <div>
          <Typography className="leading-none font-bold tracking-[0.7px]">
            {displayNameMap?.ja}
          </Typography>
          <Typography className="leading-none mt-1.5 tracking-[0.7px]">
            {showEng && displayNameMap?.en}
          </Typography>
        </div>
      </Flex>
      <div className="py-2.5 pl-0 items-center">
        {questionType === QuestionTypeEnums.choice && (
          <QuestionTypeChoice fieldName={fieldName} question={question} />
        )}
        {questionType === QuestionTypeEnums.text && (
          <InputTextAreaForm fieldName={fieldName} question={question} />
        )}
        {questionType === QuestionTypeEnums.date && (
          <QuestionTypeDatePicker fieldName={fieldName} question={question} />
        )}
        {questionType === QuestionTypeEnums.time && (
          <QuestionTypeTimePicker fieldName={fieldName} question={question} />
        )}
      </div>
    </div>
  )
}
