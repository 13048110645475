import { ReactNode } from 'react'
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Select } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'
import { SizeType } from 'antd/es/config-provider/SizeContext'

type Props = {
  className?: string
  name: string
  options: {
    label: string | ReactNode | any
    value: number | string
  }[]
  placeholder?: string
  disabled?: boolean
  size?: SizeType
  defaultValue?: number | string
  value?: number | string
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
      >
    | undefined
  onChange?: ((name: string, value: string) => void) | null
}

export const SelectCustom = ({
  className = '',
  name,
  options = [],
  placeholder = '',
  disabled = false,
  defaultValue,
  onChange,
  value,
  rules
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <Select
          placeholder={placeholder}
          disabled={disabled}
          className={className}
          status={error ? 'error' : ''}
          suffixIcon={
            <CaretDownOutlined
              color="#137695"
              className="text-[#137695] text-[14px]"
              style={{ pointerEvents: 'none' }}
            />
          }
          {...field}
          value={field.value ?? defaultValue}
          onChange={(e) => {
            field.onChange(e)

            if (onChange) onChange(name, e)
          }}
        >
          {options.map((option) => (
            <Select.Option value={option.value} key={option.value}>
              {t(option.label)}
            </Select.Option>
          ))}
        </Select>
      )}
    />
  )
}
