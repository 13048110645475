import { useTranslation } from 'react-i18next'

import { Flex, Typography, notification } from 'antd'

import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled
} from '@ant-design/icons'

type NotificationType = 'success' | 'info' | 'warning' | 'error'

const getStyleNotificationByType = (type: NotificationType) => {
  let defaultStyle = {
    padding: 16,
    borderRadius: 4,
    display: 'flex',
    backgroundColor: 'rgba(56, 158, 13, .7)'
  }

  switch (type) {
    case 'success':
      return {
        ...defaultStyle,
        backgroundColor: 'rgba(56, 158, 13, .7)'
      }
    case 'error':
      return {
        ...defaultStyle,
        backgroundColor: 'rgba(207, 19, 34, .7)'
      }
    case 'info':
      return {
        ...defaultStyle,
        backgroundColor: 'rgba(9, 88, 217, 0.7)'
      }
    case 'warning':
      return {
        ...defaultStyle,
        backgroundColor: 'rgba(250, 173, 20, .7)'
      }
  }
}

const renderIconByType = (type: NotificationType) => {
  switch (type) {
    case 'success':
      return <CheckCircleFilled className="text-white text-[22px]" />
    case 'error':
      return <CloseCircleFilled className="text-white text-[22px]" />
    case 'info':
      return <InfoCircleFilled className="text-white text-[22px]" />
    case 'warning':
      return <ExclamationCircleFilled className="text-white text-[22px]" />
  }
}

const Notification = ({
  type,
  title,
  message
}: {
  type: NotificationType
  title: string
  message: string
}) => {
  const { t } = useTranslation()
  return (
    <Flex align="center" gap={16}>
      {renderIconByType(type)}
      <Flex vertical>
        <Typography className="font-bold text-[16px] text-white">
          {t(title)}
        </Typography>
        <Typography className="text-[14px] text-white">{t(message)}</Typography>
      </Flex>
    </Flex>
  )
}

export const openNotification = ({
  type,
  title,
  message
}: {
  type: NotificationType
  title: string
  message: string
}) => {
  notification.open({
    message: <Notification type={type} title={title} message={message} />,
    className: 'custom-notification',
    icon: <></>,
    style: getStyleNotificationByType(type)
  })
}
