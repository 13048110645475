import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography } from 'antd'

import { format } from 'date-fns'

export const DraftedMessageBox = () => {
  const { t } = useTranslation()
  const now = format(new Date(), 'yyyy/MM/dd')

  return (
    <Flex className="">
      <Typography.Text className="text-[14px]" style={{ color: '#137695' }}>
        {t('questionnaire.label.saveDraft')}
      </Typography.Text>
      <Typography.Text
        className="text-[14px] ml-5"
        style={{ color: '#707070' }}
      >
        {t('questionnaire.label.datetime')} {now}
      </Typography.Text>
    </Flex>
  )
}
