// define custom hooks here
import { useState, useEffect, useRef, useCallback } from "react"

/**
 * Allows a component to toggle a value between true and false
 */
export const useToggle = (defaultValue: any) => {
  const [value, setValue] = useState(defaultValue)

  const toggleValue = (value: any) => {
    setValue((currentValue: any) =>
      typeof value === "boolean" ? value : !currentValue
    )
  }

  return [value, toggleValue]
}

/**
 * Allows a component to run a callback function only when specific dependencies change. It uses the React library's built-in useEffect and useRef hooks.
 */
export const useUpdateEffect = (callback: Function, dependencies: any) => {
    const firstRenderRef = useRef(true)
  
    useEffect(() => {
      if (firstRenderRef.current) {
        firstRenderRef.current = false
        return
      }
      return callback()
    }, dependencies)
}

/**
 * Allows a component to keep track of the previous value of a variable. It uses the built-in useRef hook from the React library.
 */
export const usePrevious = (value: any) => {
    const currentRef = useRef(value)
    const previousRef = useRef()
  
    if (currentRef.current !== value) {
      previousRef.current = currentRef.current
      currentRef.current = value
    }
  
    return previousRef.current
}

/**
 * Allows a component to keep track of the state's history. It uses the built-in useState, useCallback, and useRef hooks from the React library.
 */
export const  useStateWithHistory = (
    defaultValue: any,
    { capacity = 10 } = {}
  ) => {
    const [value, setValue] = useState(defaultValue)
    const historyRef = useRef([value])
    const pointerRef = useRef(0)
  
    const set = useCallback(
      (v: any) => {
        const resolvedValue = typeof v === "function" ? v(value) : v
        if (historyRef.current[pointerRef.current] !== resolvedValue) {
          if (pointerRef.current < historyRef.current.length - 1) {
            historyRef.current.splice(pointerRef.current + 1)
          }
          historyRef.current.push(resolvedValue)
  
          while (historyRef.current.length > capacity) {
            historyRef.current.shift()
          }
          pointerRef.current = historyRef.current.length - 1
        }
        setValue(resolvedValue)
      },
      [capacity, value]
    )
  
    const back = useCallback(() => {
      if (pointerRef.current <= 0) return
      pointerRef.current--
      setValue(historyRef.current[pointerRef.current])
    }, [])
  
    const forward = useCallback(() => {
      if (pointerRef.current >= historyRef.current.length - 1) return
      pointerRef.current++
      setValue(historyRef.current[pointerRef.current])
    }, [])
  
    const go = useCallback((index: number) => {
      if (index < 0 || index > historyRef.current.length - 1) return
      pointerRef.current = index
      setValue(historyRef.current[pointerRef.current])
    }, [])
  
    return [
      value,
      set,
      {
        history: historyRef.current,
        pointer: pointerRef.current,
        back,
        forward,
        go,
      },
    ]
}
  
const useStorage = (key: string, defaultValue: any, storageObject: any) => {
    const [value, setValue] = useState(() => {
      const jsonValue = storageObject.getItem(key)
      if (jsonValue != null) return JSON.parse(jsonValue)
  
      if (typeof defaultValue === "function") {
        return defaultValue()
      } else {
        return defaultValue
      }
    })
  
    useEffect(() => {
      if (value === undefined) return storageObject.removeItem(key)
      storageObject.setItem(key, JSON.stringify(value))
    }, [key, value, storageObject])
  
    const remove = useCallback(() => {
      setValue(undefined)
    }, [])
  
    return [value, setValue, remove]
}

/**
 * Allows a component to store a value in the browser's LocalStorage and keep it in sync with the component's state
 */
export const useLocalStorage = (key: string, defaultValue: any) => {
    return useStorage(key, defaultValue, window.localStorage)
}

/**
 * Allows a component to store a value in the browser's SessionStorage and keep it in sync with the component's state
 */
export const useSessionStorage = (key: string, defaultValue: any) => {
    return useStorage(key, defaultValue, window.sessionStorage)
}

/**
 * Allows a component to handle asynchronous operations and keep track of the loading, error, and value states
 */
export const useAsync = (callback: Function, dependencies: Array<any> = []) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [value, setValue] = useState()
  
    const callbackMemoized = useCallback(() => {
      setLoading(true)
      setError(undefined)
      setValue(undefined)
      callback()
        .then(setValue)
        .catch(setError)
        .finally(() => setLoading(false))
    }, dependencies)
  
    useEffect(() => {
      callbackMemoized()
    }, [callbackMemoized])
  
    return { loading, error, value }
}