import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
import listTranslationEn from 'locales/en'
import listTranslationJp from 'locales/jp'
import listTranslationVi from 'locales/vi'

const resources = {
  ja: {
    translation: listTranslationJp
  },
  vi: {
    translation: listTranslationVi
  },
  en: {
    translation: listTranslationEn
  }
}

const savedLanguage = localStorage.getItem('i18nextLng');

if (!savedLanguage) {
  localStorage.setItem('i18nextLng', 'ja');
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['ja', 'vi', 'en'],
    resources,
    // lng: 'ja',
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'], 
      lookupLocalStorage: 'i18nextLng',
      lookupCookie: 'i18next',
    },
    initImmediate: false,
  })

export default i18n
