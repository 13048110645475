import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from 'antd'

import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  open?: boolean
  onCancel: () => void
  onConfirm: () => void
}

const UpdateConfirmModal = ({ open, onCancel, onConfirm }: Props) => {
  const { t } = useTranslation('')

  const handleConfirm = () => {
    onConfirm() // call api

    onCancel() // to hidden modal
  }

  return (
    <>
      <CustomModal open={open} onCancel={onCancel} onOk={handleConfirm}>
        <Flex vertical className="pt-4 pb-5 items-center justify-center">
          <Typography className="text-[20px] text-[#545454] font-bold leading-none tracking-[1px]">
            {t('questionnaire.editConfirm')}
          </Typography>

          <Paragraph className="mt-[10rem] mb-0">
            {t('questionnaire.helperText.update')}
          </Paragraph>

          <Flex className="justify-center gap-[12px] mt-[10rem]">
            <Button
              type="primary"
              className="w-[180px] font-bold"
              htmlType="submit"
              onClick={() => handleConfirm()}
              autoInsertSpace={false}
            >
              {t('button.keep')}
            </Button>
            <Button
              className="w-[180px] border-[#137695] text-[#137695] font-bold"
              onClick={onCancel}
            >
              {t('button.cancel')}
            </Button>
          </Flex>
        </Flex>
      </CustomModal>
    </>
  )
}

export default UpdateConfirmModal
