import React from 'react'
import {
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Tooltip, Typography } from 'antd'

import { cloneSection, initSection } from '../../../hooks/survey/useSurvey'
import { SubsectionTypeEnums } from '../../../models/survey'
import { SubsectionItemCreateCard } from './SubsectionItemCreateCard'
import { MoreOutlined } from '@ant-design/icons'
import { ReactComponent as CopyIcon } from 'assets/svgs/copyWhite.svg'
import { ReactComponent as PlusOutlined } from 'assets/svgs/plus–42.svg'
import { ReactComponent as TrashIcon } from 'assets/svgs/trashWhite.svg'
import { InputForm } from 'components/elements/InputForm'

type Props = {
  section: any
  fieldName: string
  index: number
  maxOrder: number
  append: any
  disabledRemove: boolean
  remove: UseFieldArrayRemove
}

export const SectionItemCreateCard = ({
  section,
  fieldName,
  index,
  maxOrder,
  append,
  disabledRemove,
  remove
}: Props) => {
  const { t } = useTranslation()
  const { control, watch, getValues } = useFormContext()
  const displayEnglish = watch('displayEnglish')
  const displayCategories = watch('displayCategories')

  const {
    fields: subsectionList,
    append: appendSub,
    remove: removeSub
  } = useFieldArray({
    control,
    name: `${fieldName}.subsectionList`
  })

  const onCopy = () => {
    append(cloneSection(getValues(fieldName), maxOrder))
  }

  return (
    <Flex vertical className="mt-3.5 gap-[6px]">
      {Boolean(displayCategories) && (
        <>
          <Row>
            <Col span={12} className="self-center">
              <Typography.Text strong style={{ color: '#707070' }}>
                {t('questionnaire.helperText.enterCategory')}
              </Typography.Text>
            </Col>
            <Col span={12} />
          </Row>

          <Flex className="w-full h-[90px] bg-[#137695]">
            <div className="w-[3%] justify-center flex items-center">
              <MoreOutlined style={{ color: '#BDCBD5' }} className="text-4xl" />
            </div>
            <div className="h-[auto] w-[85%] justify-center items-center">
              <InputForm
                name={`${fieldName}.title.ja`}
                className="w-[99%] h-[30px] pr-[5px] mt-[10px]"
                placeholder={t('questionnaire.placeholder.questionCategory')}
                defaultValue={section?.title?.ja}
              />
              {Boolean(displayEnglish) && (
                <InputForm
                  name={`${fieldName}.title.en`}
                  className="w-[99%] h-[30px] pr-[5px] mt-[10px]"
                  placeholder={t('questionnaire.placeholder.englishDisplay')}
                  defaultValue={section?.title?.en}
                />
              )}
            </div>
            <div className="min-w-[12%] justify-between flex items-center px-3">
              <Tooltip placement="top" title={t('popover.copy')}>
                <Button
                  className="shadow-none"
                  shape="circle"
                  type="primary"
                  onClick={() => onCopy()}
                  icon={<CopyIcon style={{ color: '#fff' }} color="#fff" />}
                />
              </Tooltip>
              <Tooltip placement="top" title={t('button.delete')}>
                <Button
                  className="shadow-none bg-[#137695]"
                  type="primary"
                  shape="circle"
                  disabled={disabledRemove}
                  onClick={() => remove(index)}
                  icon={<TrashIcon width="16" style={{ color: '#fff' }} />}
                />
              </Tooltip>
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined style={{ color: '#FFF' }} />}
                className="shadow-none"
                onClick={() => append(initSection(t, maxOrder))}
              />
            </div>
          </Flex>
        </>
      )}

      {subsectionList.map((subsection: any, idx: number) => {
        const subFieldName = `${fieldName}.subsectionList.${idx}`
        const maxOrder = Math.max(
          ...Array.from(subsectionList, (o: any) => o.order)
        )
        const maxNo = Math.max(...Array.from(subsectionList, (o: any) => o.no))
        // console.log(`subsectionList ${subFieldName}`, { maxOrder, maxNo })

        const { subsectionType } = subsection

        if (subsectionType === SubsectionTypeEnums.question) {
          return (
            <fieldset name={subFieldName} key={subsection.id}>
              <SubsectionItemCreateCard
                fieldName={subFieldName}
                prevMax={{ maxOrder, maxNo }}
                append={appendSub}
                remove={removeSub}
                subsection={subsection}
                key={idx}
                disabledRemove={subsectionList.length === 1}
                index={idx}
              />
            </fieldset>
          )
        } else {
          // TODO render other type
          return null
        }
      })}
    </Flex>
  )
}
