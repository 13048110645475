import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilledIcon } from 'assets/svgs/exclamationCircleFilled.svg'
import CustomModal from 'components/modals'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  keepStatus: boolean
  onChangeKeepStatus: () => void
  onCancel: () => void
  onOk: () => void
}

const SaveDraftFeedbackDialog = ({
  isModalOpen = false,
  onCancel,
  keepStatus,
  onChangeKeepStatus,
  onOk
}: Props) => {
  const { t } = useTranslation('')

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={onOk}>
      <div className="text-center pt-4 pb-[40px]">
        <Typography className="text-xl font-bold tracking-[1px] leading-none">
          {t('button.saveDraft')}
        </Typography>

        <ExclamationCircleFilledIcon className="mt-20 m-auto w-[40px] h-[40px]" />
        <Paragraph className="mt-8 mb-0 leading-none">
          {t('content.savingContentTemporarily')}
        </Paragraph>
        <Paragraph className="mt-2">{t('content.isThatOkay')}</Paragraph>

        <Paragraph className="mt-14 text-error">
          {t('content.statusAwaitingResponse')}
        </Paragraph>

        <Checkbox
          checked={keepStatus}
          onChange={() => onChangeKeepStatus()}
          className="flex justify-center items-center mt-16 mb-8"
        >
          {t('content.saveWithoutStatus')}
        </Checkbox>

        <Button
          type="primary"
          className="w-[180px] font-bold"
          onClick={onOk}
          autoInsertSpace={false}
        >
          {t('button.keep')}
        </Button>
      </div>
    </CustomModal>
  )
}

export default SaveDraftFeedbackDialog
