import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Col, Flex, Row, Typography } from 'antd'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { ReactComponent as CrownIcon } from 'assets/svgs/crown.svg'
import { InputLabel } from 'components/elements/InputLabel'
import { SelectCustom } from 'components/elements/Select'
import CustomModal from 'components/modals'
import { Admin } from 'models/admin'

const { Paragraph } = Typography

type Props = {
  admin: Admin | null
  onCancel: () => void
  onSuccess: () => void
  onConfirm: () => void
}

export type DataForm = {
  role: string
}
const schema = yup
  .object()
  .shape({
    role: yup.string().required()
  })
  .required()

const EditAdminModal = ({ admin, onCancel, onSuccess, onConfirm }: Props) => {
  const { t } = useTranslation('')

  const handleOk = () => {
    //todo
  }

  const methods = useForm<DataForm>({
    resolver: yupResolver(schema)
  })

  const { handleSubmit } = methods

  const onSubmit = (data: DataForm) => {
    // TODO call api
    onCancel()
    if (admin?.currentOwner) {
      onConfirm()
    } else {
      onSuccess()
    }
  }
  return (
    <CustomModal
      open={admin ? true : false}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <Flex vertical className="p-5 pt-4 text-center">
        <Typography className="text-[20px] text-base font-bold leading-none">
          {t('editAdmin')}
        </Typography>
        <Paragraph className="tracking-[.7px] mt-[26px] mb-0">
          {admin?.currentOwner && t('content.editAdminImportant')}
          {!admin?.currentOwner && t('content.editAdmin')}
        </Paragraph>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-[90px] gap-3 items-center">
              <Col flex="148px">
                <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
                  {t('lable.customerName')}
                </Paragraph>
              </Col>
              <Col>
                <Paragraph className="mb-0">{admin?.fullName}</Paragraph>
              </Col>
            </Row>
            <Row className="mt-1.5 gap-3 items-center">
              <Col flex="148px">
                <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0">
                  {t('lable.mailAddress')} <br />
                  <Typography className="text-xs p-0">
                    {t('lable.id')}
                  </Typography>
                </Paragraph>
              </Col>
              <Col>
                <Paragraph className="mb-0">{admin?.email}</Paragraph>
              </Col>
            </Row>
            {!admin?.currentOwner && (
              <>
                <Typography className="text-left">
                  <Paragraph className="mb-0 text-xs mt-2">
                    {t('content.registerIdLogin')}
                  </Paragraph>
                  <Paragraph className="mb-0 text-xs mt-1">
                    {t('content.checkAdminRegister')}
                  </Paragraph>
                </Typography>
                <Row className="mt-1.5 items-center gap-3 text-left">
                  <Col flex="148px">
                    <InputLabel label={t('role')} />
                  </Col>
                  <Col flex="auto">
                    <SelectCustom
                      name="role"
                      className="w-full h-[32px]"
                      placeholder={t('placeholder.pleaseSelect')}
                      options={[
                        { label: 'テキスト 1', value: 1 },
                        { label: 'テキスト 2', value: 2 },
                        { label: 'テキスト 3', value: 3 },
                        { label: 'テキスト 4', value: 4 }
                      ]}
                    />
                  </Col>
                </Row>
                <Row className="mt-1.5 items-center gap-3 text-left">
                  <Col flex="148px">
                    <InputLabel label={t('affiliation')} />
                  </Col>
                  <Col flex="auto">
                    <SelectCustom
                      name="affiliation"
                      className="w-full h-[32px]"
                      placeholder={t('placeholder.pleaseSelect')}
                      options={[
                        { label: 'テキスト 1', value: 1 },
                        { label: 'テキスト 2', value: 2 },
                        { label: 'テキスト 3', value: 3 },
                        { label: 'テキスト 4', value: 4 }
                      ]}
                    />
                  </Col>
                </Row>
              </>
            )}
            {admin?.currentOwner && (
              <Row className="mt-1.5 gap-3">
                <Col flex="148px">
                  <Paragraph className="font-bold text-left px-2.5 py-2 leading-none bg-[#F0F3F7] mb-0 h-full flex items-center">
                    {t('ownerRights')}
                  </Paragraph>
                </Col>
                <Col className="flex-1 text-left">
                  <Paragraph className="mb-0 font-bold text-primary leading-none flex items-center gap-1.5">
                    <CrownIcon className="inline-block w-[17px] " />
                    {t('currentOwner')}
                  </Paragraph>
                  <SelectCustom
                    name="role"
                    className="w-full h-[32px] mt-3"
                    placeholder={t('placeholder.pleaseSelect')}
                    options={[
                      { label: 'テキスト 1', value: 1 },
                      { label: 'テキスト 2', value: 2 },
                      { label: 'テキスト 3', value: 3 },
                      { label: 'テキスト 4', value: 4 }
                    ]}
                  />
                </Col>
              </Row>
            )}

            <Flex className="justify-center gap-[12px] mt-[88px]">
              <Button
                type="primary"
                className="w-[180px] font-bold"
                htmlType="submit"
              >
                {t('button.registration')}
              </Button>
              <Button
                className="w-[180px] border-[#137695] text-[#137695] font-bold"
                onClick={onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </CustomModal>
  )
}

export default EditAdminModal
