import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Flex, Typography } from 'antd'
import * as yup from 'yup'

import { QuestionItemFeedback } from '../../../components/screens/QuestionnaireResult'
import { openNotification } from '../../../components/widgets/Notification'
import { FEEDBACK_STATUS } from '../../../configs/constant'
import useSurveyList from '../../../hooks/survey/useSurveyList'
import { StatusEnums, SurveyStatusEnums } from '../../../models/survey'
import { forceTypeBoolean, isObjectEmpty } from '../../../utilities/helpers'
import SaveDraftFeedbackDialog from './SaveDraftFeedbackDialog'
import SaveMedicalQuestionnaireSuccess from './SaveMedicalQuestionnaireSuccess'
// import { ReactComponent as EyeNoHidden } from 'assets/svgs/eye-blue-37.svg'
// import { ReactComponent as EyeHidden } from 'assets/svgs/eye-hidden14132.svg'
import useSurveyFeedback, {
  useCreateSurveyFeedback
} from 'hooks/useSurveyFeedback'

const schema = yup.object().shape({
  // TODO define schema
})

type InputForm = yup.InferType<typeof schema>

export default function SurveyFeedbackCreate() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isVisibleDraftDialog, setIsVisibleDraftDialog] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [displayEnglish, setDisplayEnglish] = useState<boolean>(true)
  const [displayCategories, setDisplayCategories] = useState<boolean>(true)
  const [survey, setSurvey] = useState<any>(null)
  const [surveyFeedback, setSurveyFeedback] = useState<any>(null)
  const [keepStatus, setKeepStatus] = useState<any>(false)

  const { variablesFilter: filterSurveyFeedback, loadSurveyFeedbackList } =
    useSurveyFeedback()
  const { fetchSurveyDetailList } = useSurveyList()
  const {
    createSurveyFeedback,
    updateSurveyFeedback,
    parseFormFeedbackSurvey
  } = useCreateSurveyFeedback()

  const userRefId: string = searchParams.get('userRefId') ?? ''
  const surveyFeedbackRefId: string =
    searchParams.get('surveyFeedbackRefId') ?? ''

  useEffect(() => {
    // console.log('surveyFeedbackRefId', surveyFeedbackRefId)

    if (surveyFeedbackRefId) {
      const fetchDataEdit = async () => {
        const variablesFilterSurveyFeedback = {
          ...filterSurveyFeedback,
          filter: `(eq,STRING,refId,${surveyFeedbackRefId})`,
          paginationInput: { page: 0, size: 1 }
        }

        const feedbacks = await loadSurveyFeedbackList({
          variables: variablesFilterSurveyFeedback
        })

        const feedback = feedbacks.data.filterSurveyFeedback.payload[0]
        if (!feedback) {
          openNotification({
            type: 'error',
            title: t('commonError'),
            message: ''
          })
          return
        }

        setSurveyFeedback(feedback)

        const survey = await fetchSurveyDetailList({
          filter: `(eq,STRING,refId,${feedback.surveyRefId})`
        })

        setDisplayCategories(
          forceTypeBoolean(survey?.additionalInfo?.displayCategories) ?? false
        )
        setDisplayEnglish(
          forceTypeBoolean(survey?.additionalInfo?.displayEnglish) ?? false
        )
        setSurvey(survey)
      }
      fetchDataEdit()
    } else {
      const fetchDataCreate = async () => {
        const survey = await fetchSurveyDetailList({
          filter: `(eq,STRING,surveyStatus,${SurveyStatusEnums.assigned})%(eq,STRING,surveyStatus,${SurveyStatusEnums.completed})`,
          sortBy: `(desc,createdDate)`
        })

        setDisplayCategories(
          forceTypeBoolean(survey?.additionalInfo?.displayCategories) ?? false
        )
        setDisplayEnglish(
          forceTypeBoolean(survey?.additionalInfo?.displayEnglish) ?? false
        )
        setSurvey(survey)
      }
      fetchDataCreate()
    }
  }, [surveyFeedbackRefId])

  useEffect(() => {
    const element = document.querySelector('.ant-layout-content')

    if (element) {
      element.classList.add('!overflow-hidden')
    }

    return () => {
      // componentWillUnmount
      const element = document.querySelector('.ant-layout-content')
      if (element) {
        element.classList.remove('!overflow-hidden')
      }
    }
  }, [])

  const methods = useForm<InputForm>({
    defaultValues: survey && parseFormFeedbackSurvey(survey, surveyFeedback),
    // resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const {
    handleSubmit,
    trigger,
    formState: { errors, isValid },
    getValues,
    reset
  } = methods

  useEffect(() => {
    if (survey) {
      const fromValues = parseFormFeedbackSurvey(survey, surveyFeedback)
      reset({
        ...fromValues
      })
    }
  }, [survey])

  const onSubmit = (data: InputForm) => {
    console.log(data)
  }

  const handleCreateSurveyFeedback = async (feedbackStatus: string) => {
    const assignResult = await createSurveyFeedback(userRefId, survey?.refId)
    if (!assignResult) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: ''
      })
      return
    }

    // Call API to update feedback answer
    const { refId } = assignResult
    const formData = getValues()

    const surveyFeedbackResult = await updateSurveyFeedback(
      refId,
      formData,
      feedbackStatus
    )
    // console.log(surveyFeedbackResult)
    if (!surveyFeedbackResult || !surveyFeedbackResult.isUpdated) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: ''
      })
      return
    }

    if (feedbackStatus === FEEDBACK_STATUS.COMPLETED) {
      setIsModalVisible(true)
      return
    }

    openNotification({
      type: 'success',
      title: t('saveComplete'),
      message: t('questionnaire.helperText.saveDraftComplete')
    })
    navigate('/consultant-management')
  }

  const handleUpdateSurveyFeedback = async (feedbackStatus: string) => {
    // Call API to update feedback answer
    const formData = getValues()

    const status = keepStatus ? surveyFeedback.feedbackStatus : feedbackStatus
    const surveyFeedbackResult = await updateSurveyFeedback(
      surveyFeedbackRefId,
      formData,
      status
    )
    // console.log(surveyFeedbackResult)
    if (!surveyFeedbackResult || !surveyFeedbackResult.isUpdated) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: ''
      })
      return
    }

    if (feedbackStatus === FEEDBACK_STATUS.COMPLETED) {
      setIsModalVisible(true)
      return
    }

    openNotification({
      type: 'success',
      title: t('saveComplete'),
      message: t('questionnaire.helperText.saveDraftComplete')
    })
    navigate('/consultant-management')
  }

  const assignSurveyFeedback = (feedbackStatus: string) => {
    if (!userRefId) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.userRefIdUndefined')
      })
      return
    }

    // console.log({ isValid }, errors, getValues())
    if (isObjectEmpty(errors)) {
      // manual trigger from validate
      trigger().then()
    }

    if (!isValid || !isObjectEmpty(errors)) {
      openNotification({
        type: 'error',
        title: t('commonError'),
        message: t('errors.enterRequiredResults')
      })
      return 0
    }

    if (surveyFeedbackRefId) {
      handleUpdateSurveyFeedback(feedbackStatus).then()
    } else {
      handleCreateSurveyFeedback(feedbackStatus).then()
    }
  }

  const openDraftDialog = () => {
    setIsVisibleDraftDialog(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
    navigate('/consultant-management')
  }

  return (
    <div className="QuestionnaireResultCreate w-full pr-[31px] mt-[-8px] mb-5">
      <SaveMedicalQuestionnaireSuccess
        isModalOpen={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
      />
      <SaveDraftFeedbackDialog
        isModalOpen={isVisibleDraftDialog}
        keepStatus={keepStatus}
        onChangeKeepStatus={() => setKeepStatus(!keepStatus)}
        onOk={() => {
          setIsVisibleDraftDialog(false)
          assignSurveyFeedback(
            keepStatus ? FEEDBACK_STATUS.DRAFT : FEEDBACK_STATUS.ASSIGNED
          )
        }}
        onCancel={() => setIsVisibleDraftDialog(false)}
      />

      <Flex vertical gap="small" className="w-full main-input-container pt-1">
        <Flex className="items-center justify-between">
          <Flex className="pl-[54px] gap-[14px] items-center">
            <Typography className="leading-none tracking-[0.7px]">
              {t('questionnaire.title')}
            </Typography>
            <div className="flex items-center w-[30rem] max-w-[30rem] pl-[7px] h-[30px] border border-solid border-[#D9E0E5]">
              <Typography className="leading-none truncate">
                {survey?.displayNameMap?.ja ?? ''}
              </Typography>
            </div>
          </Flex>

          <Flex className="gap-2" justify="center" align="center">
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              onClick={() => assignSurveyFeedback(FEEDBACK_STATUS.COMPLETED)}
              className="shadow-none w-[90px] h-[30px] font-bold"
              autoInsertSpace={false}
            >
              {t('button.keep')}
            </Button>
            <Button
              type="primary"
              ghost
              htmlType="submit"
              size="middle"
              onClick={() => openDraftDialog()}
              className="shadow-none min-w-[90px] h-[30px] font-bold"
            >
              {t('button.saveDraft')}
            </Button>
          </Flex>
        </Flex>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            {survey?.sectionList &&
              survey.sectionList.map((section: any, index: number) => (
                <div key={index}>
                  {displayCategories ? (
                    <Flex
                      vertical
                      className="bg-[#137695] min-h-[56px] mt-2 p-2 gap-5"
                    >
                      <div className="">
                        <Typography className="min-h-[14px] tracking-[1.4px] pb-2 font-bold text-white leading-none uppercase">
                          {displayEnglish && section?.title?.en}
                        </Typography>

                        <Typography className="tracking-[1.4px] font-bold text-white leading-none uppercase">
                          {section?.title?.ja}
                        </Typography>
                      </div>
                    </Flex>
                  ) : null}

                  {section.subsectionList
                    .filter((sub: any) => sub.status === StatusEnums.active)
                    .filter((sub: any) => sub?.subsectionType === 'QUESTION')
                    .map((subsection: any, idx: number) => {
                      const activatedIndex = section.subsectionList.findIndex(
                        (sub: any) => sub.refId === subsection.refId
                      )
                      const fieldName = `sectionList.${index}.subsectionList.${activatedIndex}`
                      return (
                        <QuestionItemFeedback
                          fieldName={fieldName}
                          question={subsection}
                          showEng={displayEnglish}
                          key={idx}
                        />
                      )
                    })}
                </div>
              ))}
          </form>
        </FormProvider>
      </Flex>

      <Flex
        justify="left"
        className="absolute bg-[#F0F3F7] pt-[1em] pb-[8em] mt-[48px] ml-[-20px]"
        style={{
          width: 'calc(100% + 38px)'
        }}
      >
        <Flex>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            onClick={() => assignSurveyFeedback(FEEDBACK_STATUS.COMPLETED)}
            className="shadow-none w-[90px] h-[30px] font-bold ml-3"
            autoInsertSpace={false}
          >
            {t('button.keep')}
          </Button>
          <Button
            type="primary"
            ghost
            htmlType="submit"
            size="middle"
            onClick={() => openDraftDialog()}
            className="shadow-none min-w-[90px] h-[30px] font-bold ml-4"
          >
            {t('button.saveDraft')}
          </Button>
        </Flex>
      </Flex>
    </div>
  )
}
