import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Table } from 'antd'

import type { ColumnsType } from 'antd/es/table'
import { VALUE_WITH_NO_DATA } from 'configs/constant'
import { dataTableDetail, inspectionItem } from 'models/resultPreview'

export default function DataTestTable({
  listDataTable,
  language
}: {
  listDataTable: any
  language: any
}) {
  const { t } = useTranslation()
  // Define the columns
  const columns: ColumnsType<dataTableDetail> = [
    {
      title: t('inspectionItem'),
      dataIndex: 'inspectionItem',
      key: 'inspectionItem',
      width: '14%',
      className: 'column-style-1',
      render: (inspectionItem: inspectionItem) => (
        <div className="w-full">
          <span className="font-bold w-full">
            {inspectionItem.inspection_item?.[language] ?? VALUE_WITH_NO_DATA}
          </span>
          <br></br>
          <span className="font-normal">
            {inspectionItem.inspection_item_en?.en ?? VALUE_WITH_NO_DATA}
          </span>
        </div>
      )
    },
    {
      title: t('testResult.unit'),
      dataIndex: 'unit',
      key: 'unit',
      width: '4%',
      className: 'column-style-2',
      render: (unit) => (
        <div>
          <span>{unit === 'uom' || !unit ? '' : `${unit}`}</span>
        </div>
      )
    },
    {
      title: t('testResult.referenceValue'),
      dataIndex: 'reference_value',
      key: 'reference_value',
      width: '7%',
      className: 'column-style-3'
    },
    {
      title: t('testResult.evaluation'),
      dataIndex: 'judgement',
      width: '25%',
      key: 'judgement',
      className: 'column-style-4'
    },
    {
      title: '',
      dataIndex: 'judgement_2',
      width: '25%',
      key: 'judgement_2',
      className: 'column-style-5'
    },
    {
      title: '',
      dataIndex: 'judgement_3',
      width: '25%',
      key: 'judgement_3',
      className: 'column-style-6'
    }
  ]

  return (
    <Table
      className="w-full custom-data-test-table"
      dataSource={listDataTable}
      columns={columns}
      pagination={false}
      bordered={false}
      rowClassName={(record) => (record.active === '1' ? 'active-row' : '')}
    />
  )
}
