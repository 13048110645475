import { apolloClient } from '../utilities/apolloClient'
import { ApolloProvider } from '@apollo/client'

interface Props {
  children?: React.ReactNode
}

const AppApolloProvider = ({ children }: Props) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AppApolloProvider
