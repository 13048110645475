import { DocumentNode, gql } from '@apollo/client'

export interface VariableProps {
  page: number
  size: number
  filter: string
}

export const FILTER_MEDICAL_CHECKUP: DocumentNode = gql`
  query filterMedicalCheckupDetails(
    $filter: String!
    $page: Int!
    $size: Int!
    $sortBy: String
  ) {
    filterMedicalCheckupDetails(
      filter: $filter
      page: $page
      size: $size
      sortBy: $sortBy
    ) {
      status
      message
      timestamp
      totalPages
      currentPageNumber
      totalElements
      payload {
        medicalCheckup {
          refId
          checkupUserRef
          medicalCheckupMasterRef
          review
          additionalInfo
          createdBy
          createdDate
        }
      }
    }
  }
`

export const FILTER_MEDICAL_CHECKUP_DETAILS: DocumentNode = gql`
  query filterMedicalCheckupDetails(
    $page: Int!
    $size: Int!
    $filter: String
    $sortBy: String
  ) {
    filterMedicalCheckupDetails(
      page: $page
      size: $size
      filter: $filter
      sortBy: $sortBy
    ) {
      status
      message
      timestamp
      totalPages
      currentPageNumber
      totalElements
      payload {
        medicalCheckup {
          refId
          medicalCheckupMasterRef
          checkupUserRef
          review
          additionalInfo
          visibility {
            tenantCodes
            subTenantCodes
          }
          createdDate
        }
        medicalCheckupMaster {
          refId
          shortName
          associatedTestItemMasters
          additionalInfo
          visibility {
            tenantCodes
            subTenantCodes
          }
        }
        checkupUser {
          refId
          vendorAssignedRefId
          deviceInputRefId
          gender
          birthday
          additionalInfo
          visibility {
            tenantCodes
            subTenantCodes
          }
          createdDate
          createdBy
        }
      }
    }
  }
`
