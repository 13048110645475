import React, { FC } from 'react'

import { Button, Flex, Typography } from 'antd'
import { TFunction } from 'i18next'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import { InputLabel } from 'components/elements/InputLabel'
import CustomModal from 'components/modals/CustomModal'

type Props = {
  t: TFunction<'translation', undefined>
  isOpen: boolean
  data: any
  onCancel: () => void
  isCreate?: boolean
}

const DuplicateIdError: FC<Props> = ({
  t,
  isOpen,
  onCancel,
  data,
  isCreate = true
}) => {
  return (
    <CustomModal
      open={isOpen}
      onOk={() => {}}
      onCancel={onCancel}
      children={
        <Flex justify="center" className="pb-6 pt-4">
          <div>
            <Typography className="flex justify-center text-[20px] font-bold tracking-[1px]">
              {isCreate
                ? t('customerDetail.modals.create')
                : t('customerDetail.modals.update')}
            </Typography>

            <ExclamationCircleFilled className="mt-[76px] m-auto w-[40px] text-error" />

            <Typography className="mt-6 flex justify-center tracking-[1px] text-[#F5006B]">
              {isCreate
                ? t('customerDetail.modals.idDuplicate.create')
                : t('customerDetail.modals.idDuplicate.update')}
            </Typography>
            <div className="mt-[36px]">
              <Flex>
                <InputLabel
                  label={t('customerDetail.header.patientId')}
                  className="h-[30px] w-[148px]"
                />
                <Typography className="ml-3 flex items-center">
                  {data?.refId}
                </Typography>
              </Flex>
              <Flex className="mt-2">
                <InputLabel
                  label={t('customerDetail.modals.fullName')}
                  className="h-[30px] w-[148px]"
                />
                <Typography className="ml-3 flex items-center">
                  {(
                    (data?.additionalInfo?.firstName ?? '') +
                    (data?.additionalInfo?.lastName ?? '')
                  ).trim()}
                </Typography>
              </Flex>

              <Flex className="mt-2">
                <InputLabel
                  label={t('lable.dateOfBirth')}
                  className="h-[30px] w-[148px]"
                />
                <Typography className="ml-3 flex items-center">
                  {data?.birthday}
                </Typography>
              </Flex>
            </div>
            <Flex justify="center" className="mt-[85px]">
              <Button
                className="w-[180px] text-primary bg-white py-2 leading-none font-bold mt-[28px] m-auto border-primary"
                onClick={onCancel}
              >
                {t('button.close')}
              </Button>
            </Flex>
          </div>
        </Flex>
      }
    />
  )
}

export default DuplicateIdError
