import { useState } from 'react'
import { useBetween } from 'use-between'

const useSharedQuestionnaires = () => {
  const [hiddenQuestionnaires, setHiddenQuestionnaires] = useState<any[]>([])

  return {
    hiddenQuestionnaires,
    setHiddenQuestionnaires
  }
}

export const useQuestionnaires = () => useBetween(useSharedQuestionnaires)