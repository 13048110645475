import { useState } from 'react'

import dayjs from 'dayjs'

import {
  AGGREGATION_FILTER,
  AggregationFilter
} from '../graphql/AggregationFilter/aggregationFilter'
import { FILTER_MEDICAL_CHECKUP } from '../graphql/MedicalCheckup/filterMedicalCheckup'
import { UPDATE_MEDICAL_CHECKUP } from '../graphql/MedicalCheckup/updateMedicalCheckup'
import { useUpdateMedicalCheckup } from './useMedicalCheckup'
import useTestItemMaster from './useTestItemMaster'
import useTestResult, { useUpdateTestResult } from './useTestResult'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  FEEDBACK_STATUS,
  PATIENT_ID_CODE,
  STATUS_CODE_SUCCESS
} from 'configs/constant'
import { CREATE_CHECKUP_USER } from 'graphql/CheckupUser/createCheckupUser'
import {
  FILTER_CHECKUP_USER,
  UPDATE_CHECKUP_USER
} from 'graphql/CheckupUser/filterCheckupUser'
import { CREATE_SURVEY_FEEDBACK } from 'graphql/SurveyFeedback/create'
import { FILTER_SURVEY_FEEDBACK } from 'graphql/SurveyFeedback/filter'
import { UPDATE_SURVEY_FEEDBACK } from 'graphql/SurveyFeedback/update'
import { QuestionTypeEnums } from 'models/survey'
import { Endpoint } from 'utilities/apolloClient'

export type Customer = {
  refId: string
  vendorAssignedRefId: string | null
  gender: string
  birthday: string
  additionalInfo: {
    avatar?: string
    firstName?: string
    firstNameKana?: string
    lastName?: string
    lastNameKana?: string
    fullName?: string
    zipCode?: string
    country?: string
    apartment?: string
    stateName?: string
    province?: string
    district?: string
    detailAddress?: string
    email?: string
    dialCodePhone?: string
    phone?: string
    dialCodeEmergencyContact?: string
    emergencyContact?: string
    //
    note?: string
    collaborativeApplication?: string
    userID?: string
    applicationStartDate?: string
    //
    typeOfInsurance?: string
    numberOfInsurance?: string
    insuranceSymbol?: string
    insuranceNumber?: string
    insuranceStartDate?: string
    insuranceEndDate?: string
    address?: string
  }
  createdDate: string
  createdBy: string
  visibility?: any
  fullName?: string
  age?: number
  doctorName?: string
  dateOfExam?: string
  resultOfExam?: number
  medicalCheckupDetail?: any
}

export type CustomerDetail = {
  id?: string
  avatar?: string
  firstName?: string
  lastName?: string
  firstNameKana?: string
  lastNameKana?: string
  fullName?: string
  birthday: string
  patientId?: string
  age?: number
  gender: string
  zipCode?: string
  country?: string
  province?: string
  district?: string
  detailAddress?: string
  stateName?: string
  apartment?: string
  dialCodePhone?: string
  phone?: string
  dialCodeEmergencyContact?: string
  emergencyContact?: string
  email?: string
  //
  collaborativeApplication?: string
  userID?: string
  applicationStartDate?: string
  note?: string
  dateOfExam?: string
  resultOfExam?: string
  //
  typeOfInsurance?: string
  numberOfInsurance?: string
  insuranceSymbol?: string
  insuranceNumber?: string
  insuranceStartDate?: string
  insuranceEndDate?: string
  //
  doctorName?: string
  medicalStatus?: number
  medicalRecordNumber?: number
  medicalCheckupDetail?: Object
  address?: string
}

type Filter = {
  filter: string
  page: number
  size: number
  sortBy?: string
}

function calculateAge(date: string) {
  if (!date) return

  const birthDate = new Date(date)
  const currentDate = new Date()

  let age = currentDate.getFullYear() - birthDate.getFullYear()
  const monthDifference = currentDate.getMonth() - birthDate.getMonth()
  const dayDifference = currentDate.getDate() - birthDate.getDate()

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--
  }

  return age
}

const cleanData = (obj: Record<string, string | null>) =>
  obj
    ? Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value != null)
      )
    : {}

const useCustomer = () => {
  const [filter] = useState<Filter>()
  const [aggregationFilter] = useState<AggregationFilter>()
  const [loading, setLoading] = useState<boolean>(true)

  const [getMedicalCheckup] = useLazyQuery(FILTER_MEDICAL_CHECKUP, {
    fetchPolicy: 'network-only'
  })

  const { updateMedicalCheckup: handleUpdateMedicalCheckup } =
    useUpdateMedicalCheckup()
  const { updateTestResult } = useUpdateTestResult()
  const { getListTestResult } = useTestResult()
  const { getListItemMaster } = useTestItemMaster()

  const [createCustomer] = useMutation(CREATE_CHECKUP_USER)
  const [updateCustomer] = useMutation(UPDATE_CHECKUP_USER)
  const [updateMedicalCheckup] = useMutation(UPDATE_MEDICAL_CHECKUP)
  const [loadDataSurveyFeedback] = useLazyQuery(FILTER_SURVEY_FEEDBACK)
  const [createSurveyFeedbackMutation] = useMutation(CREATE_SURVEY_FEEDBACK, {
    context: { version: Endpoint.SURVEY_CORE }
  })
  const [updateSurveyFeedbackMutation] = useMutation(UPDATE_SURVEY_FEEDBACK, {
    context: { version: Endpoint.SURVEY_CORE }
  })

  const { refetch: refetchUser } = useQuery(FILTER_CHECKUP_USER, {
    variables: filter,
    skip: !filter
  })

  const { refetch } = useQuery(AGGREGATION_FILTER, {
    variables: aggregationFilter,
    skip: !aggregationFilter
  })

  const onCreateCustomer = async (customer: any) => {
    const handledData = {
      ...customer,
      additionalInfo: cleanData(customer.additionalInfo)
    }

    try {
      const { data } = await createCustomer({
        variables: {
          request: [handledData]
        }
      })
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const onUpdateCustomer = async (customer: any) => {
    try {
      const firstName = customer.firstName || ''
      const lastName = customer.lastName || ''
      const firstNameKana = customer.firstNameKana || ''
      const lastNameKana = customer.lastNameKana || ''

      const fullName =
        `${firstName}${lastName} ${firstNameKana}${lastNameKana}`.trim()

      const additionalInfo = {
        avatar: customer.avatar ? customer.avatar : null,
        firstName: customer.firstName ? customer.firstName : null,
        firstNameKana: customer.firstNameKana ? customer.firstNameKana : null,
        lastName: customer.lastName ? customer.lastName : null,
        lastNameKana: customer.lastNameKana ? customer.lastNameKana : null,
        fullName: fullName || null,
        province: customer.province ? customer.province : null,
        district: customer.district ? customer.district : null,
        detailAddress: customer.detailedAddress
          ? customer.detailedAddress
          : null,
        email: customer.email ? customer.email : null,
        dialCodePhone: customer.dialCodePhone ? customer.dialCodePhone : null,
        phone: customer.phone ? customer.phone : null,
        dialCodeEmergencyContact: customer.dialCodeEmergencyContact
          ? customer.dialCodeEmergencyContact
          : null,
        emergencyContact: customer.emergencyContact
          ? customer.emergencyContact
          : null,
        typeOfInsurance: customer.typeOfInsurance
          ? customer.typeOfInsurance
          : null,
        numberOfInsurance: customer.numberOfInsurance
          ? customer.numberOfInsurance
          : null,
        insuranceSymbol: customer.insuranceSymbol
          ? customer.insuranceSymbol
          : null,
        insuranceNumber: customer.insuranceNumber
          ? customer.insuranceNumber
          : null,
        insuranceStartDate: customer.insuranceStartDate
          ? customer.insuranceStartDate
          : null,
        insuranceEndDate: customer.insuranceEndDate
          ? customer.insuranceEndDate
          : null,
        collaborativeApplication: customer.collaborativeApplication
          ? customer.collaborativeApplication
          : null,
        applicationStartDate: customer.applicationStartDate
          ? customer.applicationStartDate
          : null,
        userID: customer.userID ? customer.userID : null,
        note: customer.note ? customer.note : null,
        isDeleted: 'false',
        country: customer.country ? customer.country : null,
        apartment: customer.apartment ? customer.apartment : null,
        stateName: customer.stateName ? customer.stateName : null,
        address: customer.address ? customer.address : null,
        zipCode: customer.zipCode ? customer.zipCode : null
      }

      const dataConverted = {
        refId: customer.id,
        gender: customer.gender ? customer.gender : 'MALE',
        birthday: `${dayjs(customer.birthday, 'YYYY/MM/DD').format('YYYY-MM-DD')}`,
        additionalInfo: cleanData(additionalInfo)
      }

      const { data } = await updateCustomer({
        variables: {
          request: [dataConverted]
        }
      })
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const onUpdateDataCustomerByNewId = async (oldId: string, newId: string) => {
    try {
      let surveyFeedbackResponse: any = await getSurveyFeedback({
        filter: `(in,STRING,userRefId,${oldId});(ne,STRING,feedbackStatus,DELETED)`
      })

      surveyFeedbackResponse =
        surveyFeedbackResponse?.data?.filterSurveyFeedback?.payload
      if (
        Array.isArray(surveyFeedbackResponse) &&
        surveyFeedbackResponse.length > 0
      ) {
        for (let i = 0; i < surveyFeedbackResponse.length; i++) {
          let dataCreateSurveyFeedback = {
            surveyFeedbackInputs: [
              {
                userRefId: newId,
                surveyRefId: surveyFeedbackResponse[i].surveyRefId,
                feedbackStatus: FEEDBACK_STATUS.ASSIGNED
              }
            ]
          }

          let surveyFeedbackCreate = await createSurveyFeedbackMutation({
            variables: dataCreateSurveyFeedback,
            context: { version: Endpoint.SURVEY_CORE },
            fetchPolicy: 'network-only'
          })
          if (
            surveyFeedbackCreate?.data?.addSurveyFeedback?.statusCode ===
            STATUS_CODE_SUCCESS
          ) {
            let surveyFeedback =
              surveyFeedbackCreate?.data?.addSurveyFeedback?.payload?.[0]

            let selectedAnswerList: any = []

            if (Array.isArray(surveyFeedbackResponse[i]?.selectedAnswerList)) {
              for (let question of surveyFeedbackResponse[i]
                .selectedAnswerList) {
                switch (question.question.questionType) {
                  case QuestionTypeEnums.choice:
                    selectedAnswerList.push({
                      questionRefId: question.question.refId,
                      options: question.options.map(function (item: any) {
                        return item.refId
                      })
                    })
                    break
                  case QuestionTypeEnums.text:
                    selectedAnswerList.push({
                      questionRefId: question.question.refId,
                      options: [],
                      displayNameMap: { ...question.displayNameMap }
                    })
                    break
                  case QuestionTypeEnums.date:
                    selectedAnswerList.push({
                      questionRefId: question.question.refId,
                      selectedDate: question.selectedDate
                    })
                    break
                  case QuestionTypeEnums.time:
                    selectedAnswerList.push({
                      questionRefId: question.question.refId,
                      selectedTime: question.selectedTime
                    })
                    break
                }
              }
            }

            let dataUpdateSurveyFeedback = {
              refId: surveyFeedback.refId,
              endedDateTime: surveyFeedbackResponse[i].endedDateTime,
              feedbackStatus: surveyFeedbackResponse[i].feedbackStatus,
              selectedAnswerList: selectedAnswerList
            }

            await updateSurveyFeedback({
              surveyFeedbackInput: dataUpdateSurveyFeedback
            })
          }
        }
      }

      const medicalCheckupResponse = await getListMedicalCheckupByUserId(oldId)
      if (
        Array.isArray(medicalCheckupResponse) &&
        medicalCheckupResponse.length > 0
      ) {
        let dataUpdateMedicalCheckup = []
        for (let i = 0; i < medicalCheckupResponse.length; i++) {
          let { createdDate, createdBy, ...medicalCheckupUpdate } =
            medicalCheckupResponse[i].medicalCheckup

          dataUpdateMedicalCheckup.push({
            ...medicalCheckupUpdate,
            checkupUserRef: newId
          })
        }

        await handleUpdateMedicalCheckup(dataUpdateMedicalCheckup)
      }

      let testResultResponse = await getListTestResult({
        filter: `(eq,STRING,medicalCheckupUserRefId,${oldId});(eq,STRING,status,PUBLISHED)`,
        sortBy: '(desc,readingTakenOn)'
      })
      testResultResponse = testResultResponse?.data?.filterTestResult?.payload

      if (Array.isArray(testResultResponse) && testResultResponse.length > 0) {
        let dataUpdateTestResult = []
        let itemMasterCustomerIdResponse: any = await getListItemMaster({
          filter: `(in,STRING,code.coding.code,${PATIENT_ID_CODE});(eq,STRING,status,PUBLISHED)`
        })

        itemMasterCustomerIdResponse =
          itemMasterCustomerIdResponse?.data?.filterTestItemMaster?.payload

        let itemMasterCustomerId: any
        if (
          Array.isArray(itemMasterCustomerIdResponse) &&
          itemMasterCustomerIdResponse.length > 0
        )
          itemMasterCustomerId = itemMasterCustomerIdResponse[0].refId

        for (let i = 0; i < testResultResponse.length; i++) {
          if (testResultResponse[i].itemMasterRefId === itemMasterCustomerId) {
            dataUpdateTestResult.push({
              ...testResultResponse[i],
              medicalCheckupUserRefId: newId,
              value: newId
            })

            continue
          }

          dataUpdateTestResult.push({
            ...testResultResponse[i],
            medicalCheckupUserRefId: newId
          })
        }

        await updateTestResult(dataUpdateTestResult)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getSurveyFeedback = async (variables: any) => {
    try {
      const response = await loadDataSurveyFeedback({
        variables: {
          filter: '',
          sortBy: '',
          ...variables
        },
        context: { version: Endpoint.SURVEY_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'no-cache'
      })

      return response
    } catch (error) {
      console.error(error)
    }
  }

  const updateSurveyFeedback = async (variables: any) => {
    try {
      const response = await updateSurveyFeedbackMutation({
        variables: variables
      })

      return response
    } catch (error) {
      console.error(error)
    }
  }

  const getListMedicalCheckupByUserId = async (userId: string) => {
    try {
      const result = await getMedicalCheckup({
        variables: {
          filter: `(eq,STRING,checkupUserRef,${userId});(eq,STRING,status,PUBLISHED);(ne,STRING,additionalInfo.status,DELETED)`,
          page: 0,
          size: -1,
          sortBy: '(desc,createdDate)'
        },
        context: { version: Endpoint.CHECKUP_CORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })

      return result?.data?.filterMedicalCheckupDetails?.payload
    } catch (err) {
      console.error(err)
    }
  }

  const handleDataCustomerDetail = (customer: any) => {
    const firstName = customer.firstName || ''
    const lastName = customer.lastName || ''
    const firstNameKana = customer.firstNameKana || ''
    const lastNameKana = customer.lastNameKana || ''

    const fullName =
      `${firstName}${lastName} ${firstNameKana}${lastNameKana}`.trim()

    const dataConverted = {
      refId: customer.id,
      gender: customer.gender ? customer.gender : 'MALE',
      birthday: `${dayjs(customer.birthday, 'YYYY/MM/DD').format('YYYY-MM-DD')}`,
      additionalInfo: {
        avatar: customer.avatar ? customer.avatar : null,
        firstName: customer.firstName ? customer.firstName : null,
        firstNameKana: customer.firstNameKana ? customer.firstNameKana : null,
        lastName: customer.lastName ? customer.lastName : null,
        lastNameKana: customer.lastNameKana ? customer.lastNameKana : null,
        fullName: fullName || null,
        zipCode: customer.zipCodePart1
          ? `${customer.zipCodePart1}${customer.zipCodePart2}`
          : null,
        province: customer.province ? customer.province : null,
        district: customer.district ? customer.district : null,
        detailAddress: customer.detailedAddress
          ? customer.detailedAddress
          : null,
        email: customer.email ? customer.email : null,
        dialCodePhone: customer.dialCodePhone
          ? customer.dialCodePhone
          : undefined,
        phone: customer.phone ? customer.phone : null,
        dialCodeEmergencyContact: customer.dialCodeEmergencyContact
          ? customer.dialCodeEmergencyContact
          : undefined,
        emergencyContact: customer.emergencyContact
          ? customer.emergencyContact
          : null,
        typeOfInsurance: customer.typeOfInsurance
          ? customer.typeOfInsurance
          : null,
        numberOfInsurance: customer.numberOfInsurance
          ? customer.numberOfInsurance
          : null,
        insuranceSymbol: customer.insuranceSymbol
          ? customer.insuranceSymbol
          : null,
        insuranceNumber: customer.insuranceNumber
          ? customer.insuranceNumber
          : null,
        insuranceStartDate: customer.insuranceStartDate
          ? customer.insuranceStartDate
          : null,
        insuranceEndDate: customer.insuranceEndDate
          ? customer.insuranceEndDate
          : null,
        collaborativeApplication: customer.collaborativeApplication
          ? customer.collaborativeApplication
          : null,
        applicationStartDate: customer.applicationStartDate
          ? customer.applicationStartDate
          : null,
        userID: customer.userID ? customer.userID : null,
        note: customer.note ? customer.note : null,
        isDeleted: 'false'
      }
    }

    return dataConverted
  }

  const onDeleteCustomer = async (customer: any) => {
    try {
      const additionalInfo = {
        avatar: customer.avatar ? customer.avatar : null,
        firstName: customer.firstName ? customer.firstName : null,
        firstNameKana: customer.firstNameKana ? customer.firstNameKana : null,
        lastName: customer.lastName ? customer.lastName : null,
        lastNameKana: customer.lastNameKana ? customer.lastNameKana : null,
        fullName: customer.fullName || null,
        zipCode: customer.zipCodePart1
          ? `${customer.zipCodePart1}${customer.zipCodePart2}`
          : null,
        province: customer.province ? customer.province : null,
        district: customer.district ? customer.district : null,
        detailAddress: customer.detailedAddress
          ? customer.detailedAddress
          : null,
        email: customer.email ? customer.email : null,
        dialCodePhone: customer.dialCodePhone ? customer.dialCodePhone : null,
        phone: customer.phone ? customer.phone : null,
        dialCodeEmergencyContact: customer.dialCodeEmergencyContact
          ? customer.dialCodeEmergencyContact
          : null,
        emergencyContact: customer.emergencyContact
          ? customer.emergencyContact
          : null,
        typeOfInsurance: customer.typeOfInsurance
          ? customer.typeOfInsurance
          : null,
        numberOfInsurance: customer.numberOfInsurance
          ? customer.numberOfInsurance
          : null,
        insuranceSymbol: customer.insuranceSymbol
          ? customer.insuranceSymbol
          : null,
        insuranceNumber: customer.insuranceNumber
          ? customer.insuranceNumber
          : null,
        insuranceStartDate: customer.insuranceStartDate
          ? customer.insuranceStartDate
          : null,
        insuranceEndDate: customer.insuranceEndDate
          ? customer.insuranceEndDate
          : null,
        collaborativeApplication: customer.collaborativeApplication
          ? customer.collaborativeApplication
          : null,
        applicationStartDate: customer.applicationStartDate
          ? customer.applicationStartDate
          : null,
        userID: customer.userID ? customer.userID : null,
        note: customer.note ? customer.note : null,
        isDeleted: 'true'
      }

      const dataConverted = {
        refId: customer.id,
        gender: customer.gender,
        birthday: customer.birthday,
        additionalInfo: cleanData(additionalInfo)
      }

      const { data } = await updateCustomer({
        variables: {
          request: [dataConverted]
        }
      })
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const onFilterCustomer = async (filterValue: Filter) => {
    const { data } = await refetchUser(filterValue)

    if (!data) return undefined

    const customer: Customer = data.filterCheckupUser.payload[0]

    const age = calculateAge(customer.birthday)
    let healthCheckStatus

    const { data: additionalData } = await getMedicalCheckup({
      variables: {
        filter: `(eq,STRING,checkupUserRef,${customer.refId});(eq,STRING,status,PUBLISHED);(ne,STRING,additionalInfo.status,DELETED)`,
        page: 0,
        size: 1,
        sortBy: '(desc,createdDate)'
      }
    })
    const medicalCheckup =
      additionalData.filterMedicalCheckupDetails.payload[0]?.medicalCheckup
    if (!additionalData || !medicalCheckup) {
      healthCheckStatus = 0
    } else {
      healthCheckStatus = medicalCheckup.additionalInfo.healthCheckStatus
    }

    const dataConvert: CustomerDetail = {
      id: customer.refId,
      avatar: customer.additionalInfo.avatar,
      firstName: customer.additionalInfo.firstName,
      lastName: customer.additionalInfo.lastName,
      firstNameKana: customer.additionalInfo.firstNameKana,
      lastNameKana: customer.additionalInfo.lastNameKana,
      fullName: customer.additionalInfo.fullName,
      birthday: customer.birthday,
      age: age,
      gender: customer.gender,
      zipCode: customer.additionalInfo.zipCode,
      country: customer.additionalInfo.country,
      province: customer.additionalInfo.province,
      district: customer.additionalInfo.district,
      detailAddress: customer.additionalInfo.detailAddress,
      apartment: customer.additionalInfo.apartment,
      stateName: customer.additionalInfo.stateName,
      dialCodePhone: customer.additionalInfo.dialCodePhone,
      phone: customer.additionalInfo.phone,
      dialCodeEmergencyContact:
        customer.additionalInfo.dialCodeEmergencyContact,
      emergencyContact: customer.additionalInfo.emergencyContact,
      email: customer.additionalInfo.email,
      //
      collaborativeApplication:
        customer.additionalInfo.collaborativeApplication,
      userID: customer.additionalInfo.userID,
      applicationStartDate: customer.additionalInfo.applicationStartDate,
      note: customer.additionalInfo.note,
      //
      typeOfInsurance: customer.additionalInfo.typeOfInsurance,
      numberOfInsurance: customer.additionalInfo.numberOfInsurance,
      insuranceNumber: customer.additionalInfo.insuranceNumber,
      insuranceSymbol: customer.additionalInfo.insuranceSymbol,
      insuranceStartDate: customer.additionalInfo.insuranceStartDate,
      insuranceEndDate: customer.additionalInfo.insuranceEndDate,
      medicalStatus: healthCheckStatus,
      patientId: customer.refId,
      address: customer.additionalInfo.address
    }

    return dataConvert
  }

  const onFilterCustomers = async (filterValue: AggregationFilter) => {
    setLoading(true)
    const { data } = await refetch(filterValue)

    if (!data)
      return {
        customers: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
      }

    const customers = data.commonAggregationFilter.payload.map(
      (customer: any) => {
        const age = calculateAge(customer.birthday)

        const dateOfExam = customer?.testResult
          ? customer.testResult.readingTakenOn
          : null

        return {
          ...customer,
          birthday: customer.birthday.split('T')[0],
          age,
          fullName: customer.additionalInfo?.fullName
            ? customer.additionalInfo.fullName
            : null,
          doctorName: customer?.testResult ? customer.testResult.valueBy : null,
          dateOfExam: dateOfExam ? dateOfExam.split('T')[0] : null,
          resultOfExam: customer?.medicalCheckup
            ? customer.medicalCheckup.additionalInfo.healthCheckStatus
            : 0,
          medicalCheckupDetail: customer.medicalCheckup
        }
      }
    )

    setLoading(false)
    return {
      customers,
      currentPage: filterValue.page + 1,
      totalPages: Math.ceil(
        data.commonAggregationFilter.totalElements / filterValue.size
      ),
      totalRecords: data.commonAggregationFilter.totalElements
    }
  }

  const onUpdateStatusCustomer = async (userId: string, status: number) => {
    try {
      const { data: medicalCheckup } = await getMedicalCheckup({
        variables: {
          filter: `(eq,STRING,checkupUserRef,${userId})`,
          page: 0,
          size: 1,
          sortBy: '(desc,createdDate)'
        }
      })
      const medicalCheckupDetail =
        medicalCheckup.filterMedicalCheckupDetails.payload[0]?.medicalCheckup

      if (!medicalCheckupDetail) {
        return
      }

      let { createdBy, createdDate, ...rest } = medicalCheckupDetail
      let updatedData = {
        ...rest,
        additionalInfo: {
          ...medicalCheckupDetail.additionalInfo,
          healthCheckStatus: status
        }
      }

      const { data } = await updateMedicalCheckup({
        variables: {
          request: [updatedData]
        }
      })

      return data
    } catch (error) {
      console.error(error)
    }
  }

  const onCountStatusCustomer = async (newQuery: any[]) => {
    setLoading(true)

    newQuery.push({
      field: 'status',
      operator: 'eq',
      value: 'PUBLISHED'
    })
    newQuery.push({
      field: 'additionalInfo.isDeleted',
      operator: 'eq',
      value: 'false'
    })

    const filteredArray = newQuery.filter(
      (item) =>
        !item.field ||
        !item.field.includes('medicalCheckup.additionalInfo.healthCheckStatus')
    )

    const query = {
      collection: 'checkupUser',
      page: 0,
      size: -1,
      request: [
        {
          type: 'MATCH',
          criteria: {
            and: filteredArray
          }
        },
        {
          type: 'LOOKUP',
          criteria: {
            from: 'medicalCheckup',
            localField: 'refId',
            foreignField: 'checkupUserRef',
            as: 'medicalCheckup'
          }
        },
        {
          type: 'ADD_FIELDS',
          criteria: {
            medicalCheckup: {
              $arrayElemAt: [
                {
                  $sortArray: {
                    input: {
                      $filter: {
                        input: '$medicalCheckup',
                        as: 'mc',
                        cond: {
                          $eq: ['$$mc.status', 'PUBLISHED']
                        }
                      }
                    },
                    sortBy: {
                      createdDate: -1
                    }
                  }
                },
                0
              ]
            }
          }
        },
        {
          type: 'SORT',
          criteria: {
            field: 'createdDate',
            direction: 'desc'
          }
        },
        {
          type: 'GROUP',
          criteria: {
            field: 'medicalCheckup.additionalInfo.healthCheckStatus'
          }
        }
      ]
    }
    const { data } = await refetch(query)

    const countCustomerByStatus = data.commonAggregationFilter.payload.reduce(
      (acc: any[], item: any) => {
        const status = item._id === null || item._id === 0 ? 0 : item._id

        const existingItem = acc.find((entry) => entry.status === status)

        if (existingItem) {
          existingItem.count += item.data.length
        } else {
          acc.push({
            status,
            count: item.data.length
          })
        }

        return acc
      },
      []
    )

    setLoading(false)
    return {
      data: countCustomerByStatus
    }
  }

  return {
    onCreateCustomer,
    onFilterCustomer,
    onFilterCustomers,
    onUpdateCustomer,
    onDeleteCustomer,
    handleDataCustomerDetail,
    onUpdateStatusCustomer,
    onCountStatusCustomer,
    onUpdateDataCustomerByNewId,
    loading
  }
}

export default useCustomer
