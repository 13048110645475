import { FormEventHandler } from 'react'
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input, Typography } from 'antd'

import { SizeType } from 'antd/es/config-provider/SizeContext'

type Props = {
  className?: string
  name: string
  placeholder?: string
  defaultValue?: string
  showErrorMessage?: boolean
  size?: SizeType
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
  onInput?: FormEventHandler<HTMLInputElement> | undefined
}

export const InputForm = ({
  className = '',
  name,
  placeholder,
  defaultValue,
  size = 'middle',
  showErrorMessage = false,
  rules,
  onInput
}: Props) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <>
          <Input
            className={className}
            size={size}
            status={error ? 'error' : ''}
            placeholder={placeholder}
            {...field}
            value={field.value ?? defaultValue}
            onInput={onInput}
          />
          {showErrorMessage && error?.message && (
            <Typography className="text-error text-xs h-3 mt-1.5 leading-none text-left">
              {t(error?.message)}
            </Typography>
          )}
        </>
      )}
    />
  )
}

type InputProps = {
  className?: string
  name: string
  placeholder?: string
  value?: string
  size?: SizeType
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
  onChange?: ((name: string, value: string) => void) | null
  disabled?: boolean
  type?: string
}

export const InputCustom = ({
  className = '',
  name,
  placeholder,
  value,
  size = 'middle',
  rules,
  onChange,
  disabled = false,
  type = 'text'
}: InputProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const {
          value: fieldValue,
          onChange: fieldOnChange,
          ...restField
        } = field
        return (
          <Input
            type={type}
            className={className}
            size={size}
            status={error ? 'error' : ''}
            placeholder={placeholder}
            value={fieldValue ?? value}
            onChange={(e) => {
              fieldOnChange(e)
              if (onChange) {
                onChange(name, e.target.value)
              }
            }}
            {...restField}
            disabled={disabled}
          />
        )
      }}
    />
  )
}

type InputNumberProps = {
  className?: string
  name: string
  placeholder?: string
  value?: string
  size?: SizeType
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >
    | undefined
  onChange?: ((name: string, value: string) => void) | null
  disabled?: boolean
  type?: string
  min?: number
  step?: number
}

export const InputNumberCustom = ({
  className = '',
  name,
  placeholder,
  value,
  size = 'middle',
  rules,
  onChange,
  disabled = false,
  min,
  step = 1
}: InputNumberProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const {
          value: fieldValue,
          onChange: fieldOnChange,
          ...restField
        } = field
        return (
          <Input
            type="number"
            className={className}
            size={size}
            status={error ? 'error' : ''}
            placeholder={placeholder}
            value={fieldValue ?? value}
            onChange={(e) => {
              fieldOnChange(e)
              if (onChange) {
                onChange(name, e.target.value)
              }
            }}
            {...restField}
            min={min}
            step={step}
            disabled={disabled}
          />
        )
      }}
    />
  )
}
