import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  CheckboxProps,
  Flex,
  Popover,
  TableProps
} from 'antd'
import i18n from 'i18next'

import { useRoles, useRolesPermissions } from '../../../hooks/useRoles'
import { isArrayEmpty } from '../../../utilities/helpers'
import { TLocales } from '../country'
import AddRoleModel from './AddRoleModel'
import AddRoleSuccessModel from './AddRoleSuccessModel'
import PermissionTable from './PermissionTable'
import PopoverTitlePermissionEdit from './PopoverTitlePermissionEdit'
import { ReactComponent as CheckedIcon } from 'assets/svgs/checkedIcon.svg'
import { ReactComponent as EditIcon } from 'assets/svgs/pen.svg'
import { ReactComponent as UnCheckIcon } from 'assets/svgs/unCheckIcon.svg'
import {
  Permission as IPermission,
  Role,
  permissionListSample
} from 'models/admin'

interface AssignRoleProps {
  checked: boolean
  role: any
  permission: string
}

const AssignPermissionMemo = ({ checked, role, permission }: AssignRoleProps) =>
  useMemo(
    () => (
      <AssignRolePermissions
        checked={checked}
        role={role}
        permission={permission}
      />
    ),
    [checked, permission, role]
  )

const AssignRolePermissions = ({
  checked: checkedProps,
  role: roleProps,
  permission: permissionKey
}: AssignRoleProps) => {
  const [checked, setChecked] = useState<boolean>(checkedProps)
  const [role, setRole] = useState<any>({})
  const { setRolePermissions, rolePermissions } = useRolesPermissions()

  React.useEffect(() => {
    setRole(roleProps)
  }, [])

  const onChange: CheckboxProps['onChange'] = (e) => {
    const {
      target: { checked: _checked }
    } = e
    setChecked(_checked)

    let prevRole = { ...role }

    let { permissions, id } = prevRole
    const newData = [...rolePermissions]
    const prevIndex = newData.findIndex((r) => r.id === id)

    if (prevIndex > -1) {
      prevRole = newData[prevIndex]
      permissions = prevRole.permissions
    }

    const nextPermissions = permissions.filter(
      (p: string) => p !== permissionKey
    )

    if (_checked) {
      nextPermissions.push(permissionKey)
    }

    prevRole.permissions = nextPermissions

    if (prevIndex > -1) {
      newData.splice(prevIndex, 1, {
        ...role,
        ...prevRole
      })
    } else {
      newData.push(prevRole)
    }

    setRolePermissions(newData)
  }

  return <Checkbox name={role.name} checked={checked} onChange={onChange} />
}

export default function RolePermissionTab() {
  const { t } = useTranslation()
  const currentLocale = i18n.language
  const [showAddRoleModal, setShowAddRoleModal] = useState<boolean>(false)
  const [showAddRoleSuccess, setShowAddRoleSuccess] = useState<boolean>(false)

  const { loading, roles, onGetRoles, onAssignRolesPermissions } = useRoles()
  const { rolePermissions, edit, setEdit, setRolePermissions } =
    useRolesPermissions()

  React.useEffect(() => {
    onGetRoles()
  }, [])

  const permissionListSort = permissionListSample.sort(
    (a, b) => Number(a.key) - Number(b.key)
  )

  const getRoleLocale = (item: Role) => {
    const locale: TLocales = currentLocale as TLocales
    return item[locale] ?? ''
  }

  const roleColumns = roles.map(function (item: Role) {
    return {
      title: !edit ? (
        getRoleLocale(item)
      ) : (
        <Popover
          content={<PopoverTitlePermissionEdit role={item} />}
          trigger="click"
          overlayClassName="popover-permission"
        >
          {getRoleLocale(item)}{' '}
          <EditIcon className="cursor-pointer text-primary inline-block w-[14px]" />
        </Popover>
      ),
      ...item,
      id: item.id,
      name: item.name,
      description: getRoleLocale(item),
      dataIndex: item.name,
      permissions: item.permissions,
      order: item.order,
      width: '90px'
    }
  })

  const columns: TableProps<IPermission>['columns'] = [
    {
      title: '',
      dataIndex: 'groupName',
      key: 'groupName',
      width: '90px',
      onCell: (_, index) => {
        // 予約一覧
        // if (index === 0) {
        //   return { rowSpan: 2 }
        // }
        // if (index === 1) {
        //   return { colSpan: 0 }
        // }

        if (index === 1) {
          return { rowSpan: 2 }
        }
        if (index === 2) {
          return { colSpan: 0 }
        }

        // 事前設定
        if (index === 4) {
          // return { rowSpan: 4 }
        }
        if (index === 5 || index === 6 || index === 7) {
          // return { colSpan: 0 }
        }

        return {}
      }
    },
    {
      title: '',
      dataIndex: 'subName',
      key: 'subName',
      width: '100px',
      onCell: (_, index) => {
        // 商品一覧
        if (index === 4) {
          return { rowSpan: 2 }
        }
        if (index === 5) {
          return { colSpan: 0 }
        }

        return {}
      }
    },
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: '180px'
    },
    ...roleColumns
  ]

  function getDataSourceItem(permission: IPermission) {
    let { permissionKey } = permission
    return roleColumns.reduce((data: Record<string, ReactElement>, role) => {
      const { permissions } = role

      if (edit) {
        const checked = permissions.includes(permissionKey)
        data[role.name] = (
          <AssignPermissionMemo
            checked={checked}
            role={role}
            permission={permissionKey}
          />
        )
      } else if (permissions.includes(permissionKey)) {
        data[role.name] = (
          <Flex
            justify="center"
            align="center"
            className="h-[20px] w-full min-w-6"
          >
            <CheckedIcon style={{ width: '100%', height: '100%' }} />
          </Flex>
        )
      } else {
        data[role.name] = (
          <Flex
            justify="center"
            align="center"
            className="h-[20px] w-full min-w-6"
          >
            <UnCheckIcon style={{ width: '100%', height: '100%' }} />
          </Flex>
        )
      }

      return data
    }, {})
  }

  const dataSource = permissionListSort.map(function (item: IPermission) {
    const data = getDataSourceItem(item)
    return {
      key: item.key,
      groupName: t(item.groupName),
      subName: t(item.subName),
      name: t(item.name),
      ...data
    }
  })

  const handleAssignRolePermissions = () => {
    setEdit((prev) => !prev)

    if (isArrayEmpty(rolePermissions)) return
    onAssignRolesPermissions(rolePermissions).then(() => setRolePermissions([]))
  }

  const [minHeight, setMinHeight] = useState(window.innerHeight - 270)
  useEffect(() => {
    const handleResize = () => {
      setMinHeight(window.innerHeight - 270)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Flex className="justify-end gap-2">
        <Button
          className={`${!edit ? 'bg-[#1376952B]' : 'bg-white'} mt-2.5 text-[#137695] py-2 px-12 leading-none border border-[#137695] font-bold tracking-[.7px]`}
          onClick={() => handleAssignRolePermissions()}
        >
          {edit && t('button.keep')}
          {!edit && t('lable.edit')}
        </Button>
        <Button
          className="mt-2.5 text-white bg-primary py-2 px-8 leading-none font-bold"
          type="primary"
          onClick={() => setShowAddRoleModal(true)}
        >
          {t('newAddition')}
        </Button>
      </Flex>

      <Flex
        className="w-full overflow-auto"
        style={{
          minHeight: `${minHeight}px`
        }}
      >
        <PermissionTable
          dataSource={dataSource}
          columns={columns}
          loading={loading}
        />
      </Flex>
      <AddRoleModel
        isModalOpen={showAddRoleModal}
        onCancel={() => setShowAddRoleModal(false)}
        onSuccess={() => setShowAddRoleSuccess(true)}
      />
      <AddRoleSuccessModel
        isModalOpen={showAddRoleSuccess}
        onCancel={() => setShowAddRoleSuccess(false)}
      />
    </>
  )
}
