import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Tag } from 'antd'

type Props = {
  className?: string
  title?: string
  isMultiple?: boolean
  locale?: boolean
  ns?: string
  name: string
  tags: any[]
}

export const CheckableTagForm = ({
  className = '',
  name,
  tags = [],
  title = 'title',
  isMultiple = true,
  locale = false,
  ns = ''
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field
        const selectedTags: string[] = value instanceof Array ? value : [value]

        const { value: _, ...restField } = { ...field }
        // delete restField.value

        return (
          <>
            {tags.map((tag: any) => (
              <Tag.CheckableTag
                {...restField}
                className={`rounded-2xl min-w-36 leading-[1.867rem] text-center ${className}`}
                key={tag.id}
                checked={
                  isMultiple ? selectedTags.includes(tag.id) : value === tag.id
                }
                onChange={(checked) => {
                  if (isMultiple) {
                    const selectedIds = checked
                      ? [...value, tag.id]
                      : value.filter((id: string) => id !== tag.id)
                    onChange(selectedIds)
                  } else {
                    onChange(tag.id)
                  }
                }}
              >
                <span className="text-sm">
                  {locale ? t(`${ns}.${tag[title]}`) : tag[title]}
                </span>
              </Tag.CheckableTag>
            ))}
          </>
        )
      }}
    />
  )
}
