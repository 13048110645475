import common from './common.json'
import customerManagement from './customerManagement.json'
import inspection from './inspection.json'
import questionnaire from './questionnaire.json'
import prefectures from 'locales/en/prefectures.json'
import basicSetting from 'locales/vi/basicSetting.json'
import consultant from 'locales/vi/consultant.json'
import customerDetail from 'locales/vi/customerDetail.json'
import customerRegister from 'locales/vi/customerRegister.json'
import errors from 'locales/vi/errors.json'
import questionnaireResultCreate from 'locales/vi/questionnaireResultCreate.json'

const listTranslationJp = {
  ...common,
  ...{ customerManagement },
  ...{ customerDetail },
  ...{ customerRegister },
  ...{ basicSetting },
  ...{ qrc: questionnaireResultCreate },
  ...{ consultant },
  ...questionnaire,
  ...inspection,
  ...errors,
  prefectures
}

export default listTranslationJp
