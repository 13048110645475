import React, { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { API_LOGIN_CLIENT_TOKEN, TOKEN_PROVIDER } from '../configs/api'
import { useRolePermissions } from '../hooks/role-permission/usePermissions'
import {
  LoginApiProps,
  TOKEN_PROVIDER_ENUM,
  loginApi,
  loginKeycloak
} from '../services/AuthService'
import {
  decodedToken,
  getAccessToken,
  removeAccessToken,
  removeClientAccessToken,
  setAccessToken,
  setClientAccessToken
} from '../utilities/helpers'
import { jwtDecode } from 'jwt-decode'
import networkAdapter from 'utilities/networkAdapter'

interface Props {
  children?: React.ReactNode
}

interface AuthProps {
  access_token: string
  expires_in: string
  refresh_expires_in: string
  refresh_token: string
  token_type: string
}

export type AuthContextType = {
  auth: AuthProps | null
  token: string | null
  logOut: () => void
  loginAction: ({ email, password }: LoginApiProps) => any
  user: any
}

const AuthContext = createContext<AuthContextType | null>(null)

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const { resetUserId, setUserIdState } = useRolePermissions()

  const [auth, setAuth] = useState<AuthProps | null>(null)
  const [user, setUser] = useState<any>(null)
  const [token, setToken] = useState<string | null>(getAccessToken() ?? null)

  React.useEffect(() => {
    const sub = decodedToken(token)
    setUserIdState(sub)
  }, [token])

  const onLogin = async (email: string, password: string) => {
    let token: string
    let response: any
    let code: any

    if (TOKEN_PROVIDER === TOKEN_PROVIDER_ENUM.api) {
      const responseApi = await loginApi({ email, password })
      response = responseApi.payload
      code = responseApi?.status
    } else {
      response = await loginKeycloak({ username: email, password })
    }
    if (!response || response.error)
      return response ?? { error: true, code: code }
    token = response.access_token
    setAuth(response)
    setAccessToken(response)
    setToken(token)
    setUser(jwtDecode(token))

    await handleLoginClient().then()
  }

  const loginAction = ({ email, password }: LoginApiProps) => {
    return onLogin(email, password)
  }

  const handleLoginClient = async () => {
    try {
      const response = await networkAdapter.post(API_LOGIN_CLIENT_TOKEN, {})

      setClientAccessToken(response.payload.access_token)
    } catch (err) {
      console.error(err)
    }
  }

  const logOut = () => {
    setAuth(null)
    setToken(null)
    removeAccessToken()
    removeClientAccessToken()
    resetUserId()

    navigate('/')
    return
  }

  return (
    <AuthContext.Provider value={{ token, auth, loginAction, logOut, user }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => {
  return useContext(AuthContext)
}
