export interface Country {
  name: string
  value: string
  iso2: string
}

export const LocaleKeys = ['ja', 'en', 'vi'] as const
export type TLocales = 'ja' | 'en' | 'vi'

export const countries: Country[] = [
  {
    name: '日本',
    value: 'ja',
    iso2: 'jp'
  },
  {
    name: '英語',
    value: 'en',
    iso2: 'us'
  },
  {
    name: 'ベトナム',
    value: 'vi',
    iso2: 'vn'
  }
]
