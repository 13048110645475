import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Tooltip, Typography } from 'antd'

import { EyeOutlined, QuestionCircleFilled } from '@ant-design/icons'

const ConfirmBox = () => {
  const { t } = useTranslation()

  return (
    <Flex vertical className="">
      <Typography.Text className="text-[11px] text-white">
        <EyeOutlined style={{ color: '#fff' }} className="text-base" />
        {t('questionnaire.helperText.hiddenTooltip')}
        <br /> {t('questionnaire.helperText.unhideTooltip')}
        <br /> {t('questionnaire.helperText.unhideTooltip2')}
      </Typography.Text>
    </Flex>
  )
}
export const UnhideTooltipPopconfirm = () => {
  return (
    <span>
      <Tooltip
        title={<ConfirmBox />}
        color="#000000CC"
        overlayStyle={{
          maxWidth: '30rem'
        }}
        overlayInnerStyle={{
          borderRadius: '5px'
        }}
      >
        <QuestionCircleFilled style={{ color: '#FFF', marginLeft: '3px' }} />
      </Tooltip>
    </span>
  )
}
