import React, { FC } from 'react'

import { Dropdown, Flex, MenuProps, Typography } from 'antd'
import { TFunction } from 'i18next'

import useCustomer from '../../../hooks/useCustomer'

const Status: FC<{
  t: TFunction<'translation', undefined>
  status: number
  currentStatus: number
  onUpdateStatus: (status: number) => void
}> = ({ t, status, currentStatus, onUpdateStatus }) => {
  return (
    <Flex
      justify="center"
      align="center"
      onClick={() => onUpdateStatus(status)} // Gọi hàm khi click vào item
    >
      <Typography
        style={{
          background: currentStatus == status ? '#CDD6DD73' : ''
        }}
        className="px-2 rounded-[13px] py-[2px] text-[14px] tracking-[1.4px] flex justify-center border-[1px] border-[#BDCBD5]"
      >
        {status == 0
          ? t('customerManagement.result.incompatible')
          : status == 1
            ? t('customerManagement.result.creating')
            : status == 2
              ? t('customerManagement.result.waiting')
              : status == 3
                ? t('customerManagement.result.ended')
                : t('customerManagement.result.sent')}
      </Typography>
    </Flex>
  )
}

type Props = {
  t: TFunction<'translation', undefined>
  status: number
  userId: string
  disable: boolean
  isStatusUpdated: boolean
  setStatusUpdated: (status: boolean) => void
}

const StatusDropdown: FC<Props> = ({
  t,
  status,
  userId,
  disable,
  isStatusUpdated,
  setStatusUpdated
}) => {
  const { onUpdateStatusCustomer } = useCustomer()

  const handleUpdateStatus = async (status: number) => {
    await onUpdateStatusCustomer(userId, status).then(() => {
      setStatusUpdated(!isStatusUpdated)
    })
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Status
          t={t}
          status={0}
          currentStatus={status}
          onUpdateStatus={handleUpdateStatus}
        />
      ),
      style: {
        padding: 0,
        marginBottom: '5px'
      }
    },
    {
      key: '2',
      label: (
        <Status
          t={t}
          status={1}
          currentStatus={status}
          onUpdateStatus={handleUpdateStatus}
        />
      ),
      style: {
        padding: 0,
        marginBottom: '5px'
      }
    },
    {
      key: '3',
      label: (
        <Status
          t={t}
          status={3}
          currentStatus={status}
          onUpdateStatus={handleUpdateStatus}
        />
      ),
      style: {
        padding: 0,
        marginBottom: '5px'
      }
    },
    {
      key: '4',
      label: (
        <Status
          t={t}
          status={2}
          currentStatus={status}
          onUpdateStatus={handleUpdateStatus}
        />
      ),
      style: {
        padding: 0,
        marginBottom: '5px'
      }
    },
    {
      key: '5',
      label: (
        <Status
          t={t}
          status={4}
          currentStatus={status}
          onUpdateStatus={handleUpdateStatus}
        />
      ),
      style: {
        padding: 0
      }
    }
  ]

  return (
    <Dropdown
      disabled={disable}
      menu={{ items }}
      placement="bottom"
      trigger={['click']}
      dropdownRender={(menu) => (
        <div className="bg-[#EFF3F6] rounded-t-[5px] w-[159px]">
          <Typography className="text-[#137695] text-[14px] font-bold px-[9px] py-[4px] border-[1px] border-[#BDCBD5] rounded-t-[5px]">
            {t('content.changeStatus')}
          </Typography>
          {React.cloneElement(menu as React.ReactElement, {
            style: {
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              border: '1px solid #BDCBD5',
              borderTop: 'none',
              paddingTop: '10px',
              paddingBottom: '10px'
            }
          })}
        </div>
      )}
    >
      <Flex justify="center" align="center">
        <Typography
          style={{
            background: `${
              status == 0 || status == 1
                ? '#BDCBD5'
                : status == 2
                  ? '#137695'
                  : status == 3
                    ? '#DF2475'
                    : '#545454'
            }`
          }}
          className="px-3 text-nowrap min-w-[119px] rounded-[13px] py-[2px] text-[14px] tracking-[1.4px] text-white flex justify-center cursor-pointer"
        >
          {status == 0
            ? t('customerManagement.result.incompatible')
            : status == 1
              ? t('customerManagement.result.creating')
              : status == 2
                ? t('customerManagement.result.waiting')
                : status == 3
                  ? t('customerManagement.result.ended')
                  : t('customerManagement.result.sent')}
        </Typography>
      </Flex>
    </Dropdown>
  )
}

export default StatusDropdown
