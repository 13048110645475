import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'

import { API_USER } from '../../configs/api'
import { MEDICAL_CHECKUP_STATUS } from '../../configs/constant'
import { RECORD_COUNT } from '../../models/customer'
import { sleep } from '../../utilities/helpers'
import networkAdapter from '../../utilities/networkAdapter'
import Content from './components/Content'
import Widget from './components/Widget'
import CustomPagination from 'components/elements/CustomPagination'
import ScreenLoader from 'components/loading/ScreenLoader'
import useCustomer from 'hooks/useCustomer'

export default function CustomerManagement() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { onFilterCustomers, onCountStatusCustomer, loading } = useCustomer()
  const [customers, setCustomers] = useState<any>()
  const [countCustomerByStatus, setCountCustomerByStatus] = useState<any>()

  // query params
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const pageNumber = Number(queryParams.get('page'))
  const sizeNumber = Number(queryParams.get('size'))
  const orderValue = queryParams.get('orderBy')
  const sortValue = queryParams.get('sortBy')
  const newOrderValue =
    orderValue && sortValue ? `(${sortValue},${orderValue})` : undefined

  const defaultSize = RECORD_COUNT.OneHundred
  const defaultOrderBy = {
    type: 'SORT',
    criteria: {
      field: 'createdDate',
      direction: 'desc'
    }
  }

  const defaultRequests: any[] = [
    {
      type: 'MATCH',
      criteria: {
        and: [
          {
            field: 'status',
            operator: 'eq',
            value: 'PUBLISHED'
          },
          {
            field: 'additionalInfo.isDeleted',
            operator: 'eq',
            value: 'false'
          }
        ]
      }
    },
    {
      type: 'LOOKUP',
      criteria: {
        from: 'medicalCheckup',
        localField: 'refId',
        foreignField: 'checkupUserRef',
        as: 'medicalCheckup'
      }
    },
    {
      type: 'ADD_FIELDS',
      criteria: {
        medicalCheckup: {
          $arrayElemAt: [
            {
              $sortArray: {
                input: {
                  $filter: {
                    input: '$medicalCheckup',
                    as: 'mc',
                    cond: {
                      $eq: ['$$mc.status', 'PUBLISHED']
                    }
                  }
                },
                sortBy: {
                  createdDate: -1
                }
              }
            },
            0
          ]
        }
      }
    },
    {
      type: 'LOOKUP',
      criteria: {
        from: 'testResult',
        localField: 'medicalCheckup.refId',
        foreignField: 'medicalCheckupRefId',
        as: 'testResult'
      }
    },
    {
      type: 'ADD_FIELDS',
      criteria: {
        testResult: {
          $arrayElemAt: [
            {
              $sortArray: {
                input: {
                  $filter: {
                    input: '$testResult',
                    as: 'tr',
                    cond: {
                      $eq: ['$$tr.status', 'PUBLISHED']
                    }
                  }
                },
                sortBy: {
                  createdDate: 1
                }
              }
            },
            0
          ]
        }
      }
    }
  ]

  // filter value
  const [requests, setRequest] = useState<any>(defaultRequests)
  const [page, setPage] = useState<number>(pageNumber ? pageNumber : 1)
  const [size, setSize] = useState<number>(
    sizeNumber ? sizeNumber : defaultSize
  )
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [selectOptions, setSelectOptions] = useState<
    { key: string; value: string; label: string }[]
  >([])

  const [isStatusUpdated, setStatusUpdated] = useState<boolean>(false)
  const [listIdSelected, setListIdSelected] = useState<any>()
  const [newQueryCount, setNewQueryCount] = useState<any[]>([])
  const [isExportData, setIsExportData] = useState<any>(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sleep(500)
        const response = await networkAdapter
          .getClient(API_USER)
          .then((response: any) => {
            return response
          })
        const value = response.map((item: any) => {
          return {
            key: item.id,
            value: `${item.firstName} ${item.lastName}`,
            label: `${item.firstName} ${item.lastName}`
          }
        })
        setSelectOptions(value)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData().then()
  }, [])

  useEffect(() => {
    const newQuery: any[] = []

    if (queryParams.get('fullName')) {
      newQuery.push({
        or: [
          {
            field: 'refId',
            operator: 'like',
            value: queryParams.get('fullName')
          },
          {
            field: 'additionalInfo.fullName',
            operator: 'like',
            value: queryParams.get('fullName')
          }
        ]
      })
    }

    if (
      (queryParams.get('startAge') && queryParams.get('endAge')) ||
      (queryParams.get('startAge') === '0' && queryParams.get('endAge'))
    ) {
      const currentDate = dayjs()
      const startAgeNumber = Number(queryParams.get('startAge'))
      const endAgeNumber = Number(queryParams.get('endAge'))

      const startAge = currentDate
        .subtract(endAgeNumber + 1, 'year')
        .add(1, 'day')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      const endAge = currentDate
        .subtract(startAgeNumber, 'year')
        .subtract(1, 'day')
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      newQuery.push({
        field: 'birthday',
        operator: 'between',
        value: {
          start: startAge,
          end: endAge
        }
      })
    }

    if (
      (queryParams.get('startAge') && !queryParams.get('endAge')) ||
      (queryParams.get('startAge') === '0' && !queryParams.get('endAge'))
    ) {
      const currentDate = dayjs()
      const startAgeNumber = Number(queryParams.get('startAge'))

      const startDate = currentDate
        .subtract(startAgeNumber, 'year')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      newQuery.push({
        field: 'birthday',
        operator: 'lte',
        value: startDate
      })
    }

    if (
      !queryParams.get('startAge') &&
      queryParams.get('startAge') !== '0' &&
      queryParams.get('endAge')
    ) {
      const currentDate = dayjs()
      const endAgeNumber = Number(queryParams.get('endAge'))

      const endDate = currentDate
        .subtract(endAgeNumber + 1, 'year')
        .add(1, 'day')
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      newQuery.push({
        field: 'birthday',
        operator: 'gte',
        value: endDate
      })
    }

    if (queryParams.get('gender')) {
      newQuery.push({
        field: 'gender',
        operator: 'eq',
        value: queryParams.get('gender')
      })
    }

    if (queryParams.get('doctorName')) {
      const doctorName = queryParams.get('doctorName')?.trim() || null

      newQuery.push({
        field: 'testResult.valueBy',
        operator: 'like',
        value: doctorName
      })
    }

    if (queryParams.get('startDate') && queryParams.get('endDate')) {
      newQuery.push({
        field: 'testResult.readingTakenOn',
        operator: 'between',
        value: {
          start: queryParams.get('startDate'),
          end: queryParams.get('endDate')
        }
      })
    }

    if (
      queryParams.get('status') &&
      queryParams.get('status') !== '10' &&
      queryParams.get('status') !== '0'
    ) {
      newQuery.push({
        field: 'medicalCheckup.additionalInfo.healthCheckStatus',
        operator: 'eq',
        value: Number(queryParams.get('status'))
      })
    }

    if (queryParams.get('status') && queryParams.get('status') === '0') {
      newQuery.push({
        field: 'medicalCheckup.additionalInfo.healthCheckStatus',
        operator: 'nin',
        value: [
          MEDICAL_CHECKUP_STATUS.RESULTS_IN_PROGRESS,
          MEDICAL_CHECKUP_STATUS.AWAITING_JUDGEMENT,
          MEDICAL_CHECKUP_STATUS.JUDGMENT_COMPLETE,
          MEDICAL_CHECKUP_STATUS.SUBMITTED
        ]
      })
    }

    const newRequests = defaultRequests

    if (newQuery.length > 0) {
      newRequests.push({
        type: 'MATCH',
        criteria: {
          and: newQuery
        }
      })
    }

    setNewQueryCount(newQuery)
    setRequest(newRequests)
  }, [location.search])

  useEffect(() => {
    let isCancelled = false
    const fetchData = async () => {
      try {
        const filteredCustomers = await onFilterCustomers({
          collection: 'checkupUser',
          page: page - 1,
          size: size,
          request: requests
        })
        const countCustomerByStatus = await onCountStatusCustomer(newQueryCount)
        if (!isCancelled) {
          setTotalRecords(filteredCustomers.totalRecords)
          setCustomers(filteredCustomers.customers)
          setCountCustomerByStatus(countCustomerByStatus)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData().then()

    return () => {
      isCancelled = true
    }
  }, [requests, newQueryCount, isStatusUpdated, page, size])

  useEffect(() => {
    if (!queryParams.toString()) {
      queryParams.set('page', '1')
      queryParams.set('size', `${defaultSize}`)
      navigate(`${location.pathname}?${queryParams.toString()}`)
    }

    const currentPage = Number(queryParams.get('page'))
    const currentSize = Number(queryParams.get('size'))

    if (page !== currentPage) {
      setPage(currentPage)
    }
    if (size !== currentSize) {
      setSize(currentSize)
    }
    if (newOrderValue) {
      const newRequest = defaultRequests
      newRequest.push({
        type: 'SORT',
        criteria: {
          field: orderValue,
          direction: sortValue
        }
      })
      setRequest(newRequest)
    }
    if (!newOrderValue) {
      const newRequest = defaultRequests
      newRequest.push(defaultOrderBy)
      setRequest(newRequest)
    }
  }, [location.search])

  useEffect(() => {
    const currentPage = Number(queryParams.get('page'))
    const currentSize = Number(queryParams.get('size'))

    if (page !== currentPage || size !== currentSize) {
      queryParams.set('page', page.toString())
      queryParams.set('size', size.toString())
      navigate(`${location.pathname}?${queryParams.toString()}`)
    }
  }, [page, size])

  return (
    <div className="min-w-[1900px] relative mt-[-12px]">
      {isExportData && <ScreenLoader />}
      <div className="mb-2">
        <Widget
          t={t}
          selectOptions={selectOptions}
          queryParams={queryParams}
          listIdSelected={listIdSelected}
          setIsExportData={setIsExportData}
        />
      </div>

      <div className="mb-5">
        <Content
          t={t}
          initialData={customers}
          initialDataCount={countCustomerByStatus}
          loading={loading}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          isStatusUpdated={isStatusUpdated}
          setStatusUpdated={setStatusUpdated}
          setListIdSelected={setListIdSelected}
          queryParams={queryParams}
        />
      </div>

      {customers && customers.length > 0 ? (
        <div>
          <CustomPagination
            page={page}
            size={size}
            totalRecords={totalRecords}
            setPage={setPage}
          />
        </div>
      ) : null}
    </div>
  )
}
