import { FILTER_SURVEY_DETAIL } from '../../graphql/Survey/filter'
import { Endpoint } from '../../utilities/apolloClient'
import { forceTypeBoolean } from '../../utilities/helpers'
import { useLazyQuery } from '@apollo/client'

export const useDetailSurvey = () => {
  const [queryDetailSurvey, { loading }] = useLazyQuery(FILTER_SURVEY_DETAIL, {
    variables: {
      filter: `(eq,STRING,refId,)`,
      paginationInput: { page: 0, size: 1 }
    },
    context: { version: Endpoint.SURVEY_CORE }
  })

  const fetchSurveyDetail = async (variables: any) => {
    try {
      const listSurvey: any = await queryDetailSurvey({
        variables,
        context: { version: Endpoint.SURVEY_CORE },
        fetchPolicy: 'network-only' // Used for first execution
      })

      if (listSurvey && listSurvey?.data) {
        const {
          filterSurvey: { payload }
        } = listSurvey.data
        if (payload.length === 1) {
          let survey = payload[0]
          const { sectionList: _sections, additionalInfo } = survey
          const fixedSection = _sections.find(
            (section: any) => section.title.ja === '固定の質問'
          )
          const sectionList = _sections.filter(
            (section: any) => section.title.ja !== '固定の質問'
          )
          return {
            ...survey,
            sectionList,
            fixedSection,
            displayCategories:
              forceTypeBoolean(additionalInfo?.displayCategories) ?? false,
            displayEnglish:
              forceTypeBoolean(additionalInfo?.displayEnglish) ?? false
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    loading,
    queryDetailSurvey,
    fetchSurveyDetail
  }
}
