import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Modal, Spin, Typography } from 'antd'

import ResultPreviewPdf from './exportPdf/ResultPreviewPdf'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ReactComponent as NextIcon } from 'assets/svgs/NextIcon.svg'
import { ReactComponent as Print } from 'assets/svgs/print.svg'
import {
  ComprehensiveJudgment,
  DataTestTable,
  GeneralComment,
  HealthGuidance,
  HealthStatusReport,
  JudgmentCategory,
  LifeStyleReport,
  SpecificHealth,
  SyndromeDetermination,
  TestName
} from 'components/screens/ResultPreview'
import { useTenantList } from 'hooks/tenant/useTenantList'
import useTestItemCategory from 'hooks/useTestItemCategory'
import useTestItemMaster from 'hooks/useTestItemMaster'
import useTestItemsMaster from 'hooks/useTestItemsMaster'
import useTestResult from 'hooks/useTestResult'
import {
  category,
  categoryAndItemCategory,
  dataTableDetail,
  evaluation,
  evaluationCategory,
  generalComment,
  healthGuidance,
  specificHealth,
  syndromeDetermination,
  testNameModel,
  title
} from 'models/resultPreview'
import { infoFixedCategory } from 'pages/InterviewResultCreate/components/InspectionResultCreate'
import {
  getValueCategoryWithMedicalCheckup,
  mergeItemMasterByLanguage
} from 'utilities/helpers'

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  id: any
  listId: []
  data: any
  formatCategory: any
  customer: any
  setId: any
  language: any
}

const ResultPreviewModal = ({
  isModalOpen = false,
  onCancel,
  id,
  listId,
  data,
  formatCategory,
  setId,
  customer,
  language
}: Props) => {
  const { t } = useTranslation('')
  const { getListItemMaster, getListItemMasterByMultipleLanguages } =
    useTestItemMaster()
  const { loadTestItemMasterList } = useTestItemsMaster()

  const [listTestNameData, setListTestNameData] = useState<any>(null)
  const [listEvaluationData, setListEvaluationData] = useState<any>(null)
  const [listEvaluationCategoryData, setListEvaluationCategoryData] =
    useState<any>(null)
  const [listSyndromeDeterminationData, setListSyndromeDeterminationData] =
    useState<any>(null)
  const [listSpecificHealthData, setListSpecificHealthData] =
    useState<any>(null)
  const [listGeneralCommentData, setListGeneralCommentData] =
    useState<any>(null)
  const [listHealthGuidanceData, setListHealthGuidanceData] =
    useState<any>(null)
  const [listDataTable, setListDataTable] = useState<any>(null)
  const [listTitle, setListTitle] = useState<any>(null)
  const [showIconNext, setShowIconNext] = useState<boolean>(true)
  const [PDFFileName, setPDFFileName] = useState<any>(null)
  const [pdfResult, setPdfResult] = useState<any>(null)
  const { getListTestResult } = useTestResult()
  const { getListItemCategory } = useTestItemCategory()
  const [loading, setLoading] = useState<boolean>(false)
  const { tenant } = useTenantList()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const resultListIds: any = []
        if (listId) {
          for (let i = 0; i < listId.length; i++) {
            if (listId[i] === id) {
              if (i < listId.length - 2) {
                setShowIconNext(true)
                resultListIds.push(listId[i], listId[i + 1], listId[i + 2])
                if (i === listId.length - 3) {
                  setShowIconNext(false)
                }
              } else {
                setShowIconNext(false)
                for (let j = i; j < listId.length; j++) {
                  resultListIds.push(listId[j])
                }
              }
            }
          }
        }
        const listTestName: testNameModel[] = []
        const listEvaluation: evaluation[] = []
        const listEvaluationCategory: evaluationCategory[] = []
        const listItemMaster = []
        for (let item of data) {
          for (let id of resultListIds) {
            if (item.key === id) {
              const listCategory: category[] = []
              for (let categoryResult of item.result) {
                listItemMaster.push(categoryResult.idItemMaster)
                // listCategory.push({
                //   id: categoryResult.idItemMaster,
                //   categoryName: categoryResult.categoryName
                // })
              }
              for (let categoryFormatDetail of formatCategory) {
                listCategory.push({
                  id: categoryFormatDetail.refId,
                  categoryName: categoryFormatDetail.lang
                })
              }
              listTestName.push({
                id: item.key,
                title: '一般健康診断コースA',
                categoryName: listCategory
              })
            }
          }
        }

        if (listTestName.length < 3) {
          for (let i = 0; i <= 3 - listTestName.length; i++) {
            listTestName.push({
              id: '',
              title: '',
              categoryName: []
            })
          }
        }
        const uniqueItemMasters = Array.from(new Set(listItemMaster))

        setListTestNameData(listTestName)
        for (let itemMaster of uniqueItemMasters) {
          const list: evaluation[] = []
          let categoryName = ''
          let detailName = ''
          for (let item of data) {
            for (let id of resultListIds) {
              let count = 0
              if (item.key === id) {
                for (let categoryResult of item.result) {
                  if (itemMaster === categoryResult.idItemMaster) {
                    for (let formatIdItemMaster of formatCategory) {
                      if (itemMaster === formatIdItemMaster.idItemMaster) {
                        categoryName = formatIdItemMaster.lang
                        detailName = formatIdItemMaster.lang
                        list.push({
                          id: categoryResult.idTestResult,
                          evaluation: categoryResult.evaluation
                        })
                        count = 1
                      }
                    }
                  }
                }
                if (count === 0) {
                  list.push({
                    id: '',
                    evaluation: ''
                  })
                }
              }
            }
          }
          if (categoryName && detailName) {
            listEvaluationCategory.push({
              idItemMaster: itemMaster,
              categoryName: categoryName,
              detailName: detailName,
              evaluationList: list
            })
          }
        }

        const getListTestResults: any = await getListTestResult({
          filter: `(in,STRING,medicalCheckupRefId,${resultListIds});(eq,STRING,status,PUBLISHED)`,
          page: 0,
          size: -1,
          sortBy: '(desc,createdDate)'
        })

        const testResultsListConvert: any = Array.isArray(
          getListTestResults?.data?.filterTestResult?.payload
        )
          ? getListTestResults.data.filterTestResult.payload
          : []

        const dataPdf: any = await handleDataPdf(
          resultListIds,
          testResultsListConvert,
          formatCategory
        )

        const testResultIds: any = []
        for (let testResult of testResultsListConvert) {
          testResultIds.push(testResult.itemMasterRefId)
        }
        const itemMasterIdEvaluation =
          await getListItemMasterByMultipleLanguages([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'in',
                    value: testResultIds
                  },
                  {
                    field: 'code.coding.code',
                    operator: 'eq',
                    value: '03.0001'
                  }
                ]
              }
            }
          ])

        for (let testItemMaster of itemMasterIdEvaluation) {
          for (let testResult of testResultsListConvert) {
            if (testItemMaster.refId === testResult.itemMasterRefId) {
              listEvaluation.push({
                id: testResult.medicalCheckupRefId,
                evaluation: testResult.value
              })
            }
          }
        }
        const listEvaluationResult: evaluation[] = []
        for (let id of resultListIds) {
          let count = 0
          for (let evaluation of listEvaluation) {
            if (id === evaluation.id) {
              listEvaluationResult.push({
                id: evaluation.id,
                evaluation: evaluation.evaluation
              })
              count = 1
            }
          }
          if (count === 0) {
            listEvaluationResult.push({
              id: id,
              evaluation: ''
            })
          }
        }
        if (listEvaluationResult.length < 3) {
          for (let i = 0; i <= 3 - listEvaluationResult.length; i++) {
            listEvaluationResult.push({
              id: '',
              evaluation: ''
            })
          }
        }

        setListEvaluationData(listEvaluationResult)

        const listSyndromeDetermination: syndromeDetermination[] = []
        const listSyndromeDeterminationResult: syndromeDetermination[] = []
        const itemMasterIdSyndromeDetermination = await loadTestItemMasterList({
          variables: {
            filter: `(in,STRING,refId,${testResultIds});(like,STRING,code.coding.code,03.0004)`,
            page: 0,
            size: -1
          }
        })
        for (let testItemMaster of itemMasterIdSyndromeDetermination?.data
          .filterTestItemMaster.payload) {
          for (let testResult of testResultsListConvert) {
            if (testItemMaster.refId === testResult.itemMasterRefId) {
              listSyndromeDetermination.push({
                id: testResult.medicalCheckupRefId,
                syndrome: testResult.value
              })
            }
          }
        }
        for (let id of resultListIds) {
          let count = 0
          for (let syndromeDetermination of listSyndromeDetermination) {
            if (id === syndromeDetermination.id) {
              listSyndromeDeterminationResult.push({
                id: syndromeDetermination.id,
                syndrome: syndromeDetermination.syndrome
              })
              count = 1
            }
          }
          if (count === 0) {
            listSyndromeDeterminationResult.push({
              id: id,
              syndrome: ''
            })
          }
        }
        setListSyndromeDeterminationData(listSyndromeDeterminationResult)

        const listSpecificHealth: specificHealth[] = []
        const listSpecificHealthResult: specificHealth[] = []
        const itemMasterIdSpecificHealth = await loadTestItemMasterList({
          variables: {
            filter: `(in,STRING,refId,${testResultIds});(like,STRING,code.coding.code,1.00069)`,
            page: 0,
            size: -1
          }
        })
        for (let testItemMaster of itemMasterIdSpecificHealth?.data
          .filterTestItemMaster.payload) {
          for (let testResult of testResultsListConvert) {
            if (testItemMaster.refId === testResult.itemMasterRefId) {
              listSpecificHealth.push({
                id: testResult.medicalCheckupRefId,
                specificHealthDetail: testResult.value
              })
            }
          }
        }
        for (let id of resultListIds) {
          let count = 0
          for (let specificHealth of listSpecificHealth) {
            if (id === specificHealth.id) {
              listSpecificHealthResult.push({
                id: specificHealth.id,
                specificHealthDetail: specificHealth.specificHealthDetail
              })
              count = 1
            }
          }
          if (count === 0) {
            listSpecificHealthResult.push({
              id: id,
              specificHealthDetail: ''
            })
          }
        }
        setListSpecificHealthData(listSpecificHealthResult)

        const listGeneralComment: generalComment[] = []
        const listGeneralCommentResult: generalComment[] = []
        const itemMasterIdGeneralComment =
          await getListItemMasterByMultipleLanguages([
            {
              type: 'MATCH',
              criteria: {
                and: [
                  {
                    field: 'refId',
                    operator: 'in',
                    value: testResultIds
                  },
                  {
                    field: 'code.coding.code',
                    operator: 'eq',
                    value: '03.0152'
                  }
                ]
              }
            }
          ])

        for (let testItemMaster of itemMasterIdGeneralComment) {
          for (let testResult of testResultsListConvert) {
            if (testItemMaster.refId === testResult.itemMasterRefId) {
              listGeneralComment.push({
                id: testResult.medicalCheckupRefId,
                comment: testResult.value
              })
            }
          }
        }
        for (let id of resultListIds) {
          let count = 0
          for (let generalComment of listGeneralComment) {
            if (id === generalComment.id) {
              listGeneralCommentResult.push({
                id: generalComment.id,
                comment: generalComment.comment
              })
              count = 1
            }
          }
          if (count === 0) {
            listGeneralCommentResult.push({
              id: id,
              comment: ''
            })
          }
        }
        if (listGeneralCommentResult.length < 3) {
          for (let i = 0; i <= 3 - listGeneralCommentResult.length; i++) {
            listGeneralCommentResult.push({
              id: '',
              comment: ''
            })
          }
        }
        setListGeneralCommentData(listGeneralCommentResult)

        const itemCategoryNameList = await getListItemCategory({
          filter: `(in,STRING,associatedTestItemMasterRefIds,${uniqueItemMasters});(eq,STRING,status,PUBLISHED)`,
          page: 0,
          size: -1
        })

        const listCategoryAndItemCategory: categoryAndItemCategory[] = []

        for (let uniqueItemMasterId of uniqueItemMasters) {
          let associatedTestItemMasterRefIds: any = []
          for (let itemCategoryName of itemCategoryNameList?.data
            .filterTestItemCategory.payload) {
            for (let itemId of itemCategoryName.associatedTestItemMasterRefIds) {
              if (uniqueItemMasterId === itemId) {
                associatedTestItemMasterRefIds =
                  itemCategoryName.associatedTestItemMasterRefIds
              }
            }
          }
          listCategoryAndItemCategory.push({
            categoryId: uniqueItemMasterId,
            listItemCategory: associatedTestItemMasterRefIds
          })
        }
        const listIdItemMasterConvert: any = []
        for (let item of listCategoryAndItemCategory) {
          for (let id of item.listItemCategory) {
            listIdItemMasterConvert.push(id)
          }
        }

        const dataTable: dataTableDetail[] = []

        for (let formatCategoryDetail of formatCategory) {
          let judgement = ''
          let judgement_2 = ''
          let judgement_3 = ''
          for (let testResult of testResultsListConvert) {
            if (
              formatCategoryDetail.idItemMaster ===
                testResult.itemMasterRefId &&
              testResult.medicalCheckupRefId === resultListIds[0]
            ) {
              judgement = testResult.evaluation
            }
            if (
              formatCategoryDetail.idItemMaster ===
                testResult.itemMasterRefId &&
              testResult.medicalCheckupRefId === resultListIds[1]
            ) {
              judgement_2 = testResult.evaluation
            }
            if (
              formatCategoryDetail.idItemMaster ===
                testResult.itemMasterRefId &&
              testResult.medicalCheckupRefId === resultListIds[2]
            ) {
              judgement_3 = testResult.evaluation
            }
          }
          dataTable.push({
            id: formatCategoryDetail.refId,
            inspectionItem: {
              inspection_item: formatCategoryDetail.lang,
              inspection_item_en: formatCategoryDetail.lang
            },
            unit: '',
            reference_value: '',
            judgement: judgement,
            judgement_2: judgement_2,
            judgement_3: judgement_3,
            active: '1'
          })
          for (let itemResultFormat of formatCategoryDetail.itemMaster) {
            let judgement_result = ''
            let judgement_result_2 = ''
            let judgement_result_3 = ''
            for (let testResult of testResultsListConvert) {
              if (
                itemResultFormat.refId === testResult.itemMasterRefId &&
                testResult.medicalCheckupRefId === resultListIds[0]
              ) {
                judgement_result = testResult.value
              } else {
              }
              if (
                itemResultFormat.refId === testResult.itemMasterRefId &&
                testResult.medicalCheckupRefId === resultListIds[1]
              ) {
                judgement_result_2 = testResult.value
              }
              if (
                itemResultFormat.refId === testResult.itemMasterRefId &&
                testResult.medicalCheckupRefId === resultListIds[2]
              ) {
                judgement_result_3 = testResult.value
              }
            }

            dataTable.push({
              id: itemResultFormat.refId,
              inspectionItem: {
                inspection_item: itemResultFormat.lang,
                inspection_item_en: itemResultFormat.lang
              },
              unit: itemResultFormat.unitOfMeasure,
              reference_value: '',
              judgement: judgement_result,
              judgement_2: judgement_result_2,
              judgement_3: judgement_result_3,
              active: '0'
            })
          }
        }
        const listEvaluationCategoryResult: evaluationCategory[] = []
        for (let categoryFormatDetail of formatCategory) {
          let listEvaluation: evaluation[] = []
          for (let dataTableDetail of dataTable) {
            if (categoryFormatDetail.refId === dataTableDetail.id) {
              listEvaluation.push({
                id: resultListIds[0],
                evaluation: dataTableDetail.judgement
              })
              listEvaluation.push({
                id: resultListIds[1],
                evaluation: dataTableDetail.judgement_2
              })
              listEvaluation.push({
                id: resultListIds[2],
                evaluation: dataTableDetail.judgement_3
              })
            }
          }
          listEvaluationCategoryResult.push({
            idItemMaster: categoryFormatDetail.refId,
            categoryName: categoryFormatDetail.lang,
            detailName: categoryFormatDetail.lang,
            evaluationList: listEvaluation
          })
        }
        setListEvaluationCategoryData(listEvaluationCategoryResult)
        setListDataTable(dataTable)
        const listHealthGuidance: healthGuidance[] = []
        const itemMasterIdHealthGuidance = await loadTestItemMasterList({
          variables: {
            filter: `(in,STRING,refId,${testResultIds});(like,STRING,code.coding.code,03.0005);(like,STRING,additionalInfo.key1,カテゴリマスタ)`,
            page: 0,
            size: -1
          }
        })
        let anwserHealthGuidance = ''
        for (let testItemMaster of itemMasterIdHealthGuidance?.data
          .filterTestItemMaster.payload) {
          for (let testResult of testResultsListConvert) {
            if (
              testItemMaster.refId === testResult.itemMasterRefId &&
              testResult.medicalCheckupRefId === resultListIds[0]
            ) {
              anwserHealthGuidance = testResult.value
            }
          }
        }
        listHealthGuidance.push({
          title: t('pdfExport.healthGuidance'),
          sub_title: 'Health Guidance',
          anwser: anwserHealthGuidance
        })
        setListHealthGuidanceData(listHealthGuidance)

        const itemMasterIdReports = await loadTestItemMasterList({
          variables: {
            filter: `(like,STRING,code.coding.code,1.00246);(like,STRING,additionalInfo.key1,基本情報マスター)`,
            page: 0,
            size: -1
          }
        })
        const titleList: title[] = []
        const titleListResult: any = []
        for (let testResult of testResultsListConvert) {
          if (
            itemMasterIdReports.data.filterTestItemMaster.payload?.[0].refId ===
            testResult.itemMasterRefId
          ) {
            titleList.push({
              id: testResult.medicalCheckupRefId,
              date: '',
              report_id: testResult.value
            })
          }
        }

        for (let idMedicalCheckup of resultListIds) {
          let consutantDate = ''
          let report_id = ''
          let count = 0
          for (let medicalCheckup of data) {
            if (medicalCheckup.id === idMedicalCheckup) {
              consutantDate = medicalCheckup.consultationDate
            }
          }
          for (let titleDetail of titleList) {
            if (idMedicalCheckup === titleDetail.id) {
              report_id = titleDetail.report_id ?? ''
              count = 1
            }
          }
          if (count === 0) {
            titleListResult.push({
              id: idMedicalCheckup,
              date: consutantDate,
              report_id: ''
            })
          } else {
            titleListResult.push({
              id: idMedicalCheckup,
              date: consutantDate,
              report_id: report_id
            })
          }
        }
        if (titleListResult.length < 3) {
          for (let i = 0; i <= 3 - titleListResult.length; i++) {
            titleListResult.push({
              id: '',
              date: '',
              report_id: null
            })
          }
        }
        setListTitle(titleListResult)

        let pdfResult: any = {
          customer: customer,
          data: dataPdf,
          category: formatCategory
        }

        setPdfResult(pdfResult)
        const currentDate = new Date()
        const currentTimeString = currentDate
          .toLocaleString()
          .replace(/\s/g, '')
        let fileNamePDF = `${customer?.patientId}-${currentTimeString}.pdf`
        setPDFFileName(fileNamePDF)
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }

    if (listId && id && data && data.length > 0 && formatCategory) {
      fetchData()
    }
  }, [listId, data, id, formatCategory])

  const handleDataPdf = async (
    medicalCheckupIds: any,
    testResults: any,
    dataCategory: any
  ) => {
    let result: any = []
    const infoFixedCategory = [
      {
        code: '03.0001',
        name: 'comprehensiveJudgment'
      },
      {
        code: '03.0152',
        name: 'generalComments'
      },
      {
        code: '03.0004',
        name: 'metabolicSyndromeAssessment'
      },
      {
        code: '1.00069',
        name: 'specificHealthGuidance'
      }
    ]
    let fixedDataCategory: any = await getFixedDataCategory()

    for (let i = 0; i < 3; i++) {
      if (!medicalCheckupIds?.[i]) {
        result.push({})
        continue
      }

      let medicalCheckup = data.find(
        (item: any) => item.id === medicalCheckupIds[i]
      )

      let medicalCheckupResult: any = {
        id: medicalCheckupIds[i],
        consultationDate: medicalCheckup?.consultationDate,
        doctorName: medicalCheckup?.doctorName,
        evaluation: medicalCheckup?.evaluation,
        category: getValueCategoryWithMedicalCheckup(
          medicalCheckupIds[i],
          dataCategory,
          testResults
        )
      }

      //fixedCategory
      for (let fixedCategory of infoFixedCategory) {
        let find = testResults.find(
          (item: any) =>
            medicalCheckupIds[i] === item?.medicalCheckupRefId &&
            fixedDataCategory?.[fixedCategory.code]?.refId ===
              item?.itemMasterRefId
        )

        medicalCheckupResult[fixedCategory.name] = find?.value
      }

      result.push(medicalCheckupResult)
    }

    return result
  }

  const getFixedDataCategory = async () => {
    const arrCodes = [...infoFixedCategory]
    const codesString = arrCodes.map((item) => item.code).join(',')

    const dataFixedCategory = await getListItemMaster({
      filter: `(in,STRING,code.coding.code,${codesString});(eq,STRING,status,PUBLISHED)`
    })

    let itemMaster = Array.isArray(
      dataFixedCategory?.data?.filterTestItemMaster?.payload
    )
      ? dataFixedCategory?.data?.filterTestItemMaster?.payload
      : []

    itemMaster = mergeItemMasterByLanguage(itemMaster)

    const dataCategoryByCode = itemMaster.reduce((data: any, item: any) => {
      if (item?.code?.coding?.[0]?.code) {
        data[item?.code?.coding?.[0]?.code] = item
      }
      return data
    }, {})

    return dataCategoryByCode
  }

  const handleOk = () => {
    //todo
  }

  const handlePdfDownload = () => {
    const currentDate = new Date()
    const currentTimeString = currentDate.toLocaleString().replace(/\s/g, '')
    let fileNamePDF = `${customer?.patientId}-${currentTimeString}.pdf`
    return fileNamePDF
  }

  return (
    <Modal
      footer={null}
      closeIcon={null}
      centered
      className="modal"
      maskClosable={true}
      style={{ padding: 10 }}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={onCancel}
      width={1900}
    >
      {loading && (
        <Flex className="items-center justify-center min-h-[500px]">
          <Spin />
        </Flex>
      )}
      {!loading && (
        <div id="pdf-content">
          <Flex vertical className="ml-[5px] mr-[5px]">
            <Flex className="w-full pl-[10px] mt-[5px] ">
              <Flex className="flex justify-end w-full">
                <button
                  className="w-[23px] h-[21px] text-black border border-gray-600 flex items-center justify-center text-[18px] pb-[4px]"
                  onClick={onCancel}
                >
                  x
                </button>
              </Flex>
            </Flex>
            <Flex className="w-full pl-[10px] mt-[5px]">
              <Flex className="w-[50%]">
                <Typography className="w-full pl-[14px] font-bold text-[16px]">
                  {customer?.id}　/　{customer?.firstName + customer?.lastName}
                </Typography>
              </Flex>
              <Flex className="w-[50%] justify-end">
                <PDFDownloadLink
                  className="w-[120px] bg-gradient-to-r from-[#327790] to-[#97C9CC] ml-[20px] text-[#ffffff]"
                  document={
                    pdfResult && (
                      <ResultPreviewPdf pdfResult={pdfResult} tenant={tenant} />
                    )
                  }
                  fileName={handlePdfDownload()}
                >
                  <button className="w-[12%] ml-[30px] mt-[2px] text-[#ffffff]">
                    {t('button.export_PDF')}
                  </button>
                </PDFDownloadLink>

                <button className="hidden w-[120px] ml-[15px] bg-gradient-to-r from-[#327790] to-[#97C9CC] text-[#ffffff]">
                  {t('button.export_CSV')}
                </button>
              </Flex>
            </Flex>
            <Flex className="h-[40px] w-full mt-[10px] bg-gradient-to-r from-[#93D1CA] to-[#127594] text-[#ffffff] text-[16px]">
              <div className="w-[25%] flex justify-between">
                <div>
                  <span className="font-bold"></span>
                </div>
                <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
              </div>
              {listTitle?.[0] && (
                <div className="w-[25%] flex justify-between">
                  <div className="w-[99%] mt-[7px] ml-[11px] mr-[11px] flex justify-between">
                    <span className="font-bold">
                      {listTitle[0].date.replace(/-/g, '/')}
                    </span>
                    <span className="font-bold">
                      {listTitle[0].report_id !== null
                        ? t('lable.reportNo') + listTitle[0].report_id
                        : ''}
                    </span>
                  </div>
                  <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
                </div>
              )}
              {listTitle?.[1] && (
                <div className="w-[25%] flex justify-between">
                  <div className="w-[99%] mt-[7px] ml-[11px] mr-[11px] flex justify-between">
                    <span className="font-bold">
                      {listTitle[1].date.replace(/-/g, '/')}
                    </span>
                    <span className="font-bold">
                      {listTitle[1].report_id !== null
                        ? t('lable.reportNo') + listTitle[1].report_id
                        : ''}
                    </span>
                  </div>
                  <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
                </div>
              )}
              {listTitle?.[2] && (
                <div className="w-[25%] flex justify-between">
                  <div className="w-[99%] mt-[7px] ml-[11px] mr-[11px] flex justify-between">
                    <div>
                      <span className="font-bold">
                        {listTitle[2].date.replace(/-/g, '/')}
                      </span>
                    </div>
                    <div className="flex justify-center">
                      <span className="font-bold">
                        {listTitle[2].report_id !== null
                          ? t('lable.reportNo') + listTitle[2].report_id
                          : ''}
                      </span>
                      {showIconNext && (
                        <NextIcon
                          className="ml-1 mt-[4px] cursor-pointer"
                          onClick={() => {
                            const index: string = id
                            const listIdCoppy: string[] = [...listId]
                            let idx = listIdCoppy.indexOf(index)
                            setId(listId[idx + 3])
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-[1px] h-full bg-[#BFC6CB]"></div>
                </div>
              )}
            </Flex>
            {listTestNameData && (
              <TestName listTestName={listTestNameData} language={language} />
            )}
            {listEvaluationData && (
              <ComprehensiveJudgment listEvaluation={listEvaluationData} />
            )}
            {/* {listSyndromeDeterminationData && (
            <SyndromeDetermination
              listSyndromeDetermination={listSyndromeDeterminationData}
            />
          )}
          {listSpecificHealthData && (
            <SpecificHealth listSpecificHealth={listSpecificHealthData} />
          )} */}
            {listGeneralCommentData && (
              <GeneralComment listGeneralComment={listGeneralCommentData} />
            )}
            {listEvaluationCategoryData && (
              <JudgmentCategory
                listCategory={listEvaluationCategoryData}
                language={language}
              />
            )}
            <Flex className="flex mt-[10px]">
              {listDataTable && (
                <DataTestTable
                  listDataTable={listDataTable}
                  language={language}
                />
              )}
            </Flex>
            {/* <HealthStatusReport />
          <LifeStyleReport /> */}
            {/* {listHealthGuidanceData && (
            <HealthGuidance listHealthGuidance={listHealthGuidanceData} />
          )} */}
          </Flex>
        </div>
      )}
    </Modal>
  )
}

export default ResultPreviewModal
