import React from 'react'

import { Flex, Typography } from 'antd'

import { FunctionEditor } from './FunctionEditor'
import { FunctionEvaluate } from './FunctionEvaluate'
import { FunctionReader } from './FunctionReader'

export default function RuleLogical() {
  return (
    <Flex vertical gap="large">
      <Typography className="text-[#545454] text-[16px] font-bold">
        Function FPG
      </Typography>

      <Flex gap="middle" className="size-full">
        <FunctionEditor />

        <Flex vertical className="size-full">
          <FunctionEvaluate />
          <FunctionReader />
        </Flex>
      </Flex>
    </Flex>
  )
}
