export type Customer = {
  id: string
  name: string
  dateOfBirth: string
  age: number
  gender: string
  dateOfExam: string
  resultExam: number
  doctorName: string
  lastUpdateDate: string | null
}
export interface result {
  categoryName: string
  comment: string
}
export type CustomerDetailInputTable = {
  key: React.Key
  id: string
  consultationDate: string
  registrationDate: string
  result: result[]
  doctorName: string
}

export enum TAB {
  BASIC_INFORMATION = '1',
  RESULT_LIST = '2',
  INTERVIEW_LIST = '3'
}

export enum GENDER {
  Male = 'MALE',
  Female = 'FEMALE'
}

export enum RECORD_COUNT {
  Fifty = 50,
  OneHundred = 100,
  TwoHundred = 200,
  FiveHundred = 500
}

export type InputForm = {
  id: string
  patientId?: string
  avatar?: string
  firstName: string
  lastName: string
  firstNameKana?: string
  lastNameKana?: string
  fullName?: string
  gender: string
  birthday: string
  zipCode?: string
  country?: string
  apartment?: string
  stateName?: string
  province?: string
  district?: string
  detailedAddress?: string
  dialCodePhone: string
  phone?: string
  dialCodeEmergencyContact: string
  emergencyContact?: string
  email?: string
  typeOfInsurance?: string
  numberOfInsurance?: string
  insuranceStartDate?: string
  insuranceEndDate?: string
  insuranceSymbol?: string
  insuranceNumber?: string
  collaborativeApplication?: string
  userID?: string
  applicationStartDate?: string
  note?: string
  address?: string
}
