import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, Flex, Typography } from 'antd'

import { ReactComponent as ExclamationCircleFilled } from 'assets/svgs/exclamationCircleFilled.svg'
import CustomModal from 'components/modals'
import {
  MEDICAL_CHECKUP_STATUS,
  MEDICAL_CHECKUP_STATUS_TEXT
} from 'configs/constant'
import { isNumber } from 'utilities/helpers'

const { Paragraph } = Typography

type Props = {
  isModalOpen: boolean
  onCancel: () => void
  onOk: () => void
  data: any
  handleCreateMedicalCheckup: any
  status: any
}

const TemporarySaveModal = ({
  isModalOpen = false,
  onCancel,
  onOk,
  data,
  handleCreateMedicalCheckup,
  status
}: Props) => {
  const { t } = useTranslation('')
  const [saveState, setSaveState] = useState<any>(false)
  const [changeStatus, setChangeStatus] = useState<any>(
    MEDICAL_CHECKUP_STATUS.RESULTS_IN_PROGRESS
  )
  const [showChangeStatus, setShowChangeStatus] = useState<boolean>(true)

  const handleOk = async () => {
    if (saveState) {
      await handleCreateMedicalCheckup(
        data,
        MEDICAL_CHECKUP_STATUS.NOT_COMPATIBLE
      )
    } else {
      await handleCreateMedicalCheckup(data, changeStatus)
    }

    onOk()
  }

  useEffect(() => {
    if (isNumber(status)) {
      switch (status) {
        case MEDICAL_CHECKUP_STATUS.NOT_COMPATIBLE:
          setChangeStatus(MEDICAL_CHECKUP_STATUS.RESULTS_IN_PROGRESS)
          break
        default:
          setChangeStatus(status)
          setShowChangeStatus(false)
      }
    }
  }, [status])

  return (
    <CustomModal open={isModalOpen} onCancel={onCancel} onOk={handleOk}>
      <div className="text-center pt-4 px-2 pb-10">
        <Typography className="text-xl font-bold tracking-[1px] leading-none">
          {t('button.saveDraft')}
        </Typography>

        <ExclamationCircleFilled className="mt-[63px] m-auto w-[40px] text-error" />
        <Paragraph className="mt-[23px] mb-0 leading-none tracking-[.7px]">
          {t('content.savingContentTemporarily')}
        </Paragraph>
        <Paragraph className="mt-[7px] mb-0 leading-none tracking-[.7px]">
          {t('content.isThatOkay')}
        </Paragraph>

        {showChangeStatus && (
          <>
            <Paragraph className="mt-[30px] mb-0 leading-none text-error tracking-[.7px] whitespace-break-spaces">
              {t('content.autoStatusChange', {
                status1: t(
                  MEDICAL_CHECKUP_STATUS_TEXT[
                    MEDICAL_CHECKUP_STATUS.NOT_COMPATIBLE
                  ]
                ),
                status2: t(MEDICAL_CHECKUP_STATUS_TEXT[changeStatus])
              })}
            </Paragraph>
            <Checkbox
              onChange={(e) => setSaveState(e.target.checked)}
              className="flex justify-center items-center mt-[62px] leading-none"
            >
              {t('content.saveWithoutStatus')}
            </Checkbox>
          </>
        )}

        <Button
          type="primary"
          className={`w-[180px] font-bold ${showChangeStatus ? 'mt-[37px]' : 'mt-24'} tracking-[.7px]`}
          htmlType="submit"
          autoInsertSpace={false}
          onClick={handleOk}
        >
          {t('button.keep')}
        </Button>
      </div>
    </CustomModal>
  )
}

export default TemporarySaveModal
